import React, { useEffect, useState } from "react";
import { ChevronLeft, LayoutDashboard } from "lucide-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PlanboardTitle from "./PlanboardTitle";
import PlanboardAvatarGroup from "./PlanboardAvatarGroup";
import PlanboardEndDate from "./PlanboardEndDate";
import { ROUTES } from "../../constants";
import PlanboardsAPI from "../../api/services/Planboard";
import { useDispatch } from "react-redux";
import { resetPlanboardState } from "../../redux/slice/planboard";
import UserRole from "./UserRole";

const PlanboardDesignerHeader = ({ planboard }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [members, setMembers] = useState([]);

  const handleBack = () => {
    setTimeout(() => dispatch(resetPlanboardState()), 2000);
    // dispatch(resetPlanboardState());
    if (location?.state?.from?.pathname == ROUTES.enterPlanboardName) {
      navigate(ROUTES.dashboard);
    } else if (location?.state?.from?.pathname) {
      navigate(location.state.from.pathname);
    } else {
      navigate(ROUTES.dashboard);
    }
  };
  const getTeamMembers = async () => {
    if (id === undefined) return;
    try {
      const response = await PlanboardsAPI.getMembers(id);
      console.log({ response });
      if (response.status === 200) {
        return setMembers(response.data.data);
      }
    } catch (error) {
      if (error.response.status === 404) setMembers([]);
    }
  };
  useEffect(() => {
    getTeamMembers();
  }, [navigate]);

  return (
    <header className="flex flex-wrap sm:justify-start sm:flex-nowrap w-full bg-white text-sm py-3 sm:py-0 dark:bg-slate-800 ">
      <nav
        className="relative max-w-8xl w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex items-center justify-between">
          <a
            className="flex-none text-xl font-semibold dark:text-white flex"
            aria-label="Brand"
          >
            <span className="flex items-center">
              <button
                onClick={handleBack}
                className="hover:bg-gray-100 border rounded-md p-1 dark:hover:text-slate-900"
              >
                <ChevronLeft />
              </button>
              <span className="ms-5 text-primary-400 dark:text-white">
                <LayoutDashboard size={32} strokeWidth={1} />
              </span>
              <span className="ml-2 pb-0.5">
                {planboard?.name ? (
                  // <PlanboardTitle
                  //   planboard={{
                  //     name: planboard.name,
                  //     _id: planboard._id,
                  //     workspaceId: planboard.workspaceId,
                  //   }}
                  // />
                  <>{planboard.name}</>
                ) : (
                  "Planboard Title"
                )}
              </span>
            </span>
          </a>
        </div>

        <div
          id="navbar-collapse-with-animation"
          className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block"
        >
          <div className="flex flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row p-4 sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:ps-7">
            {/* <span className="italic">&nbsp;({planboard?.userRole})</span> */}
            <UserRole role={planboard?.userRole} />
            <PlanboardEndDate
              progress={planboard.progress}
              daysLeft={planboard.daysLeft}
              endDate={planboard.endDate}
            />
            <PlanboardAvatarGroup members={members} />
            {/* <UserMenu /> */}
          </div>
        </div>
      </nav>
    </header>
  );
};
export default PlanboardDesignerHeader;
