import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Users1API from '../../api/services/User1';
import { useToast } from '../../hooks';
import { useDispatch } from 'react-redux';

const Password = ({ user }) => {
    const validationSchema = Yup.object({
        oldPassword: Yup.string()
            .required('Old password is required')
            .matches(/((?=.*\d.*\d.*\d)(?=.*[a-z].*[a-z].*[a-z])(?=.*[A-Z].*[A-Z].*[A-Z])(?=.*[!@#$&*]).{10,128})/, 'Password must contain at least three digits, three lowercase letters, three uppercase letters, and one special character')
            .min(10, 'Password must be between 10 and 128 characters long')
            .max(128, 'Password must be between 10 and 128 characters long'),
        newPassword: Yup.string()
            .required('New password is required')
            .matches(/((?=.*\d.*\d.*\d)(?=.*[a-z].*[a-z].*[a-z])(?=.*[A-Z].*[A-Z].*[A-Z])(?=.*[!@#$&*]).{10,128})/, 'Password must contain at least three digits, three lowercase letters, three uppercase letters, and one special character')
            .min(10, 'Password must be between 10 and 128 characters long')
            .max(128, 'Password must be between 10 and 128 characters long'),
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Please confirm your new password')
            .matches(/((?=.*\d.*\d.*\d)(?=.*[a-z].*[a-z].*[a-z])(?=.*[A-Z].*[A-Z].*[A-Z])(?=.*[!@#$&*]).{10,128})/, 'Password must contain at least three digits, three lowercase letters, three uppercase letters, and one special character')
            .min(10, 'Password must be between 10 and 128 characters long')
            .max(128, 'Password must be between 10 and 128 characters long'),
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        },
        validationSchema,
        onSubmit: handlePasswordSubmit,
    });

    const [google, setGoogle] = useState(false);
    const toast = useToast();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user.provider === "google" && user.passwordCreatedOn === null) {
            setGoogle(true);
            formik.setFieldValue('oldPassword', 'dummy');
        }
    }, [user]);

    async function handlePasswordSubmit() {

        if (google) {
            try {
                const response = await Users1API.setPassword(formik.values.newPassword);
                formik.resetForm();
                toast.success(response.data.data);
            } catch (error) {
                toast.error(error.response.data.data);
                formik.resetForm();

            }
        } else {
            try {
                const response = await Users1API.changePassword(formik.values.oldPassword, formik.values.newPassword);
                formik.resetForm();
                toast.success(response.data.data);
            } catch (error) {
                toast.error(error.response.data.data)
                formik.resetForm();

            }
        }

    };

    return (
        <div className="py-6 sm:py-8 space-y-5 border-t border-gray-200 first:border-t-0 dark:border-neutral-500">
            <div className="inline-flex items-center gap-x-2">
                <h2 className="font-semibold text-gray-800 dark:text-neutral-200">Password</h2>
                <div className="hs-tooltip inline-block">
                    <svg className="hs-tooltip-toggle flex-shrink-0 ms-1 size-3 text-gray-500 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    <div className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-[60] p-4 w-96 bg-white rounded-xl shadow-xl dark:bg-neutral-900 dark:text-neutral-400" role="tooltip">
                        <p className="font-medium text-gray-800 dark:text-neutral-200">Password requirements:</p>
                        <p className="mt-1 text-sm font-normal text-gray-500 dark:text-neutral-400">Ensure that these requirements are met:</p>
                        <ul className="mt-1 ps-3.5 list-disc list-outside text-sm font-normal text-gray-500 dark:text-neutral-400">
                            <li>Password must be between 10 and 128 characters long,</li>
                            <li>At least three lowercase characters,</li>
                            <li>At least three uppercase characters,</li>
                            <li>At least three digits and a special character.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <form onSubmit={formik.handleSubmit}>
                {!google && (<div className="w-full grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                    <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
                        <label htmlFor="oldPassword" className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-400">
                            Current password
                        </label>
                    </div>

                    <div className="sm:col-span-8 xl:col-span-4">
                        <div className="relative">
                            <input id="hs-pro-dappcp"
                                type="text"
                                name="oldPassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.oldPassword}
                                className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 border focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-500 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600" placeholder="Enter current password" />
                            <button type="button" data-hs-toggle-password='{
                                    "target": "#hs-pro-dappcp"
                                  }' class="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500">
                                <svg class="flex-shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path class="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24" />
                                    <path class="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68" />
                                    <path class="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61" />
                                    <line class="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22" />
                                    <path class="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                                    <circle class="hidden hs-password-active:block" cx="12" cy="12" r="3" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div class="sm:col-span-10 xl:col-span-6">
                        {formik.touched.oldPassword && formik.errors.oldPassword ? (
                            <div className="text-red-600">{formik.errors.oldPassword}</div>
                        ) : null}
                    </div>
                </div>)}

                <div className="w-full grid mt-2 sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                    <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
                        <label for="hs-pro-dappnp" className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-400">
                            New password
                        </label>
                    </div>

                    <div className="sm:col-span-8 xl:col-span-4">
                        <div className="relative">
                            <input
                                id="hs-pro-dappnp"
                                type="text"
                                name="newPassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.newPassword}
                                disabled={!formik.values.oldPassword && !google}
                                className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent border dark:border-neutral-500 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                                placeholder="Enter new password"
                            />
                            <button type="button" data-hs-toggle-password='{
                "target": "#hs-pro-dappnp"
              }' class="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500">
                                <svg class="flex-shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path class="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24" />
                                    <path class="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68" />
                                    <path class="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61" />
                                    <line class="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22" />
                                    <path class="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                                    <circle class="hidden hs-password-active:block" cx="12" cy="12" r="3" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div class="sm:col-span-10 xl:col-span-6">
                        {formik.touched.newPassword && formik.errors.newPassword ? (
                            <div className="text-red-600">{formik.errors.newPassword}</div>
                        ) : null}
                    </div>
                </div>

                <div className="w-full grid mt-2 sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                    <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2" style={{ visibility: 'hidden' }}>
                        <label for="hs-pro-dapprnp" className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-500">
                            Confirm new password
                        </label>
                    </div>

                    <div className="sm:col-span-8 xl:col-span-4">
                        <div className="relative">
                            <input
                                id="hs-pro-dapprnp"
                                type="text"
                                name="confirmNewPassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmNewPassword}
                                disabled={!formik.values.oldPassword && !google}
                                className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 border disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-500 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                                placeholder="Repeat new password"
                            />  <button type="button" data-hs-toggle-password='{
                                "target": "#hs-pro-dapprnp"
                              }' class="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500">
                                <svg class="flex-shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path class="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24" />
                                    <path class="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68" />
                                    <path class="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61" />
                                    <line class="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22" />
                                    <path class="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                                    <circle class="hidden hs-password-active:block" cx="12" cy="12" r="3" />
                                </svg>
                            </button>
                        </div>
                    </div>  <div class="sm:col-span-10 xl:col-span-6">
                        {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? (
                            <div className="text-red-600">{formik.errors.confirmNewPassword}</div>
                        ) : null}
                    </div>

                </div>

                <div className="w-full grid mt-2 sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
                    <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2"></div>
                    <div className="sm:col-span-8 xl:col-span-4 mt-2">
                        <button type="submit" disabled={!formik.isValid || formik.isSubmitting || !formik.values.oldPassword} class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500">
                            Save changes
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Password;
