import { URLS } from "../../constants/index.jsx";
import apiClient from "../client";

const ResetPasswordAPI = {
  forgot: ({ email }: { email: string }) =>
    apiClient.post(URLS.forgotPassword, { email }),
  validate: ({ id, token }: { id: string; token: string }) =>
    apiClient.post(URLS.validatePassword, { id, token }),
  reset: ({
    userId,
    token,
    password,
  }: {
    userId: string;
    token: string;
    password: string;
  }) => apiClient.post(URLS.resetPassword, { userId, token, password }),
};

export default ResetPasswordAPI;
