import React from "react";
import BrandLogo from "../BrandLogo";
import BuildIcon from "../../assets/icons/Build.svg";
import ExecuteIcon from "../../assets/icons/Execute.svg";
import CollaborateIcon from "../../assets/icons/Collaborate.svg";

const Sidebar = () => {
  return (
    <div className="hidden min-h-screen lg:w-[400px] xl:w-[430px] bg-sky-100 lg:flex flex-col p-6 dark:bg-slate-950">
      {/* <!-- Header --> */}
      <div className="flex justify-center items-center">
        <BrandLogo collapsed={false} />
      </div>
      {/* <!-- End Header --> */}

      {/* <!-- Body --> */}
      <div className="h-full flex flex-col justify-center gap-y-6 dark:text-gray-300">
        <div className="flex space-x-3 p-3 bg-blue-100 rounded-lg dark:bg-slate-900">
          <img
            src={BuildIcon}
            className="w-20 bg-gray-100 rounded-full"
            alt="Build"
          />
          <div className="">
            <div className="text-lg font-semibold">Build Strategy</div>
            <div className="font-thin text-sm mt-2">
              Unveil superpowers of AI and build the strategic plans in minutes
            </div>
          </div>
        </div>
        <div className="flex space-x-3 p-3 bg-blue-100 rounded-lg dark:bg-slate-900">
          <img
            src={CollaborateIcon}
            className="w-20 bg-gray-100 rounded-full"
            alt="Build"
          />
          <div className="">
            <div className="text-lg font-semibold">Collaborate</div>
            <div className="font-thin text-sm mt-2">
              Ignite strategic collaboration with the team
            </div>
          </div>
        </div>
        <div className="flex space-x-3 p-3 bg-blue-100 rounded-lg dark:bg-slate-900">
          <img
            src={ExecuteIcon}
            className="w-20 bg-gray-100 rounded-full"
            alt="Build"
          />
          <div className="">
            <div className="text-lg font-semibold">Execute</div>
            <div className="font-thin text-sm mt-2">
              Execute strategies and dig deeper to ensure success
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Body --> */}
    </div>
  );
};

export default Sidebar;
