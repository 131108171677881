import React, { useEffect, useState } from "react";
import Sidebar from "../components/CreatePlanboardSteps/Sidebar";
import Step1 from "../components/CreatePlanboardSteps/Step1";
import Step2 from "../components/CreatePlanboardSteps/Step2";
import Step3 from "../components/CreatePlanboardSteps/Step3";
import CreatePlanboardHeader2 from "../components/CreatePlanboardSteps/CreatePlanboardHeader2";
import PlanboardsAPI from "../api/services/Planboard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../constants";
import { formatDateToDateString } from "../utils/dateTime";
import LoadingDrawer from "../components/CreatePlanboardSteps/LoadingDrawer";
import DrawerLoading from "../components/CreatePlanboardSteps/DrawerLoading";

interface Node {
  title: string;
  description: string;
  selected: boolean;
}

interface PlanboardData {
  attachments: File[];
  comment: string;
  defaultBusiness: boolean;
  description: string;
  domain: { id?: string };
  department: { id?: string };
  role: { id?: string };
  endDate: string;
  schedule: {
    meetingTypeId: string;
    date: string;
    start: string;
    end: string;
  };
  geography: string;
  members: {}[];
  name: string;
  notify: boolean;
  scope: string;
  subdomain: { id?: string };
  workspaceId: string;
  scheduleEvent: boolean;
}

const initialPlanboardData = {
  attachments: [],
  comment: "",
  defaultBusiness: false,
  description: "",
  domain: {},
  endDate: "",
  schedule: {
    meetingTypeId: "",
    date: "",
    start: "",
    end: "",
  },
  geography: "",
  members: [],
  name: "",
  notify: false,
  scope: "",
  subdomain: {},
  workspaceId: "",
  scheduleEvent: false,
  department: {},
  role: {},
};

const CreatePlanboard = () => {
  const [planboardData, setPlanboardData] =
    useState<PlanboardData>(initialPlanboardData);
  const [activeStep, setActiveStep] = useState(1);
  const [error, setError] = useState<null | string>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const updateStepData = (data: any) => {
    setPlanboardData((prev) => {
      return {
        ...prev,
        ...data,
      };
    });
  };

  const handleCreatePlanboard = async (values: {}) => {
    let newPlanboardData: PlanboardData = { ...planboardData, ...values };
    try {
      const planboardId = sessionStorage.getItem("planboardId");
      let createData: any = {
        description: newPlanboardData.description,
        domainId: newPlanboardData.domain?.id,
        endDate: formatDateToDateString(new Date(newPlanboardData.endDate)),
        geography: newPlanboardData.geography,
        markAsDefault: newPlanboardData.defaultBusiness,
        members: newPlanboardData.members,
        name: newPlanboardData.name,
        planboardId: planboardId || "undefined",
        remark: newPlanboardData.comment,
        scope: newPlanboardData.scope,
        subdomainId: newPlanboardData.subdomain?.id,
        workspaceId: newPlanboardData.workspaceId,
        departmentId: newPlanboardData?.department?.id,
        roleId: newPlanboardData?.role?.id,
      };
      if (newPlanboardData.scheduleEvent) {
        createData.schedule = {
          meetingTypeId: newPlanboardData.schedule.meetingTypeId,
          date: formatDateToDateString(
            new Date(newPlanboardData.schedule.date)
          ),
          start: newPlanboardData.schedule.start,
          end: newPlanboardData.schedule.end,
        };
      }
      if (newPlanboardData.attachments.length > 0) {
        createData.attachments = newPlanboardData.attachments;
      }
      console.log({ createData });
      const response = await PlanboardsAPI.create(createData);
      if (response.data.error) {
        return setError(
          response?.data?.data ??
            "An error occured while creating the planboard."
        );
      }
      if (response.status === 200) {
        // setOpenDrawer(response.data.data.planboardId);
        // setOpenDrawer(true);
        return navigate(
          ROUTES.planboardDesigner + "/" + response.data.data.planboardId,
          { replace: true, state: { from: location.state.from } }
        );
      }
    } catch (error: any) {
      if (error.response.status === 500)
        return setError("Internal Server Error.");
      return setError(error?.response?.data?.data ?? "Error");
    }
  };

  const saveStateToLocalStorage = () => {
    localStorage.setItem(
      "routeState",
      JSON.stringify(location.state?.from?.pathname ?? "/dashboard")
    );
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const workspace = searchParams.get("workspace");

    if (workspace !== null && workspace !== undefined)
      setPlanboardData((prev) => ({ ...prev, workspaceId: workspace }));
    window.addEventListener("beforeunload", saveStateToLocalStorage);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", saveStateToLocalStorage);
    };
  }, []);

  useEffect(() => {
    console.log({ planboardData });
  }, [planboardData]);

  return (
    <div className="flex">
      <Sidebar />
      <main
        className="flex flex-col max-h-screen w-full"
        data-hs-stepper={`{"currentIndex":${activeStep}}`}
      >
        {/* <CreatePlanboardHeader isError={isError} /> */}
        <CreatePlanboardHeader2 activeStep={activeStep} />
        <div className="h-[90%] flex justify-center dark:bg-slate-800">
          <div className="w-full relative flex justify-center grow">
            <div id="content" className="w-full overflow-auto px-5 pb-10">
              <div style={{ display: activeStep === 1 ? "" : "none" }}>
                <Step1
                  planboardData={planboardData}
                  updateStepData={updateStepData}
                  setActiveStep={setActiveStep}
                />
              </div>
              <div style={{ display: activeStep === 2 ? "" : "none" }}>
                <Step2
                  planboardData={planboardData}
                  updateStepData={updateStepData}
                  setActiveStep={setActiveStep}
                  handleCreatePlanboard={handleCreatePlanboard}
                />
              </div>
              <div style={{ display: activeStep === 3 ? "" : "none" }}>
                <Step3
                  updateStepData={updateStepData}
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  handleCreatePlanboard={handleCreatePlanboard}
                  error={error}
                  planboardData={planboardData}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <!-- ========== END MAIN CONTENT ========== --> */}
    </div>
  );
};

export default CreatePlanboard;
