import { URLS } from "../../constants";
import { getAuth, postAuth, putAuth } from "../client";

const InviteAPI = {
  new: (emails: string[], team: { _id: string; name: string }) => {
    return postAuth(URLS.newInvite, { emails, team });
  },
  validate: (inviteId: string, token: string) => {
    return postAuth(URLS.validateInvite, {
      inviteId,
      token,
    });
  },
  get: (teamId: string) => {
    return getAuth(URLS.getInvitations + teamId);
  },
  update: (id: string, updateData: {}) => {
    return putAuth(URLS.updateInvitation, { id, updateData });
  },
};

export default InviteAPI;
