import React, { useState } from "react";
import EditLabel from "../Generic/EditLabel";

interface TitleProps {
  updateTitle: (value: string) => void;
  title: string;
}

const Title = ({ updateTitle, title }: TitleProps) => {
  const [edit, setEdit] = useState(false);

  return (
    <EditLabel
      edit={edit}
      label={title}
      setEdit={setEdit}
      updateLabel={updateTitle}
      iconSize={12}
    />
  );
};

export default Title;
