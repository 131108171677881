import React, { useEffect } from "react";
import Button from "../Generic/Button";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../Spinner";

interface NavButtonsProps {
  isFinal?: boolean;
  isFirst?: boolean;
  // setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: () => void;
  handleBack: () => void;
  isSubmitting?: boolean;
  handleReset?: () => void;
}

const NavButtons = ({
  isFinal = false,
  isFirst = false,
  handleSubmit,
  handleBack,
  handleReset,
  isSubmitting = false,
}: NavButtonsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleCancel = () => {
    const getHistoryFromStorage = localStorage.getItem("routeState");
    localStorage.removeItem("routeState");
    if (location?.state?.from?.pathname) {
      navigate(-1);
    } else {
      if (getHistoryFromStorage) {
        navigate(getHistoryFromStorage.replace(/["']/g, ""));
      } else {
        navigate("/dashboard");
      }
    }
  };
  useEffect(() => {
    console.log({ isSubmitting });
  }, [isSubmitting]);
  const nextButtonText = isFinal ? "Launch" : "Next";

  return (
    <div className="border-t px-10 py-3 xl:py-5 absolute w-full z-10 left-0 bottom-0 flex justify-between items-center gap-x-2 bg-white dark:bg-slate-800 dark:border-neutral-700">
      <Button
        label="Cancel"
        variant="outlined"
        // data-hs-stepper-reset-btn
        // onClick={() => setActiveStep(1)}
        onClick={handleCancel}
      />
      <div className="flex items-center space-x-3">
        {handleReset && (
          <Button label="Reset" onClick={handleReset} variant="outlined" />
        )}
        <Button
          label="Back"
          startIcon={<ChevronLeft size={16} />}
          // data-hs-stepper-back-btn
          onClick={handleBack}
          disabled={isFirst}
        />
        <Button
          label={
            <div className="flex items-center justify-center gap-x-2">
              {isSubmitting ? <Spinner /> : null} {nextButtonText}
            </div>
          }
          endIcon={<ChevronRight size={16} />}
          disabled={isSubmitting}
          // data-hs-stepper-next-btn
          onClick={handleSubmit}
          // className={`${isFinal ? "hidden" : ""}`}
          type="submit"
        />
      </div>
    </div>
  );
};

export default NavButtons;
