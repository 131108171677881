import React, { ReactNode } from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import { MenuItem } from "@mui/material";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: 12,
      marginRight: 4,
      marginLeft: 4,
      "& .lucide": {
        width: 14,
        height: 14,
        marginRight: 6,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 14,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      // "&:active": {
      //   backgroundColor: alpha(
      //     theme.palette.primary.main,
      //     theme.palette.action.selectedOpacity
      //   ),
      // },
    },
  },
}));
interface Option {
  icon: ReactNode;
  label: ReactNode;
}

interface CustomMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  options: Option[];
  onChange: (value: Option) => void;
  id: string;
}

const CustomMenu = ({
  anchorEl,
  open,
  handleClose,
  options,
  onChange,
  id,
}: CustomMenuProps) => {
  return (
    <StyledMenu
      id={id}
      // MenuListProps={{
      //   "aria-labelledby": "demo-customized-button",
      // }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}

    >
      {options.map((option: Option) => (
        <MenuItem
          onClick={() => onChange(option)} key={String(option.label)}>
          {option.icon}
          {option.label}
        </MenuItem>
      ))}
    </StyledMenu>
  );
};

export default CustomMenu;
