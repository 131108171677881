import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorkspace,
  updateWorkspace,
} from "../redux/slice/workspaces/workspacesSlice";
import WorkspacesAPI from "../api/services/Workspace";
import ConfirmBox from "../components/ConfirmBox";
import { AlertCircle } from "lucide-react";
import Alert from "../components/Generic/Alert";
import { useToast } from "./useToast";
import { RootState } from "../redux/store";
import Workspace from "../types/interfaces/workspace";
import Organization from "../types/interfaces/Organization";

const useWorkspace = () => {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [showConflictAlert, setShowConflictError] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();

  const organizations = useSelector(
    (state: RootState) => state.organizations
  ) as {
    value: Organization[];
  };
  const workspaces = useSelector((state: RootState) => state.workspaces) as {
    value: Workspace[];
  };

  const getWorkspaceDetails = async (id: string) => {
    try {
      setLoading(true);
      const response = await getWorkspace({
        workspaceId: id,
        organizationId: organizations.value[0].organizationId,
      });
      setLoading(false);
      if (response?.status?.code === 200) return setWorkspace(response?.data);
      return setError("Cannot fetch workspace");
    } catch (error) {
      return setError("Technical Error");
    }
  };

  const handleUpdateWorkspace = async (values: any, id: string) => {
    const originalWorkspace = workspace;
    const requestData = {
      workspaceId: id,
      ...values,
    };
    if (requestData.hasOwnProperty("workspaceName")) {
      requestData.name = requestData.workspaceName;
    }
    setWorkspace((prev) => ({ ...prev, ...requestData }));
    try {
      const response = await WorkspacesAPI.update(requestData);
      if (response.status === 200) {
        if (requestData.hasOwnProperty("workspaceName")) {
          delete requestData.workspaceName;
        }
        dispatch(updateWorkspace(requestData));
        return;
      } else {
        console.log({ response });
        setWorkspace(originalWorkspace);
        setError("Unable to update the workspace");
        return;
      }
    } catch (error: any) {
      setWorkspace(originalWorkspace);
      if (error.response.status === 409) {
        setError(
          error.response?.data?.data ?? "Provided workspace name already exists"
        );
        setShowConflictError(true);
        return;
      }
      setError("Unable to update the workspace");
      return;
    }
  };
  const updateDescription = (value: string, id: string) => {
    handleUpdateWorkspace({ description: value }, id);
  };

  const updateName = (value: string, id: string) => {
    handleUpdateWorkspace({ workspaceName: value }, id);
  };

  const togglePinned = async (id: string, name: string, pinned: boolean) => {
    await handleUpdateWorkspace({ pinned: !pinned }, id);
    toast.info(`${name} has been ${pinned ? "unpinned" : "pinned"}`);
  };

  const handleDelete = async (id: string) => {
    await handleUpdateWorkspace({ active: false }, id);
  };

  const renderAlert = (props: any) => {
    return (
      <Alert {...props}>
        <div className="mt-3 mb-6 dark:text-white">
          <div className="w-full flex justify-center mb-5">
            <AlertCircle size={40} color="#eeb813" />
          </div>
          <div className="flex justify-center text-xl mb-5">
            You cannot delete&nbsp; <b>My Workspace</b>
          </div>
        </div>
      </Alert>
    );
  };

  const renderConflictAlert = () => {
    return (
      <Alert
        open={showConflictAlert}
        onClose={() => {
          setShowConflictError(false);
          setError(null);
        }}
        title="Alert - Conflict"
        id="workspace-update-conflict"
      >
        <div className="mt-3 mb-6 dark:text-white">
          <div className="w-full flex justify-center mb-5">
            <AlertCircle size={40} color="#eeb813" />
          </div>
          <div className="flex justify-center text-xl mb-5">
            Workspace with same name already exists!
          </div>
        </div>
      </Alert>
    );
  };

  const renderDeleteConfirmBox = (props: any) => {
    return (
      <ConfirmBox {...props}>
        <div className="mt-3 mb-6 dark:text-white">
          <div className="w-full flex justify-center mb-5">
            <AlertCircle size={40} color="#eeb813" />
          </div>
          <div className="flex justify-center text-center text-xl mb-5">
            Are you sure you want to delete Workspace -{workspace?.name}
            &nbsp;?
          </div>
          <div className="flex justify-center">
            You will not be able to restore this action !
          </div>
        </div>
      </ConfirmBox>
    );
  };
  return {
    workspace,
    error,
    loading,
    handleUpdateWorkspace,
    updateDescription,
    updateName,
    togglePinned,
    handleDelete,
    getWorkspaceDetails,
    renderDeleteConfirmBox,
    renderAlert,
    renderConflictAlert,
  };
};

export default useWorkspace;
