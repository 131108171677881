import React, { useState } from "react";
import Modal, { ModalBody, ModalHeader } from "../Generic/Modal";
import UserSelect from "../Generic/UserSelect";
import Spinner from "../Spinner";
import SelectedUsersList from "./SelectedUsersList";
import { PlanboardUser } from "../../types/interfaces/PlanboardUser";
import { Tooltip } from "@mui/material";
import InviteMembersModal from "../CreatePlanboardSteps/components/InviteMembersModal";
import { useDispatch, useSelector } from "react-redux";
import PlanboardsAPI from "../../api/services/Planboard";
import Planboard from "../../types/interfaces/Planboard";
import { RootState } from "../../redux/store";
import { updateActivePlanboard } from "../../redux/slice/planboard";
import { ROLE_TYPES } from "../../types/types/PlanboardRoles";

interface PlanboardAvatarGroupProps {
  members: PlanboardUser[];
  readOnly?: boolean;
}
const buttonStyle =
  "py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-500 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600";

const users = [1, 2, 3, 4, 5];
const PlanboardAvatarGroup = ({
  members,
  readOnly = false,
}: PlanboardAvatarGroupProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const planboardValue: Planboard = useSelector(
    (state: RootState) => state.planboard
  ).value as Planboard;
  const handleTeam = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleChange = (options: any) => {
    setSelectedMembers(options);
  };
  const saveMemberChanges = () => {};

  const handleAddMember = async (member: any) => {
    // setSelectedMembers([...selectedMembers, { ...member, role: "Viewer" }]);
    console.log({ member });
    let newMember: any = { role: "Viewer" };
    member.userId
      ? (newMember["userId"] = member.userId)
      : (newMember["emailId"] = member.email);
    try {
      const response = await PlanboardsAPI.addMember({
        member: newMember,
        planboardId: planboardValue.planboardId,
      });
      if (response.status === 200) {
        const newUser: PlanboardUser = {
          userId: member.userId ? member.userId : null,
          email: member.email,
          profilePic: member.profilePic ? member.profilePic : null,
          // status: "pending",
          role: member.role,
          name: member.name ? member.name : null,
          gender: member.gender ? member.gender : null,
        };
        dispatch(
          updateActivePlanboard({
            ...planboardValue,
            members:
              planboardValue.members !== null
                ? [...planboardValue.members, newUser]
                : [newUser],
          })
        );
        return;
        //@ts-ignore
        //   dispatch(getPlanboardSummary(planboardValue.planboardId));
      } else return setError("error adding member");
    } catch (error) {
      return setError("An error occurred while trying to add the member");
    }
  };

  const renderAvatar = (user: PlanboardUser) => {
    return (
      <Tooltip title={user.name}>
        <img
          className="hs-tooltip-toggle relative inline-block size-[32px] cursor-pointer rounded-full ring-2 ring-white hover:z-10"
          src={user.profilePic ? user.profilePic : ""}
          alt={user.email}
        />
      </Tooltip>
    );
  };

  return (
    <div>
      <div className="flex -space-x-2">
        {members.slice(0, 4).map((member) => renderAvatar(member))}
        {(planboardValue.userRole === ROLE_TYPES.CREATOR ||
          planboardValue.userRole === ROLE_TYPES.COCREATOR) && (
          <Tooltip title="Add member">
            <div className="inline-block" onClick={handleTeam}>
              <div className="hs-tooltip-toggle relative flex size-[32px] cursor-pointer rounded-full ring-2 ring-white hover:z-10 bg-green-200 items-center justify-center">
                +
              </div>
            </div>
          </Tooltip>
        )}
      </div>
      <InviteMembersModal
        open={open}
        onClose={handleClose}
        onSubmit={handleAddMember}
      />
      {/* <Modal open={open} onClose={handleClose} id="edit-team-modal">
        <ModalHeader title="Edit Team" onClose={handleClose} />
        {loading ? (
          <div className="flex h-48 justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <>
            <ModalBody>
              <div id="team-members" className="m-4 h-20">
                <UserSelect
                  onChange={handleChange}
                  selectedValues={selectedMembers}
                  options={options}
                />
              </div>
              <div>
                <div className="text-md font-medium mt-8 ms-2 mb-6">
                  Assign Roles
                </div>
                <SelectedUsersList users={users} />
              </div>
            </ModalBody>
            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
              <button
                type="button"
                className={buttonStyle}
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={buttonStyle}
                onClick={saveMemberChanges}
              >
                Save
              </button>
            </div>
          </>
        )}
      </Modal> */}
    </div>
  );
};

export default PlanboardAvatarGroup;
