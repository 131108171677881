import React from "react";
import ComingSoon from "./ComingSoon";
import withLayout from "../containers/MasterLayoutContainer";

const Activity = () => {
  return (
    <div className="flex m-2 h-full">
      <main
        id="content"
        role="main"
        className="w-full max-h-[90vh] overflow-y-scroll transition-all duration-300 ease-in"
      >
        <div className="mx-auto h-full dark:text-white bg-white border-x-gray-200 py-10 px-4 sm:px-6 lg:px-8 xl:border-x dark:bg-gray-800 dark:border-x-gray-700 border-t-2 border-t-primary-400 rounded-lg">
          <ComingSoon />
        </div>
      </main>
    </div>
  );
};

export default withLayout(Activity);
