import { Trash2, UserPlus } from "lucide-react";
import React, { useState } from "react";
import IconButton from "../Generic/IconButton";
import Button from "../Generic/Button";
import { SUPPORTED_FILE_TYPES } from "../../constants/file";
import BrowseInput from "../Attachments/BrowseInput";
import NoFilePlaceholder from "../Attachments/NoFilePlaceholder";

// interface File {
//   name: string;
//   size: number;
//   type: string;
// }

interface AttachmentsProps {
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  selectedFiles: File[];
}

function formatFileSize(fileSizeInBytes: number): string {
  const units = ["Bytes", "KB", "MB", "GB", "TB"];
  let unitIndex = 0;

  while (fileSizeInBytes >= 1024 && unitIndex < units.length - 1) {
    fileSizeInBytes /= 1024;
    unitIndex++;
  }

  return `${fileSizeInBytes.toFixed(2)} ${units[unitIndex]}`;
}

const Attachments = ({ setSelectedFiles, selectedFiles }: AttachmentsProps) => {
  const removeFile = (index: number) => {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
  };

  return (
    <>
      <div className="flex">
        <label htmlFor="invite-members" className="dark:text-white grow">
          Attachments
          <span className="italic text-xs text-gray-500">&nbsp;(optional)</span>
        </label>
      </div>
      <div className="">
        <div
          id="invite-members"
          className="mt-2 border border-gray-200 rounded-lg flex justify-center dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
        >
          {selectedFiles.length > 0 ? (
            <>
              {selectedFiles.length > 0 && (
                <ul className="w-full p-2 text-xs">
                  {selectedFiles.map((file, index) => (
                    <li
                      key={index}
                      className="grid grid-cols-4 gap-4 hover:bg-gray-100 rounded px-3"
                    >
                      <span className="col-span-2 flex items-center">
                        {file.name}
                      </span>
                      <span className="flex items-center justify-end">
                        {formatFileSize(file.size)}
                      </span>
                      <span className="text-end">
                        <IconButton
                          icon={<Trash2 size={12} />}
                          onClick={() => removeFile(index)}
                          tooltipTitle="Remove file"
                        />
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </>
          ) : (
            <NoFilePlaceholder
              setSelectedFiles={setSelectedFiles}
              selectedFiles={selectedFiles}
            />
          )}
        </div>
        <div className={selectedFiles.length > 0 ? "visible" : "invisible"}>
          <BrowseInput
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
        </div>
      </div>
    </>
  );
};

export default Attachments;
