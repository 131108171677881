import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
  PayloadAction,
} from "@reduxjs/toolkit";
import EventsAPI from "../../../api/services/Events";

interface Event {
  _id: string;
  title: string;
  description: string;
  planboardId: string;
  configuration: {};
}

interface EventsState {
  value: Event[];
  loading: boolean;
  error: null | unknown;
}

const initialState: EventsState = {
  value: [],
  loading: false,
  error: null,
};

// export const createEvent = createAsyncThunk(
//   "events/createEvent",
//   async ({ title, description, planboardId, configuration }: Event) => {
//     try {
//       const response = await EventsAPI.createEvent({
//         title,
//         description,
//         planboardId,
//         ,
//       });
//       if (!response.data.success)
//         return isRejectedWithValue(
//           new Error(response?.data?.message ?? "Server error")
//         );
//       return response.data.data;
//     } catch (error: any) {
//       return isRejectedWithValue(error.message);
//     }
//   }
// );

// export const getAllEvents = createAsyncThunk(
//   "events/readEvents",
//   async (planboardId: string) => {
//     try {
//       const response = await EventsAPI.getAllEvents(planboardId);
//       if (!response.data.success)
//         return isRejectedWithValue(
//           new Error(response?.data?.message ?? "Server error")
//         );
//       return response.data.data;
//     } catch (error: any) {
//       return isRejectedWithValue(error.message);
//     }
//   }
// );

// export const updateEvent = createAsyncThunk(
//   "events/updateEvent",
//   async ({ id, updateData }: { id: string; updateData: {} }) => {
//     try {
//       const response = await EventsAPI.updateEvent({ id, updateData });
//       if (!response.data.success)
//         return isRejectedWithValue(
//           new Error(response?.data?.message ?? "Server error")
//         );
//       return response.data.data;
//     } catch (error: any) {
//       return isRejectedWithValue(error.message);
//     }
//   }
// );

// export const deleteEvent = createAsyncThunk(
//   "events/deleteEvent",
//   async (id: string) => {
//     try {
//       const response = await EventsAPI.deleteEventById(id);
//       if (!response.data.success)
//         return isRejectedWithValue(
//           new Error(response?.data?.message ?? "Server error")
//         );
//       return response.data.data;
//     } catch (error: any) {
//       return isRejectedWithValue(error.message);
//     }
//   }
// );

// export const deleteAllEvents = createAsyncThunk(
//   "events/deleteAllEvents",
//   async (planboardId: string) => {
//     try {
//       const response = await EventsAPI.deleteEventsByPlanboardId(planboardId);
//       if (!response.data.success)
//         return isRejectedWithValue(
//           new Error(response?.data?.message ?? "Server error")
//         );
//       return response.data.data;
//     } catch (error: any) {
//       return isRejectedWithValue(error.message);
//     }
//   }
// );

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    // addEvent: (state, action: PayloadAction<Event>) => {
    //   state.events.push(action.payload);
    // },
    // removeEvent: (state, action: PayloadAction<string>) => {
    //   state.events = state.events.filter((event) => event._id !== action.payload);
    // },
    // updateEvent: (state, action: PayloadAction<{ id: string; newData: Partial<Event> }>) => {
    //   const { id, newData } = action.payload;
    //   state.events = state.events.map((event) => (event._id === id ? { ...event, ...newData } : event));
    // },
  },
  // extraReducers: (builder) => {
  //   builder;
  //   // .addCase(createEvent.pending, (state) => {
  //   //   state.loading = true;
  //   // })
  //   // .addCase(createEvent.fulfilled, (state, action: PayloadAction<Event>) => {
  //   //   state.loading = false;
  //   //   state.value.push(action.payload);
  //   // })
  //   // .addCase(createEvent.rejected, (state, action) => {
  //   //   state.loading = false;
  //   //   state.error = action.error.message ?? "Failed to create event.";
  //   // })
  //   // .addCase(getAllEvents.pending, (state) => {
  //   //   state.loading = true;
  //   // })
  //   // .addCase(
  //   //   getAllEvents.fulfilled,
  //   //   (state, action: PayloadAction<Event[]>) => {
  //   //     state.loading = false;
  //   //     state.value = action.payload;
  //   //   }
  //   // )
  //   // .addCase(getAllEvents.rejected, (state, action) => {
  //   //   state.loading = false;
  //   //   state.error = action.error.message ?? "Failed to read events.";
  //   // })
  //   // .addCase(updateEvent.pending, (state) => {
  //   //   state.loading = true;
  //   // })
  //   // .addCase(
  //   //   updateEvent.fulfilled,
  //   //   (
  //   //     state,
  //   //     action: PayloadAction<{ id: string; newData: Partial<Event> }>
  //   //   ) => {
  //   //     state.loading = false;
  //   //     const { id, newData } = action.payload;
  //   //     state.value = state.value.map((event) =>
  //   //       event._id === id ? { ...event, ...newData } : event
  //   //     );
  //   //   }
  //   // )
  //   // .addCase(updateEvent.rejected, (state, action) => {
  //   //   state.loading = false;
  //   //   state.error = action.error.message ?? "Failed to update event.";
  //   // })
  //   // .addCase(deleteEvent.pending, (state) => {
  //   //   state.loading = true;
  //   // })
  //   // .addCase(
  //   //   deleteEvent.fulfilled,
  //   //   (state, action: PayloadAction<string>) => {
  //   //     state.loading = false;
  //   //     state.value = state.value.filter(
  //   //       (event) => event._id !== action.payload
  //   //     );
  //   //   }
  //   // )
  //   // .addCase(deleteEvent.rejected, (state, action) => {
  //   //   state.loading = false;
  //   //   state.error = action.error.message ?? "Failed to delete event.";
  //   // })
  //   // .addCase(deleteAllEvents.pending, (state) => {
  //   //   state.loading = true;
  //   // })
  //   // .addCase(deleteAllEvents.fulfilled, (state) => {
  //   //   state.loading = false;
  //   //   state.value = [];
  //   // })
  //   // .addCase(deleteAllEvents.rejected, (state, action) => {
  //   //   state.loading = false;
  //   //   state.error = action.error.message ?? "Failed to delete all events.";
  //   // })
  // },
});

// export const { addEvent, removeEvent, updateEvent } = eventsSlice.actions;
export default eventsSlice.reducer;
