import React, { useEffect, useState } from "react";
import TeamsAPI from "../../api/services/Teams";
import moment from "moment";
import NewMemberModal from "./NewMemberModal";
import { useToast } from "../../hooks";
import EmptyState from "../EmptyState";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Organization from "../../types/interfaces/Organization";
import useOrganizationRoles from "../../hooks/useOrganizationRoles";
import Select from "../Generic/Select";

interface Member {
  member: {
    name: string | null;
    profilePic: string | null;
    gender: string | null;
    emailId: string;
  };
  role: string;
  inviteId: string;
  status: string;
  active: boolean;
  invitedOn: string;
}
interface MembersListProps {
  search: string;
}

const MembersList = ({ search }: MembersListProps) => {
  const [members, setMembers] = useState<Member[]>([]);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [itemsPerIndex, setItemsPerIndex] = useState(10);
  const { options } = useOrganizationRoles();
  const [selectedRole, setSelectedRole] = useState(options[2].value);
  const [currentRole, setCurrentRole] = useState("Viewer");
  const user = useSelector((state: RootState) => state.user.value);
  const organizations = useSelector(
    (state: RootState) => state.organizations.value
  ) as Organization[];

  const toast = useToast();
  const totalMembers = members.length + (organizations?.[0]?.owner ? 1 : 0);

  const getMembers = async () => {
    try {
      setLoading(true);
      const response = await TeamsAPI.getMembers({
        index,
        itemsPerIndex,
        search,
      });
      setLoading(false);
      if (response.status === 200) {
        setMembers(response.data.data);
        // setOriginalMembers(response.data.data);
      }
    } catch (error: any) {
      console.log({ error });
      if (error?.response?.status === 404)
        toast.info("No members are yet invited", 1500);
    }
  };
  useEffect(() => {
    getMembers();
  }, [index, itemsPerIndex, search]);
  useEffect(() => {
    console.log({ members });
  }, [members]);

  const getRole = () => {
    if (organizations[0].owner.email === user.email) setCurrentRole("Creator");
    const member = members.find(
      (member: any) => member.member.emailId === user.email
    );
    if (member) {
      setCurrentRole("Viewer");
    }
  };
  useEffect(() => {
    if (loading || members.length === 0) return;
    getRole();
  }, [members, loading]);
  const handleRoleChange = async (value: string, member: any) => {
    try {
      const response = await TeamsAPI.updateRole({
        userId: member.member.id,
        role: value,
      });
      if (response.status === 200) {
        toast.info("Role updated");
        setMembers((prev) =>
          prev.map((m) =>
            m.inviteId === member.inviteId ? { ...m, role: value } : m
          )
        );
      }
    } catch (error) {
      console.log({ error });
      toast.error("Error updating role");
    }
    console.log({ value, member });
  };

  // useEffect(() => {
  //   if (search) {
  //     const searchValue = search.toLowerCase();
  //     const results = members.filter((member: Member) => {
  //       const memberName = member?.member?.name?.toLowerCase();
  //       const memberEmail = member?.member?.emailId?.toLowerCase();
  //       return (
  //         (memberName && memberName.includes(searchValue)) ||
  //         (memberEmail && memberEmail.includes(searchValue))
  //       );
  //     });
  //     setMembers(results);
  //   } else {
  //     setMembers(originalMembers);
  //   }
  // }, [search]);
  // if (members.length === 0) return null;

  return (
    <div className="overflow-x-auto [&::-webkit-scrollbar]:h-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
      <div className="min-w-full inline-block align-middle">
        {/* <!-- Table -->  */}
        <table className="min-w-full divide-y divide-gray-200 dark:divide-slate-700">
          <thead>
            <tr>
              <th scope="col" className="min-w-[250px]">
                <div className="pe-4 py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-slate-200">
                  Member
                </div>
              </th>

              <th scope="col" className="min-w-48">
                <div className="px-4 py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-slate-200">
                  Role
                </div>
              </th>

              <th scope="col">
                <div className="px-4 py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-slate-200">
                  Email
                </div>
              </th>

              <th scope="col" className="min-w-36">
                <div className="px-4 py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-slate-200">
                  Added on
                </div>
              </th>

              <th scope="col">
                <div className="px-4 py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-slate-200">
                  Status
                </div>
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 dark:divide-slate-700">
            {organizations &&
              organizations.length &&
              organizations[0].owner && (
                <tr>
                  <td className="size-px whitespace-nowrap pe-4 py-3">
                    <div className="w-full flex items-center gap-x-3">
                      {organizations[0].owner.profilePic ? (
                        <img
                          className="flex-shrink-0 size-[38px] rounded-full"
                          src={organizations[0].owner.profilePic}
                          alt="Image Description"
                        />
                      ) : (
                        <span className="flex flex-shrink-0 justify-center items-center size-[38px] bg-white border border-gray-200 text-gray-700 text-xs font-medium uppercase rounded-full dark:bg-slate-800 dark:border-slate-700 dark:text-slate-300">
                          {organizations[0].owner.email.charAt(0).toUpperCase()}
                        </span>
                      )}
                      <div className="grow">
                        <span className="text-sm font-medium text-gray-800 dark:text-slate-200">
                          {organizations[0].owner.name}{" "}
                          <span className="text-gray-6000 dark:text-slate-400 italic">
                            (Owner)
                          </span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="size-px whitespace-nowrap px-4 py-3">
                    <span className="text-sm text-gray-600 dark:text-slate-400">
                      Creator
                    </span>
                  </td>
                  <td className="size-px whitespace-nowrap px-4 py-3">
                    <span className="text-sm text-gray-600 dark:text-slate-400">
                      {organizations[0].owner.email}
                    </span>
                  </td>
                  <td className="size-px whitespace-nowrap px-4 py-3 ms-5 dark:text-gray-400">
                    -
                  </td>
                  <td className="size-px whitespace-nowrap px-4 py-3">
                    <span className="capitalize inline-flex items-center gap-x-1.5 py-1.5 px-2.5 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                      <span className="size-1.5 inline-block bg-gray-800 rounded-full dark:bg-slate-200"></span>
                      Active
                    </span>
                  </td>
                </tr>
              )}
            {members.length > 0 ? (
              members.map((member: Member) => (
                <tr>
                  <td className="size-px whitespace-nowrap pe-4 py-3">
                    <div className="w-full flex items-center gap-x-3">
                      {member.member.profilePic ? (
                        <img
                          className="flex-shrink-0 size-[38px] rounded-full"
                          src={member.member.profilePic}
                          alt="Image Description"
                        />
                      ) : (
                        <span className="flex flex-shrink-0 justify-center items-center size-[38px] bg-white border border-gray-200 text-gray-700 text-xs font-medium uppercase rounded-full dark:bg-slate-800 dark:border-slate-700 dark:text-slate-300">
                          {member.member.emailId.charAt(0).toUpperCase()}
                        </span>
                      )}
                      <div className="grow">
                        <span className="text-sm font-medium text-gray-800 dark:text-slate-200">
                          {member?.member?.name ? (
                            member.member.name
                          ) : (
                            <div className="text-gray-500">User</div>
                          )}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="size-px whitespace-nowrap px-4 py-3">
                    <span className="text-sm text-gray-600 dark:text-slate-400">
                      {member.status === "accepted" &&
                      String(currentRole).toLowerCase() === "creator" ? (
                        <div className="">
                          <Select
                            onChange={(value) =>
                              handleRoleChange(value, member)
                            }
                            options={options}
                            value={member.role}
                          />
                        </div>
                      ) : (
                        member.role
                      )}
                      {/* {member.role} */}
                    </span>
                  </td>
                  <td className="size-px whitespace-nowrap px-4 py-3">
                    <span className="text-sm text-gray-600 dark:text-slate-400">
                      {member.member.emailId}
                    </span>
                  </td>
                  <td className="size-px whitespace-nowrap px-4 py-3">
                    <span className="text-sm text-gray-600 dark:text-slate-400">
                      {moment(member.invitedOn).format(
                        "ddd, DD-MM-YYYY hh:mm a"
                      )}
                    </span>
                  </td>
                  <td className="size-px whitespace-nowrap px-4 py-3">
                    {member.status === "accepted" ? (
                      <span className="capitalize inline-flex items-center gap-x-1.5 py-1.5 px-2.5 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                        <span className="size-1.5 inline-block bg-gray-800 rounded-full dark:bg-slate-200"></span>
                        Active
                      </span>
                    ) : (
                      <span className="inline-flex items-center gap-1.5 py-1.5 px-2 text-xs font-medium bg-gray-100 text-gray-800 rounded-full dark:bg-slate-500/20 dark:text-slate-400">
                        <span className="size-1.5 inline-block bg-gray-800 rounded-full dark:bg-slate-400"></span>
                        Pending
                      </span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center py-4">
                  <EmptyState
                    line1="No invited members"
                    line2="You will see the list of all the members that are invited to this organization"
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <!-- End Table -->  */}
        <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-slate-700">
          <div>
            <p className="text-sm text-gray-600 dark:text-slate-400">
              <span className="font-semibold text-gray-800 dark:text-slate-200">
                {totalMembers}
              </span>{" "}
              results
            </p>
          </div>

          <div>
            <div className="inline-flex gap-x-2">
              <button
                type="button"
                disabled={index === 0}
                onClick={() => setIndex((prev) => prev - 1)}
                className="py-1.5 px-2 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-slate-700 transition-all duration-100 dark:text-white dark:hover:bg-slate-800"
              >
                <svg
                  className="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m15 18-6-6 6-6" />
                </svg>
                Prev
              </button>

              <button
                type="button"
                onClick={() => setIndex((prev) => prev + 1)}
                disabled={index * itemsPerIndex >= totalMembers - itemsPerIndex}
                className="py-1.5 px-2 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-slate-700 transition-all duration-100 dark:text-white dark:hover:bg-slate-800"
              >
                Next
                <svg
                  className="flex-shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <NewMemberModal updateMemberList={getMembers} members={members} />
    </div>
  );
};

export default MembersList;
