import React, { useState } from "react";
import SelectedUsersList from "../../components/CreatePlanboardSteps/components/SelectedUsersList";
import { UserPlus } from "lucide-react";
import Button from "../../components/Generic/Button";
import InviteMembersModal from "../../components/CreatePlanboardSteps/components/InviteMembersModal";
import { Member } from "../../components/CreatePlanboardSteps/Member";
import BackButton from "./BackButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import useDissapearingError from "../../hooks/useDissapearingError";

interface InviteUsersProps {
  setOnboardingData: React.Dispatch<React.SetStateAction<{}>>;
  onBoardingData: { members: Member[] };
}

const InviteUsers = ({
  setOnboardingData,
  onBoardingData,
}: InviteUsersProps) => {
  const user = useSelector((state: RootState) => state.user.value);

  const [selectedMembers, setSelectedMembers] = useState<Member[]>(
    onBoardingData?.members ?? []
  );
  const [open, setOpen] = useState(false);
  const { error, setError } = useDissapearingError();

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };
  const handleAddMember = (member: any) => {
    console.log({ user, member });
    if (user.email === member.email) return setError("You cannot add yourself");
    setSelectedMembers([
      ...selectedMembers,
      { ...member, role: "Contributor" },
    ]);
  };
  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = () => {
    setOnboardingData((prev) => ({ ...prev, members: selectedMembers }));
    handleNext();
  };

  const handleNext = () => {
    navigate(ROUTES.enterPlanboardName);
  };

  return (
    <div className="w-[500px]">
      <div>
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
          Invite members
        </h1>
      </div>
      <div
        id="invite-members"
        className="mt-10 border border-gray-200 rounded-lg p-5 flex items-center justify-center dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
      >
        {selectedMembers.length ? (
          <>
            <SelectedUsersList
              members={selectedMembers}
              setSelectedMembers={setSelectedMembers}
            />
          </>
        ) : (
          <>
            <div className="flex flex-col items-center space-y-5">
              <UserPlus size={40} color="#979797" />
              You haven't invited any collaborators yet
              <Button
                startIcon={<UserPlus size={16} />}
                label="Add People"
                onClick={() => setOpen(!open)}
              />
            </div>
          </>
        )}
      </div>
      <div
        className={`${error ? "visible" : "invisible"} text-xs text-red-500`}
      ></div>
      {selectedMembers.length > 0 && (
        <>
          <button
            className="py-2.5 mt-5 px-3 w-full inline-flex justify-center items-center gap-x-1 text-sm rounded-lg border border-gray-200 dark:border-0 shadow-sm disabled:opacity-50 disabled:pointer-events-none font-semibold bg-blue-600 text-white hover:bg-blue-700 "
            onClick={() => setOpen(!open)}
            type="button"
          >
            <UserPlus size={16} />
            Add People
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className="py-2.5 mt-5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
          >
            Next
          </button>
        </>
      )}
      <InviteMembersModal
        open={open}
        onClose={handleClose}
        onSubmit={handleAddMember}
      />
      <BackButton handleBack={handleBack} />
      <button
        type="button"
        onClick={handleNext}
        className="absolute top-4 end-4 inline-flex items-center gap-x-1 text-sm decoration-2 hover:underline font-medium rounded-lg border border-transparent text-gray-500 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:underline focus:text-blue-600 dark:text-neutral-400 dark:hover:text-white dark:focus:text-white"
      >
        skip
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-chevron-right"
        >
          <path d="m9 18 6-6-6-6" />
        </svg>
      </button>
    </div>
  );
};

export default InviteUsers;
