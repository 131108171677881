import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../components/Auth/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import PublicAPI from "../../api/services/Public";
import { useToast } from "../../hooks";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Spinner from "../../components/Spinner";

interface FormValues {
  email: string;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Email is required')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email address'),
});
const ForgetPassword: React.FC = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [show, setShow] = useState<boolean>(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const otpRefs = useRef<(HTMLInputElement | null)[]>([]);
  const toast = useToast()

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        if (otpRefs.current.length > 0 && otpRefs.current[0]) {
          otpRefs.current[0]?.focus();
        }
      }, 100);
    }
  }, [show]);

  const handleEmailSubmit = async () => {
    try {
      setLoading(true)
      const response = await PublicAPI.forgetPassword(formik.values.email)
      // const response ={data:{error:false}}
      if (!response.data.error) {
        setShow(true);
        setLoading(false)
        toast.success(response.data.data)
        scrollToBottom();
      }
    } catch (error: any) {
      console.log(error)
      formik.resetForm();
      setLoading(false)
      toast.error(error.response.data.data)
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: handleEmailSubmit,
  });

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth"
    });
  };

  const handleOTPchange = (element: HTMLInputElement, index: number) => {
    if (isNaN(Number(element.value))) return false;
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value) {
      (element.nextSibling as HTMLInputElement).focus();
    }
  };

  const handleOTPsubmit = async () => {
    try {
      setLoading(true)
      const otpConcatenated = otp.join('');
      const otpNumber = parseInt(otpConcatenated);
      const response = await PublicAPI.verifyOTP(formik.values.email, otpNumber)
      // const response ={data:{error:false, data:{name:"dfgh"}}}
      const ans = response?.data?.data
      if (!response.data.error) {
        setLoading(false)
      setOtp (new Array(6).fill(""))
        navigate(ROUTES.setPassword, { state: { ans } })
      }
    } catch (error: any) {
      setLoading(false)
      setOtp (new Array(6).fill(""))
      toast.error(error.response.data.data);
    }
  }

  let allFilled = otp.every(value => value !== "");

  return (
    <main className="flex min-h-full bg-white dark:bg-slate-900">
      <Sidebar />

      <div className="grow">
        <div className="w-full h-screen max-h-[100vh] overflow-y-scroll bg-white dark:bg-slate-900">
          {/* <!--Email Content --> */}
          <div className="grow px-5">
            <div className="h-full min-h-screen sm:w-[448px] flex flex-col justify-center mx-auto space-y-5">
              {/* <!-- Title --> */}
              <div>
                <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                  Reset your password
                </h1>
                <p className="mt-1 text-sm text-gray-500 dark:text-neutral-500">
                  Enter the email address associated with your account. We’ll send you a OTP to reset your password.
                </p>
              </div>
              {/* <!-- End Title --> */}

              <form onSubmit={formik.handleSubmit}>
                <div className="space-y-5">
                  <div>
                    <label htmlFor="hs-pro-dale" className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                      Email
                    </label>
                    <input
                      ref={emailRef}
                      value={formik.values.email}
                      // onChange={(e) => handleEmail(e.target.value)}
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="hs-pro-dale"
                      className="py-2.5 px-3 block w-full border-gray-200 rounded-lg border text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                      placeholder="you@email.com"
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-600">{formik.errors.email}</div>
                  ) : null}

                  {loading && <Spinner/>}

                  <button type="submit" disabled={!formik.isValid || formik.isSubmitting || !formik.values.email || loading} className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-start bg-blue-600 border border-blue-600 text-white text-sm font-medium rounded-lg shadow-sm align-middle hover:bg-blue-700  disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500">
                    Send OTP
                  </button>

                </div>
              </form>

              <p className="text-sm text-gray-500 dark:text-neutral-500">
                You didn’t forget your password? &nbsp;
                <Link
                  className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium focus:outline-none focus:underline dark:text-blue-500"
                  to={ROUTES.signin}
                >
                  Sign in
                  <svg
                    className="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </Link>
              </p>
            </div>
          </div>
          {/* <!--Email End Content --> */}

          {/* <!--otp Content --> */}
          {show && (
            <div className="grow px-5">
              <div className="h-full min-h-screen sm:w-[448px] flex flex-col justify-center mx-auto space-y-5">
                {/* <!-- Title --> */}
                <div>
                  <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                    2-Step Verification
                  </h1>
                  <p className="mt-1 text-sm text-gray-500 dark:text-neutral-500">
                    We sent a verification code to your email.
                  </p>
                  <p className="text-sm text-gray-500 dark:text-neutral-500">
                    Enter the code from the email in the field below.
                  </p>
                </div>
                {/* <!-- End Title --> */}

                <form>
                  <div className="space-y-2">
                    <div className="flex gap-x-2" data-hs-pin-input>
                      {otp.map((data, index) => (
                        <input
                          key={index}
                          ref={(el) => otpRefs.current[index] = el}
                          type="text"
                          className="border py-2 px-3 block w-[48px] h-[48px] border-gray-200 text-center rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
                          maxLength={1}
                          value={data}
                          onChange={(e) => handleOTPchange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                          data-hs-pin-input-item
                        />
                      ))}
                    </div>
                    {loading && <Spinner/>}

                    <div className="pt-2">
                      <button
                        type="button"
                        onClick={handleOTPsubmit}
                        disabled={!allFilled || loading}
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                      >
                        Verify my account
                      </button>
                    </div>
                  </div>
                </form>

                <p className="text-sm text-gray-500 dark:text-neutral-500">
                  Didn't receive an email? &nbsp;
                  <button
                    className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium focus:outline-none focus:underline dark:text-blue-500"
                    onClick={handleEmailSubmit}
                  >
                    Resend
                    <svg
                      className="flex-shrink-0 size-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m9 18 6-6-6-6" />
                    </svg>
                  </button>
                </p>
              </div>
            </div>
          )}
          {/* <!--otp End Content --> */}

        </div>
      </div>
    </main>
  );
};

export default ForgetPassword;
