import { PUBLIC_URLS } from "../../constants";
import { postAuth } from "../client";

const PublicAPI = {
  authenticateWithSocialAccount: ({
    emailId,
    name,
    profilePic,
  }: {
    emailId: string;
    name: string;
    profilePic: string;
  }) => {
    return postAuth(PUBLIC_URLS.authenticateWithSocialAccount, {
      emailId,
      name,
      profilePic,
    });
  },
  authenticateUser: ({
    emailId,
    password,
  }: {
    emailId: string;
    password: string;
  }) => postAuth(PUBLIC_URLS.authenticateUser, { emailId, password }),
  createAccount: ({
    emailId,
    name,
    contactNo,
    password,
  }: {
    emailId: string;
    name: string;
    contactNo: string;
    password: string;
  }) =>
    postAuth(PUBLIC_URLS.createAccount, { emailId, name, contactNo, password }),
  refreshToken: ({ refreshToken }: { refreshToken: string }) =>
    postAuth(PUBLIC_URLS.refreshToken, { refreshToken }),

  forgetPassword: (email: string) => postAuth(PUBLIC_URLS.forgetPassword, { email }),
  verifyOTP: (email: string, otp: number) => postAuth(PUBLIC_URLS.verifyOTP, { email, otp }),
  setPassword: (userId: string, resetToken: string, password: string) => postAuth(PUBLIC_URLS.setPassword, { userId,resetToken,password }),


};

export default PublicAPI;
