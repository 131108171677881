import { URLS } from "../../constants/index.jsx";
import { postAuth, putAuth } from "../client";

const WorkspacesAPI = {
  getWorkspaces: ({
    organizationId,
    index,
    itemsPerIndex,
    sort,
    search,
  }: {
    organizationId: string;
    index: number;
    itemsPerIndex: number;
    sort?: "Created With" | "Alphabetical";
    search?: string;
  }) => {
    return postAuth(URLS.getWorkspaces, {
      organizationId,
      index,
      itemsPerIndex,
      ...(sort && { sort }),
      ...(search && { search }),
    });
  },
  getWorkspace: ({
    workspaceId,
    organizationId,
  }: {
    workspaceId: string;
    organizationId: string;
  }) => {
    return postAuth(URLS.getWorkspace, {
      workspaceId,
      organizationId,
    });
  },
  create: (workspaceName: string, organizationId: string) => {
    return postAuth(URLS.createWorkspace, {
      workspaceName,
      organizationId,
    });
  },
  update: ({
    workspaceId,
    workspaceName,
    active,
    pinned,
    description,
  }: {
    workspaceId: string;
    workspaceName?: string;
    active?: boolean;
    pinned?: boolean;
    description?: string;
  }) => {
    const requestData: any = {
      workspaceId,
      ...(workspaceName && { workspaceName }),
      ...(description && { description }),
    };
    if (active !== undefined) requestData.active = active;
    if (pinned !== undefined) requestData.pinned = pinned;
    return postAuth(URLS.updateWorkspace, requestData);
  },
};

export default WorkspacesAPI;
