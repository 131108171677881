import React from "react";
import UserAvatar from "../../../assets/images/user.webp";
import EmailLight from "../../../assets/images/email-light.png";
import EmailDark from "../../../assets/images/email-dark.png";
import Select from "../../Generic/Select";
import IconButton from "../../Generic/IconButton";
import { Trash2 } from "lucide-react";
import { Member } from "../Member";

interface SelectedUsersListProps {
  members: Member[];
  setSelectedMembers: React.Dispatch<React.SetStateAction<Member[]>>;
}

const roleTypes = [
  { value: "Contributor" },
  { value: "Viewer" },
  { value: "Creator" },
];

const SelectedUsersList = ({
  members,
  setSelectedMembers,
}: SelectedUsersListProps) => {
  const handleRemoveUser = (email: string) => () => {
    console.log("handle remove user");
    setSelectedMembers((prev) => prev.filter((m) => m.email !== email));
  };
  const handleRoleChange = (email: string, newRole: string) => () => {
    console.log({ email, newRole });
    setSelectedMembers((prev) => {
      return prev.map((x) => (x.email === email ? { ...x, role: newRole } : x));
    });
  };
  const renderActions = (member: Member) => {
    const updateRole = (newRole: string) => {
      return handleRoleChange(member.email, newRole)();
    };
    return (
      <div className="flex items-center space-x-2">
        <Select
          onChange={updateRole}
          options={roleTypes}
          value={member?.role ?? "Viewer"}
        />
        <IconButton
          icon={<Trash2 size={12} />}
          tooltipTitle="Remove"
          onClick={() => handleRemoveUser(member.email)()}
        />
      </div>
    );
  };
  return (
    <div className="w-full">
      {members.map((member) => (
        <>
          {member.type === "full" ? (
            <div className="flex items-center w-full space-x-2 py-2 hover:bg-gray-100 rounded-md ps-3 pe-2">
              <img
                src={member.profilePic ? member.profilePic : UserAvatar}
                className="w-10 h-10 rounded-full dark:bg-white dark:border-black"
              />
              <div className="grow flex flex-col">
                <span className="text-sm">{member.email}</span>
                <span className="text-xs">({member.name})</span>
              </div>
              {renderActions(member)}
            </div>
          ) : (
            <div className="flex items-center space-x-2 py-2 hover:bg-gray-100 rounded-md ps-1 pe-2">
              <img
                src={EmailDark}
                className="w-10 h-10 rounded-full hidden dark:block"
              />
              <img
                src={EmailLight}
                className="w-10 h-10 rounded-full dark:hidden"
              />
              <div className="grow">{member.email}</div>
              {renderActions(member)}
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default SelectedUsersList;

interface SelectedUserActionProps {
  member: Member;
  handleRoleChange: (email: string, newRole: string) => void;
}

const SelectedUserAction = ({
  member,
  handleRoleChange,
}: SelectedUserActionProps) => {
  const updateRole = (newRole: string) => {
    handleRoleChange(member.email, newRole);
  };
  return (
    <div>
      <Select
        onChange={updateRole}
        options={roleTypes}
        value={member?.role ?? "Viewer"}
      />
    </div>
  );
};
