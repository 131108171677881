import React, { useState } from "react";
import { ROUTES } from "../../constants";
import { useNavigate } from "react-router-dom";

import Work_Icon from "../../assets/business_vector/work accounting.svg";
import Personal_Icon from "../../assets/business_vector/Girl Chart.svg";
import Education_Icon from "../../assets/business_vector/owned by marketing.svg";
import Other_Icon from "../../assets/business_vector/the crux of the data.svg";
import ButtonGroup from "./ButtonGroup";

interface Option {
  title: string;
  icon: string;
}

interface WhatBringsYoutoPlanbowProps {
  setOnboardingData: React.Dispatch<React.SetStateAction<{}>>;
}

const options: Option[] = [
  {
    title: "work",
    icon: Work_Icon,
  },
  {
    title: "personal",
    icon: Personal_Icon,
  },
  {
    title: "education",
    icon: Education_Icon,
  },
  {
    title: "other",
    icon: Other_Icon,
  },
];

const WhatBringsYoutoPlanbow = ({
  setOnboardingData,
}: WhatBringsYoutoPlanbowProps) => {
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    setTimeout(() => handleNext(value), 300);
  };

  const handleNext = (selectedOption: string) => {
    setOnboardingData((prev) => ({
      ...prev,
      reasonForVisiting: selectedOption,
    }));
    navigate(ROUTES.whatIsTeamSize, { state: { selectedOption } });
  };
  return (
    <div className="w-[500px] space-y-10">
      <div>
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
          What brings you to Planbow?
        </h1>
      </div>

      <form>
        <div className="grid grid-cols-2 gap-3 mb-5">
          <ButtonGroup
            handleChange={handleOptionChange}
            selectedOption={selectedOption}
            options={options}
            size="large"
          />
        </div>
      </form>
    </div>
  );
};

export default WhatBringsYoutoPlanbow;
