import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Input from "../Inputs/Input";
import Email from "../Inputs/Email";
import InviteAPI from "../../api/services/Invite";
import { useSelector } from "react-redux";
import { useGlobalModalContext } from "../../contexts/ModalProvider";

export const INVITE_MODAL_PROPS = {
  modalId: "invite-modal",
  modalButtonId: "invite-modal-btn",
};

const initialValues = {
  email: "",
};
const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

const Invite = ({ fetchTeamInvitations }) => {
  const team = useSelector((state) => state.team);
  const { hideModal } = useGlobalModalContext();

  const handleSubmit = async (values, { setSubmitting }) => {
    const { email } = values;
    if (team.value?._id) {
      await InviteAPI.new([email], {
        _id: team.value?._id,
        name: team.value?.name,
      });
      fetchTeamInvitations();
    }
    setSubmitting(false);
    hideModal(INVITE_MODAL_PROPS);
  };
  return (
    <>
      <button
        type="button"
        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 absolute -top-48"
        id={INVITE_MODAL_PROPS.modalButtonId}
        data-hs-overlay={`#${INVITE_MODAL_PROPS.modalId}`}
      >
        Open modal
      </button>

      <div
        id={INVITE_MODAL_PROPS.modalId}
        className="hs-overlay hidden w-full h-full fixed top-0 start-0 z-[60] overflow-x-hidden overflow-y-auto pointer-events-none"
      >
        <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => {
                const {
                  values,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  dirty,
                  errors,
                  resetForm,
                } = props;
                return (
                  <div>
                    <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
                      <h3 className="font-bold text-gray-800 dark:text-white">
                        Invite Member
                      </h3>
                      <button
                        type="button"
                        className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        // data-hs-overlay="#hs-slide-down-animation-modal"
                        data-hs-overlay={`#${INVITE_MODAL_PROPS.modalId}`}
                      >
                        <span className="sr-only">Close</span>
                        <svg
                          className="flex-shrink-0 w-4 h-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M18 6 6 18" />
                          <path d="m6 6 12 12" />
                        </svg>
                      </button>
                    </div>
                    <div className="p-4 overflow-y-auto">
                      <div className="mt-8 sm:mt-10 divide-y divide-gray-200 dark:divide-gray-700">
                        <form onSubmit={handleSubmit}>
                          <Field
                            label="Email"
                            id="email"
                            name="email"
                            component={Email}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter Email"
                          />
                        </form>
                      </div>
                    </div>
                    <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
                      <button
                        type="button"
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        data-hs-overlay={`#${INVITE_MODAL_PROPS.modalId}`}
                      >
                        Close
                      </button>
                      <button
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        type="submit"
                        disabled={isSubmitting || !dirty || errors.email}
                        onClick={handleSubmit}
                      >
                        Invite
                      </button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invite;
