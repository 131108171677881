import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import {
  CalendarCheck,
  CalendarClock,
  ClipboardList,
  Filter,
  Paperclip,
  Tag,
  Users,
  X,
} from "lucide-react";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: 12,
      marginRight: 4,
      marginLeft: 4,
      "& .lucide": {
        width: 14,
        height: 14,
        marginRight: 6,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 14,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function FilterIconMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div onClick={handleClick}>
        <span className="cursor-pointer inline-flex items-center gap-x-1.5 py-1.5 ps-3 pe-2 rounded-full text-xs font-medium bg-gray-100 dark:hover:bg-slate-900 text-black dark:bg-slate-700 dark:text-white hover:bg-gray-200">
          <button
            type="button"
            className="flex-shrink-0 h-4 w-4 inline-flex items-center justify-center rounded-full p-0.5"
          >
            <span className="sr-only">Remove badge</span>
            <Filter />
          </button>
          Filter
          <button
            type="button"
            className="flex-shrink-0 h-4 w-4 inline-flex items-center justify-center rounded-full hover:bg-gray-300 p-0.5 focus:outline-none focus:bg-blue-200 focus:text-blue-500 dark:hover:bg-blue-900"
          >
            <span className="sr-only">Remove badge</span>
            <X />
          </button>
        </span>
      </div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <ClipboardList />
          Action Item
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <CalendarCheck />
          Start Date
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <CalendarClock />
          End Date
        </MenuItem>
        {/* <Divider sx={{ my: 0.5 }} /> */}
        <MenuItem onClick={handleClose}>
          <Users />
          Assignee
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Paperclip />
          Attachments
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Tag />
          Tags
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
