import React, { useMemo, useState } from "react";
import moment from "moment";
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
// import defaultEvents from "./events";
import * as dates from "./dates";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
// import { openCreateEventDialog } from "../../redux/slice/ui/dialogSlice";
import CreateEventDialog from "./CreateEventDialog";
import "./events.css";
import EventDetailsDialog from "./EventDetailsDialog";
const mLocalizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    // style: {
    //   backgroundColor: "lightgray",
    // },
    className: "flex-1 bg-gray-200 dark:bg-slate-700",
  });

let eventt;

const EventCalendar = ({
  fetchEvents,
  events,
  currentDate,
  rawData,
  readOnly,
}) => {
  const [newReadOnly, setNewReadOnly] = useState(readOnly);
  const planboard = useSelector((state) => state.planboard.value);
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const [selectedEvent, setSelectedEvent] = useState(null);
  // const [open, setOpen] = useState(false);
  const handleClose = () => {
    setSelectedEvent(null);
    // setOpen(false);
  };
  // const [events, setEvents] = useState(defaultEvents);
  const { components, defaultDate, max, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      defaultDate: new Date(),
      max: dates.add(dates.endOf(new Date(2015, 17, 1), "day"), -1, "hours"),
      // max: new Date(planboard.endDate),
      views: Object.keys(Views).map((k) => Views[k]),
    }),
    []
  );

  const handleSelectEvent = (a) => {
    setSelectedEvent(a.id);
    eventt = rawData[a.type]?.find((item) => item?.id === a?.id) ?? null;
    if (eventt?.createdBy == user.id) {
      setNewReadOnly(true);
    }
    // setOpen(true);
    // openEventDialog();
  };

  const handleNavigate = (date, view, c) => {
    console.log({ date, view, c });
    let start, end;

    // Compute start and end dates based on the view
    switch (view) {
      case "month":
        start = moment(date).startOf("month");
        end = moment(date).endOf("month");
        break;
      case "week":
        start = moment(date).startOf("week");
        end = moment(date).endOf("week");
        break;
      case "work_week":
        start = moment(date).startOf("isoWeek"); // Monday
        end = moment(date).endOf("isoWeek");
        break;
      case "day":
        start = moment(date).startOf("day");
        end = moment(date).endOf("day");
        break;
      case "agenda":
        start = moment(date).startOf("month");
        end = moment(date).endOf("month");
        break;
      default:
        start = moment(date).startOf("month");
        end = moment(date).endOf("month");
        break;
    }

    // const oldStartDate = new Date(currentDate.start);
    // const oldEndDate = new Date(currentDate.end);
    // const newStartDate = new Date(start);
    // const newEndDate = new Date(end);
    // if (newStartDate >= oldStartDate && newEndDate <= oldEndDate) return;
    // else return
    fetchEvents(start, end);
  };

  const openEventDialog = () => {
    // dispatch(openCreateEventDialog("update"));
  };

  const handleRangeChange = (a, b) => {
    console.log({ a, b });
    if (b === "month" || b === "agenda") return fetchEvents(a.start, a.end);
    if (b === "week" || b === "work_week")
      return fetchEvents(a[0], a[a.length - 1]);
    if (b === "day") return fetchEvents(a, a);
  };
  // useEffect(() => {
  //   console.log({ selectedEvent });
  //   if (selectedEvent?.createdBy == user.id) {
  //     alert("same creator");
  //     setNewReadOnly(false);
  //   }
  // }, [selectedEvent]);

  // const onNavigate = (date, view, action) => {
  //   console.log({ date, view, action });
  // };

  return (
    <div className="grow border rounded-md p-2 dark:text-neutral-300">
      <Calendar
        popup
        components={components}
        defaultDate={defaultDate}
        events={events}
        localizer={mLocalizer}
        max={max}
        showMultiDayTimes
        step={60}
        views={views}
        onSelectEvent={handleSelectEvent}
        eventPropGetter={(myEventsList) => {
          const backgroundColor = myEventsList.colorEvento
            ? myEventsList.colorEvento
            : "lightgray";
          const color = myEventsList.color ? myEventsList.color : "black";
          return { style: { backgroundColor, color } };
        }}
        onNavigate={handleNavigate}
        onRangeChange={handleRangeChange}
      />
      <CreateEventDialog
        action="update"
        eventId={selectedEvent?.id}
        handleClose={handleClose}
        open={Boolean(selectedEvent && newReadOnly)}
        // selectedEvent={
        //   rawData.length ??
        //   rawData?.find((item) => item?.id === selectedEvent?.id) ??
        //   null
        // }
        selectedEvent={eventt}
      />
      <EventDetailsDialog
        selectedEvent={eventt}
        open={Boolean(selectedEvent && !newReadOnly)}
        handleClose={handleClose}
      />
    </div>
  );
};

export default EventCalendar;
