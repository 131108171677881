import {
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  useOnViewportChange,
  useReactFlow,
} from "reactflow";
import { v4 as uuidv4 } from "uuid";
import CanvasAPI from "../api/services/Canvas";
import { useParams } from "react-router-dom";
import NodesAPI from "../api/services/Nodes";
import { useToast } from "./useToast";
import PlanboardsAPI from "../api/services/Planboard";
import { useDispatch, useSelector } from "react-redux";
import { updateActivePlanboard } from "../redux/slice/planboard";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { ROLE_TYPES } from "../types/types/PlanboardRoles";

// Node types
const START_NODE_TYPE = "start";
const SKELETON_NODE_TYPE = "skeleton";
const CUSTOM_NODE_TYPE = "custom";

export const FLOATING_EDGE_TYPE = "floating";

// Node dimensions
const nodeHeight = 300;
const nodeWidth = 384;

// Spacing between nodes
const horizontalGap = 100;
const verticalGap = 200;

const useCanvas = () => {
  const { setEdges, setNodes, getNodes, getEdges, getViewport } =
    useReactFlow();
  const { id } = useParams();
  const toast = useToast();
  const dispatch = useDispatch();

  const planboard = useSelector((state) => state.planboard);

  const getId = () => "node_" + uuidv4();

  const getSkeletonNodes = (nodes, edges) => {
    if (!nodes) return;
    if (
      planboard.value?.userRole === ROLE_TYPES.CONTRIBUTOR ||
      planboard.value?.userRole === ROLE_TYPES.VIEWER
    )
      return { skeletonNodes: nodes, skeletonEdges: edges };
    const parentNodes = nodes.filter(
      (node) =>
        node.type !== "skeleton" && getOutgoers(node, nodes, edges).length === 0
    );
    if (!parentNodes.length) return;

    const skeletonNodes = [];
    const skeletonEdges = [];

    parentNodes.forEach((node) => {
      const skeletonNodeId = getId();
      const skeleton = {
        id: skeletonNodeId,
        type: "skeleton",
        style: { color: "#0f2" },
        data: {
          label: "skel" + skeletonNodeId,
          parentNode: {
            id: node.id,
            position: { x: node.position.x, y: node.position.y },
          },
          deletable: false,
        },
        position: {
          x: node.position.x + 450,
          y: node.height
            ? node.position.y + node.height / 2
            : node.position.y + 150,
        },
      };
      skeletonNodes.push(skeleton);
      const newEdge = {
        id: `reactflow__edge-${node.id}-${skeletonNodeId}`,
        source: node.id,
        target: skeletonNodeId,
        type: FLOATING_EDGE_TYPE,
      };
      skeletonEdges.push(newEdge);
    });
    return { skeletonNodes, skeletonEdges };
  };

  const getCurrentState = () => {
    const nodes = getNodes();
    const edges = getEdges();
    return { nodes, edges };
  };

  const getPureNodes = ({ nodes, edges }) => {
    const skeletonNodes = nodes.filter((node) => node.type === "skeleton");
    const connectedEdges = getConnectedEdges(skeletonNodes, edges);
    let remainingEdges = edges.filter((edge) => !connectedEdges.includes(edge));
    const nonSkeletonNodes = nodes.filter((node) => node.type !== "skeleton");
    return { nodes: nonSkeletonNodes, edges: remainingEdges };
  };

  const initialNodes = [
    {
      id: "1",
      type: "start",
      data: { label: "Start", sourcePosition: "right", deletable: false },
      className:
        "bg-white p-1 w-28 h-20 rounded-lg flex justify-center text-purple-700 border border-gray-300",
      position: { x: 0, y: 0 },
    },
  ];

  const resizeObserverErrorHandler = (e) => {
    if (
      e.message.includes(
        "ResizeObserver loop completed with undelivered notifications" ||
          "ResizeObserver loop limit exceeded"
      )
    ) {
      const resizeObserverErr = document.getElementById(
        "webpack-dev-server-client-overlay"
      );
      if (resizeObserverErr) {
        resizeObserverErr.style.display = "none";
      }
    }
  };

  const updateNodePosition = (node) => {
    console.log({ node }, "jjjjjjjjjjjjjjjjj");
    try {
      NodesAPI.updateNode(node.id, id, {
        metaData: {
          x_position: node.position.x,
          y_position: node.position.y,
          color: node.data.color,
        },
      });
    } catch (error) {
      console.log({ error });
    }
  };

  // const generateUniqueName = () => {
  //   const nodes = getNodes();
  //   const baseName = "New Component";
  //   const matchingNodes =
  //     nodes?.filter((node) => node?.data?.label?.includes(baseName)) ?? [];
  //   const count = matchingNodes.length;
  //   if (count > 0) {
  //     return `${baseName}${count}`;
  //   }
  //   return baseName;
  // };
  const generateUniqueName = (previousTitle = "") => {
    const nodes = getNodes();
    const baseName = "New Component";
    const sanitizedPreviousTitle = previousTitle.replace(/\s+/g, "-");

    const matchingNodes =
      nodes?.filter((node) =>
        node?.data?.label?.includes(
          previousTitle ? sanitizedPreviousTitle : baseName
        )
      ) ?? []; // Modify Search

    let count = matchingNodes.length;

    if (count > 0) {
      return previousTitle
        ? `${sanitizedPreviousTitle}${count + 1}`
        : `${baseName}${count + 1}`; // Consistent with previous title absent
    } else {
      // Default case (no similar name found)
      return previousTitle ? sanitizedPreviousTitle : baseName;
    }
  };

  const deleteMiddleNode = (deleted) => {
    if (deleted.length > 1)
      return alert("You can delete only 1 node at a time.");

    NodesAPI.delete({
      nodeId: deleted[0].id,
      planboardId: id,
    });
    // if (response.status !== 200) return console.error("Error deleting Node");
    const { nodes, edges } = getCurrentState();
    const newEdges = deleted.reduce((acc, node) => {
      const incomers = getIncomers(node, nodes, edges);
      const outgoers = getOutgoers(node, nodes, edges).filter(
        (node) => node.type !== "skeleton"
      );
      const connectedEdges = getConnectedEdges([node], edges);

      const remainingEdges = acc.filter(
        (edge) => !connectedEdges.includes(edge)
      );

      const createdEdges = incomers.flatMap(({ id: source }) =>
        outgoers.map(({ id: target }) => ({
          id: `${source}->${target}`,
          source,
          target,
          type: FLOATING_EDGE_TYPE,
        }))
      );
      saveNewEdges(createdEdges);
      return [...remainingEdges, ...createdEdges];
    }, edges);

    const filteredNodes = nodes.filter((node1) => {
      return !deleted.some((node2) => node1.id === node2.id);
    });
    const { nodes: pureNodes, edges: pureEdges } = getPureNodes({
      nodes: filteredNodes,
      edges: newEdges,
    });
    const { skeletonNodes, skeletonEdges } = getSkeletonNodes(
      pureNodes,
      pureEdges
    );
    setNodes([...pureNodes, ...skeletonNodes]);
    setEdges([...pureEdges, ...skeletonEdges]);
  };

  const saveNewEdges = async (edges) => {
    edges.map(async (edge) => {
      const response = await NodesAPI.connectEdge({
        planboardId: id,
        sourceNodeId: edge.source,
        targetNodeId: edge.target,
      });
    });
  };

  const handleEdgeConnect = (data) => {
    if (data.source !== "start")
      NodesAPI.connectEdge({
        planboardId: id,
        sourceNodeId: data.source,
        targetNodeId: data.target,
      });
    const { nodes, edges } = getPureNodes(getCurrentState());
    const { skeletonNodes, skeletonEdges } = getSkeletonNodes(nodes, [
      ...edges,
      data,
    ]);
    setEdges([...edges, ...skeletonEdges, data]);
    setNodes([...nodes, ...skeletonNodes]);
    return;
  };

  const handleEdgeDelete = (edgeId, source, target) => {
    // data.map((edge) => {
    //   if (edge.source !== "start")
    //     NodesAPI.disconnectEdge({
    //       planboardId: id,
    //       sourceNodeId: edge.source,
    //       targetNodeId: edge.target,
    //     });
    // });
    NodesAPI.disconnectEdge({
      planboardId: id,
      sourceNodeId: source,
      targetNodeId: target,
    });
    const { nodes, edges } = getPureNodes(getCurrentState());

    // const filteredEdges = edges.filter((edge1) => {
    //   return !data.some((edge2) => edge1.id === edge2.id);
    // });
    const filteredEdges = edges.filter((edge1) => edge1.id !== edgeId);
    console.log({ edgeId, source, target });
    console.log({ filteredEdges });
    const { skeletonNodes, skeletonEdges } = getSkeletonNodes(
      nodes,
      filteredEdges
    );
    setEdges([...filteredEdges, ...skeletonEdges]);
    setNodes([...nodes, ...skeletonNodes]);
    // }
  };

  const createNewNode = ({ title, id, position, description }) => {
    return {
      id,
      type: "custom",
      sourcePosition: "right",
      targetPosition: "left",
      position: {
        x: position.x + 250,
        y: position.y,
      },
      data: {
        label: title,
        description,
        background: "#ffffff",
        completed: false,
        deletable: true,
        assignedTo: null,
      },
    };
  };

  const createNewEdge = (sourceNodeId, targetNodeId) => {
    return {
      id: `reactflow__edge-${sourceNodeId}-${targetNodeId}`,
      source: sourceNodeId,
      target: targetNodeId,
      type: FLOATING_EDGE_TYPE,
      style: { strokeWidth: 3 },
    };
  };

  const addNewNode = async (parentNode) => {
    if (!parentNode) return;
    const { nodes, edges } = getPureNodes(getCurrentState());
    const nodeData = {
      parentId: parentNode.id,
      x_position: parentNode.position.x + 400,
      y_position: parentNode.position.y,
    };
    const response = await addNodeAPI(nodeData);
    if (response.error) {
      return false;
    }

    //  const { newNodes, newEdges } = handleCreateNodesAndEdges(
    //   parentNode,
    //   response.data.data.nodeId
    // );
    const newNode = createNewNode({
      title: response.title,
      description: "",
      id: response.id,
      position: { x: nodeData.x_position, y: nodeData.y_position },
    });
    const newEdge = createNewEdge(parentNode.id, response.id);

    const { skeletonNodes, skeletonEdges } = getSkeletonNodes(
      nodes.concat(newNode),
      edges.concat(newEdge)
    );

    setNodes([...nodes, newNode, ...skeletonNodes]);
    setEdges([...edges, newEdge, ...skeletonEdges]);
    return true; // Node added successfully
  };

  const addNodeAPI = async ({ parentId, x_position, y_position }) => {
    let retryAttempts = 0;
    const MAX_RETRY_ATTEMPTS = 10; // Set a maximum number of retries

    let newTitle = generateUniqueName();
    let response;

    while (retryAttempts < MAX_RETRY_ATTEMPTS) {
      try {
        response = await NodesAPI.add({
          planboardId: id,
          title: newTitle,
          parentId,
          x_position,
          y_position,
        });

        // Successful API call, break the loop
        console.log(response);
        if (response) break;
      } catch (error) {
        if (error.response && error.response.status === 409) {
          retryAttempts++;
          newTitle = generateUniqueName(newTitle);
          console.error("Conflict (409), retrying with a new name");
        } else {
          // Handle other errors
          console.error("Error adding node:", error);
          return { error: true };
        }
      }
    }

    // If retries exceeded
    if (retryAttempts === MAX_RETRY_ATTEMPTS) {
      console.error("Failed to add node after maximum retries");
      return { error: true };
    }

    // Return the result after successful API call
    return { id: response.data.data.nodeId, title: newTitle };
  };

  const updateNode = (nodeId, setNodes) => {
    const nodes = getNodes();
    const nodeIndex = nodes.findIndex((node) => node.id === nodeId);
    if (nodeIndex !== -1 && nodes[nodeIndex].data.building !== false) {
      nodes[nodeIndex].data.building = false;
      setNodes([...nodes]);
    }
  };

  let isBuildCanvasCalled = false;

  const fetchCanvas = async (setNodes, setEdges) => {
    const response = await PlanboardsAPI.getPlanboardNodes(id);
    if (response.status === 200) {
      const data = response.data.data;
      if (response.data.data.length === 0) {
        return setTimeout(() => fetchCanvas(setNodes, setEdges), 3000);
      }
      if (!isBuildCanvasCalled) {
        buildCanvas(data, setNodes, setEdges);
        isBuildCanvasCalled = true;
      }
      const hasBuilding = response.data.data.some(
        (obj) => obj.building === true
      );
      data.forEach((node) => {
        if (node.building === false) {
          updateNode(node.id, setNodes);
        }
      });
      if (hasBuilding) {
        setTimeout(() => fetchCanvas(setNodes, setEdges), 3000);
      }
      // else
      //   data.forEach((node) => {
      //     updateNode(node.id, setNodes);
      //   });
      return;
    } else {
      setNodes(initialNodes);
      toast.error(response?.data?.message ?? "Canvas Fetch Failed");
    }
    return;
  };

  function createNode(nodeData) {
    return {
      id: nodeData.id,
      type: CUSTOM_NODE_TYPE,
      position: nodeData.position,
      data: {
        label: nodeData.title,
        description: nodeData.description,
        background: nodeData?.metaData?.color,
        actionItems: nodeData?.actionItems ?? 0,
        subTasks: nodeData?.subTasks ?? 0,
        events: nodeData?.events ?? 0,
        assignedTo: nodeData?.assignedTo ?? null,
        endDate: nodeData?.endDate ?? null,
        color: nodeData?.metaData?.color,
        building: nodeData.building,
      },
    };
  }

  const generateNodes = (nodesData) => {
    return nodesData.map((node) => {
      const createdNode = createNode(node);
      createdNode.position = {
        x: node.metaData?.x_position || 0, // Use 0 as default if x_position is missing
        y: node.metaData?.y_position || 0, // Use 0 as default if y_position is missing
      };
      return createdNode;
    });
  };

  // Function to generate Node objects for React Flow
  function generateNodesWithPosition(nodesData) {
    const nodes = [];
    const childPositions = []; // Track child positions on each level

    // Find the root node
    const rootNode = nodesData.find((node) => node.parentId === null);
    if (!rootNode) return;
    // Calculate positions starting from the root node
    // calculatePositions(nodesData, rootNode, 0, 0, 0, childPositions);

    const calculatePositions = (node, parentX, parentY, level) => {
      const numChildren = node.childIds?.length || 0;

      // Calculate x position based on parent's position and number of children
      const childX = parentX + 500 + horizontalGap; // Adjust as needed

      // Calculate y position based on level (consider your layout)
      const childY = parentY;

      node.position = { x: childX, y: childY };
      childPositions[level] = childPositions[level] || [];
      childPositions[level].push(childX);

      // Recursively calculate positions for children (if any)
      if (node.childIds) {
        for (const childId of node.childIds) {
          const childNode = nodesData.find((n) => n.id === childId);
          if (!childNode) return;
          calculatePositions(childNode, childX, childY, level + 1);
        }
      }
    };

    calculatePositions(rootNode, 0, 0, 0);

    nodesData.forEach((node) => {
      nodes.push(createNode(node));
    });

    return nodes;
  }

  const setNodesMetaData = async (nodes) => {
    const newNodes = nodes.map((node) => {
      return {
        id: node.id,
        metaData: {
          x_position: node.position.x,
          y_position: node.position.y,
          color: "#118DD9",
        },
      };
    });
    const response = await NodesAPI.setNodesMetaData({
      planboardId: id,
      nodes: newNodes,
    });
    if (response.status === 200) {
      dispatch(updateActivePlanboard({ nodeInitialization: true }));
    }
  };

  // Function to generate Edge objects for React Flow
  function generateEdges(nodesData) {
    const edges = [];

    nodesData.forEach((node) => {
      if (node.childIds) {
        node.childIds.forEach((childId) => {
          edges.push({
            id: `${node.id}-${childId}`, // Create a unique edge ID
            source: node.id,
            target: childId,
            type: FLOATING_EDGE_TYPE,
            style: { strokeWidth: 3 },
          });
        });
      }
    });

    // Connect the start node to the root node
    const rootNode = nodesData.find((node) => node.parentId === null);
    if (rootNode) {
      edges.push({
        id: "start-root",
        source: "start",
        target: rootNode.id,
        type: FLOATING_EDGE_TYPE,
        style: { strokeWidth: 3 },
      });
    }

    return edges;
  }

  const buildCanvas = (data, setNodes, setEdges) => {
    let nodes;
    if (planboard?.value?.nodeInitialization) {
      nodes = generateNodes(data);
    } else {
      nodes = generateNodesWithPosition(data);
      setNodesMetaData(nodes);
    }
    if (nodes.length == 0) {
      toast.error("Error loading data");
      return;
    }
    const edges = generateEdges(data);

    // Add the start node
    nodes.push({
      id: "start",
      type: START_NODE_TYPE,
      position: { x: 0, y: 0 }, // Position the start node at the origin
      data: { label: "Start" }, // Customize the label if you want
      className:
        "bg-white p-1 w-28 h-20 rounded-lg flex justify-center text-purple-700 border border-gray-300",
    });

    const { skeletonNodes, skeletonEdges } = getSkeletonNodes(nodes, edges);
    console.log({ nodes, edges });
    setNodes([...nodes, ...skeletonNodes]);
    setEdges([...edges, ...skeletonEdges]);
  };

  return {
    addNewNode,
    deleteMiddleNode,
    fetchCanvas,
    handleEdgeDelete,
    resizeObserverErrorHandler,
    getPureNodes,
    updateNodePosition,
    handleEdgeConnect,
  };
};
export default useCanvas;
