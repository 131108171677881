import { URLS } from "../../constants/index.jsx";
import apiClient from "../client";

const RegistrationAPI = {
  step1: ({ email }: { email: string }) =>
    apiClient.post(URLS.registerEmail, {
      email,
    }),
  verifyEmail: ({ id, token }: { id: string; token: string }) =>
    apiClient.post(URLS.verifyEmail, {
      id,
      token,
    }),
  name: ({ id, name }: { id: string; name: string }) =>
    apiClient.post(URLS.registerName, {
      id,
      name,
    }),
  password: ({ id, password }: { id: string; password: string }) =>
    apiClient.post(URLS.registerPassword, {
      id,
      password,
    }),
  authSocial: () => {
    return apiClient.get(URLS.authSocial)
  }
};

export default RegistrationAPI;
