import apiClient, { postAuth, putAuth } from "../client";
import { ACTION_ITEMS_URLS, URLS } from "../../constants/index.jsx";

interface CreateProps {
  nodeId: string;
  planboardId: string;
  title: string;
  description?: string;
  parentId?: string;
  endDate?: string;
}

interface UpdateProps {
  actionItemId: string;
  title?: string;
  description?: string;
  parentId?: string;
  priority?: "low" | "medium" | "high" | "critical";
  endDate?: string; // Format: dd-MM-yyyy
  assignedTo?: number;
}

const ActionItemsAPI = {
  get: (planboardId: string, nodeId: string) => {
    return postAuth(ACTION_ITEMS_URLS.getActionItems, { planboardId, nodeId });
  },
  delete: (actionItemId: string) => {
    return postAuth(ACTION_ITEMS_URLS.deleteActionItem, { actionItemId });
  },
  update: ({
    actionItemId,
    title,
    description,
    parentId,
    priority,
    endDate,
    assignedTo,
  }: UpdateProps) => {
    const requestData: UpdateProps = {
      actionItemId,
    };
    if (title !== undefined) requestData.title = title;
    if (description !== undefined) requestData.description = description;
    if (parentId !== undefined) requestData.parentId = parentId;
    if (priority !== undefined) requestData.priority = priority;
    if (endDate !== undefined) requestData.endDate = endDate;
    if (assignedTo !== undefined) requestData.assignedTo = assignedTo;
    return postAuth(ACTION_ITEMS_URLS.updateActionItem, requestData);
  },
  create: ({
    nodeId,
    planboardId,
    title,
    description,
    parentId,
    endDate,
  }: CreateProps) => {
    const requestData: CreateProps = {
      nodeId,
      planboardId,
      title,
    };
    if (description !== undefined) requestData.description = description;
    if (parentId !== undefined) requestData.parentId = parentId;
    if (endDate !== undefined) requestData.endDate = endDate;
    return postAuth(ACTION_ITEMS_URLS.addActionItem, requestData);
  },
};

export default ActionItemsAPI;
