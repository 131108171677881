import { DB_BASE_URL } from "../../constants";
import { deleteAuth, getAuth, postAuth, putAuth } from "../client";

const EVENTS_URLS = {
  createEvent: DB_BASE_URL + "/events/add-event",
  getEvents: DB_BASE_URL + "/events/get-events",
  // getAllEvents: "/events/fetch-all",
  // getEventById: "/events",
  updateEvent: DB_BASE_URL + "/events/update-event",
  upcomingEvents: DB_BASE_URL + "/events/upcoming-events",
  cancelEvent: DB_BASE_URL + "/events/cancel-event",
  // deleteEventById: "/events/delete",
  // deleteEventsByPlanboardId: "/events/deleteMany",
};

type Reccurence = "dont repeat" | "daily" | "weekly" | "monthly" | "yearly";

export interface CreateEventProps {
  planboardId: string;
  nodeId: string;
  meetingTypeId: string;
  title: string;
  description: string;
  recurrence: Reccurence;
  start: string;
  end: string;
}
export interface UpdateEventProps {
  eventId: string;
  meetingTypeId?: string;
  title?: string;
  description?: string;
  recurrence?: Reccurence;
  start?: string;
  end?: string;
}

const EventsAPI = {
  createEvent: ({
    planboardId,
    nodeId,
    meetingTypeId,
    title,
    description,
    recurrence,
    start,
    end,
  }: CreateEventProps) => {
    return postAuth(EVENTS_URLS.createEvent, {
      planboardId,
      nodeId,
      meetingTypeId,
      title,
      description,
      recurrence,
      start,
      end,
    });
  },
  updateEvent: ({
    eventId,
    meetingTypeId,
    title,
    description,
    recurrence,
    start,
    end,
  }: UpdateEventProps) => {
    return postAuth(EVENTS_URLS.updateEvent, {
      eventId,
      meetingTypeId,
      title,
      description,
      recurrence,
      start,
      end,
    });
  },
  getEvents: ({
    start,
    end,
    planboardId,
    meetingTypeIds,
  }: {
    start: string;
    end: string;
    planboardId: string;
    meetingTypeIds: string[];
  }) =>
    postAuth(EVENTS_URLS.getEvents, {
      start,
      end,
      planboardId,
      meetingTypeIds,
    }),
  upcomingEvents: ({
    start,
    planboardId,
  }: {
    start: string;
    planboardId: string;
  }) => postAuth(EVENTS_URLS.upcomingEvents, { start, planboardId }),
  cancelEvent: (id: string) =>
    postAuth(EVENTS_URLS.cancelEvent, { eventId: id }),
  // getAllEvents: (planboardId: string) => {
  //   return getAuth(`${EVENTS_URLS.getAllEvents}/${planboardId}`);
  // },
  // getEventById: (id: string) => {
  //   return getAuth(`${EVENTS_URLS.getEventById}/${id}`);
  // },
  // updateEvent: (updateData: { id: string; updateData: {} }) => {
  //   return putAuth(EVENTS_URLS.updateEvent, updateData);
  // },
  // deleteEventById: (id: string) => {
  //   return deleteAuth(`${EVENTS_URLS.deleteEventById}/${id}`);
  // },
  // deleteEventsByPlanboardId: (planboardId: string) => {
  //   return deleteAuth(
  //     `${EVENTS_URLS.deleteEventsByPlanboardId}/${planboardId}`
  //   );
  // },
};

export default EventsAPI;
