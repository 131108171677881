import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewWorkspace,
  createWorkspace,
  getWorkspaces,
} from "../../redux/slice/workspaces/workspacesSlice";
import WorkspaceAccordion from "../WorkspaceAccordion";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { Plus } from "lucide-react";
import WorkspaceSort from "./WorkspaceSort";
import { usePageName, useToast } from "../../hooks";

const sortOptions = [
  {
    value: "Created With",
    label: "Created With",
  },
  {
    value: "Alphabetical",
    label: "Alphabetical",
  },
];

const Workspaces = () => {
  const dispatch = useDispatch();

  const organizations = useSelector((state) => state.organizations);
  const workspaces = useSelector((state) => state.workspaces);
  const [sort, setSort] = useState(sortOptions[0].value);

  const navigate = useNavigate();
  const toast = useToast();
  const { activeRoute } = usePageName();


  const handleNewWorkspace = (e) => {
    const activeWorkspaces = workspaces.value.filter(
      (workspace) => workspace.active
    );
    if (activeWorkspaces.length > 5)
      return toast.error(
        `You can only create ${Number(activeWorkspaces.length)} workspaces`
      );
    e.preventDefault();
    dispatch(createWorkspace())
      .then((result) => {
        console.log({ result: result.payload });
        if (result.payload?.status?.code == 200) {
          dispatch(addNewWorkspace(result.payload.data));
          toast.success(
            <div>
              New workspace <strong>{result.payload.data.name}</strong> created
              successfully
            </div>
          );
          navigate(ROUTES.workspaces + "/" + result.payload.data.id, {
            state: { new: true },
          });
        }
      })
      .catch((error) => {
        console.log({ error });
        toast.error("Error creating new workspace");
      });
  };

  const fetchWorkspaces = () => {
    dispatch(
      getWorkspaces({
        organizationId: organizations?.value[0]?.organizationId,
        index: 0,
        itemsPerIndex: 100,
        sort,
      })
    );
  };

  useEffect(() => {
    fetchWorkspaces();
  }, [sort]);

  // const previousSort = useRef(sort); // Initialize with current sort value

  // useEffect(() => {
  //   if (sort !== previousSort.current) {
  //     fetchWorkspaces();
  //   }
  //   previousSort.current = sort; // Update after the effect
  // }, [sort]);
  return (
    <div className="flex h-full dark:bg-slate-900">
      <main
        id="all-workspaces"
        role="main"
        className="w-full max-h-[98vh] overflow-y-scroll bg-white border border-gray-200 dark:bg-slate-800 dark:border-gray-700"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        {/* <!-- Invoice --> */}
        <div className="max-w-[100rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10">
          {/* <!-- Grid --> */}
          <div className="mb-5 pb-5 flex justify-between items-center border-b border-gray-200 dark:border-neutral-500">
            <div>
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                All Workspaces
              </h2>
            </div>
            {/* <!-- Col --> */}

            <div className="inline-flex gap-x-2">
              <button
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-300 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                onClick={handleNewWorkspace}
              >
                <Plus />
                Create workspace
              </button>
            </div>
            {/* <!-- Col --> */}
          </div>
          <div className="w-56">
            <WorkspaceSort
              options={sortOptions}
              selectedValue={sort}
              setSelectedValue={setSort}
            />
          </div>
          {/* <!-- End Grid --> */}
          {/* <!-- Grid --> */}
          <div className="mb-5 pb-5 flex w-full justify-between items-center dark:border-neutral-700 flex-col space-y-3">
            {(workspaces.loading && workspaces.value.length === 0) ||
              organizations.loading ? (
              <div className="w-full space-y-8 my-5">
                {[1, 2, 3, 4, 5].map((item) => (
                  <div key={item} class="flex animate-pulse ms-2 me-10 w-full">
                    <h3 class="h-11 bg-gray-200 rounded-md dark:bg-slate-700 w-full"></h3>
                  </div>
                ))}
              </div>
            ) : (
              workspaces.value
                .filter((workspace) => workspace.active !== false)
                .map((workspace) => (
                  <WorkspaceAccordion
                    workspace={workspace}
                    key={workspace.id}
                    planboards={workspace.planBoards}
                  />
                ))
            )}
          </div>
          {/* <!-- End Grid --> */}
        </div>
      </main>
    </div>
  );
};

export default Workspaces;
