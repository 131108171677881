import { createSlice, createAsyncThunk, ThunkAction } from "@reduxjs/toolkit";
import PlanboardsAPI from "../../../api/services/Planboard";
import Planboard from "../../../types/interfaces/Planboard";

// Thunk action creator type
type FetchPlanboardsThunkActionCreator = ({
  param1,
  param2,
}: {
  param1: string;
  param2: string;
}) => ThunkAction<void, IPlanboardsState, null, FetchPlanboardsAction>;

// Thunk action type
interface FetchPlanboardsAction {
  type: string;
  payload: { loading: boolean } | { error: any } | Planboard[]; // Add Planboard[] for fulfilled state
}
interface IPlanboardsState {
  error: object | null;
  value: Planboard[];
  loading: boolean;
}
const initialState: IPlanboardsState = {
  error: null,
  value: [],
  loading: false,
};
export const fetchRecentPlanboards = createAsyncThunk(
  "planboards/fetchRecentPlanboards",
  async (teamId: string, thunkAPI) => {
    try {
      const response = await PlanboardsAPI.getRecentPlanboards(teamId);
      return response.data.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);
const recentPlanboardsSlice = createSlice({
  name: "recentPlanboards",
  initialState,
  reducers: {
    // removeFromPlanboard: (state, action) => {
    //   const { id } = action.payload;
    //   state.value = state.value.filter((planboard) => planboard._id !== id);
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecentPlanboards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRecentPlanboards.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(fetchRecentPlanboards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as { error: any };
      });
  },
});
export default recentPlanboardsSlice.reducer;
