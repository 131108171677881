import React from "react";
import ErrorMessage from "./ErrorMessage";
import ErrorIcon from "./ErrorIcon";

export default function Email({
  field,
  label,
  name,
  id,
  value,
  form: { touched, errors },
  ...props
}) {
  return (
    <div>
      <label htmlFor="email" className="block text-sm mb-2 dark:text-white">
        {label}
      </label>
      <div className="relative">
        <input
          required
          aria-describedby="email-error"
          type="email"
          {...field}
          {...props}
          className="py-3 px-4 block w-full hover:bg-gray-100 dark:hover:bg-slate-800 border border-gray-200 rounded-lg text-sm focus:border-primary-600 focus:ring-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
        />
        <ErrorIcon touched={touched} errors={errors} field={field} />
      </div>
      <ErrorMessage touched={touched} errors={errors} field={field} />
    </div>
  );
}
