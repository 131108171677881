import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import OBJECTIVES from "../../data/objectives.json";
import BackButton from "./BackButton";
interface Option {
  title: String;
  icon: string;
}

interface SelectObjectiveProps {
  setOnboardingData: React.Dispatch<React.SetStateAction<{}>>;
}

const maxSelections = 5;

const SelectObjective = ({ setOnboardingData }: SelectObjectiveProps) => {
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const handleOptionChange = (option: Option) => {
    // Check if the option is already selected
    const isSelected = selectedOptions.some(
      (selectedOption) => selectedOption.title === option.title
    );

    if (isSelected) {
      // If the option is already selected, remove it from the selectedOptions array
      setSelectedOptions(
        selectedOptions.filter(
          (selectedOption) => selectedOption.title !== option.title
        )
      );
    } else {
      // If the number of selected options is less than the maximum limit, add the option to the selectedOptions array
      if (selectedOptions.length < maxSelections) {
        setSelectedOptions([...selectedOptions, option]);
      }
    }
  };

  const handleNext = () => {
    if (!selectedOptions) return alert("select an option");
    setOnboardingData((prev) => ({
      ...prev,
      scope: selectedOptions.map((option) => option.title).join(", "),
    }));
    navigate(ROUTES.selectDeadline);
  };
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    console.log({ selectedOptions });
  }, [selectedOptions]);

  return (
    <>
      <div>
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
          Select your objective
        </h1>
        <p className="mt-1 text-sm text-gray-500 dark:text-neutral-500">
          You can select upto {maxSelections}
        </p>
      </div>

      <form>
        <div className="flex flex-wrap gap-2 mb-5 max-h-[65vh] overflow-auto">
          {OBJECTIVES.map((option: { title: string; icon: string }) => (
            <label
              key={option.title}
              htmlFor={"hs-pro-donpf1" + option.title}
              className="relative flex cursor-pointer text-sm focus:outline-none"
            >
              <input
                type="checkbox"
                id={"hs-pro-donpf1" + option.title}
                name="hs-pro-donpf"
                checked={selectedOptions.some(
                  (opt) => opt.title === option.title
                )}
                className={`peer absolute hidden top-0 start-0 size-full bg-transparent border-gray-200 text-transparent rounded-xl cursor-pointer focus:ring-0 focus:ring-offset-0 after:relative after:-z-[1] after:block after:size-full after:rounded-lg checked:after:bg-blue-50 checked:text-transparent checked:border-blue-600 checked:hover:border-blue-600 checked:focus:border-blue-600 checked:bg-none disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:checked:after:bg-blue-500/10 dark:checked:border-blue-500 ${
                  selectedOptions.length >= maxSelections &&
                  !selectedOptions.some((opt) => opt.title === option.title)
                    ? "disabled"
                    : ""
                }`}
                onChange={() => handleOptionChange(option)}
              />
              <span className="flex peer-checked:text-blue-600 border peer-checked:border-blue-500 peer-checked:bg-blue-50 dark:text-white dark:peer-checked:text-blue-500 py-3 px-4 rounded-xl capitalize">
                <svg
                  className="inline-block mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24} // You can pass the width dynamically
                  height={24} // You can pass the height dynamically
                  viewBox="0 0 24 24"
                  strokeWidth={2} // You can pass the strokeWidth dynamically
                  dangerouslySetInnerHTML={{ __html: option.icon }}
                />
                {option.title}
              </span>
            </label>
          ))}
        </div>

        {/* {selectedOptions.length >= maxSelections && (
          <p className="text-red-500 text-sm mb-2">
            You can select up to {maxSelections} options.
          </p>
        )} */}

        <button
          type="button"
          onClick={handleNext}
          className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
        >
          Next
        </button>
        <BackButton handleBack={handleBack} />
      </form>
    </>
  );
};

export default SelectObjective;
