import React from "react";
import { PlanboardUser } from "../../types/interfaces/PlanboardUser";

const UserChip = ({ user }: { user: PlanboardUser }) => {
  return (
    <span className="p-0.5 pe-3 flex flex-nowrap items-center bg-gray-100 border border-gray-200 text-xs text-gray-800 rounded-full dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200">
      <img
        className="flex-shrink-0 size-6 rounded-full"
        src={
          user?.profilePic ??
          "https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
        }
        alt="User-profile"
      />
      <span className="ms-1.5 whitespace-nowrap">
        {user?.name ?? "Username"}
      </span>
    </span>
  );
};

export default UserChip;
