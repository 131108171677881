import { ExternalLink, MoreVertical, Trash2, Star } from "lucide-react";
import React, { useState } from "react";
// import Menu, { MenuButton, MenuItem } from "../Menu/Menu";
import PlanboardsAPI from "../../api/services/Planboard";
import { useToast } from "../../hooks";
import ConfirmBox from "../Generic/ConfirmBox";
import DeletePlanboardConfirmBox from "../ConfirmBox/DeletePlanboardConfirmBox";
import { useDispatch, useSelector } from "react-redux";
import { removePlanboard } from "../../redux/slice/planboards/planboardsSlice";
import { fetchRecentPlanboards } from "../../redux/slice/recentPlanboards/recentPlanboards";
import { Menu, MenuItem } from "@mui/material";
import IconButton from "../Generic/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import {
  updatePlanBoardInWorkspace,
  updateWorkspace,
} from "../../redux/slice/workspaces/workspacesSlice";

// interface PlanboardCardMoreMenuProps {
//   planboard: {
//     _id: string;
//     name: string;
//     workspaceId: string;
//   };
// }
const PlanboardCardMoreMenu = ({ planboard }) => {
  const team = useSelector((state) => state.team);
  const [openMenu, setMenuOpen] = useState(false);
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false);
  const toast = useToast();
  const workspaces = useSelector((state) => state.workspaces.value);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    const response = await PlanboardsAPI.update({
      id: planboard._id,
      updateData: { active: false },
    });
    if (response.data.success) {
      dispatch(
        removePlanboard({
          id: planboard._id,
          workspaceId: planboard.workspaceId,
        })
      );
      dispatch(fetchRecentPlanboards(team.value._id));
      toast.success(`Planboard - ${planboard.name} deleted successfully`);
    }
    setMenuOpen(false);
  };

  const handlePlanboardDeleteCancel = () => {
    setIsConfirmBoxOpen(false);
    handleClose();
  };
  const handlePlanboardDeleteConfirm = () => {
    setIsConfirmBoxOpen(false);
    handleClose();
    handleDelete();
  };

  const handleNavigation = () => {
    navigate(`${ROUTES.planboardDesigner}/${planboard.planboardId}`, {
      state: { from: location },
    });
  };

  const findWorkspace = () => {
    for (const item of workspaces) {
      const { id, planBoards } = item;
      for (const planBoard in planBoards) {
        if (planBoards[planBoard].planBoardId === planBoard.planBoardId) {
          return item;
        }
      }
    }
  };

  const handleFavourite = async () => {
    let status = true;
    planboard?.favourite ? (status = false) : (status = true);
    try {
      handleClose();
      const workspace = findWorkspace();
      dispatch(
        updatePlanBoardInWorkspace({
          workspaceId: workspace.id,
          planboardId: planboard.planboardId,
          planboardData: { favourite: status },
        })
      );
      const response = await PlanboardsAPI.markAsFavourite(
        planboard?.planboardId,
        status
      );
      if (response.status === 200) {
        toast.info(
          `Planboard: ${planboard.name} has been ${status ? "added to" : "removed from"
          } favourites.`
        );
      } else {
        toast.error(
          `Failed to ${status ? "add" : "remove"} Planboard: ${planboard.name
          } ${status ? "to" : "from"} favourites. Please try again.`
        );
        dispatch(
          updatePlanBoardInWorkspace({
            workspaceId: workspace.id,
            planboardId: planboard.planboardId,
            planboardData: { favourite: !status },
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {/* <Menu
        open={openMenu}
        onClose={() => setMenuOpen(false)}
        menuButton={
          <MenuButton
            icon={<MoreVertical size={16} />}
            onClick={() => setMenuOpen((prev) => !prev)}
          />
        }
      >
        <MenuItem
          icon={<Trash2 size={16} />}
          onClick={() => setIsConfirmBoxOpen(true)}
        >
          Delete
        </MenuItem>
      </Menu> */}
      <div onClick={handleClick}>
        <IconButton icon={<MoreVertical size={16} strokeWidth={1} />} />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiMenu-paper": {
            borderRadius: "10px",
            boxShadow:
              "0px 2px 1px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
          },
        }}
      >
        {/* <MenuItem onClick={() => setIsConfirmBoxOpen(true)}>
          <div className="text-sm">Delete</div>
        </MenuItem>
        <MenuItem onClick={handleNavigation}>
          <div className="text-sm">Manage</div>
        </MenuItem> */}
        <div class="dark:bg-slate-900">
          <button
            type="button"
            onClick={handleNavigation}
            class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
          >
            <ExternalLink size={16} strokeWidth={1} />
            Open Planboard
          </button>

          <button
            type="button"
            onClick={handleFavourite}
            class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
          >
            <Star size={16} strokeWidth={1} />
            {!planboard.favourite
              ? "Add to Favourite"
              : "Remove from Favourite"}
          </button>

          <button
            type="button"
            onClick={() => setIsConfirmBoxOpen(true)}
            class="w-full text-red-600 flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
          >
            <Trash2 size={16} strokeWidth={1} />
            Delete
          </button>
        </div>
      </Menu>

      <DeletePlanboardConfirmBox
        handleCancel={handlePlanboardDeleteCancel}
        handleConfirm={handlePlanboardDeleteConfirm}
        open={isConfirmBoxOpen}
        name={planboard.name}
      />
    </div>
  );
};

export default PlanboardCardMoreMenu;
