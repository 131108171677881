import React from "react";
import BrandLogo from "../components/BrandLogo";

const ComingSoon = () => {
  return (
    <div className="max-w-[50rem] flex flex-col mx-auto size-full">
      <div className="mx-auto">
        <BrandLogo collapsed={false} />
      </div>

      <main id="content" role="main" className="my-auto">
        <div className="text-center py-10 px-4 sm:px-6 lg:px-8">
          <h1 className="block text-2xl font-bold">Nothing Here</h1>
          <p className="mt-8 text-lg">Your content will show up here!</p>
        </div>
      </main>

      {/* <footer className="mt-auto text-center py-5">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <p className="text-sm">Planbow</p>
        </div>
      </footer> */}
    </div>
  );
};

export default ComingSoon;
