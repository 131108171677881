import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OrganizationAPI from "../../../api/services/Organisation";
import { logout } from "../auth/authSlice";

// Replace with your actual API functions
export const getOrganizations = createAsyncThunk(
  "organizations/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await OrganizationAPI.getOrganisations();
      if (response.status === 200) return response.data.data;
      rejectWithValue("Not Found");
    } catch (error) {
      console.log({ error });
      if (error.response.status === 500) {
        return rejectWithValue("Internal Server Error");
      }
      if (error.response.status === 401) {
        return rejectWithValue(error?.response?.data ?? "Unauthorized Access");
      }
      if (error.response.status === 404) {
        return rejectWithValue({
          message: error?.response?.data?.data ?? "Not Found",
          status: 404,
        });
      } else return rejectWithValue("error");
    }
  }
);

const initialState = {
  value: [],
  loading: true,
  error: null,
};

const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    addOrganizations: (state, action) => {
      if (action.payload !== null) state.value = action.payload;
      state.error = null;
      state.loading = false;
    },
    addNewOrganization: (state, action) => {
      state.value.push(action.payload);
      state.error = null;
      state.loading = false;
    },
    clearOrganisation: (state) => {
      return {
        ...initialState,
      };
    },
    updateOrganization: (state, action) => {
      const { id, updateData } = action.payload;
      const index = state.value.findIndex((org) => org.organizationId === id);
      if (index !== -1) {
        state.value[index] = { ...state.value[index], ...updateData };
      }
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialState)
      .addCase(getOrganizations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrganizations.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(getOrganizations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
        state.value = [];
      });
  },
});

export const {
  addNewOrganization,
  clearOrganisation,
  updateOrganization,
  addOrganizations,
} = organizationsSlice.actions;
export default organizationsSlice.reducer;
