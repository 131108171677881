import React, { useEffect, useState } from "react";
import PlanboardDesignerHeader from "../components/PlanboardDesignerHeader";
import DesignerNavigation from "../components/DesignerNavigation";
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Canvas from "../components/Canvas";
import withPlanboardDesigner from "../containers/PlanboardDesignerContainer";
import { getPlanboardSummary } from "../redux/slice/planboard";
import PlanboardDesignerActivity from "../components/PlanboardDesignerActivity";
import { useToast } from "../hooks";
import Events from "../components/Events";
import CreateEventDialog from "../components/Events/CreateEventDialog";
import Timeline from "../components/Gantt";
import Analytics from "../components/Analytics";
// import AssignActionItemModal from "../components/Modals/AssignActionItemModal";
import ContentsTab from "../components/Contents";
import PlanboardSummary from "../components/PlanboardSummary";
import PlanboardsAPI from "../api/services/Planboard";
import { dependencies } from "echarts";

const PlanboardDesigner = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [selectedNav, setSelectedNav] = useState("summary");
  const planboard = useSelector((state) => state.planboard);
  const location = useLocation();

  useEffect(() => {
    if (params.id === "undefined") return;
    dispatch(getPlanboardSummary(params.id));
  }, [params.id]);

  useEffect(() => {
    document.title = "Planbow - Planboard Designer";
  }, []);

  useEffect(() => {
    if (location?.state?.nav) setSelectedNav(location.state.nav);
  }, [location]);

  return (
    <main className="flex min-h-screen dark:bg-slate-900">
      <div className="bg-slate-50 dark:bg-slate-900 w-full flex flex-col">
        <PlanboardDesignerHeader planboard={planboard.value} />
        <DesignerNavigation
          selectedNav={selectedNav}
          setSelectedNav={setSelectedNav}
        />
        {/* <CreateEventDialog /> */}
        <div className="flex m-1 grow">
          <main id="content" role="main" className="w-full flex">
            <div className="w-full flex">
              {selectedNav === "canvas" ? (
                <Canvas selectedNav={selectedNav} />
              ) : selectedNav === "activity" ? (
                <PlanboardDesignerActivity />
              ) : selectedNav === "events" ? (
                <Events />
              ) : selectedNav === "timeline" ? (
                <Timeline />
              ) : selectedNav === "content" ? (
                <ContentsTab />
              ) : selectedNav === "analytics" ? (
                <Analytics />
              ) : selectedNav === "summary" ? (
                <PlanboardSummary />
              ) : null}
            </div>
          </main>
        </div>
      </div>
    </main>
  );
};

export default withPlanboardDesigner(PlanboardDesigner);
