import React from "react";
import { ICONS } from "../../assets";
import { Link } from "react-router-dom";
import IconBlack from "../../assets/images/planbow_icon_black.svg";
import IconWhite from "../../assets/images/planbow_icon_white.svg";
import LogoBlack from "../../assets/images/planbow_logo_black.svg";
import LogoWhite from "../../assets/images/planbow_logo_white.svg";

const BrandLogo = ({ collapsed }) => {
  return (
    <Link to="/">
      {collapsed ? (
        <>
          <img
            className="w-9 block dark:hidden"
            src={IconBlack}
            alt="planbow"
          />
          <img
            className="w-9 hidden dark:block"
            src={IconWhite}
            alt="planbow"
          />
        </>
      ) : (
        <>
          <img
            className="w-36 block dark:hidden"
            src={LogoBlack}
            alt="planbow"
          />
          <img
            className="w-36 hidden dark:block"
            src={LogoWhite}
            alt="planbow"
          />
        </>
      )}
    </Link>
  );
};

export default BrandLogo;
