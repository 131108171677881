import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Datepicker from "tailwind-datepicker-react";
import { useClickAway } from "@uidotdev/usehooks";
import { CalendarDays } from "lucide-react";

import { formatDateToDateString } from "../../utils/dateTime";

const DatePicker = ({ date, handleChange }) => {
  // console.log({ date });
  const options = {
    // title: "Demo Title",
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    clearBtnText: "Clear",
    maxDate: new Date("2030-01-01"),
    minDate: new Date(),
    theme: {
      background: "bg-gray-100 dark:bg-gray-800",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "",
    },
    icons: {
      // () => ReactElement | JSX.Element
      prev: () => (
        <span>
          <ChevronLeft />
        </span>
      ),
      next: () => (
        <span>
          <ChevronRight />
        </span>
      ),
    },
    datepickerClassNames: "top-12",
    defaultDate: new Date(date),
    language: "en",
    disabledDates: [],
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Select Date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  };
  const [show, setShow] = useState(false);

  const ref = useClickAway(() => {
    setShow(false);
  });

  const handleClose = (state) => {
    setShow(state);
  };

  useEffect(() => {
    console.log("component loaded");
  }, []);

  return (
    <div ref={ref} className="relative">
      <Datepicker
        options={options}
        onChange={handleChange}
        show={show}
        setShow={handleClose}
        classNames="fixed"
      >
        <div className="flex items-center space-x-1 py-2 px-2.5 border border-gray-300 rounded-lg text-sm dark:bg-slate-900 dark:border-neutral-700 dark:placeholder-neutral-500 w-4/12">
          <CalendarDays size={20} className="mr-2 text-gray-400" />
          <input
            type="text"
            placeholder="Select Date"
            value={formatDateToDateString(new Date(date))}
            onFocus={() => setShow(true)}
            readOnly
            className="bg-transparent focus:outline-none text-sm"
          />
        </div>
      </Datepicker>
    </div>
  );
};

export default DatePicker;
