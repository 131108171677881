import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES, URLS, BASE_URL } from "../constants";
import { setLoginData } from "../redux/slice/login/loginSlice";
import { getUser } from "../redux/slice/user/userSlice";
import ResetPasswordAPI from "../api/services/ResetPassword.ts";
import RegistrationAPI from "../api/services/Registration.ts";
import apiClient from "../api/client";

const REACT_APP_GATEWAY_URL = BASE_URL;

const withAuth = (WrappedComponent) => {
  return function AuthenticationContainer() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const checkUserLoggedIn = async () => {
      try {
        const response = await apiClient.get(URLS.isLoggedIn);
        return response;
      } catch (error) {
        // console.log(error);
      }
    };
    const handleGoogleSignIn = (isLogin = false) => {
      if (isLogin) {
        dispatch(
          setLoginData({
            method: "google",
            user: {},
          })
        );
      }
      window.location.href = REACT_APP_GATEWAY_URL + URLS.googleSignIn;
    };
    const handleLinkedInSignIn = (isLogin = false) => {
      if (isLogin) {
        dispatch(
          setLoginData({
            method: "linkedin",
            user: {},
          })
        );
      }
      window.location.href = REACT_APP_GATEWAY_URL + URLS.linkedinSignIn;
    };
    const handleEmailSignIn = async (values) => {
      try {
        const response = await apiClient.post(URLS.login, {
          username: values.email,
          password: values.password,
        });
        return response;
      } catch (error) {
        // console.log(error);
      }
    };
    const fetchUserDetails = () => {
      dispatch(getUser());
    };
    const handleForgotPassword = async (values) => {
      try {
        const response = await ResetPasswordAPI.forgot({ email: values.email });
        return response;
      } catch (err) {
        throw err;
      }
    };
    const handleRegisterEmail = async (values) => {
      try {
        const response = await RegistrationAPI.step1({ email: values.email });
        return response;
      } catch (err) {
        throw err;
      }
    };
    const handleRegisterName = async (id, values) => {
      try {
        const response = await RegistrationAPI.name({ id, name: values.name });
        return response;
      } catch (err) {
        throw err;
      }
    };
    const handleRegisterPassword = async (id, values) => {
      try {
        const response = await RegistrationAPI.password({
          id,
          password: values.password,
        });
        return response;
      } catch (error) {
        throw error;
      }
    };
    const handleRedirection = (user, path) => {
      const getPreviousRoute = localStorage.getItem("prevState");
      if (getPreviousRoute) {
        const decrypted = atob(getPreviousRoute);
        localStorage.removeItem("prevState");
        console.log({ decrypted });
        const item = JSON.parse(decrypted);
        const now = new Date();
        // compare the expiry time of the item with the current time
        if (now.getTime() < item.expiry) {
          return navigate(item.value);
        }
      }
      if (!user.value?.activeTeamId) {
        return navigate(ROUTES.createTeam);
      }
      return navigate(path);
    };

    const getSocialAuth = async () => {
      try {
        const response = await RegistrationAPI.authSocial();
        return response;
      } catch (error) {
        throw error;
      }
    };
    const props = {
      checkUserLoggedIn,
      handleGoogleSignIn,
      handleLinkedInSignIn,
      fetchUserDetails,
      handleEmailSignIn,
      handleForgotPassword,
      handleRegisterEmail,
      handleRegisterName,
      handleRegisterPassword,
      handleRedirection,
      user,
      getSocialAuth,
    };
    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
