import React from "react";
import PlanboardCard from "../PlanboardCard";

export default function PlanboardCardGrid({ planboards }) {
  return (
    // <!-- Card Blog -->
    <div className="w-full z-10">
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {planboards.map((planboard) => {
          return (
            <PlanboardCard key={planboard.planboardId} planboard={planboard} />
          );
        })}
      </div>
    </div>
    // <!-- End Card Blog -->
  );
}
