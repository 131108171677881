import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useWorkspace, useToast } from "../../hooks";
import {
  deleteWorkspace,
  getWorkspaces,
} from "../../redux/slice/workspaces/workspacesSlice";
import AvatarGroup from "../AvatarGroup";
import Menu, { MenuButton, MenuItem } from "../Menu/Menu";
import { AlertCircle, MoreVertical, Pin, Plus } from "lucide-react";
import ConfirmBox from "../ConfirmBox";
import Alert from "../Generic/Alert";
import { ROUTES } from "../../constants";
import PlanboardCardGrid from "../PlanboardCardGrid";
import WorkspaceMenu from "./WorkspaceMenu";
import EditLabel from "../Generic/EditLabel";
import IconButton from "../Generic/IconButton";
import BG from "../../assets/images/bg2.svg";

const WorkspaceDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();

  const organizations = useSelector((state) => state.organizations);

  const [editTitle, setEditTitle] = useState(false);
  const [openMenu, setMenuOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [editDescription, setEditDescription] = useState(false);

  const {
    workspace,
    error,
    loading,
    updateDescription,
    updateName,
    togglePinned,
    handleDelete,
    getWorkspaceDetails,
    renderDeleteConfirmBox,
    renderAlert,
    renderConflictAlert,
  } = useWorkspace();

  const handleToggleClick = () => {
    togglePinned(workspace.id, workspace.name, workspace.pinned);
  };

  const handleCreatePlanboardNavigate = () => {
    navigate(`${ROUTES.createPlanboard}?workspace=${id}`, {
      state: { from: location },
    });
  };

  const handleDeleteClick = () => {
    setMenuOpen(false);
    if (workspace.name === "My Workspace") setOpenAlert(true);
    else setOpenConfirm(true);
  };

  const handleEditDescription = () => {
    setEditDescription(true);
  };

  const handleDeleteConfirm = async () => {
    setOpenConfirm(false);
    handleDelete(id).then(() => {
      toast.info("Workspace deleted successfully");
      navigate("/workspaces");
    });
  };
  const handleDeleteCancel = () => {
    setOpenAlert(false);
    setOpenConfirm(false);
  };

  const handleDescriptionUpdate = (value) => {
    updateDescription(value, id);
  };
  const handleTitleUpdate = (value) => {
    updateName(value, id);
  };

  useEffect(() => {
    if (id !== "new") {
      // getActiveWorkspaceDetails(id);
      getWorkspaceDetails(id);
      // getPlanboardsDetails(id);
    }
  }, [id]);

  const updateLocationState = () => {
    console.log("clear location state");
    dispatch(
      getWorkspaces({
        organizationId: organizations.value[0].organizationId,
        index: 0,
        itemsPerIndex: 100,
      })
    );
    navigate(ROUTES.workspaces + "/" + id, { replace: true });
  };

  useEffect(() => {
    if (location?.state?.new) {
      return setEditTitle(true);
    }
  }, [location]);
  useEffect(() => {
    if (editTitle) return;
    console.log("test 1");
    if (location?.state?.new) {
      updateLocationState();
    }
  }, [editTitle]);
  return (
    <div className="flex  h-full dark:bg-gray-800 ">
      <main
        id="content"
        role="main"
        className="w-full h-screen max-h-[98vh] overflow-y-scroll bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-700 "
      >
        <div
          id="workspace-details"
          className="mx-2 text-gray-800 dark:text-gray-200 flex items-center justify-between p-5 px-3 border-b border-gray-200 "
        >
          <div className="flex grow">
            <div className="grow">
              <div className="text-xl leading-8 min-h-8">
                {loading ? (
                  <div className="flex h-8 items-center animate-pulse">
                    <h3 class="h-6 bg-gray-200 rounded-full dark:bg-gray-700 w-[20%]"></h3>
                  </div>
                ) : workspace?.name ? (
                  workspace.name === "My Workspace" ? (
                    workspace.name
                  ) : (
                    <EditLabel
                      edit={editTitle}
                      label={workspace.name}
                      setEdit={setEditTitle}
                      updateLabel={handleTitleUpdate}
                    />
                  )
                ) : (
                  <div className="text-xl leading-8">Workspace Name</div>
                )}
              </div>
              {loading ? (
                <div className="flex h-8 items-center animate-pulse">
                  <h3 class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-[20%]"></h3>
                </div>
              ) : workspace?.description || editDescription ? (
                <div className="text-xs leading-8">
                  <EditLabel
                    edit={editDescription}
                    label={workspace.description}
                    setEdit={setEditDescription}
                    updateLabel={handleDescriptionUpdate}
                    iconSize={12}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex items-center justify-between ms-5 gap-x-3">
            <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-white/10 dark:text-white">
              Planboards:
              <span className="inline-flex items-center px-1 rounded-full text-xs font-medium bg-primary-400 text-white">
                {workspace?.planBoardCount ?? 0}
              </span>
            </span>
            <IconButton
              icon={
                <Pin
                  color="#c0c0c0"
                  size={20}
                  className={` ${workspace?.pinned ? "stroke-blue-600" : ""}`}
                />
              }
              tooltipTitle={workspace?.pinned ? "Unpin" : "Pin"}
              onClick={handleToggleClick}
            />
            <WorkspaceMenu
              setEditTitle={setEditTitle}
              handleDelete={handleDeleteClick}
              handleEditDescription={handleEditDescription}
            />
          </div>
          {renderConflictAlert()}
          {renderDeleteConfirmBox({
            handleConfirm: handleDeleteConfirm,
            open: openConfirm,
            title: (
              <div className="flex justify-center">
                Delete {workspace?.name}
              </div>
            ),
            onClose: handleDeleteCancel,
            id: "delete-team-confirm-1",
          })}
          {renderAlert({
            onClose: handleDeleteCancel,
            id: "team-delete-alert",
            open: openAlert,
            title: "Alert - Delete Team",
          })}
        </div>
        {/* <!-- Invoice --> */}
        <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10">
          {/* <!-- Grid --> */}
          <div className="pb-5 flex justify-between items-center border-b border-gray-200 dark:border-gray-700">
            <div>
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                Planboards
              </h2>
            </div>
            {/* <!-- Col --> */}

            <div className="inline-flex gap-x-2">
              <div
                onClick={handleCreatePlanboardNavigate}
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 cursor-pointer"
                // to={`${ROUTES.createPlanboard}?workspace=${id}`}
              >
                <Plus />
                Create planboard
              </div>
            </div>
            {/* <!-- Col --> */}
          </div>
          {/* <!-- End Grid --> */}
          <div className="relative mb-5 p-5 pt-10 flex justify-between items-center dark:border-gray-700 flex-col">
            {workspace?.planBoards?.length ? (
              <PlanboardCardGrid planboards={workspace.planBoards} />
            ) : (
              <div className=" text-xl dark:text-white pt-5 text-center w-full min-h-72">
                No planboards in this workspace yet! Add one to get started.
              </div>
            )}
            <div
              className={`absolute top-0 ${
                workspace?.planBoards?.length ? "opacity-5" : "opacity-10"
              }  z-0 h-full w-full object-contain overflow-hidden`}
            >
              <img className="" src={BG} alt="background_workspace" />
              <img className="" src={BG} alt="background_workspace" />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default WorkspaceDetail;
