import React, { useEffect, useState } from "react";
import Button from "../Button";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import Input from "../Inputs/Input";
import DatePicker from "../Inputs/DatePicker";
import Select from "../Inputs/Select";
import Checkbox from "../Inputs/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { closeCreateEventDialog } from "../../redux/slice/ui/dialogSlice";
import Modal, { ModalBody, ModalHeader } from "../Generic/Modal";
import { useToast } from "../../hooks";
import GlobalAPI from "../../api/services/Global";
import Textarea from "../Inputs/Textarea";
import EventsAPI from "../../api/services/Events";
import { useParams } from "react-router-dom";
import { formatDateToDateString } from "../../utils/dateTime";
// import { createEvent } from "../../redux/slice/events/eventsSlice";

// import { RootState } from "../../redux/store";

// interface FormValues {
//   title: string;
//   description: string;
//   eventType: string;
//   date: string;
//   notifyAll: boolean;
// }

let initialValues = {
  title: "",
  description: "",
  type: "",
  startDate: new Date(),
  endDate: "",
  notifyAll: false,
  recurrsUntil: "",
  repeat: "",
  allDay: false,
  timeFrom: "09:00 AM",
  timeTo: "10:00 AM",
};

const eventTypes = [
  { value: "Kick-off Meeting", text: "Kick-off Meeting" },
  { value: "Focus Area Discussion", text: "Focus Area Discussion" },
  { value: "Follow Up", text: "Follow Up" },
  { value: "Status Check", text: "Status Check" },
  { value: "Daily Catch Up", text: "Daily Catch Up" },
  { value: "Brainstorming Workshop", text: "Brainstorming Workshop" },
  {
    value: "Risk Identification & Mitigation",
    text: "Risk Identification & Mitigation",
  },
  { value: "Planboard Closure", text: "Planboard Closure" },
  { value: "Other", text: "Other" },
];
const repeatTypes = [
  { value: "don't repeat", text: "Don't Repeat" },
  { value: "daily", text: "Daily" },
  { value: "weekly", text: "Weekly" },
  { value: "monthly", text: "Monthly" },
  { value: "yearly", text: "Yearly" },
];

const startTime = "00:00"; // Adjust this to your desired starting time
const endTime = "23:00"; // Adjust this to your desired ending time

const timeOptions = [];

for (
  let hour = parseInt(startTime.split(":")[0]);
  hour <= parseInt(endTime.split(":")[0]);
  hour++
) {
  for (let minute = 0; minute <= 55; minute += 30) {
    // Get formatted hour and minute with leading zeros
    // const formattedHour = hour.toString().padStart(2, "0");
    let formattedHour = (hour % 12 || 12).toString().padStart(2, "0");
    const formattedMinute = minute.toString().padStart(2, "0");

    // Calculate AM/PM indicator
    const isAM = hour < 12;
    const period = isAM ? "AM" : "PM";

    // Create formatted time string
    const formattedTime = `${formattedHour}:${formattedMinute} ${period}`;

    // Add time option
    timeOptions.push({ value: formattedTime, text: formattedTime });
  }
}
function convertTo24HourFormat(timeString) {
  const [time, period] = timeString.split(" ");
  let [hours, minutes] = time.split(":");

  // Convert hours to 24-hour format
  if (hours === "12") {
    hours = "00";
  }
  if (period === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  // Ensure minutes are two digits
  minutes = minutes.padStart(2, "0");

  return `${hours}:${minutes}`;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  repeat: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  notifyAll: Yup.boolean(),
  startDate: Yup.string().required("Required"),
  // endDate: Yup.string().required("Required"),
  timeFrom: Yup.string().required("Required"),
  timeTo: Yup.string().required("Required"),
});

const CreateEventDialog = ({
  open,
  action,
  eventId,
  handleClose,
  selectedEvent,
  postSubmit,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const planboard = useSelector((state) => state.planboard);
  const dialog = useSelector((state) => state.dialog);
  const { id } = useParams();

  const [meetingTypes, setMeetingTypes] = useState();

  const handleCreate = async ({
    description,
    endDate,
    type,
    title,
    repeat,
    startDate,
    timeFrom,
    timeTo,
  }) => {
    // const [val, nodeId] = dialog.isCreateEventDialogOpen.split(":");
    try {
      const response = await EventsAPI.createEvent({
        planboardId: id,
        meetingTypeId: type,
        title,
        description,
        recurrence: repeat,
        start: `${formatDateToDateString(
          new Date(startDate)
        )} ${convertTo24HourFormat(timeFrom)}`,
        end: `${formatDateToDateString(
          new Date(startDate)
        )} ${convertTo24HourFormat(timeTo)}`,
        nodeId: eventId,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
      toast.error("Error creating event");
      handleClose();
    }
  };

  const handleUpdate = async ({
    description,
    endDate,
    type,
    title,
    repeat,
    startDate,
    timeFrom,
    timeTo,
  }) => {
    try {
      const response = await EventsAPI.updateEvent({
        eventId: selectedEvent.id,
        meetingTypeId: type,
        title,
        description,
        recurrence: repeat,
        start: `${formatDateToDateString(
          new Date(startDate)
        )} ${convertTo24HourFormat(timeFrom)}`,
        end: `${formatDateToDateString(
          new Date(startDate)
        )} ${convertTo24HourFormat(timeTo)}`,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
      toast.error("Error creating event");
      handleClose();
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!id) return;
    console.log(values, setSubmitting);
    const {
      description,
      endDate,
      type,
      title,
      repeat,
      startDate,
      timeFrom,
      timeTo,
    } = values;
    setSubmitting(true);
    console.log({ action });
    if (action == "update") {
      handleUpdate({
        description,
        endDate,
        type,
        title,
        repeat,
        startDate,
        timeFrom,
        timeTo,
      }).then(() => {
        resetForm();
        setSubmitting(false);
        handleClose();
        toast.success(`Successfully updated  the  Event ${title} !`);
      });
    } else {
      handleCreate({
        description,
        endDate,
        type,
        title,
        repeat,
        startDate,
        timeFrom,
        timeTo,
      }).then(() => {
        resetForm();
        setSubmitting(false);
        handleClose();
        toast.success(`Successfully created  the  Event ${title} !`);
      });
    }
    postSubmit && postSubmit();
    return;
  };

  // const handleClose = () => {
  //   dispatch(closeCreateEventDialog());
  // };
  const handleCancel = (resetForm) => {
    resetForm();
    handleClose();
  };

  useEffect(() => {
    const getMeetingTypes = async () => {
      const response = await GlobalAPI.getMeetingTypes();
      if (response.status !== 200) return alert("Failed to fetch Event Types");
      if (response.status === 200) {
        setMeetingTypes(() =>
          response.data.data.map(({ id, name }) => ({
            value: id,
            text: name,
          }))
        );
      }
    };
    getMeetingTypes();
  }, []);
  const eventValues = {
    title: "",
    description: "",
    type: "",
    startDate: "",
    endDate: "",
    // notifyAll: false,
    // recurrsUntil: "",
    repeat: "",
    // allDay: false,
    timeFrom: "09:00 AM",
    timeTo: "10:00 PM",
  };

  const getInitialValues = () => {
    return action === "create"
      ? initialValues
      : {
          ...initialValues,
          ...getValues(),
        };
  };

  const getValues = () => {
    if (!selectedEvent) return {};
    const { title, recurrence, start, end, description, meetingTypeId } =
      selectedEvent;
    const b = {
      title,
      description,
      type: meetingTypeId,
      startDate: new Date(start),
      endDate: new Date(end),
      repeat: recurrence,
      // timeFrom: start,
      // timeTo: end,
      timeFrom: "09:00 AM",
      timeTo: "10:00 AM",
    };
    return b;
  };

  console.log({ selectedEvent });

  return (
    <div>
      <Modal
        open={open}
        id="create-event"
        onClose={handleClose}
        width="min-w-[60vw]"
      >
        <ModalHeader
          title={action === "create" ? "Schedule Meeting" : "Edit Meeting"}
          onClose={handleClose}
        />
        {open && (
          <Formik
            // initialValues={action === "create" ? initialValues : getValues()}
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const {
                values,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
                isValid,
                errors,
              } = props;
              console.log({ errors, values });
              return (
                <Form>
                  <ModalBody>
                    <div className="mt-2 overflow-hidden">
                      <div className="divide-y divide-gray-200 dark:divide-gray-700 overflow-auto h-[55vh] p-4 ">
                        <form onSubmit={handleSubmit}>
                          <div className="grid grid-cols-2 gap-4">
                            <Field
                              label="Title"
                              id="title"
                              name="title"
                              component={Input}
                              value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Title"
                              options={eventTypes}
                            />
                            <Field
                              label="Description"
                              id="description"
                              name="description"
                              component={Textarea}
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Description"
                            />

                            {/* {values.repeat &&
                              values.repeat !== "don't repeat" && (
                                <div className="w-[40%] px-2">
                                  <Field
                                    label="Repeat Until"
                                    id="recurrsUntil"
                                    name="recurrsUntil"
                                    component={DatePicker}
                                    value={values.recurrsUntil}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Date"
                                  />
                                </div>
                              )} */}
                            {/* </div> */}

                            {/* <div class="flex items-center">
                            <Field
                              label="All Day"
                              id="allDay"
                              name="allDay"
                              component={Checkbox}
                              value={values.allDay}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Notify All"
                            />
                          </div> */}

                            <div className="flex">
                              <div className="grow">
                                <Field
                                  label="Start Date & Time"
                                  id="startDate"
                                  name="startDate"
                                  component={DatePicker}
                                  value={values.startDate}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Date"
                                  // minDate={new Date()}
                                  // maxDate={new Date(planboard.value.endDate)}
                                />
                              </div>
                              {values.allDay === false && (
                                <div className="flex w-[40%] ps-2 items-end gap-x-2">
                                  <Field
                                    label="&nbsp;"
                                    id="timeFrom"
                                    name="timeFrom"
                                    component={Select}
                                    value={values.timeFrom}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="From"
                                    options={timeOptions}
                                    defaultText="From"
                                    // defaultValue="09:00 AM"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="flex">
                              <div className="grow">
                                {/* <Field
                                  label="End Date & Time"
                                  id="endDate"
                                  name="endDate"
                                  component={DatePicker}
                                  value={values.endDate}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Date"
                                /> */}
                              </div>
                              {values.allDay === false && (
                                <div className="flex w-[40%] ps-2 items-end gap-x-2">
                                  <Field
                                    label="&nbsp;"
                                    id="timeTo"
                                    name="timeTo"
                                    component={Select}
                                    value={values.timeTo}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="To"
                                    options={timeOptions}
                                    defaultText="To"
                                    // defaultValue="10:00 AM"
                                  />
                                </div>
                              )}
                            </div>
                            <Field
                              label="Meeting Type"
                              id="type"
                              name="type"
                              component={Select}
                              value={values.type}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Type"
                              options={meetingTypes}
                            />
                            <div className="flex">
                              <div className="grow">
                                <Field
                                  label="Repeat"
                                  id="repeat"
                                  name="repeat"
                                  component={Select}
                                  value={values.repeat}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="To"
                                  options={repeatTypes}
                                />
                              </div>

                              {/* Location Input Field */}
                              {/* <Field
                            label="Notify All"
                            id="notifyAll"
                            name="notifyAll"
                            component={Checkbox}
                            value={values.notifyAll}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Notify All"
                          /> */}
                              {/* Color Field */}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </ModalBody>
                  <div className="flex justify-between items-center gap-x-2 p-4 sm:px-7 dark:border-gray-700">
                    <Button
                      type="button"
                      className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      onClick={() => handleCancel(resetForm)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      type="submit"
                      loading={isSubmitting}
                      // disabled={isSubmitting || !isValid}
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                    >
                      {action === "create"
                        ? "Create Meeting"
                        : "Update Meeting"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal>
    </div>
  );
};

export default CreateEventDialog;
