import { Check } from "lucide-react";
import React, { useEffect, useState } from "react";
import { PlanboardUser } from "../../types/interfaces/PlanboardUser";

interface SelectProps {
  options: PlanboardUser[];
  selectedValue?: PlanboardUser;
  onChange: (selectedValue: PlanboardUser) => void;
}

const UserSelectSingle: React.FC<SelectProps> = ({
  options,
  selectedValue,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectedValue);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (optionValue: any) => {
    setSelectedOption(optionValue);
    onChange(optionValue);
    handleToggle();
  };

  //   useEffect(() => {
  //     if (selectedValue) setSelectedOption(selectedValue);
  //     if (!selectedOption && selectedValue) {
  //       setSelectedOption(selectedValue);
  //     }
  //     console.log({ selectedValue });
  //   }, [selectedValue]);
  //   useEffect(() => {
  //     console.log({ selectedOption });
  //   }, [selectedOption]);

  return (
    <div className="relative ">
      <button
        type="button"
        onClick={handleToggle}
        className="py-3 px-4 pe-9 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
      >
        <div className="flex flex-wrap flex-0 items-center">
          {selectedOption ? (
            <TagsItem option={selectedOption} handleSelect={handleSelect} />
          ) : (
            <div className="text-gray-400">Choose a Member...</div>
          )}
        </div>
      </button>

      <div className="absolute top-1/2 end-4 -translate-y-1/2">
        <svg
          className="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="m7 15 5 5 5-5" />
          <path d="m7 9 5-5 5 5" />
        </svg>
      </div>

      {isOpen && (
        <div className="absolute mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700 top-full">
          {options.length > 0 ? (
            options.map((option) => (
              <div
                key={option.userId}
                onClick={() => handleSelect(option)}
                className="cursor-pointer py-2 px-4 w-full text-sm text-gray-800 hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800 selected"
              >
                <div className="flex items-center">
                  <div className="h-8 w-8 me-2">{getIcon(option)}</div>
                  <div>
                    <span className="text-sm font-semibold text-gray-800 dark:text-gray-200">
                      {option.name}
                    </span>
                    {option.email && (
                      <span className="text-xs text-gray-500 ml-2">
                        ({option.email})
                      </span>
                    )}
                  </div>
                  <div className="ms-auto">
                    {selectedValue &&
                      selectedValue.userId === option.userId && (
                        <span className="">
                          <Check color="#46b72f" />
                        </span>
                      )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="dark:text-gray-200">No Active Users</div>
          )}
        </div>
      )}
    </div>
  );
};
export default UserSelectSingle;

// Rest of the code remains the same
function getInitials(name: string) {
  // Extract first letter of each word in the name
  return name
    .split(/\s+/)
    .map((word) => word[0]?.toUpperCase())
    .join("");
}

function hashFn(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i); // JS-specific hash algorithm
    hash |= 0; // Convert to 32-bit integer
  }
  return hash;
}

const getIconBackground = (user: any) => {
  const hash = Math.abs(hashFn(user.name || "")); // Use a hashing function
  const colorIndex = hash % readableColors.length;
  return readableColors[colorIndex];
};

const readableColors = [
  " bg-gray-800 text-white dark:bg-white dark:text-gray-800",
  " bg-gray-500 text-white",
  " bg-green-500 text-white",
  " bg-blue-600 text-white dark:bg-blue-500",
  " bg-red-500 text-white",
  " bg-yellow-500 text-white",
];

const getIcon = (user: any) => {
  if (user.profilePic) {
    return (
      <img
        src={user.profilePic}
        alt={user.name}
        className="inline-block rounded-full mw-full"
      />
    );
  }
  return (
    <span
      className={
        "inline-flex items-center justify-center h-full w-full font-semibold leading-none rounded-full" +
        getIconBackground(user)
      }
    >
      {getInitials(user?.name || "U")}
    </span>
  );
};

const TagsItem = ({
  option,
  handleSelect,
}: {
  option: any;
  handleSelect: any;
}) => {
  return (
    <div className="flex flex-nowrap items-center relative z-10 bg-white border border-gray-200 rounded-full p-1 m-1 dark:bg-slate-900 dark:border-gray-700">
      <div className="h-6 w-6 me-1 text-xs" data-icon>
        {getIcon(option)}
      </div>
      <div className="whitespace-nowrap" data-title>
        {option.name}
      </div>
      <div
        className="inline-flex flex-shrink-0 justify-center items-center h-5 w-5 ms-2 rounded-full text-gray-800 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 text-sm dark:bg-gray-700/50 dark:hover:bg-gray-700 dark:text-gray-400 cursor-pointer"
        data-remove
        onClick={() => handleSelect(option)}
      >
        <svg
          className="flex-shrink-0 w-3 h-3"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M18 6 6 18" />
          <path d="m6 6 12 12" />
        </svg>
      </div>
    </div>
  );
};
