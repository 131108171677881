import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { closeColorDialog } from "../../redux/slice/ui/dialogSlice";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Button from "../Button";
import { useState } from "react";
import NodesAPI from "../../api/services/Nodes";


const ColorModel = ({ color, setColor, selectedColor, setSelectedColor }) => {

  // const dialog = useSelector((state: RootState) => state.dialog.isColorDialogOpen);
  // const dispatch = useDispatch();
  const colors = ['#F87171','#88DBED','#94B8FB','#F2CF95','#E0AF5D','#FFBBBB','#00A389','#D862BC','#8644A2','#41B06E','#141E46','#FDA403','#E8751A','#898121'];

  // const handleColorSelect = (color) => {
  // };
  const handleClose = () => {
    setColor(false)
    // dispatch(closeColorDialog());
  }

  const handleUpdate = (color) => {
    setSelectedColor(color);
    handleClose()
  }

  // console.log(dialog,"lllllllllllll")

  return (
    <div>
      {/* jjjjjjjjjjjjjj */}
      <Dialog
        open={color}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Select Node Color
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            <div style={{ display: 'flex', flexWrap: "wrap", width: "300px", justifyContent: "center", gap: '10px' }}>
              {colors.map((color) => (
                <div
                  key={color}
                  style={{
                    borderRadius: "50%",
                    width: '40px',
                    height: '40px',
                    backgroundColor: color,
                    border: selectedColor === color ? '2px solid black' : '1px solid gray',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleUpdate(color)}
                />
              ))}
            </div>

          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleUpdate}
          >
            Done
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default ColorModel;
