import React from "react";

import PriorityMenu from "./PriorityMenu";
import StatusMenu from "./StatusMenu";
import AccordianDateRange from "./AccordianDateRange";
import AccordianUsers from "../Accordion/AccordianUsers";
import {
  convertDateStringToDate,
  formatDateToDateString,
} from "../../utils/dateTime";
import DueDate from "./DueDate";
import DeleteElementIcon from "../ActionItems/DeleteElementIcon";
import { SubTask } from "../../types/interfaces/SubTask";
import SubTasksAPI from "../../api/services/Tasks";
import Progress from "./Progress";
import AssignedTo from "./AssignedTo";
import UserChip from "../Generic/UserChip";
import { PlanboardUser } from "../../types/interfaces/PlanboardUser";
import FileChip from "../ActionItems/FileChip";

interface SecondBandProps {
  element: any;
  updateElement: (id: string, data: {}) => void;
  handleRemoveSubTask: (id: string) => void;
  //   setTasks:React.Dispatch<React.SetStateAction<SubTask[]>>;
  updateMarkedAsDone: (taskId: string, value: boolean) => void;
  actionItemDate: {
    createdOn: Date;
    endDate: Date;
  };
  members: PlanboardUser[];
  readOnly: boolean;
}

const SecondBand = ({
  element,
  updateElement,
  handleRemoveSubTask,
  updateMarkedAsDone,
  actionItemDate,
  members,
  readOnly,
}: SecondBandProps) => {
  const getStatus = (newDate: Date) => {
    const today = formatDateToDateString(new Date());
    const dueDate = formatDateToDateString(new Date(newDate));
    if (
      element.endDate !== null &&
      convertDateStringToDate(today) > convertDateStringToDate(dueDate) &&
      element.progress < 100
    ) {
      return "delayed";
    }
    return null;
  };

  const updatePriority = (value: string) => {
    updateElement(element.id, { priority: value });
  };

  const updateDueDate = (newDate: Date) => {
    const newStatus = getStatus(newDate);
    const updateData: any = {
      endDate: formatDateToDateString(new Date(newDate)),
    };
    if (newStatus != null && newStatus !== element.status) {
      updateData.status = newStatus;
    }
    updateElement(element.id, updateData);
  };

  const updateAssignedTo = (user: number) => {
    updateElement(element.id, { assignedTo: user });
  };

  const updateProgress = (value: number) => {
    if (element.progress === value) return;
    let newStatus =
      value === 0
        ? "todo"
        : value > 0 && value < 100
        ? "in progress"
        : "completed";
    const today = formatDateToDateString(new Date());
    if (
      element.endDate !== null &&
      convertDateStringToDate(today) > new Date(element.endDate) &&
      value < 100
    ) {
      newStatus = "delayed";
    }
    let updateData: any = { progress: value };
    if (newStatus !== element.status) {
      updateData.status = newStatus;
    }
    updateElement(element.id, updateData);
  };
  return (
    <div className="border dark:border-neutral-500 mt-2 rounded-md items-center p-3 flex justify-between text-xs dark:text-white">
      <PriorityMenu
        updatePriority={updatePriority}
        priority={element?.priority ?? "low"}
        readOnly={readOnly}
      />
      {/* <StatusMenu status={element?.status ?? "in progress"} /> */}
      <Progress
        progress={element?.progress ?? 0}
        status={element?.status ?? "in progress"}
        updateProgress={updateProgress}
        readOnly={readOnly}
      />
      <DueDate
        dueDate={
          element?.endDate !== null ? new Date(element.endDate) : new Date()
        }
        updateDueDate={updateDueDate}
        actionItemDate={actionItemDate}
        readOnly={readOnly}
      />
      {/* <AccordianUsers /> */}
      <AssignedTo
        assignedTo={element.assignedTo}
        updateAssignedTo={updateAssignedTo}
        members={members}
        readOnly={readOnly}
      />

      <div className="capitalize flex items-center">
        created by&nbsp;
        <UserChip user={element.createdBy} />
      </div>
      <FileChip
        taskId={element.id}
        attachments={element.attachments}
        updateElement={updateElement}
        readOnly={readOnly}
      />
      {!readOnly && (
        <DeleteElementIcon
          elementId={element.id}
          removeElement={handleRemoveSubTask}
        />
      )}
      {/* <div
            id="icon-list"
            className={`flex gap-x-2 text-gray-400 ${
              hover || open ? "visible" : "invisible"
            }`}
          >
            <IconsSet removeElement={removeElement} elementId={id} />
          </div> */}
    </div>
  );
};

export default SecondBand;
