import React, { useEffect, useState } from 'react'
import Sidebar from "../../components/Auth/Sidebar";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import PublicAPI from '../../api/services/Public';
import { useToast } from '../../hooks';
import Spinner from '../../components/Spinner';

interface FormValues {
  newPassword: string;
  confirmNewPassword: string;
}

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .required('New password is required')
    .matches(/((?=.*\d{3})(?=.*[a-z]{3})(?=.*[A-Z]{3})(?=.*[!@#$&*]).{10,128})/, 'Password must contain at least one digit, one lowercase letter, one uppercase letter, and one special character')
    .min(10, 'Password must be between 10 and 128 characters long')
    .max(128, 'Password must be between 10 and 128 characters long'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Please confirm your new password'),
});

const SetPassword = () => {

  const handlePasswordSubmit = async () => {
    try {
      setLoading(true)
      const response = await PublicAPI.setPassword(location.state.ans.id, location.state.ans.resetToken, formik.values.newPassword);
      if (!response.data.error) {
        setLoading(false)
        toast.success(response.data.data)
        navigate(ROUTES.signin)
      }
    } catch (error: any) {
      setLoading(false)
      console.error('Error changing password:', error);
      toast.error(error.response.data.data);
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema,
    onSubmit: handlePasswordSubmit
  });
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const toast = useToast()

  useEffect(() => {
    if (!location.state) {
      navigate(ROUTES.signin)
    }
  }, [location])

  return (
    <>
      <main className="flex min-h-full bg-white dark:bg-slate-900">
        <Sidebar />
        <div className="grow px-5">
          <div className="w-full h-screen max-h-[100vh] overflow-y-scroll bg-white dark:bg-slate-800">
            <div className="grow px-5">
              <div className="h-full min-h-screen sm:w-[448px] flex flex-col justify-center mx-auto space-y-5">
                {/* <!-- Title --> */}
                <div>
                  <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                    Reset your password
                  </h1>
                  <p className="mt-1 text-sm text-gray-500 dark:text-neutral-500">
                    Enter the New Password.
                  </p>
                </div>
                {/* <!-- End Title --> */}

                <form onSubmit={formik.handleSubmit}>

                  <div className="space-y-5">
                    {/* New Password */}
                    <div>
                      <label htmlFor="hs-pro-dale" className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                        New Password
                      </label>
                      <div className="sm:col-span-8 xl:col-span-4">
                        <div className="relative">
                          <input id="hs-pro-dappcp"
                            type="text"
                            name="newPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.newPassword}
                            className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500  border cus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600" placeholder="Enter new password" />

                          <button type="button" data-hs-toggle-password='{
                "target": "#hs-pro-dappcp"
              }' className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500">
                            <svg className="flex-shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              <path className="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24" />
                              <path className="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68" />
                              <path className="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61" />
                              <line className="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22" />
                              <path className="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                              <circle className="hidden hs-password-active:block" cx="12" cy="12" r="3" />
                            </svg>
                          </button>

                        </div>
                        {formik.touched.newPassword && formik.errors.newPassword ? (
                          <div className="text-red-600">{formik.errors.newPassword}</div>
                        ) : null}
                      </div>
                    </div>
                    {/* New Password */}

                    {/* Confirm password */}
                    <div>
                      <label htmlFor="hs-pro-dale" className="block mb-2 text-sm font-medium text-gray-800 dark:text-white">
                        Confirm New Password
                      </label>
                      <div className="sm:col-span-8 xl:col-span-4">
                        <div className="relative">
                          <input id="hs-pro-dappp"
                            type="text"
                            name="confirmNewPassword"
                            // type={formik.values.oldPassword ? 'text' : 'password'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmNewPassword}
                            className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500  border cus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600" placeholder="Enter confirm new password" />

                          <button type="button" data-hs-toggle-password='{
                "target": "#hs-pro-dappp"
              }' className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500">
                            <svg className="flex-shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              <path className="hs-password-active:hidden" d="M9.88 9.88a3 3 0 1 0 4.24 4.24" />
                              <path className="hs-password-active:hidden" d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68" />
                              <path className="hs-password-active:hidden" d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61" />
                              <line className="hs-password-active:hidden" x1="2" x2="22" y1="2" y2="22" />
                              <path className="hidden hs-password-active:block" d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                              <circle className="hidden hs-password-active:block" cx="12" cy="12" r="3" />
                            </svg>
                          </button>

                        </div>
                        {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? (
                          <div className="text-red-600">{formik.errors.confirmNewPassword}</div>
                        ) : null}
                      </div>
                    </div>
                    {/* Confirm password */}
                    {loading && <Spinner/>}

                    <button type="submit" disabled={!formik.isValid || formik.isSubmitting || !formik.values.newPassword || !formik.values.confirmNewPassword || loading} className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-start bg-blue-600 border border-blue-600 text-white text-sm font-medium rounded-lg shadow-sm align-middle hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-300">
                      Confirm
                    </button>
                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}

export default SetPassword