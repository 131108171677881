import React, { useEffect, useState } from "react";
import Users1API from "../../api/services/User1";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/slice/user/userSlice";
import { useToast } from "../../hooks";
import Spinner from "../../components/Spinner";

const options = [
  {
    value: "Male",
    icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiI+CjxwYXRoIGQ9Ik0gMi4xMDE1NjMgMjMgQyAyLjEwMTU2MyAyMyAyLjEwMTU2MyAyMS41IDIuMTAxNTYzIDIxLjMwMDc4MSBDIDIuMTAxNTYzIDE5LjEwMTU2MyA1LjM5ODQzOCAxNy4xMDE1NjMgOSAxNi4zOTg0MzggQyA5IDE2LjM5ODQzOCAxMC4xMDE1NjMgMTUuODAwNzgxIDkuODAwNzgxIDE0LjY5OTIxOSBDIDguNjk5MjE5IDEzLjMwMDc4MSA4LjM5ODQzOCAxMS44MDA3ODEgOC4zOTg0MzggMTEuODAwNzgxIEMgOC4xOTkyMTkgMTEuNjk5MjE5IDcuODk4NDM4IDExLjUgNy42OTkyMTkgMTEuMzAwNzgxIEMgNy4zOTg0MzggMTAuODk4NDM4IDcgOS42OTkyMTkgNy4xMDE1NjMgOC44MDA3ODEgQyA3LjE5OTIxOSA4IDcuMzk4NDM4IDguMzAwNzgxIDcuNSA4LjEwMTU2MyBDIDYuNjk5MjE5IDYuMzAwNzgxIDcuMTAxNTYzIDQgOCAyLjMwMDc4MSBDIDkuODk4NDM4IC0xIDEzLjg5ODQzOCAwIDE0LjUgMS4xOTkyMTkgQyAxOC4zMDA3ODEgMC41IDE4LjE5OTIxOSA2LjUgMTcuNjAxNTYzIDggQyAxNy42MDE1NjMgOCAxNy44OTg0MzggOC4xMDE1NjMgMTcuODk4NDM4IDkuNSBDIDE3LjgwMDc4MSAxMSAxNi42MDE1NjMgMTEuODk4NDM4IDE2LjYwMTU2MyAxMS44OTg0MzggQyAxNi42MDE1NjMgMTIuMzAwNzgxIDE2LjEwMTU2MyAxMy41IDE1LjMwMDc4MSAxNC42MDE1NjMgQyAxNC42MDE1NjMgMTYgMTYgMTYuMzAwNzgxIDE2IDE2LjMwMDc4MSBDIDE5LjYwMTU2MyAxNyAyMi44OTg0MzggMTkgMjIuODk4NDM4IDIxLjE5OTIxOSBDIDIyLjg5ODQzOCAyMS4zOTg0MzggMjIuODk4NDM4IDIyLjg5ODQzOCAyMi44OTg0MzggMjIuODk4NDM4IEMgMjIuODk4NDM4IDI0LjMwMDc4MSAxNy41IDI1LjgwMDc4MSAxMi41IDI1LjgwMDc4MSBDIDcuNjAxNTYzIDI1LjgwMDc4MSAyLjEwMTU2MyAyNS4zMDA3ODEgMi4xMDE1NjMgMjMgWiI+PC9wYXRoPgo8L3N2Zz4=",
  },
  {
    value: "Female",
    icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCI+CiAgICA8cGF0aCBkPSJNNSwyN2MwLTUsNi45NTctNC4xNzQsOC02Ljk5OVYxOWMtMy43NzgsMC01LjkxNC0xLjg4NC01LjkxNC0xLjg4NEM5LjA2LDE1LjQ3Myw2LjMyNiw0LjA0MywxMy4wNDksNC4wNDMgYzAsMCwwLjkwNy0xLjA0MywyLjA4LTEuMDQzYzguMjE4LDAsNS41MSwxMi40MSw3LjYzNSwxNC4xNTRjMCwwLTEuOTY4LDEuODQ2LTUuNzY1LDEuODQ2djEuMDAxQzE4LjA0MywyMi44MjYsMjUsMjIsMjUsMjdINXoiPjwvcGF0aD4KPC9zdmc+",
  },
  // {

  //   value: "other",
  //   icon: Education_Icon,
  // }
];

const PersonalInfo = ({ user }) => {
  const [personalInfo, setPersonalInfo] = useState();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const toast = useToast()

  const fetch = async () => {
    try {
      const response = await Users1API.getUser(user.id);
      setPersonalInfo(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleUpdateInfo = async () => {
    try {
      setLoading(true)
      const response = await Users1API.updateUser(
        personalInfo.name,
        personalInfo.gender,
        personalInfo.contactNo
      );
      if (response.status === 200) {
        toast.success("User Updated Successfully")
        setLoading(false)
        setEdit(false);
        const ans = {
          ...user, ...personalInfo
        }
        dispatch(setUser(ans));
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false)
      toast.error(error.response.data.data)

    }
  };

  const handleCanceLInfo = () => {
    setPersonalInfo({
      ...personalInfo,
      name: "",
      contactNo: "",
      gender: null,
    });
  };

  const handleChange = (value) => {
    setPersonalInfo({ ...personalInfo, gender: value });
  };

  return (
    <>
      <div className="py-6 sm:py-8 space-y-5 border-t border-gray-200 first:border-t-0 dark:border-neutral-500">
        <h2 className="font-semibold text-gray-800 dark:text-neutral-200">
          Personal info
        </h2>

        {/* <!--Name Grid --> */}
        <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
            <label
              for="hs-pro-dappinm"
              className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-400"
            >
              Name
            </label>
          </div>

          <div className="sm:col-span-8 xl:col-span-4">
            <input
              value={personalInfo?.name}
              onChange={(e) => {
                setPersonalInfo({
                  ...personalInfo,
                  name: e.target.value,
                });
              }}
              disabled={!edit}
              id="hs-pro-dappinm"
              type="text"
              className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 border focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-500 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
              placeholder="Enter full name"
            />

            <p className="text-xs mt-1 text-gray-500 dark:text-neutral-400">
              Enter your full name, or a display name you are comfortable with.
            </p>
          </div>
        </div>
        {/* <!--Name End Grid --> */}

        {/* <!--Email Grid --> */}
        <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
            <label
              for="hs-pro-dappiem"
              className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-400"
            >
              Email
            </label>
          </div>

          <div className="sm:col-span-8 xl:col-span-4">
            <input
              disabled
              value={personalInfo?.email}
              id="hs-pro-dappiem"
              type="text"
              className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 border disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-500 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
              placeholder="Enter email address"
            />

            <p className="text-xs mt-1 text-gray-500 dark:text-neutral-400">
              Email address you use to log in with Planbow.
            </p>
          </div>
        </div>
        {/* <!--Email End Grid --> */}

        {/* <!--Contact Grid --> */}
        <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
            <label
              for="hs-pro-dappiun"
              className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-400"
            >
              Contact No.
            </label>
          </div>

          <div className="sm:col-span-8 xl:col-span-4">
            <input
              value={personalInfo?.contactNo}
              onChange={(e) => {
                setPersonalInfo({
                  ...personalInfo,
                  contactNo: e.target.value,
                });
              }}
              disabled={!edit}
              id="hs-pro-dappiun"
              type="tel"
              maxLength={10}
              className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 border focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-500 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
              placeholder="Enter Contact Number"
            />

            <p className="text-xs mt-1 text-gray-500 dark:text-neutral-400">
              Enter your contact number.
            </p>
          </div>
        </div>
        {/* <!--Contact End Grid --> */}

        {/* <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
            <label
              for="hs-pro-dappiun"
              className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-400"
            >
              Gender
            </label>
          </div>

          <div className="sm:col-span-8 xl:col-span-4">
            <div class="flex gap-x-6">
              {options.map((option) => (
                <label
                  key={option.value}
                  htmlFor={"hs-pro-donpf1" + option.value}
                  className="relative flex cursor-pointer text-sm focus:outline-none"
                >
                  <input
                    type="radio"
                    disabled={!edit}
                    id={"hs-pro-donpf1" + option.value}
                    value={option.value}
                    name="hs-pro-donpf"
                    className="peer absolute hidden top-0 start-0 size-full bg-transparent border-gray-200 text-transparent rounded-xl cursor-pointer focus:ring-0 focus:ring-offset-0 after:relative after:-z-[1] after:block after:size-full after:rounded-lg checked:after:bg-blue-50 checked:text-transparent checked:border-blue-600 checked:hover:border-blue-600 checked:focus:border-blue-600 checked:bg-none disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-500 dark:checked:after:bg-blue-500/10 dark:checked:border-blue-500"
                    onChange={() => handleChange(option.value)}
                    checked={personalInfo?.gender === option.value}
                  />
                  <span
                    className={`peer-checked:text-blue-600 border peer-checked:border-blue-500 peer-checked:bg-blue-50 dark:text-white dark:peer-checked:text-blue-500 py-3 px-4 rounded-xl capitalize`}
                  >
                    {option.icon && (
                      <img
                        src={option.icon}
                        width="40px"
                        height="40px"
                      />
                    )}
                    <span
                      className={`flex justify-center items-center font-semibold text-md`}
                    >
                      {option.value}
                    </span>
                  </span>
                </label>
              ))}

            </div>
          </div>
        </div> */}

        {/* <!--personal Button Group --> */}
        <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2"></div>

          <div className="sm:col-span-8 xl:col-span-4">
            <div className="flex gap-x-3">
              {!loading ? <>

                <button
                  onClick={() => {
                    !edit ? setEdit(true) : handleUpdateInfo();
                  }}
                  type="button"
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {!edit ? "Edit" : "Save"}
                </button>

                <button
                  disabled={!edit}
                  onClick={handleCanceLInfo}
                  type="button"
                  className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-500 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                >
                  Cancel
                </button>
              </> : <Spinner />}
            </div>
          </div>
        </div>
        {/* <!--personal End Button Group --> */}
      </div>
    </>
  );
};

export default PersonalInfo;
