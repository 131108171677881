import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
}

const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        showLoader: (state) => {
            state.value = true;
        },
        hideLoader: (state) => {
            state.value = false;
        },
    },
})

export const { showLoader, hideLoader } = loadingSlice.actions

export default loadingSlice.reducer;