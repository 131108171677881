import { URLS } from "../../constants";
import planboard from "../../redux/slice/planboard";
import { postAuth } from "../client";

const AnalyticsAPI = {
  getStats: (planboardId: string) => postAuth(URLS.getKpis, { planboardId }),
  getFocusAreaChart: (planboardId: string) =>
    postAuth(URLS.getFocusAreaChart, { planboardId }),
};

export default AnalyticsAPI;
