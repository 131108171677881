import React, { useState } from "react";
import Menu, { MenuProps } from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import { MenuItem } from "@mui/material";
import IconButton from "../../Generic/IconButton";

import {
  ArrowDownFromLine,
  ArrowUpFromLine,
  Copy,
  Eraser,
  Layers3,
  Maximize2,
  Minimize2,
  MoreVertical,
  Palette,
  Pencil,
  Share,
  ListTree,
  Scissors,
  ClipboardPaste,
  Sigma,
  SmilePlus,
} from "lucide-react";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    // boxShadow:
    //   "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    boxShadow: "5px 10px 18px #888888",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: 12,
      marginRight: 4,
      marginLeft: 4,
      "&:hover": {
        backgroundColor: "#e8e8e8",
      },
      "& .lucide": {
        width: 14,
        height: 14,
        marginRight: 6,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 14,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const RowMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRowContextMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };
  return (
    <div>
      <IconButton
        icon={<MoreVertical height={14} width={14} />}
        //   height={14}
        //   width={14}
        onClick={handleRowContextMenu}
      />
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Pencil color="#808080" />
          Edit
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Scissors color="#808080" />
          Cut
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Copy color="#808080" />
          Copy
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ClipboardPaste color="#808080" />
          Paste
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Layers3 color="#808080" />
          Duplicate
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListTree color="#808080" />
          Add Sub-item
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Palette color="#808080" />
          Apply Color
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <SmilePlus color="#808080" />
          Add Icon
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Eraser color="#808080" />
          Clear
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Sigma color="#808080" />
          Conditional Formatting
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

export default RowMenu;
