import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../hooks";
import TeamsAPI from "../api/services/Teams";
import { addOrganizations } from "../redux/slice/organisations/slice";

const useQuery = () => new URLSearchParams(useLocation().search);
let type, planboardId, userId, emailId, id;

const InviteRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const toast = useToast();
  const token = localStorage.getItem("access_token");
  const user = useSelector((state) => state.user);

  const query = useQuery();
  // planboardId = query.get("planboardId");
  // userId = query.get("userId");
  type = query.get("type");
  emailId = query.get("emailId");
  id = query.get("id");

  const invitationDetails = JSON.parse(
    localStorage.getItem("invitationDetails")
  );

  useEffect(() => {
    if (invitationDetails) {
      if (invitationDetails.id) id = invitationDetails.id;
      if (invitationDetails.emailId) emailId = invitationDetails.emailId;
      if (invitationDetails.type) type = invitationDetails.type;
    }
  }, [invitationDetails]);

  useEffect(() => {
    if (token && user && user.value && emailId) {
      if (emailId !== user.value.email) {
        toast.error("Sign out of current user to proceed with the invitation");
        localStorage.removeItem("invitationDetails");
        localStorage.removeItem("prevRoute");
        return navigate(ROUTES.dashboard);
      }
      if (emailId && id && type && emailId === user.value.email) {
        if (type === "organization") {
          acceptOrganizationInvitation();
        }
      }
    }
  }, [type, id, emailId, user.value]);

  useEffect(() => {
    if (!token) return redirectToLogin();
  }, [token]);

  // const acceptPlanboardInvitation = async () => {
  //   if (!queryValues?.planboardId || !queryValues?.type) return;
  //   console.log(type);
  //   let response;
  //   try {
  //     if (queryValues.type === "planboard")
  //       response = await PlanboardsAPI.acceptInvitation(
  //         queryValues.planboardId
  //       );
  //     if (response.status === 200) {
  //       localStorage.removeItem("invitationDetails");
  //       localStorage.removeItem("prevRoute");
  //       navigate(ROUTES.sharedWithMe);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     if (error.response.status === 409)
  //       return setError(error.response.data.data);
  //     if (error.response.status === 400)
  //       return setError(error.response.data.data);
  //     return setError(
  //       error.response.data?.data ?? "Failed to join the planboard"
  //     );
  //   }
  // };

  const acceptOrganizationInvitation = async () => {
    try {
      const response = await TeamsAPI.acceptInvitation(id);
      if (response.status === 200) {
        dispatch(addOrganizations([response.data.data]));
        localStorage.removeItem("invitationDetails");
        localStorage.removeItem("prevRoute");
      }
    } catch (error) {
      console.log({ error });
      if (error.response.status === 409)
        toast.error(
          error.response.data?.data ?? "Invitation already accepted."
        );
    }

    navigate(ROUTES.dashboard);
  };

  useEffect(() => {
    if (location?.state && location?.state?.inviteId) {
      id = location.state.inviteId;
      acceptOrganizationInvitation();
    }
  }, [location]);

  const redirectToLogin = () => {
    const data = { type };
    if (planboardId) data.planboardId = planboardId;
    if (id) data.id = id;
    if (userId) data.userId = userId;
    if (emailId) data.emailId = emailId;
    localStorage.setItem("prevRoute", location.pathname);
    localStorage.setItem("invitationDetails", JSON.stringify(data));
    return navigate(ROUTES.signin);
  };

  return <div className="dark:bg-slate-800 h-screen w-screen" />;
};

export default InviteRedirect;
