import React, { useEffect, useState } from "react";
// import departments from "../../data/departments.json";
import { useNavigate } from "react-router-dom";
import { GLOBAL_URLS, ROUTES, URLS } from "../../constants";
import BackButton from "./BackButton";
import { postAuth } from "../../api/client";
import { useDebounce } from "@uidotdev/usehooks";

interface SelectDepartmentProps {
  setOnboardingData: React.Dispatch<React.SetStateAction<{}>>;
}

interface Option {
  departmentId: string;
  name: string;
}

const SelectDepartment = ({ setOnboardingData }: SelectDepartmentProps) => {
  const navigate = useNavigate();

  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleDepartmentSelection = (department: Option) => {
    setTimeout(() => handleNext(department), 300);
  };

  const handleNext = (selectedDepartment: Option) => {
    setOnboardingData((prev) => ({
      ...prev,
      departmentId: selectedDepartment.departmentId,
      category: selectedDepartment.name,
    }));
    navigate(ROUTES.selectSubDepartment, {
      state: { selectedDepartment: selectedDepartment.departmentId },
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const fetchSuggestions = async () => {
    try {
      setLoading(true);
      const response = await postAuth(GLOBAL_URLS.searchDepartments, {
        search: debouncedSearch,
      });
      setLoading(false);
      if (response.status === 200) {
        setDepartments(response.data.data);
      }
    } catch (error: any) {
      if (error.response.status === 404) {
        setDepartments([]);
        return setError(
          error?.response?.data?.data ??
            "No business domains found for given search"
        );
      }
      return setError("Error fetching domains");
    }
  };

  useEffect(() => {
    fetchSuggestions();
  }, [debouncedSearch]);

  return (
    <>
      <div>
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
          Please select your department
        </h1>
      </div>

      {/* <form>
        <div className="flex flex-wrap gap-2 mb-5">
          {Object.keys(departments).map((option) => (
            <label
              htmlFor={"hs-pro-donpf1" + option}
              className="relative flex cursor-pointer text-sm focus:outline-none"
            >
              <input
                type="radio"
                id={"hs-pro-donpf1" + option}
                name="hs-pro-donpf"
                onChange={() => handleDepartmentSelection(option)}
                className="peer absolute hidden top-0 start-0 size-full bg-transparent border-gray-200 text-transparent rounded-xl cursor-pointer focus:ring-0 focus:ring-offset-0 after:relative after:-z-[1] after:block after:size-full after:rounded-lg checked:after:bg-blue-50 checked:text-transparent checked:border-blue-600 checked:hover:border-blue-600 checked:focus:border-blue-600 checked:bg-none disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:checked:after:bg-blue-500/10 dark:checked:border-blue-500"
              />
              <span className="peer-checked:text-blue-600 border peer-checked:border-blue-500 peer-checked:bg-blue-50 dark:text-white dark:peer-checked:text-blue-500 py-3 px-4 rounded-xl capitalize">
                {option}
              </span>
            </label>
          ))}
        </div>
        <BackButton handleBack={handleBack} />
      </form> */}
      <form>
        <div className="bg-white py-5 -mx-1 sticky top-0 dark:bg-slate-900">
          <input
            type="text"
            className="block border w-full text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 py-2 px-3"
            placeholder="Search..."
            autoFocus
            onChange={handleSearchInputChange}
          />
        </div>
        {loading ? (
          <div className="animate-pulse">
            <ul className="my-5 space-y-3">
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
              <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
            </ul>
          </div>
        ) : (
          <div className="flex flex-wrap gap-2 mb-5 max-h-[55vh] overflow-auto">
            {departments.map((option: Option) => (
              <label
                key={option.departmentId}
                htmlFor={"hs-pro-donpf1" + option.departmentId}
                className="relative flex cursor-pointer text-sm focus:outline-none"
              >
                <input
                  type="radio"
                  id={"hs-pro-donpf1" + option.departmentId}
                  name="hs-pro-donpf"
                  className="peer absolute hidden top-0 start-0 size-full bg-transparent border-gray-200 text-transparent rounded-xl cursor-pointer focus:ring-0 focus:ring-offset-0 after:relative after:-z-[1] after:block after:size-full after:rounded-lg checked:after:bg-blue-50 checked:text-transparent checked:border-blue-600 checked:hover:border-blue-600 checked:focus:border-blue-600 checked:bg-none disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:checked:after:bg-blue-500/10 dark:checked:border-blue-500"
                  checked={selectedDepartment === option.departmentId}
                  onChange={() => handleDepartmentSelection(option)}
                />
                <span className="peer-checked:text-blue-600 border peer-checked:border-blue-500 peer-checked:bg-blue-50 dark:text-white dark:peer-checked:text-blue-500 py-3 px-4 rounded-xl capitalize">
                  {option.name}
                </span>
              </label>
            ))}
          </div>
        )}
        <BackButton handleBack={handleBack} />
      </form>
    </>
  );
};

export default SelectDepartment;
