import React from "react";
import ErrorMessage from "./ErrorMessage";
import ErrorIcon from "./ErrorIcon";

export default function Textarea({
  field,
  label,
  name,
  id,
  value,
  hideLabel,
  className,
  placeholder,
  isOptional = false,
  form: { touched, errors },
  ...props
}) {
  return (
    <div>
      {hideLabel ? null : (
        <label htmlFor="name" className="flex text-sm mb-2 dark:text-white">
          {label}
          {isOptional && (
            <span className="italic text-sm text-gray-500">
              &nbsp;(optional)
            </span>
          )}
        </label>
      )}
      <div className="relative">
        <textarea
          {...field}
          {...props}
          className={
            className ||
            "py-3 px-4 block w-full border hover:bg-gray-100 dark:hover:bg-slate-800 dark:focus:bg-slate-800 border-gray-200 rounded-lg text-sm focus:border-primary-600 focus:ring-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
          }
          required
          placeholder={placeholder}
          aria-describedby={`${id}-error`}
        />
        <ErrorIcon touched={touched} errors={errors} field={field} />
      </div>
      <ErrorMessage touched={touched} errors={errors} field={field} />
    </div>
  );
}
