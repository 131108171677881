import React from "react";

type VariantValue = "rectangle" | "circle" | "rounded";

interface SkeletonProps {
  animation?: false | "pulse";
  variant?: VariantValue;
  height?: string;
  width?: string;
}

const Skeleton = ({
  animation = "pulse",
  variant = "rectangle",
  height = "h-5",
  width = "w-full",
}: SkeletonProps) => {
  const animationClass = animation ? `animate-${animation} ` : "";
  const variantClass =
    variant === "circle"
      ? "block rounded-full "
      : variant === "rounded"
      ? "rounded-md "
      : "rounded-none ";

  // const widthClass = width ? `w-[${width}] ` : "w-full ";

  const className = `bg-gray-200 dark:bg-gray-700 ${width} ${height} ${animationClass} ${variantClass}`;

  return (
    <div
      className={className}
      // style={{ height: height + "px", width: width ? width : "100%" }}
    />
  );
};

export default Skeleton;
