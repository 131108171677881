import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  AlertCircle,
  AlertTriangle,
  Check,
  ChevronRight,
  CircleDashed,
  Clock,
  Copy,
  Maximize2,
  Minimize2,
  Pencil,
} from "lucide-react";
import Tooltip from "../Preline/Tooltip";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: 12,
      marginRight: 4,
      marginLeft: 4,
      "& .lucide": {
        width: 14,
        height: 14,
        marginRight: 6,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 14,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const options = [
  {
    label: "Critical",
    icon: <AlertTriangle size={14} />,
    color: "text-red-700",
  },
  { label: "High", icon: <AlertCircle size={14} />, color: "text-orange-600" },
  { label: "Medium", icon: <Clock size={14} />, color: "text-yellow-500" },
  { label: "Low", icon: <Clock size={14} />, color: "text-gray-500" },
];

interface Option {
  label: string;
  icon: React.ReactNode;
  color: string;
}

interface PriorityMenuProps {
  updatePriority: (value: string) => void;
  priority: string;
  readOnly: boolean;
}

export default function PriorityMenu({
  updatePriority,
  priority,
  readOnly,
}: PriorityMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const [value, setValue] = React.useState<Option>(options[0]);

  const [value, setValue] = React.useState<Option>(() => {
    // Find the corresponding option based on the priority prop
    const matchingOption = options.find(
      (opt) => opt.label.toLowerCase() === priority
    );
    return matchingOption || options[0]; // Default to the first option if no match
  });

  const handleChange = (option: Option) => {
    setValue(option);
    updatePriority(option.label.toLowerCase());
    handleClose();
  };

  // const bg =
  //   value.label === "Completed"
  //     ? "bg-green-100 text-green-800 hover:bg-green-200"
  //     : value.label === "In Progress"
  //     ? "bg-orange-100 text-orange-800 hover:bg-orange-200"
  //     : "bg-red-100 text-red-800 hover:bg-red-200";

  return (
    <div>
      <Tooltip
        title={readOnly ? `${value.label} priority` : "Click to Edit Priority"}
      >
        {readOnly ? (
          <div className={`${value.color}`}>{value.icon}</div>
        ) : (
          <div onClick={handleClick}>
            <span
              // className={`cursor-pointer inline-flex items-center gap-x-1.5 p-2 rounded-full text-xs font-medium bg-${value.color}-100 hover:bg-${value.color}-200 text-${value.color}-800`}
              className={`cursor-pointer inline-flex items-center rounded-full text-xs font-medium ${value.color} hover:scale-125 transition duration-300`}
            >
              {value.icon}
              {/* {value.label} */}
              {/* <ChevronRight
          size={16}
          className={`transition-transform transform ${
            open ? "rotate-90" : ""
          }`}
        /> */}
            </span>
          </div>
        )}
      </Tooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem onClick={() => handleChange(option)} key={option.label}>
            {option.icon}
            {option.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
