import React from "react";
import Tooltip from "../Preline/Tooltip";

const UserRole = ({ role }: { role: string }) => {
  return (
    // <div className="hs-dropdown relative inline-flex [--placement:top-right]">
    //   {/*   <!-- Help Button Icon --> */}
    //   <button
    //     // id="hs-pro-dnhd"
    //     type="button"
    //     className="px-2 pb-1 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent text-gray-600 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
    //   >
    //     ({role})
    //   </button>
    //   {/*   <!-- End Help Button Icon --> */}

    //   {/*   <!-- Help Dropdown --> */}
    //   <div
    //     className="hs-dropdown-menu hs-dropdown-open:opacity-100 w-60 transition-[opacity,margin] duration opacity-0 hidden z-10 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]"
    //     aria-labelledby="hs-pro-dnhd"
    //   >
    //     <div className="p-1">
    //       <a
    //         className="flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
    //         href="#"
    //       >
    //         <svg
    //           className="flex-shrink-0 mt-1 w-4 h-4"
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="24"
    //           height="24"
    //           viewBox="0 0 24 24"
    //           fill="none"
    //           stroke="currentColor"
    //           stroke-width="2"
    //           stroke-linecap="round"
    //           stroke-linejoin="round"
    //         >
    //           <circle cx="12" cy="12" r="10" />
    //           <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
    //           <path d="M12 17h.01" />
    //         </svg>
    //         Help Centre
    //       </a>
    //       <a
    //         className="flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
    //         href="#"
    //       >
    //         <svg
    //           className="flex-shrink-0 mt-1 w-4 h-4"
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="24"
    //           height="24"
    //           viewBox="0 0 24 24"
    //           fill="none"
    //           stroke="currentColor"
    //           stroke-width="2"
    //           stroke-linecap="round"
    //           stroke-linejoin="round"
    //         >
    //           <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
    //           <circle cx="9" cy="7" r="4" />
    //           <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
    //           <path d="M16 3.13a4 4 0 0 1 0 7.75" />
    //         </svg>
    //         Community
    //       </a>
    //       <a
    //         className="flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
    //         href="#"
    //       >
    //         <svg
    //           className="flex-shrink-0 mt-1 w-4 h-4"
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="24"
    //           height="24"
    //           viewBox="0 0 24 24"
    //           fill="none"
    //           stroke="currentColor"
    //           stroke-width="2"
    //           stroke-linecap="round"
    //           stroke-linejoin="round"
    //         >
    //           <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
    //           <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
    //         </svg>
    //         What’s New
    //       </a>

    //       <div className="my-1 border-t border-gray-200 dark:border-neutral-800"></div>

    //       <a
    //         className="flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
    //         href="#"
    //       >
    //         <svg
    //           className="flex-shrink-0 mt-1 w-4 h-4"
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="24"
    //           height="24"
    //           viewBox="0 0 24 24"
    //           fill="none"
    //           stroke="currentColor"
    //           stroke-width="2"
    //           stroke-linecap="round"
    //           stroke-linejoin="round"
    //         >
    //           <circle cx="12" cy="12" r="10" />
    //           <path d="M12 16v-4" />
    //           <path d="M12 8h.01" />
    //         </svg>
    //         Privacy and Legal
    //       </a>
    //       <a
    //         className="flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
    //         href="#"
    //       >
    //         <svg
    //           className="flex-shrink-0 mt-1 w-4 h-4"
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="24"
    //           height="24"
    //           viewBox="0 0 24 24"
    //           fill="none"
    //           stroke="currentColor"
    //           stroke-width="2"
    //           stroke-linecap="round"
    //           stroke-linejoin="round"
    //         >
    //           <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
    //           <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
    //         </svg>
    //         Documentation
    //       </a>
    //       <a
    //         className="flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
    //         href="#"
    //       >
    //         <svg
    //           className="flex-shrink-0 mt-1 w-4 h-4"
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="24"
    //           height="24"
    //           viewBox="0 0 24 24"
    //           fill="none"
    //           stroke="currentColor"
    //           stroke-width="2"
    //           stroke-linecap="round"
    //           stroke-linejoin="round"
    //         >
    //           <rect width="20" height="14" x="2" y="7" rx="2" ry="2" />
    //           <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
    //         </svg>
    //         Hire an Expert
    //         <div className="ms-auto">
    //           <span className="inline-flex items-center gap-1.5 py-px px-1.5 rounded text-[10px] leading-4 font-medium bg-gray-100 text-gray-800 dark:bg-neutral-700 dark:text-neutral-300">
    //             New
    //           </span>
    //         </div>
    //       </a>

    //       <div className="my-1 border-t border-gray-200 dark:border-neutral-800"></div>

    //       <a
    //         className="flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
    //         href="#"
    //       >
    //         <svg
    //           className="flex-shrink-0 mt-1 w-4 h-4"
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="24"
    //           height="24"
    //           viewBox="0 0 24 24"
    //           fill="none"
    //           stroke="currentColor"
    //           stroke-width="2"
    //           stroke-linecap="round"
    //           stroke-linejoin="round"
    //         >
    //           <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
    //           <line x1="9" x2="15" y1="10" y2="10" />
    //           <line x1="12" x2="12" y1="7" y2="13" />
    //         </svg>
    //         Submit Feedback
    //       </a>
    //       <div className="hs-dropdown relative [--strategy:static] md:[--strategy:absolute] [--adaptive:none] md:[--trigger:hover]">
    //         <button
    //           type="button"
    //           className="hs-dropdown-toggle w-full flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
    //         >
    //           <svg
    //             className="flex-shrink-0 w-4 h-4"
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //             stroke="currentColor"
    //             stroke-width="2"
    //             stroke-linecap="round"
    //             stroke-linejoin="round"
    //           >
    //             <rect width="20" height="16" x="2" y="4" rx="2" />
    //             <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
    //           </svg>
    //           Contact Us
    //           <svg
    //             className="rotate-90 md:rotate-0 ms-auto w-3 h-3"
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //             stroke="currentColor"
    //             stroke-width="2"
    //             stroke-linecap="round"
    //             stroke-linejoin="round"
    //           >
    //             <path d="m9 18 6-6-6-6" />
    //           </svg>
    //         </button>

    //         <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 md:w-48 hidden z-10 top-0 end-full md:!me-3 md:mt-1 md:p-1 bg-white md:shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] md:rounded-lg before:absolute before:-end-5 before:top-0 before:h-full before:w-5 dark:bg-neutral-900 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]">
    //           <a
    //             className="flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
    //             href="#"
    //           >
    //             <svg
    //               className="flex-shrink-0 mt-1 w-4 h-4"
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="24"
    //               height="24"
    //               viewBox="0 0 24 24"
    //               fill="none"
    //               stroke="currentColor"
    //               stroke-width="2"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             >
    //               <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
    //               <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
    //             </svg>
    //             Contact Support
    //           </a>
    //           <a
    //             className="flex gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
    //             href="#"
    //           >
    //             <svg
    //               className="flex-shrink-0 mt-1 w-4 h-4"
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="24"
    //               height="24"
    //               viewBox="0 0 24 24"
    //               fill="none"
    //               stroke="currentColor"
    //               stroke-width="2"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             >
    //               <path d="M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 18 0v7a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3" />
    //             </svg>
    //             Contact Sales
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/*   <!-- End Help Dropdown --> */}
    // </div>
    <span className="inline-flex items-center bg-white border border-gray-200 text-gray-800 text-xs rounded-md dark:bg-neutral-800 dark:text-neutral-200 dark:border-neutral-700">
      <Tooltip title={`Your role on this planboard is ${role}`} placement="left">
        <span className="py-[5px] px-2 text-gray-500 dark:text-neutral-500" style={{ cursor: "pointer" }}>
          Role
        </span>
        <span className="border-s border-gray-200 font-medium text-xs rounded-e-md dark:border-neutral-700">
          <span className="py-[5px] px-2 inline-flex items-center gap-1.5">
            {role}
          </span>
        </span>
      </Tooltip>
    </span>
  );
};

export default UserRole;
