import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import withAuth from '../../containers/AuthenticationContainer';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from '../../components/Inputs/Input';
import { ROUTES } from '../../constants';
import RegistrationAPI from '../../api/services/Registration.ts';

const initialValues = {
    name: '',
};
const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
});

function RegisterName({
    handleRegisterName
}) {
    const [state, setState] = useState({
        isValid: true,
        validatingToken: true,
        message: '',
        loading: false,
        email: '',
    });
    const navigate = useNavigate();
    const { id, token } = useParams();
    const handleSubmit = (values, { setSubmitting }) => {
        handleRegisterName(id, values).then((response) => {
            setSubmitting(false);
            if (response.data.message === 'success') {
                navigate(ROUTES.registerPassword, { state: { name: values.name, id, email: state.email } });
            }
        })
    }
    const validateToken = async () => {
        const response = await RegistrationAPI.verifyEmail({ id, token });
        setState((prev) => ({
            ...prev,
            validatingToken: false,
        }));
        if (response.data.message === 'no-user') {
            setState((prev) => ({
                ...prev,
                isValid: false,
                message: 'No user found',
            }));
        } else if (response.data.message === 'invalid token') {
            setState((prev) => ({
                ...prev,
                isValid: false,
                message: 'Invalid Token',
            }));
        } else if (response.data.message === 'success') {
            setState((prev) => ({
                ...prev,
                email: response.data.email,
            }));
        }
        return;
    };
    useEffect(() => {
        id && token && validateToken();
    }, [id, token]);
    return (
        <div className="dark:bg-slate-900 bg-gray-100 flex h-screen items-center py-16">
            <main className="w-full max-w-md mx-auto p-6">
                <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-4 sm:p-7">
                        <div className="text-center mb-6">
                            <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">Enter Your Full Name</h1>
                        </div>
                        {
                            state.isValid ?
                                <div className="mt-5">
                                    {/* <!-- Form --> */}
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {
                                            (props) => {
                                                const {
                                                    values,
                                                    isSubmitting,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                } = props;
                                                return (
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="grid gap-y-4">
                                                            {/* <!-- Form Group --> */}
                                                            <Field
                                                                label="Name"
                                                                id="name"
                                                                name="name"
                                                                component={Input}
                                                                value={values.name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {/* <!-- End Form Group --> */}

                                                            <button disabled={isSubmitting} type="submit" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">Next</button>
                                                        </div>
                                                    </form>
                                                )
                                            }}
                                    </Formik>
                                    {/* <!-- End Form --> */}
                                </div>
                                : <div className="mt-5">
                                    <h6 class="text-base dark:text-white">
                                        Link Expired
                                    </h6>
                                    <p class="dark:text-white">
                                        {state.message}
                                    </p>
                                    <div className="text-center">
                                        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                                            <Link className="text-primary-400 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" to={ROUTES.login}>
                                                Back to login!
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </main>
        </div>
    )
}

export default withAuth(RegisterName);