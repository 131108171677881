import React, { useEffect, useState } from "react";
import ErrorIcon from "./ErrorIcon";
import ErrorMessage from "./ErrorMessage";
import Datepicker from "tailwind-datepicker-react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const options = {
  // title: "Demo Title",
  autoHide: true,
  todayBtn: false,
  clearBtn: false,
  clearBtnText: "Clear",
  // maxDate: new Date("2030-01-01"),
  minDate: new Date(),
  theme: {
    background: "",
    todayBtn: "bg-primary-400 hover:bg-primary-500",
    clearBtn: "",
    icons: "",
    text: "",
    // disabledText: "bg-gray-600",
    input:
      "bg-white dark:bg-slate-900 border-gray-200 dark:border-gray-700 py-3 text-sm",
    inputIcon: "",
    selected: "bg-primary-400 hover:bg-primary-500 !text-white",
  },
  icons: {
    prev: () => (
      <span>
        <ChevronLeft />
      </span>
    ),
    next: () => (
      <span>
        <ChevronRight />
      </span>
    ),
  },
  datepickerClassNames: "top-12",
  // defaultDate: new Date(),
  language: "en",
  disabledDates: [],
  weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
  inputNameProp: "date",
  inputIdProp: "date",
  inputPlaceholderProp: "Select Date",
  inputDateFormatProp: {
    day: "numeric",
    month: "long",
    year: "numeric",
  },
};

interface DatePickerProps {
  field: any;
  label: string;
  name: string;
  id: string;
  value: string;
  className: string;
  placeholder: string;
  hideLabel: boolean;
  form: any;
  onChange: any;
  minDate: Date;
  maxDate: Date;
}

const DatePicker = ({
  field,
  label,
  name,
  id,
  value,
  className,
  placeholder,
  hideLabel,
  form: { touched, errors },
  onChange,
  minDate,
  maxDate,
  ...props
}: DatePickerProps) => {
  const [show, setShow] = useState<Boolean>(false);
  const [options, setOptions] = useState({
    // title: "Demo Title",
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    clearBtnText: "Clear",
    // maxDate,
    minDate,
    theme: {
      background: "",
      todayBtn: "bg-primary-400 hover:bg-primary-500",
      clearBtn: "",
      icons: "",
      text: "",
      // disabledText: "bg-gray-600",
      input:
        "bg-white dark:bg-slate-900 hover:bg-gray-100 border-gray-200 dark:border-gray-700 py-3 text-sm",
      inputIcon: "",
      selected: "bg-primary-400 hover:bg-primary-500 !text-white",
    },
    icons: {
      prev: () => (
        <span>
          <ChevronLeft />
        </span>
      ),
      next: () => (
        <span>
          <ChevronRight />
        </span>
      ),
    },
    datepickerClassNames: "top-12",
    defaultDate: new Date(),
    language: "en",
    disabledDates: [],
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Select Date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  });
  const handleClose = (state: Boolean) => {
    setShow(state);
  };
  // console.log({ value, field, props });
  const handleChange = (selectedDate: Date) => {
    onChange({
      target: {
        ...field,
        value: selectedDate,
        type: "date",
      },
    });
  };
  return (
    <div>
      {hideLabel ? null : (
        <label htmlFor={id} className="flex text-sm mb-2 dark:text-white">
          {label}
        </label>
      )}
      <div className="relative hover:bg-gray-100 rounded-lg focus:z-40">
        {/* <input
          type="date"
          {...field}
          {...props}
          id={id}
          name={name}
          className={
            className ||
            "py-3 px-4 border block w-full border-gray-200 rounded-lg text-sm focus:border-primary-600 focus:ring-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
          }
        /> */}
        <Datepicker
          {...field}
          {...props}
          // value={new Date(value)}
          id={id}
          name={name}
          options={options}
          onChange={handleChange}
          show={show}
          setShow={handleClose}
        />
        <ErrorIcon touched={touched} errors={errors} field={field} />
      </div>
      <ErrorMessage touched={touched} errors={errors} field={field} />
    </div>
  );
};

export default DatePicker;
