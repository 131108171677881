export const ROUTES = {
  account: "/account",
  activity: "/activity",
  createOrganisation: "/onboarding/create-organization",
  createPlanboard: "/create-planboard",
  createTeam: "/create-team",
  dashboard: "/dashboard",
  aiAgents: "/ai-agents",
  template: "/template",
  integrations:"/integrations",
  enterPlanboardName: "/onboarding/enter-planboard-name",
  forgotPassword: "/forgot-password",
  invite: "/invite",
  inviteMembers: "/onboarding/invite-members",
  login: "/login",
  logout: "/logout",
  newOrganisation: "/new-organisation",
  planboardDesigner: "/planboard-designer",
  problemDetails: "/onboarding/problem-details",
  registerEmail: "/register/step-1",
  registerName: "/register/step-2",
  registerPassword: "/register/step-3",
  root: "/",
  selectDeadline: "/onboarding/select-duration",
  selectDepartment: "/onboarding/select-department",
  selectIndustry: "/onboarding/select-industry",
  selectObjective: "/onboarding/select-objective",
  selectSpecialization: "/onboarding/select-specialization",
  selectSubDepartment: "/onboarding/select-sub-department",
  selectSubIndustry: "/onboarding/select-sub-industry",
  selectSubRole: "/onboarding/select-sub-role",
  sharedWithMe: "/shared-with-me",
  signin: "/sign-in",
  profile: "/profile",
  forgetPassword: "/forget-password",
  setPassword: "/set-password",
  signup: "/sign-up",
  signInCallback: "/callback",
  signUp: "/sign-up",
  socialLoginFailure: "/auth/social/login-failure",
  socialLoginSuccess: "/auth/social/login-success",
  starred: "/starred",
  teams: "/teams",
  trash: "/trash",
  verifyEmail: "/register/verify-email",
  whatBringsYoutoPlanbow: "/onboarding/what-brings-you-to-planbow",
  whatIsTeamSize: "/onboarding/what-is-team-size",
  whatIsYourRole: "/onboarding/what-is-your-role",
  workspaces: "/workspaces",
};

const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
export const DB_BASE_URL = GATEWAY_URL + "/app";

export const GLOBAL_URLS = {
  getMeetingTypes: DB_BASE_URL + "/global/get-meeting-types",
  searchDepartments: DB_BASE_URL + "/global/search-departments",
  searchSubDepartments: DB_BASE_URL + "/global/search-sub-departments",
  searchRoles: DB_BASE_URL + "/global/search-roles",
  searchSubRoles: DB_BASE_URL + "/global/search-sub-roles",
};

export const USERS_URLS = {
  updateUser: DB_BASE_URL + "/users/update-user",
  getUser: DB_BASE_URL + "/users/get-user",
  changePassword: DB_BASE_URL + "/users/change-password",
  setPassword: DB_BASE_URL + "/users/set-password",
  uploadProfile: DB_BASE_URL + "/users/upload-profile",
  orgnaizationBelonging: DB_BASE_URL + "/users/orgnaization-belonging",
};

export const PLANBOARD_URLS = {
  addMember: DB_BASE_URL + "/planboard/add-member",
  removeMember: DB_BASE_URL + "/planboard/remove-member",
  updateRole: DB_BASE_URL + "/planboard/update-role",
  planboardBuildStatus: DB_BASE_URL + "/planboard/planboard-build-status",
  getMembers: DB_BASE_URL + "/planboard/get-members",
  sharedPlanboards: DB_BASE_URL + "/planboard/shared-planboards",
  acceptInvitation: DB_BASE_URL + "/planboard/accept-invitation",
  nameExists: DB_BASE_URL + "/planboard/name-exists",
  markAsFavourite: DB_BASE_URL + "/planboard/mark-as-favourite",
  favouritePlanboards: DB_BASE_URL + "/planboard/favourite-planboards",
  recentPlanboards: DB_BASE_URL + "/planboard/recent-planboards",
};

export const NODES_URLS = {
  setNodesMetaData: DB_BASE_URL + "/nodes/set-nodes-meta-data",
  updateNode: DB_BASE_URL + "/nodes/update-node",
  disconnectEdge: DB_BASE_URL + "/nodes/disconnect-edge",
  connectEdge: DB_BASE_URL + "/nodes/connect-edge",
  getNodeDetails: DB_BASE_URL + "/nodes/get-node-details",
};

export const ACTION_ITEMS_URLS = {
  getActionItems: DB_BASE_URL + "/action-items/get-action-items",
  addActionItem: DB_BASE_URL + "/action-items/add-action-item",
  updateActionItem: DB_BASE_URL + "/action-items/update-action-item",
  deleteActionItem: DB_BASE_URL + "/action-items/delete-action-item",
};
export const SUBTASKS_URLS = {
  get: DB_BASE_URL + "/tasks/get-tasks",
  add: DB_BASE_URL + "/tasks/add-task",
  update: DB_BASE_URL + "/tasks/update-task",
  delete: DB_BASE_URL + "/tasks/delete-task",
  addAttachment: DB_BASE_URL + "/tasks/add-attachment",
  removeAttachment: DB_BASE_URL + "/tasks/remove-attachment",
  aiGenerativeDeliverables: DB_BASE_URL + "/tasks/ai-generative-deliverables",
};

export const NOTIFICATION_URLS = {
  unreadCount: DB_BASE_URL + "/notify/unread-count",
  MarkAsRead: DB_BASE_URL + "/notify/mark-as-read",
  getNotifications: DB_BASE_URL + "/notify/get-notifications",
};

export const PUBLIC_URLS = {
  authenticateWithSocialAccount:
    DB_BASE_URL + "/public/authenticate-with-social-account",
  createAccount: DB_BASE_URL + "/public/create-account",
  authenticateUser: DB_BASE_URL + "/public/authenticate-user",
  refreshToken: DB_BASE_URL + "/public/refresh-token",
  forgetPassword: DB_BASE_URL + "/public/forgot-password",
  verifyOTP: DB_BASE_URL + "/public/verify-otp",
  setPassword: DB_BASE_URL + "/public/set-password",
};

export const TEAM_URLS = {
  subscribedPlanUsage: DB_BASE_URL + "/teams/subscribed-plan-usage",
  updateRole: DB_BASE_URL + "/teams/update-role",
};

export const URLS = {
  searchMembers: DB_BASE_URL +"/teams/search-members",
  inviteMember: DB_BASE_URL + "/teams/invite-member",
  getMembers: DB_BASE_URL + "/teams/get-members",
  acceptTeamsInvitation: DB_BASE_URL + "/teams/accept-invitation",
  getFocusAreaChart: DB_BASE_URL + "/analytics/focus-area-chart",
  getKpis: DB_BASE_URL + "/analytics/kpis",
  getFiles: DB_BASE_URL + "/files/get-files",
  removeAttachment: DB_BASE_URL + "/planboard/remove-attachment",
  addAttachment: DB_BASE_URL + "/planboard/add-attachment",
  deleteNode: DB_BASE_URL + "/nodes/delete-node",
  getPlanboardNodes: DB_BASE_URL + "/planboard/get-planboard-nodes",
  getStrategicNodes: DB_BASE_URL + "/planboard/get-strategic-nodes",
  getPlanboardSummary: DB_BASE_URL + "/planboard/planboard-summary",
  validatePrompt: DB_BASE_URL + "/open.ai/validate-prompt",
  planboardValidatePrompt: DB_BASE_URL + "/planboard/validate-prompt",
  authSocial: "/auth/auth-social",
  createCanvas: "/canvas/create",
  addNode: DB_BASE_URL + "/nodes/add-node",
  createOrganization: "/organisation/create",
  // createPlanboard: "/planboard/create",
  createPlanboard: DB_BASE_URL + "/planboard/create-planboard",
  createWorkspace: DB_BASE_URL + "/workspace/create-workspace",
  createNodeElement: "/node-elements/create",
  createTeam: "/teams/create",
  deletePlanboard: "/planboard/delete",
  forgotPassword: "/forgot-password",
  getCanvas: "/canvas/get?planboardId=",
  getDeletedPlanboards: "/planboard/get/bin?userId=",
  getDomains: DB_BASE_URL + "/global/search-domains",
  getSubdomains: DB_BASE_URL + "/global/search-subdomains",
  getNode: "/nodes",
  getNodes: "/nodes/all",
  getAllNodeElements: "/node-elements/all?nodeId=",
  updateNodeElement: "/node-elements/update",
  deleteNodeElement: "/node-elements/delete?id=",
  deleteAllNodeElements: "/node-elements/delete?nodeId=",
  getElementData: "/node-elements/data?id=",
  getPlanboard: "/planboard/get?id=",
  getPlanboards: "/planboard/get",
  getRecentPlanboards: "/planboard/recent?teamId=",
  getTeams: "/teams/get",
  getUser: "/user/get-user",
  getUserDetails: "/user/get?id=",
  getWorkspaces: DB_BASE_URL + "/workspace/get-workspaces",
  getWorkspace: DB_BASE_URL + "/workspace/get-workspace",
  googleSignIn: "/auth/google",
  googleSignIn: "/auth/google/callback",
  isLoggedIn: "/auth/isLoggedIn",
  linkedinSignIn: "/auth/linkedIn/authenticate",
  login: "/auth/local/login",
  registerEmail: "/register/step-1",
  registerName: "/register/name",
  registerPassword: "/register/password",
  resetPassword: "/reset-password",
  updateCanvas: "/canvas/update",
  updateNodes: "/nodes",
  updatePlanboard: DB_BASE_URL + "/planboard/update-planboard",
  updatePlanboardUsers: "/planboard/update-users",
  updateUser: "/user",
  validatePassword: "/reset-password/validate",
  verifyEmail: "/register/verify-email",
  newInvite: "/invite/new",
  validateInvite: "/invite/validate",
  getTeamMembers: "/team-members/get?teamId=",
  getInvitations: "/invite/get?teamId=",
  updateInvitation: "/invite/update",
  updateWorkspace: DB_BASE_URL + "/workspace/update-workspace",
  generateNodes: "/open-api/generate-nodes",
  generateAttributes: "/open-api/generate-attributes",
  generateActionItems: "/open-api/generate-action-items",
  deleteTeamMember: "/team-members/delete-team-member?id=",
  updateTeamMember: "/team-members/update",
  getActionItems: "/action-items/get",
  updateActionItems: "/action-items/update",
  updateTeam: "/teams/update",
  getUsers: GATEWAY_URL + "/users/get-users",
};

export const loginPageUrl =
  process.env.REACT_APP_IDP_URL +
  "/oauth2/authorize?response_type=code&client_id=planbow&scope=openid&redirect_uri=" +
  process.env.REACT_APP_PB_IDP_CALL_BACK_URL;

export const BASE_URL =
  process.env.REACT_APP_GATEWAY_URL || "https://dev.gateway.planbow.com";

export const COLUMN_TYPES = [
  {
    value: "text",
    text: "Text Field",
  },
  {
    value: "date",
    text: "Date Picker",
  },
  {
    value: "number",
    text: "Number",
  },
];

export const ItemTypes = {
  ROW: "row",
};
