export const SUPPORTED_FILE_TYPES = [
  // Image Files
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "image/svg+xml",

  // Document Files
  "application/pdf",
  "application/msword", // .doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "application/vnd.ms-excel", // .xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  "application/vnd.ms-powerpoint", // .ppt
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
  "text/plain", // .txt

  // Compressed Files
  // "application/zip",
  // "application/x-rar-compressed",
  // "application/x-7z-compressed",
];

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
export const MAX_FILES = 5;
