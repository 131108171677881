import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SessionOut from "../components/Alerts/SessionOut";
import { useSelector } from "react-redux";
//removing the below line causes lexical error
import { isValidAccessToken } from "../api/client";
import { ROUTES } from "../constants";

const PrivateRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const login = useSelector((state) => state.login);
  const organizations = useSelector((state) => state.organizations.value);

  const access_token = localStorage.getItem("access_token");

  const handleClose = () => {
    sessionStorage.setItem("prevRoute", location.pathname);
    navigate(ROUTES.logout);
  };

  useEffect(() => {
    try {
      if (!organizations || organizations.length === 0)
        navigate(ROUTES.createOrganisation);
      if (organizations.length > 0 && organizations[0].onboarded === false)
        navigate(ROUTES.whatBringsYoutoPlanbow);
    } catch (error) {
      console.log({ error });
      navigate(ROUTES.createOrganisation);
    }
  }, [organizations]);

  return (
    <>
      <SessionOut
        handleClose={handleClose}
        open={!login.authed && !login.loading}
        loggedIn={Boolean(access_token)}
      />
      <Outlet />
    </>
  );
};

export default PrivateRoute;
