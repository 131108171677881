import { HSSelect } from "preline";
import React, { useEffect, useState } from "react";
import Select from "./Select";

const WorkspaceSort = ({
  options,
  selectedValue,
  setSelectedValue,
}: {
  options: { value: string; label: string }[];
  selectedValue: string;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <Select
      options={options}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
    />
  );
};

export default WorkspaceSort;
