import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Attachments from "./Attachments";
import Info from "./Info";
import Members from "./Members";
import HeaderBand from "../../assets/images/SummaryHeaderBand.png";
import ComponentHeader from "../componentHeader";
// import { TimeIcon } from "lucide-react";
import Label from "./ui/Label";
import ReadOnlyField from "./ui/ReadOnlyField";

const PlanboardSummary = () => {
  const planboard = useSelector((state) => state.planboard);

  return (
    <div class="m-5 w-full mt-0 md:p-4 bg-white border border-gray-200 shadow-2xl rounded-xl dark:bg-slate-800 dark:border-neutral-500 text-gray-800 dark:text-neutral-200">
      <div className="w-full">
        <ComponentHeader title="Planboard Summary" />
      </div>

      {/* <div className="relative shadow-md rounded-t-lg">
        <div className="flex overflow-hidden">
          <img src={HeaderBand} className="opacity-25 h-20" />
          <img src={HeaderBand} className="opacity-25 h-20" />
          <img src={HeaderBand} className="opacity-25 h-20" />
        </div>
        <div class="absolute top-0 h-full w-full flex items-center p-5">
          <h1 class="text-2xl font-semibold ">Planboard Summary</h1>
        </div>
      </div> */}

      {/* <!-- Form --> */}
      <form className="">
        <div class="space-y-5 border-gray-200 border dark:border-neutral-700 p-5 pb-2 ps-8">
          <h2 class="font-semibold text-gray-800 dark:text-neutral-200 pb-5">
            Planboard info
          </h2>
          <Info />
        </div>
        <div class="space-y-5 border-gray-200 border dark:border-neutral-700 border-t-0 p-5 pb-2 ps-8">
          <h2 class="font-semibold text-gray-800 dark:text-neutral-200 pb-5">
            Business Area
          </h2>
          <div className="grid grid-cols-2 gap-y-5 gap-x-8 pb-5 ps-3">
            <div className="grid grid-cols-[20%_76%]">
              <Label htmlFor="summary-domain" label="Industry" />
              {planboard.loading ? (
                <div className="w-60 h-4 ms-4 animate-pulse bg-gray-200 rounded-full dark:bg-gray-700" />
              ) : (
                <ReadOnlyField
                  value={planboard.value?.businessArea?.domain}
                  id="summary-domain"
                />
              )}
            </div>
            <div className="grid grid-cols-[20%_76%]">
              <Label htmlFor="summary-sub-domain" label="Sub-domain" />
              {planboard.loading ? (
                <div className="w-60 h-4 ms-4 animate-pulse bg-gray-200 rounded-full dark:bg-gray-700" />
              ) : (
                <ReadOnlyField
                  value={planboard.value?.businessArea?.subdomain}
                  id="summary-sub-domain"
                />
              )}
            </div>
            <div className="grid grid-cols-[20%_76%]">
              <Label htmlFor="summary-scope" label="Scope" />
              {planboard.loading ? (
                <div className="w-60 h-4 ms-4 animate-pulse bg-gray-200 rounded-full dark:bg-gray-700" />
              ) : (
                <ReadOnlyField
                  value={planboard.value?.businessArea?.scope}
                  id="summary-scope"
                />
              )}
            </div>
            <div className="grid grid-cols-[20%_76%]">
              <Label htmlFor="summary-geography" label="Geography" />
              {planboard.loading ? (
                <div className="w-60 h-4 ms-4 animate-pulse bg-gray-200 rounded-full dark:bg-gray-700" />
              ) : (
                <ReadOnlyField
                  value={planboard.value?.businessArea?.geography}
                  id="summary-geography"
                />
              )}
            </div>

            {/* <!-- End Col --> */}
          </div>
        </div>
        <div class="space-y-5 border-gray-200 border border-t-0 dark:border-neutral-700 p-5 pb-2 ps-8">
          <h2 class="font-semibold text-gray-800 dark:text-neutral-200">
            Members
            <span
              className={`text-gray-500 font-normal ${planboard.loading ? "invisible" : "visible"
                }`}
            >
              ({planboard.value?.members?.length + 1})
            </span>
          </h2>
          <Members />
        </div>
        <div class="space-y-5 border-gray-200 border border-t-0 dark:border-neutral-700 p-5 pb-2 ps-8">
          <h2 class="font-semibold text-gray-800 dark:text-neutral-200">
            Attachments{" "}
            <span
              className={`text-gray-500 font-normal ${planboard.loading ? "invisible" : "visible"
                }`}
            >
              ({planboard.value?.attachments?.length})
            </span>
          </h2>
          <Attachments attachments={planboard.value.attachments} />
        </div>
      </form>
      {/* <!-- End Form --> */}
    </div>
  );
};

export default PlanboardSummary;
