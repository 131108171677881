import React, { useEffect, useState } from "react";
import Button from "../Button";
import { AlignRight } from "lucide-react";
import Badge from "../Generic/Badge";
import AccordianTitle from "../Accordion/AccordianTitle";
import EditLabel from "../Generic/EditLabel";
import Title from "./Title";
import SecondBand from "./SecondBand";
import { SubTask } from "../../types/interfaces/SubTask";
import { useToast } from "../../hooks";
import SubTasksAPI from "../../api/services/Tasks";
import { useParams } from "react-router-dom";
import {
  convertDateStringToDate,
  formatDateToDateString,
  getTimeInHHMMAMPM,
} from "../../utils/dateTime";
import SkeletonLoading from "./SkeletonLoading";
import DataGridSkeleton from "../Skeletons/DataGrid";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { PlanboardUser } from "../../types/interfaces/PlanboardUser";

interface SubTasksProps {
  actionItem: {
    id: string;
    title: string;
    status: string;
    buildProgress: null | { status: string; text: string };
  };
  nodeId: string;
  count: number;
  actionItemDate: {
    createdOn: Date;
    endDate: Date;
  };
  setElements: React.Dispatch<React.SetStateAction<any>>;
  members: PlanboardUser[];
  readOnly: boolean;
}

interface Deliverable {
  id: string;
  name: string;
}

const SubTasks = ({
  actionItem,
  nodeId,
  count,
  actionItemDate,
  setElements,
  members,
  readOnly,
}: SubTasksProps) => {
  const toast = useToast();
  const [hover, setHover] = useState(false);
  const [tasks, setTasks] = useState<SubTask[]>([]);
  const [loading, setLoading] = useState(true);
  const [addNewLoading, setAddNewLoading] = useState(false);
  const [addNewAILoading, setAddNewAILoading] = useState(false);
  const { id } = useParams();

  const user = useSelector((state: RootState) => state.user.value);

  const handleRemoveSubTask = async (id: string) => {
    console.log(id);
    const currTasks = tasks;
    setTasks((prevTasks) => prevTasks.filter((t) => t.id !== id));
    try {
      const response = await SubTasksAPI.delete(id);
      if (response.status === 200)
        toast.info("Successfully deleted deliverable");
      else
        throw new Error(
          `Failed to delete deliverable with status code ${response.status}`
        );
    } catch (error: any) {
      setTasks(currTasks); // Revert the task list in case of error
      toast.error(error.message || "Failed to delete deliverable");
    }
  };

  function generateUniqueTitle(existingTitles: string[]) {
    console.log({ existingTitles });
    let i = 1;
    while (true) {
      let newTitle = "Deliverables #" + i;
      if (!existingTitles.includes(newTitle)) {
        return newTitle;
      }
      i++;
    }
  }
  const handleAddSubtask = async () => {
    if (!id) return;
    const currTasks = tasks;
    // const newTitle = generateUniqueName();
    const newTitle = generateUniqueTitle(tasks.map((t) => t.title));
    const newTask: SubTask = {
      id: Math.random().toString(),
      title: newTitle,
      createdOn: String(new Date()),
      endDate: null,
      priority: "low",
      status: "todo",
      planboardId: id,
      actionItemId: actionItem.id,
      assignedTo: null,
      createdBy: {
        name: user.name,
        //@ts-ignore
        id: user.id,
        email: user.email,
        profilePic: user.profilePic,
        //@ts-ignore
        gender: user.gender,
      },
      parentId: null,
      progress: 0,
      description: "",
      nodeId,
    };
    setTasks((prev) => [...prev, newTask]);
    try {
      setAddNewLoading(true);
      const response = await SubTasksAPI.create({
        actionItemId: actionItem.id,
        nodeId,
        planboardId: id,
        title: newTask.title,
        endDate: formatDateToDateString(new Date()),
      });
      setAddNewLoading(false);
      if (response.status === 200) {
        // setTasks((prev)=>prev.map((task)=>{
        //   if(task.id===newTask.id) return {...newTask , id:response.data.data.id};
        // }))
        setElements((prev: any) =>
          prev.map((element: any) => {
            if (element.id === actionItem.id) {
              return { ...element, taskCount: count + 1 };
            } else return element;
          })
        );
        setTasks((prev) =>
          prev.map((task) => {
            if (task.id === newTask.id) {
              return { ...task, id: response.data.data.id };
            } else return task;
          })
        );
        // getSubTasks();
        toast.info("Created a new deliverable");
      }
    } catch (error: any) {
      setTasks(currTasks); // Revert on failure
      toast.error(`Error adding deliverable: ${error.message}`);
    }
  };
  const getAIGeneratedDeliverables = async () => {
    try {
      setLoading(true);
      setAddNewAILoading(true);
      const response = await SubTasksAPI.addAIGenerated(actionItem.id);
      if (response.data.data.status === "building") {
        setTimeout(() => {
          getAIGeneratedDeliverables();
        }, 3000);
      }
      if (response.data.data.status === "completed") {
        getSubTasks();
        // setLoading(false);
        // setAddNewAILoading(false);
        toast.info("Created AI generated deliverables");

        return;
      }
      return;
    } catch (error: any) {
      setLoading(false);
      setAddNewAILoading(false);
      toast.error(`Error adding AI generated deliverables: ${error.message}`);
    }
  };
  const handleAddAISubtask = async () => {
    await getAIGeneratedDeliverables();
  };

  const getSubTasks = async () => {
    try {
      setLoading(true);
      const response = await SubTasksAPI.get(actionItem.id);
      setLoading(false);
      if (response.status === 200) {
        setTasks(response.data.data);
        // handleStatus(response.data.data);
      }
    } catch (error: any) {
      setLoading(false);
      console.log({ error });
      if (error.response.status !== 404) {
        toast.error("Could not fetch subtasks.");
      }
    }
  };

  const handleStatus = (tasks: SubTask[]) => {
    setTasks(
      tasks.map((task) => {
        let newStatus: "todo" | "delayed" | "completed" | "in progress" =
          task.progress === 0
            ? "todo"
            : task.progress > 0 && task.progress < 100
            ? "in progress"
            : "completed";
        const today = formatDateToDateString(new Date());
        if (
          task.endDate !== null &&
          convertDateStringToDate(today) > new Date(task.endDate) &&
          task.progress < 100
        ) {
          newStatus = "delayed";
        }
        return { ...task, status: newStatus };
      })
    );
  };

  const updateSubTask = async (
    id: string,
    updateData: { endDate?: any; title?: string; assignedTo?: any }
  ) => {
    const currTasks = tasks;
    console.log({ updateData });
    setTasks((prev: SubTask[]) =>
      prev.map((task: SubTask) => {
        if (task.id === id) {
          if (updateData.endDate) {
            return {
              ...task,
              ...updateData,
              endDate: convertDateStringToDate(updateData.endDate),
            };
          } else return { ...task, ...updateData };
        } else return task;
      })
    );
    let modifiedUpdateData = updateData;
    if (modifiedUpdateData.assignedTo)
      modifiedUpdateData = {
        ...modifiedUpdateData,
        assignedTo: modifiedUpdateData.assignedTo.userId,
      };
    try {
      const response = await SubTasksAPI.update({
        taskId: id,
        ...modifiedUpdateData,
      });
      if (response.status !== 200) throw new Error("Failed to update the task");
      if (response.status === 200) {
        if (response.data?.data?.actionItem) {
          if (response.data.data.actionItem.status !== actionItem.status) {
            setElements((prev: any) =>
              prev.map((item: any) => {
                if (item.id === actionItem.id)
                  return {
                    ...item,
                    status: response.data.data.actionItem.status,
                  };
                else return item;
              })
            );
          }
        }

        // toast.info("Updated sub-task successfully");
      }
      // else throw Error("Failed to update the sub-task");
    } catch (error) {
      setTasks(currTasks);
      toast.error("Error updating sub-task");
    }
  };

  const updateMarkedAsDone = async (taskId: string, value: boolean) => {
    // const currTasks = tasks;
    // setTasks((prev)=>prev.map((task)=>{
    //   if()
    // }))
    // const response = await SubTasksAPI.markAsDone(taskId);
    // if()
  };

  const renderPulseElements = () => {
    return Array.from({ length: Math.max(count, 1) }, (_, index) => (
      <div className="flex justify-end mb-2">
        <div className="space-y-2 animate-pulse w-[90%] border  p-2 rounded-lg">
          <div className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700" />
          <div className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700" />
          <div className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700" />
          <div className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700" />
          <div className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700" />
        </div>
      </div>
    ));
  };

  useEffect(() => {
    console.log("Sub Task Component loaded");
    getSubTasks();
  }, []);

  return (
    <div className="dark:text-gray-100">
      {loading ? (
        <>
          {renderPulseElements()}
          {addNewAILoading && (
            <div className="flex justify-end">
              <div className="w-[90%] text-center mb-3">
                Generating Deliverables for{" "}
                <span className="font-serif">{actionItem.title}</span>
              </div>
            </div>
          )}
          {renderPulseElements()}
        </>
      ) : tasks.length > 0 ? (
        tasks.map((task, index) => (
          <div key={task.id} className="mb-2 flex items-center justify-end ">
            <div
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              className="relative w-[90%] border rounded-md px-2 pb-2 border-gray-100 dark:border-neutral-500  hover:shadow-md transition shadow-sm"
            >
              <div className="flex">
                <div className="flex grow leading-8 mt-2">
                  {readOnly ? (
                    task.title
                  ) : (
                    <Title
                      title={task.title}
                      updateTitle={(value: string) =>
                        updateSubTask(task.id, { title: value })
                      }
                    />
                  )}
                </div>
                <div className="text-sm flex items-center">
                  Created On:&nbsp;
                  {formatDateToDateString(new Date(task?.createdOn))}&nbsp;
                  {getTimeInHHMMAMPM(new Date(task?.createdOn))}
                </div>
              </div>
              <SecondBand
                element={task}
                updateMarkedAsDone={updateMarkedAsDone}
                updateElement={updateSubTask}
                handleRemoveSubTask={handleRemoveSubTask}
                actionItemDate={actionItemDate}
                members={members}
                readOnly={readOnly}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="flex  justify-end">
          <div className="items-center justify-center border dark:border-neutral-500 rounded-lg flex flex-col w-[90%] p-8 gap-y-5">
            <span className="font-semibold">
              No Deliverables for this Action item
            </span>
            <span>Add some deliverables to get started</span>
          </div>
        </div>
      )}
      {!readOnly && (
        <li className="flex items-center space-x-3 mt-2">
          <Button
            onClick={handleAddSubtask}
            loading={addNewLoading}
            disabled={Boolean(addNewLoading)}
            className={`border dark:border-neutral-300 rounded-md px-2 py-1 hover:bg-gray-100 dark:hover:bg-gray-900 text-xs font-medium flex items-center gap-x-2 disabled:opacity-50 disabled:pointer-events-none`}
          >
            {addNewLoading ? "Creating..." : "Add Deliverable"}
          </Button>
          <Button
            onClick={handleAddAISubtask}
            loading={addNewLoading}
            disabled={
              Boolean(addNewAILoading) || actionItem.buildProgress !== null
            }
            className={`border dark:border-neutral-300 rounded-md px-2 py-1 hover:bg-gray-100 dark:hover:bg-gray-900 text-xs font-medium flex items-center gap-x-2 disabled:opacity-50 disabled:pointer-events-none`}
          >
            {addNewLoading ? "Creating..." : "Add AI Deliverable"}
          </Button>
        </li>
      )}
    </div>
  );
};

export default SubTasks;
