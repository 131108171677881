import React from "react";
import BrandLogo from "../../components/BrandLogo";
import BuildIcon from "../../assets/icons/Build.svg";
import CollaborateIcon from "../../assets/icons/Collaborate.svg";
import ExecuteIcon from "../../assets/icons/Execute.svg";

interface OnboardingSidebarProps {
  onBoardingData: any;
}

const Sidebar = ({ onBoardingData }: OnboardingSidebarProps) => {
  return (
    <div className="hidden min-h-screen lg:w-[400px] xl:w-[430px] bg-sky-100 lg:flex flex-col justify-between p-6 dark:bg-slate-950">
      {/* <!-- Header --> */}
      <div className="flex justify-center items-center">
        <BrandLogo collapsed={false} />
      </div>
      {/* <!-- End Header --> */}

      {/* <!-- Body --> */}
      <div className="h-full flex flex-col justify-center gap-y-6 dark:text-neutral-300">
        {onBoardingData ? (
          <>
            <div className="grid grid-cols-[70%_30%] gap-y-5">
              {onBoardingData?.reasonForVisiting && (
                <>
                  What brings you to Planbow:&nbsp;
                  <span className="capitalize font-semibold">
                    {onBoardingData.reasonForVisiting}
                  </span>
                </>
              )}
              {onBoardingData?.teamSize && (
                <>
                  Team size:&nbsp;
                  <span className="capitalize font-semibold">
                    {onBoardingData.teamSize}
                  </span>
                </>
              )}

              {onBoardingData?.proceedOptions && (
                <>
                  Problem category:&nbsp;
                  <span className="capitalize font-semibold">
                    {onBoardingData.proceedOptions}
                  </span>
                </>
              )}
            </div>
            {onBoardingData?.domain?.id && (
              <p className="text-sm font-semibold text-gray-500 dark:text-neutral-400">
                I want to solve a
                <span className="text-black dark:text-neutral-400">&nbsp;{onBoardingData.domain.name}</span>{" "}
                specific problem specializing in
                <span className="text-black dark:text-neutral-400">
                  {onBoardingData.subdomain.name}
                </span>
                .{" "}
                {onBoardingData?.department?.id && (
                  <span>
                    My department is{" "}
                    <span className="text-black dark:text-neutral-400">
                      &nbsp;{onBoardingData.department.name}
                    </span>
                    .{" "}
                  </span>
                )}
                {onBoardingData?.role?.id && (
                  <span>
                    My role is{" "}
                    <span className="text-black dark:text-neutral-400">
                      &nbsp; {onBoardingData.role.name}
                    </span>
                    .{" "}
                  </span>
                )}
                {onBoardingData?.deadline && (
                  <span>
                    I want to achieve this within{" "}
                    <span className="text-black dark:text-neutral-400">
                      &nbsp; {onBoardingData.deadline}
                    </span>
                    .
                  </span>
                )}
              </p>
            )}
            {onBoardingData.proceedOptions && (
              <p className="text-sm font-semibold text-gray-500 dark:text-neutral-400">
                {onBoardingData.category && (
                  <span>
                    I want to solve a{" "}
                    <span className="text-black dark:text-neutral-400">
                      &nbsp; {onBoardingData.category}
                    </span>{" "}
                    specific problem
                  </span>
                )}
                {onBoardingData.subCategory && (
                  <span>
                    specializing in
                    <span className="text-black dark:text-neutral-400">
                      &nbsp; {onBoardingData.subCategory}
                    </span>
                    .{" "}
                  </span>
                )}
                {onBoardingData.scope && (
                  <span>
                    Objective for this strategic plan is
                    <span className="text-black dark:text-neutral-400">&nbsp;{onBoardingData.scope}</span>.{" "}
                  </span>
                )}
                {onBoardingData.deadline && (
                  <span>
                    I want to achieve this within&nbsp;
                    <span className="text-black dark:text-neutral-400">
                      {onBoardingData.deadline}
                    </span>
                    .
                  </span>
                )}
              </p>
            )}
          </>
        ) : (
          <>
            <div className="flex space-x-3 p-3 bg-blue-100 rounded-lg dark:bg-slate-900">
              <img
                src={BuildIcon}
                className="w-20 bg-gray-100 rounded-full"
                alt="Build"
              />
              <div className="">
                <div className="text-lg font-semibold">Build Strategy</div>
                <div className="font-thin text-sm mt-2">
                  Unveil superpowers of AI and build the strategic plans in
                  minutes
                </div>
              </div>
            </div>
            <div className="flex space-x-3 p-3 bg-blue-100 rounded-lg dark:bg-slate-900">
              <img
                src={CollaborateIcon}
                className="w-20 bg-gray-100 rounded-full"
                alt="Build"
              />
              <div className="">
                <div className="text-lg font-semibold">Collaborate</div>
                <div className="font-thin text-sm mt-2">
                  Ignite strategic collaboration with the team
                </div>
              </div>
            </div>
            <div className="flex space-x-3 p-3 bg-blue-100 rounded-lg dark:bg-slate-900">
              <img
                src={ExecuteIcon}
                className="w-20 bg-gray-100 rounded-full"
                alt="Build"
              />
              <div className="">
                <div className="text-lg font-semibold">Execute</div>
                <div className="font-thin text-sm mt-2">
                  Execute strategies and dig deeper to ensure success
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* <!-- End Body --> */}
    </div>
  );
};

export default Sidebar;
