import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setUser } from "../user/userSlice";
import LoginAPI from "../../../api/services/Login";
import {
  addNewOrganization,
  addOrganizations,
  getOrganizations,
} from "../organisations/slice";
import { useToast } from "../../../hooks";
import { logout } from "../auth/authSlice";

// export const updateTokens = ({ accessToken, refreshToken }) => {
//   localStorage.setItem("access_token", accessToken);
//   localStorage.setItem("refresh_token", refreshToken);
//   return;
// };

const initialState = {
  value: {},
  authed: true,
  error: null,
  loading: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginData: (state, action) => {
      state.value = { ...action.payload };
    },
    updateAuthed: (state, action) => {
      state.authed = action.payload;
    },
    updateTokens: (state, action) => {
      const { accessToken, refreshToken, ...rest } = action.payload;
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);
      state.value = rest;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialState)
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.authed = true;
        state.value = action.payload;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const userLogin = createAsyncThunk(
  "auth/login",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const { token, user, organization } = data;
      dispatch(setUser(user));
      // if (organization !== null)
      dispatch(addOrganizations(organization));
      const { refreshToken, accessToken, ...rest } = token;
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);
      // updateTokens({ accessToken, refreshToken });
      // const organisation = await dispatch(getOrganizations())
      //   .then((result) => {
      //     if (result.payload.status === 404) {
      //       return "404";
      //     }
      //     if (result?.payload[0]?.onboarded == false) {
      //       return "not-onboarded";
      //     }
      //   })
      //   .catch((e) => {});
      // if (organisation) return { rest, organisation };
      return rest;
    } catch (error) {
      // return custom error message from API if any
      console.log({ error });
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("Unable to Login");
      }
    }
  }
);

export const { setLoginData, updateAuthed, updateTokens } = loginSlice.actions;

export default loginSlice.reducer;
