import axios from "axios";
import { BASE_URL } from "../constants";

const apiClient = axios.create({
  baseURL: BASE_URL,
});

export const post = (url: string, data: {}) => {
  return apiClient.post(url, data);
};
export const getAuth = (url: string) => {
  return apiClient.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token") || ""}`,
    },
  });
};

export const postAuth = (url: string, data: {}) => {
  return apiClient.post(url, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token") || ""}`,
    },
  });
};
export const putAuth = (url: string, data: {}) => {
  return apiClient.put(url, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token") || ""}`,
    },
  });
};
export const deleteAuth = (url: string, data?: {}) => {
  const requestOptions: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token") || ""}`,
    },
  };
  if (data) {
    requestOptions.data = { ...data };
  }

  return apiClient.delete(url, requestOptions);
};

export default apiClient;
