import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import WorkspacesAPI from "../../../api/services/Workspace";
import { logout } from "../auth/authSlice";

const initialState = {
  value: [],
  loading: false,
  error: null,
};

const workspacesSlice = createSlice({
  initialState,
  name: "workspaces",
  reducers: {
    setWorkspaces: (state, action) => {
      state.value = [...action.payload];
    },
    addNewWorkspace: (state, action) => {
      // state.value = [...current(state).value, action.payload];
      // state.value.push(action.payload);
      state.value.unshift(action.payload);
    },
    filterDeletedWorkspace: (state, action) => {
      state.value = current(state).value.filter(
        (workspace) => workspace._id !== action.payload
      );
    },
    updateWorkspace: (state, action) => {
      state.value = current(state).value.map((workspace) => {
        if (workspace.id === action.payload.workspaceId) {
          const { workspaceId, ...restOfPayload } = action.payload;
          return { ...workspace, ...restOfPayload };
        } else {
          return workspace;
        }
      });
    },
    updatePlanBoardInWorkspace: (state, action) => {
      state.value = current(state).value.map((workspace) => {
        if (workspace.id === action.payload.workspaceId) {
          let newWorkspace = { ...workspace };
          newWorkspace.planBoards = newWorkspace.planBoards.map((planBoard) => {
            if (planBoard.planboardId === action.payload.planboardId) {
              return { ...planBoard, ...action.payload.planboardData };
            } else {
              return planBoard;
            }
          });
          return newWorkspace;
        }
        return workspace;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => initialState)
      .addCase(getWorkspaces.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWorkspaces.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(getWorkspaces.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    // .addCase(createWorkspace.pending, (state) => {
    //   console.log("createWorkspace.pending");
    //   state.loading = true;
    // })
    // .addCase(createWorkspace.rejected, (state, action) => {
    //   console.log("createWorkspace.rejected");
    //   state.loading = false;
    //   state.error = action.payload.data;
    // })
    // .addCase(createWorkspace.fulfilled, (state, action) => {
    //   console.log("createWorkspace.fulfilled");
    //   state.loading = false;
    //   state.value.push(action.payload.data);
    //   // state.value = [...current(state).value, action.payload.data];
    // })
    // .addCase(updateWorkspace.pending, (state) => {
    //   state.loading = true;
    // })
    // .addCase(updateWorkspace.fulfilled, (state, action) => {
    //   const newDetails = action.payload;
    //   const index = state.value.findIndex(
    //     (workspace) => workspace._id === newDetails.id
    //   );
    //   state.value[index] = {
    //     ...current(state).value[index],
    //     ...newDetails.updateData,
    //   };
    //   state.loading = false;
    //   state.error = false;
    // })
    // .addCase(updateWorkspace.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload.error;
    // })
  },
});
// interface GetWorkspacesParams {
//   organizationId: string;
//   index: number;
//   itemsPerIndex: number;
//   sort: string;
//   search: string;
// }
/**
 * @typedef {Object} GetWorkspacesParams
 * @property {string} organizationId
 * @property {number} index
 * @property {number} itemsPerIndex
 * @property {string} sort
 * @property {string} search
 */

export const getWorkspaces = createAsyncThunk(
  "workspaces/get-all",
  async (
    /** @type {GetWorkspacesParams} */
    { organizationId, index, itemsPerIndex, sort, search },
    { rejectWithValue, getState }
  ) => {
    // const state = getState();
    // const organizationId = state.organizations.value[0].organizationId;

    try {
      const response = await WorkspacesAPI.getWorkspaces({
        organizationId,
        index,
        itemsPerIndex,
        sort,
        search,
      });
      if (response?.status === 200) {
        if (response?.data?.data?.length) {
          return response.data.data;
        }
      }
      return rejectWithValue("No data found");
    } catch (err) {
      console.log({ err });
      return rejectWithValue("Failed to fetch workspaces");
    }
  }
);
export const getWorkspace = async ({ workspaceId, organizationId }) => {
  try {
    const response = await WorkspacesAPI.getWorkspace({
      workspaceId,
      organizationId,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createWorkspace = createAsyncThunk(
  "workspace/create",
  async (_, thunkAPI) => {
    const currentState = thunkAPI.getState();
    const workspaceName = generateUniqueWorkspaceName(
      currentState.workspaces?.value
    );
    const { organizationId } = currentState.organizations?.value[0];
    console.log({ workspaceName, organizationId });
    try {
      const response = await WorkspacesAPI.create(
        workspaceName,
        organizationId
      );
      console.log({ response });
      return response.data;
    } catch (error) {
      console.log({ error });
      return { data: error };
    }
  }
);

// export const updateWorkspace = createAsyncThunk(
//   "workspaces/update",
//   async ({ id, updateData }) => {
//     try {
//       await WorkspacesAPI.update(id, updateData);
//       return { id, updateData };
//     } catch (error) {
//       return { error: true };
//     }
//   }
// );

export const deleteWorkspace = createAsyncThunk(
  "workspaces/deleteWorkspace",
  async (id, { dispatch }) => {
    await WorkspacesAPI.update(id, { isDeleted: true });
    dispatch(filterDeletedWorkspace(id));
  }
);

const generateUniqueWorkspaceName = (workspaces) => {
  const baseName = "My Workspace";
  if (!workspaces.length) return baseName;

  const matchingWorkspaces =
    workspaces?.filter((workspace) =>
      /^My Workspace(\s\d+)?$/.test(workspace.name)
    ) ?? [];
  const highestNumber = Math.max(
    ...matchingWorkspaces.map((item) =>
      Number(item.name.match(/\d+$/)?.[0] ?? 0)
    )
  );
  const uniqueName = `${baseName} ${highestNumber + 1}`;
  return uniqueName;
};

//DO NOT REMOVE THE BELOW CODE
// function restoreWorkspace(workspaceName, workspaces) {
//   // Check for existing workspaces with the same name (including deleted ones)
//   const existingWorkspace = workspaces.find((w) => w.name === workspaceName);

//   if (!existingWorkspace) {
//     // No conflict, restore directly
//     return { name: workspaceName, isDeleted: false };
//   } else {
//     // Conflict exists, generate a unique name with "restored" suffix
//     let uniqueName = `${workspaceName} (restored)`;
//     let counter = 1;

//     while (workspaces.find((w) => w.name === uniqueName)) {
//       uniqueName = `${workspaceName} (restored ${counter})`;
//       counter += 1;
//     }

//     // Restore with the unique name
//     return { name: uniqueName, isDeleted: false };
//   }
// }
export const {
  setWorkspaces,
  addNewWorkspace,
  filterDeletedWorkspace,
  updateWorkspace,
  updatePlanBoardInWorkspace,
} = workspacesSlice.actions;

export default workspacesSlice.reducer;
