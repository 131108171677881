import React, { ReactNode, useState } from "react";

export type TooltipPlacementProps = "top" | "bottom" | "left" | "right";

type TooltipProps = {
  children: ReactNode;
  title: string;
  placement?: TooltipPlacementProps;
};

const getTailwindTooltipPosition = (placement: TooltipPlacementProps) => {
  switch (placement) {
    case "top":
      return "top-0 left-1/2 transform -translate-x-1/2 -translate-y-full";
    case "right":
      return "top-1/2 left-full transform -translate-y-1/2 translate-x-2";
    case "bottom":
      return "top-full left-1/2 transform -translate-x-1/2 translate-y-2";
    case "left":
      return "top-1/2 right-full transform -translate-y-1/2 translate-x-0";
    default:
      return "";
  }
};

const Tooltip = ({ children, title, placement = "top" }: TooltipProps) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };
  const tooltipContentStyle =
    "absolute z-50 " +
    (isTooltipVisible ? "visible opacity-100" : "invisible opacity-0") +
    " transition-opacity z-[49] duration-300 bg-gray-600 text-white py-1 px-2 text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700 text-center whitespace-nowrap " +
    getTailwindTooltipPosition(placement);
  return (
    <div
      className="relative inline-block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      <div className={tooltipContentStyle}>
        {title}
        <div className="tooltip-arrow" data-popper-arrow></div>
      </div>
    </div>
  );
};

export default Tooltip;
