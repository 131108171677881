import React, { useEffect, useState } from "react";
import Drawer, { DrawerHeader } from "../Generic/Drawer";
import ComponentDrawerHeader from "./ComponentDrawerHeader";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "../ErrorComponents";
import ActionItem from "./ActionItem";
import ActionItemsAPI from "../../api/services/ActionItems";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Planboard from "../../types/interfaces/Planboard";

interface ComponentDrawerProps {
  open: boolean;
  onClose: () => void;
  node: any;
}

const ComponentDrawer = ({ open, onClose, node }: ComponentDrawerProps) => {
  const [loading, setLoading] = useState(true);
  const [elements, setElements] = useState([]);
  const { id } = useParams();
  const planboard = useSelector((state: RootState) => state.planboard)
    .value as Planboard;
  const user = useSelector((state: RootState) => state.user.value);

  const fetchActionItems = async () => {
    try {
      const response = await ActionItemsAPI.get(id ?? "", node.id);
      setLoading(false);
      if (response.status === 200) setElements(response.data.data);
    } catch (error: any) {
      if (error.response.status === 404) setElements([]);
      console.log({ error });
      setLoading(false);
    }
  };

  const handleClose = () => {
    setElements([]);
    onClose();
  };

  useEffect(() => {
    if (open && node.id) {
      setElements([]);
      setLoading(true);
      fetchActionItems();
    }
  }, [node]);

  useEffect(() => {
    console.log({ loading, elements });
  }, [loading, elements]);
  return (
    <div>
      <ErrorBoundary FallbackComponent={Fallback}>
        <Drawer isOpen={open} onClose={handleClose}>
          {open && (
            <div>
              <DrawerHeader>
                <ComponentDrawerHeader
                  onClose={handleClose}
                  node={{ id: node?.id, data: node.data }}
                  color={
                    node?.data?.color ? node.data.color : "#118DD9" ?? "#118DD9"
                  }
                  count={elements.length}
                />
              </DrawerHeader>
              <ErrorBoundary FallbackComponent={Fallback}>
                <ActionItem
                  node={{ id: node.id, data: node.data }}
                  loading={loading}
                  elements={elements}
                  setElements={setElements}
                  fetchActionItems={fetchActionItems}
                />
              </ErrorBoundary>
            </div>
          )}
        </Drawer>
      </ErrorBoundary>
    </div>
  );
};

export default ComponentDrawer;
