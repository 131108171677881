import React, { useEffect, useRef, useState } from "react";
import IconButton from "../Generic/IconButton";
import {
  ChevronRight,
  Star,
  ThumbsUp,
  MessagesSquare,
  AlignRight,
  Trash2,
} from "lucide-react";
import Badge from "../Generic/Badge";
import AccordianTitle from "./AccordianTitle";
import PriorityMenu from "../ActionItems/PriorityMenu";
import StatusMenu from "../ActionItems/StatusMenu";
import AccordianMenu from "./AccordianMenu";
import AccordianDateRange from "../ActionItems/AccordianDateRange";
import AccordianUsers from "./AccordianUsers";
import IconsSet from "./IconsSet";
import EditLabel from "../Generic/EditLabel";
import Description from "./Description";
import SecondBand from "../ActionItems/SecondBand";
import { formatDateToDateString } from "../../utils/dateTime";
import { useLocation } from "react-router-dom";

export default function Accordion({
  children,
  element,
  hover,
  setHover,
  deleteActionItem,
  updateElement,
  members,
  readOnly,
}) {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  // const observer = new IntersectionObserver((entries) => {
  //   if (entries[0].isIntersecting) {
  //     const actionItemElement = document.getElementById(
  //       location.state.actionItemId
  //     );
  //     console.log({ actionItemElement });
  //     if (actionItemElement) {
  //       actionItemElement.scrollIntoView({ behavior: "smooth" });
  //     }
  //   }
  // });

  useEffect(() => {
    if (
      location?.state?.taskId &&
      location?.state?.actionItemId === element.id
    ) {
      setOpen(true);
    }
    // if (location.state.actionItemId === element.id) {
    //   const actionItemElement = document.getElementById(location.state.actionItemId);
    //   if (actionItemElement) {
    //     observer.observe(actionItemElement);
    //   }
    // }
  }, [location]);

  const updateDescription = (value) => {
    updateElement(element.id, { description: value });
  };

  return (
    <div
      onMouseEnter={() => setHover(element.id)}
      onMouseLeave={() => setHover(false)}
      className="flex w-full items-center "
      id={element.id}
    >
      <div className="hs-accordion-group w-full my-4 pr-8 ">
        <div
          className={`hs-accordion px-1 pb-2 pt-1 border border-gray-100 dark:border-neutral-600 dark:border-l-yellow-600 dark:border-l-2 border-l-yellow-400 border-l-2 rounded hover:shadow-md  transition shadow-lg`}
          id="hs-basic-with-title-and-arrow-stretched-heading-one"
        >
          
          <div
            className={`relative ${
              open ? "border-b border-gray-500 dark:border-neutral-500 pb-4" : ""
            }`}
          >
            <div className="dark:text-white flex gap-x-4">
              <IconButton
                icon={
                  <ChevronRight
                    color="#c0c0c0"
                    size={20}
                    className="hover:stroke-black dark:hover:stroke-gray-200"
                  />
                }
                tooltipTitle="Toggle"
                onClick={() => setOpen(!open)}
                className={`transition-transform transform ${
                  open ? "rotate-90" : ""
                }`}
              />
              <div className="grow leading-8">
                {readOnly ? (
                  element.title
                ) : (
                  <AccordianTitle
                    element={element}
                    updateElement={updateElement}
                  />
                )}
                <div
                  className={`${
                    element?.description ? "" : "hidden"
                  } text-gray-400 text-sm italic`}
                >
                  {readOnly ? (
                    element?.description
                  ) : (
                    <Description
                      description={element?.description ?? ""}
                      updateDescription={updateDescription}
                    />
                  )}
                </div>
              </div>
              {/* <div>
                <span className="mt-1 inline-flex gap-x-1.5 py-1 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-white/10 dark:text-white">
                  Created&nbsp;On:
                  <span>
                    {formatDateToDateString(new Date(element.createdOn))}
                  </span>
                </span>
              </div> */}
              <div>
                <span className="mt-1 inline-flex items-center gap-x-1.5 py-1 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-white/10 dark:text-white">
                  Deliverables:
                  <span className="inline-flex items-center px-1 rounded-full text-xs font-medium bg-primary-400 text-white">
                    {element?.taskCount ?? 0}
                  </span>
                </span>
              </div>
            </div>
            <SecondBand
              element={element}
              updateElement={updateElement}
              members={members}
              readOnly={readOnly}
            />
            {!readOnly && Boolean(hover) && hover === element.id && (
              <div className="absolute top-0 inset-y-0 -right-12 flex items-center">
                <AccordianMenu
                  deleteActionItem={deleteActionItem}
                  id={element.id}
                />
              </div>
            )}
          </div>

          <div
            className={`${
              open
                ? "max-h-[500px] mt-4 overflow-scroll"
                : "max-h-0 overflow-hidden"
            } origin-top transition-all ease-in-out duration-300 `}
          >
            {open ? children : null}
          </div>
        </div>
      </div>
    </div>
  );
}
