import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Auth/Sidebar";
import BrandLogo from "../../components/BrandLogo";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import PublicAPI from "../../api/services/Public";
import { Formik, Field, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import Email from "../../components/Inputs/Email";
import Password from "../../components/Inputs/Password";
import { useToast } from "../../hooks";
import useDissapearingError from "../../hooks/useDissapearingError";
import GoogleSignInButton from "./GoogleSignInButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { userLogin } from "../../redux/slice/login/loginSlice";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .matches(
      /((?=.*\d{3})(?=.*[a-z]{3})(?=.*[A-Z]{3})(?=.*[!@#$&*]).{10,128})/,
      "Password must contain at least 3 digits, 3 lowercase letters, 3 uppercase letters, and a special character. It must be between 10 and 128 characters long."
    )
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { authed, error } = useSelector((state: RootState) => state.login);
  const organizations = useSelector((state: RootState) => state.organizations);
  const user = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState(false);

  // const [error, setError] = useState<null | string>(null);
  const { setError, ErrorComponent } = useDissapearingError();

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>
  ) => {
    try {
      setSubmitting(true);
      setLoading(true);
      const response = await PublicAPI.authenticateUser({
        emailId: values.email,
        password: values.password,
      });
      setLoading(false);
      setSubmitting(false);
      if (response.status === 200) {
        toast.success("Login Successfull");
        // @ts-ignore
        dispatch(userLogin(response.data.data));
      }
    } catch (error: any) {
      setSubmitting(false);
      setError(error.response?.data?.data ?? "Couldn't login");
    }
  };

  useEffect(() => {
    if (loading || organizations.loading || user.loading || !authed || error)
      return;

    const item = localStorage.getItem("invitationDetails");
    const invitationDetails = item ? JSON.parse(item) : null;
    const prevRoute = localStorage.getItem("prevRoute") ?? ROUTES.dashboard;

    if (
      invitationDetails &&
      user &&
      invitationDetails.emailId === user.value.email
    ) {
      navigate(prevRoute);
      return;
    }
    localStorage.removeItem("prevRoute");
    localStorage.removeItem("invitationDetails");

    if (organizations.value.length === 0) {
      navigate(ROUTES.createOrganisation);
      return;
    }

    //@ts-ignore
    if (!organizations.value[0]?.onboarded) {
      navigate(ROUTES.whatBringsYoutoPlanbow);
      return;
    }

    navigate(prevRoute);
  }, [authed, loading, organizations, user]);

  // useEffect(() => {
  //   const invitationDetails = JSON.parse(
  //     localStorage.getItem("invitationDetails")
  //   );
  //   if (loading || organizations.loading || user.loading) return;
  //   if (!authed || error) return; // Render Signin error page

  //   if (invitationDetails !== null && user) {
  //     if (invitationDetails.email === user.value.email) {
  //       const prevRoute = localStorage.getItem("prevRoute");
  //       navigate(prevRoute ? prevRoute : ROUTES.dashboard);
  //       return;
  //     }
  //   }
  //   if (organizations.value.length === 0) {
  //     return navigate(ROUTES.createOrganisation);
  //   }
  //   //@ts-ignore
  //   if (!organizations.value[0]?.onboarded)
  //     return navigate(ROUTES.whatBringsYoutoPlanbow);
  //   const prevRoute = sessionStorage.getItem("prevRoute") ?? ROUTES.dashboard;
  //   return navigate(prevRoute);
  // }, [authed, loading, organizations, user]);

  return (
    <main className="flex min-h-full bg-white dark:bg-slate-900">
      <Sidebar />

      {/* <!-- Content --> */}
      <div className="grow px-5">
        <div className="h-full min-h-screen sm:w-[448px] flex flex-col justify-center mx-auto space-y-5">
          <div className="flex justify-center items-center my-5 lg:hidden">
            <BrandLogo collapsed={false} />
          </div>
          {/* <!-- Title --> */}
          <div>
            <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-slate-200">
              Log in to your Planbow account
            </h1>
            <p className="mt-1 text-sm text-gray-500 dark:text-slate-500">
              Build digital products with Planbow.
            </p>
          </div>
          {/* <!-- End Title --> */}

          {/* <!-- Button Group --> */}
          <div className="grid sm:flex gap-2">
            <GoogleSignInButton
              // handleLogin={handleLogin}
              text="Sign in with Google"
            />
          </div>
          {/* <!-- End Button Group --> */}

          <div className="flex items-center text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-slate-500 dark:before:border-slate-700 dark:after:border-slate-700">
            Or
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, handleSubmit, isValid }) => (
              <form>
                <div className="space-y-5">
                  <Field
                    label="Email"
                    id="email"
                    name="email"
                    component={Email}
                    value={values.email}
                    placeholder="johndoe@planbow.com"
                  />
                  <Field
                    label="Password"
                    id="password"
                    name="password"
                    component={Password}
                    value={values.password}
                    placeholder="password"
                    showForgotPassword={true}
                  />
                  {/* <div>
      <div className="flex justify-between items-center mb-2">
        <label
          htmlFor="hs-pro-dalp"
          className="block text-sm font-medium text-gray-800 dark:text-white"
        >
          Password
        </label>

        <a
          className="inline-flex items-center gap-x-1.5 text-xs text-gray-600 hover:text-gray-700 decoration-2 hover:underline focus:outline-none focus:underline dark:text-slate-500 dark:hover:text-slate-600"
          href="#"
        >
          I forgot my password
        </a>
      </div>

      <div className="relative">
        <input
          type="password"
          id="password"
          className="py-2.5 px-3 block w-full border border-gray-200 hover:bg-gray-100 rounded-lg text-sm transition-all duration-100 placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:hover:bg-slate-800 dark:border-slate-700 dark:text-slate-300 dark:placeholder:text-white/60 dark:focus:ring-slate-600"
          placeholder="password"
        />
        <Tooltip title="Toggle visibility">
          <button
            id="toggle-password"
            className="absolute h-full flex items-center"
            style={{
              right: "10px",
              top: 0,
              border: "none",
              background: "none",
            }}
            onClick={togglePasswordVisibility}
          >
            <span
              id="eye-icon-show"
              className="p-2 text-base text-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-eye-off"
              >
                <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24" />
                <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68" />
                <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61" />
                <line x1="2" x2="22" y1="2" y2="22" />
              </svg>
            </span>
            <span
              id="eye-icon-hide"
              className="p-2 text-base text-blue-400 hidden"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-eye"
              >
                <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                <circle cx="12" cy="12" r="3" />
              </svg>
            </span>
          </button>
        </Tooltip>
      </div>
    </div> */}

                  <button
                    type="button"
                    disabled={!isValid || isSubmitting}
                    onClick={(event) => {
                      event.preventDefault();
                      handleSubmit();
                    }}
                    className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-slate-600"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            )}
          </Formik>
          {ErrorComponent()}
          <p className="text-sm text-gray-500 dark:text-slate-500">
            New to Planbow?&nbsp;
            <a
              className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium focus:outline-none focus:underline dark:text-blue-500 cursor-pointer"
              onClick={() => navigate(ROUTES.signup)}
            >
              Sign up
              <svg
                className="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </a>
          </p>
        </div>
      </div>
      {/* <!-- End Content --> */}
    </main>
  );
};

export default SignIn;
