import { createAsyncThunk } from "@reduxjs/toolkit";
import LoginAPI from "../../../api/services/Login";
import { setUser } from "../user/userSlice";

export const updateTokens = ({ access_token, refresh_token, id_token }) => {
  localStorage.setItem("access_token", access_token);
  localStorage.setItem("refresh_token", refresh_token);
  localStorage.setItem("id_token", id_token);
  return;
};

export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ code }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await LoginAPI.getToken(code);
      const { token, user } = data.data;
      dispatch(setUser(user));
      const { refresh_token, access_token, id_token, ...rest } = token;
      updateTokens({ access_token, refresh_token, id_token });
      return rest;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
