import { NOTIFICATION_URLS } from "../../constants";
import { postAuth } from "../client";

const NotificationAPI = {
  getNotifications: (index: number, itemsPerIndex: number) => {
    return postAuth(NOTIFICATION_URLS.getNotifications, {
      index,
      itemsPerIndex,
    });
  },
  markAsRead: (notificationId: string) => {
    return postAuth(NOTIFICATION_URLS.MarkAsRead, { notificationId });
  },
  unreadCount: () => {
    return postAuth(NOTIFICATION_URLS.unreadCount, {});
  },
};

export default NotificationAPI;
