import React, { useEffect, useState } from "react";
import AreaChart from "./components/Area";
import { ClipboardList, LayoutList, ScanEye } from "lucide-react";
import Bar from "./components/Bar";
import StatsSection from "./StatsSection";
import { useParams } from "react-router-dom";
import AnalyticsAPI from "../../api/services/Analytics";
import ComponentHeader from "../componentHeader";

export interface StatsData {
  focusArea: Status;
  actionItem: Status;
  task: Status;
  overall: Overall;
}

interface Status {
  total: number;
  inProgress: number;
  completed: number;
  delayed: number;
  noAssigned: number;
  todo: number;
}

interface Overall {
  label: string;
  percentage: number;
}
const initialStats: StatsData = {
  focusArea: {
    total: 0,
    inProgress: 0,
    completed: 0,
    delayed: 0,
    noAssigned: 0,
    todo: 0,
  },
  actionItem: {
    total: 0,
    inProgress: 0,
    completed: 0,
    delayed: 0,
    noAssigned: 0,
    todo: 0,
  },
  task: {
    total: 0,
    inProgress: 0,
    completed: 0,
    delayed: 0,
    noAssigned: 0,
    todo: 0,
  },
  overall: {
    label: "0 / 0",
    percentage: 0,
  },
};

const Analytics = () => {
  const { id } = useParams();
  const [statsData, setStatsData] = useState<StatsData>(initialStats);
  useEffect(() => {
    if (!id) return;
    const getStats = async () => {
      try {
        const response = await AnalyticsAPI.getStats(id);
        setStatsData(response.data.data);
      } catch (error) {
        console.log({ error });
      }
    };
    getStats();
  }, []);
  return (
    <div className="w-full m-5 bg-white shadow-2xl mt-0 p-4 rounded-xl dark:bg-slate-800">
      <div className="w-full">
        <ComponentHeader title="Analytics" />
      </div>

      {/* <div className=" gap-x-4 w-full  border rounded-lg  mb-8 p-4 shadow-md"> */}
      {/* <div className="text-2xl mb-3">Analytics</div> */}
      {/* </div> */}
      {/* <AreaChart /> */}

      <div className="space-y-5">
        <StatsSection statsData={statsData} />
        <Bar />
      </div>
    </div>
  );
};

export default Analytics;
