import { ReactNode } from "react";

const ReadOnlyField = ({
  value,
  description,
}: {
  value: ReactNode;
  description?: string;
}) => (
  <>
    <div className="px-3">{value}</div>
    {description && (
      <p className="text-xs ms-3 dark:text-neutral-300">{description}</p>
    )}
  </>
);
export default ReadOnlyField;
