
import React, { useEffect, useState } from "react";
import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { ViewSwitcher } from "./view-switcher";
import "./gantt.css";
import { useParams } from "react-router-dom";
import NodesAPI from "../../api/services/Nodes";
import { formatDateToDateString } from "../../utils/dateTime";
import TimeIcon from "../../assets/3DIcons/Time.png";
import ComponentHeader from "../componentHeader";
import PlanboardsAPI from "../../api/services/Planboard";
import { useSelector } from "react-redux";
import { useToast } from "../../hooks";


export default function Timeline() {
  const { id } = useParams();
  const [view, setView] = useState(ViewMode.Month);
  const [isChecked, setIsChecked] = useState(true);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const planboard = useSelector((state) => state.planboard);
  const [tasks, setTasks] = useState([])
  const currentDate = new Date();
  const toast = useToast();
  const isDarkMode = useSelector((state) => state.dialog.isDarkMode);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await PlanboardsAPI.getPlanboardNodes(id);
        if (response?.data?.data) {

          const board = {
            start: planboard.value.createdOn
              ? new Date(planboard.value.createdOn)
              : new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            end: planboard.value.endDate
              ? new Date(planboard.value.endDate)
              : new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                28
              ),
            name: planboard.value.name,
            id: planboard?.value?.planboardId,
            type: "project",
            hideChildren: false,
          };

          const data = response.data.data.map((val, key) => {
            return {
              start: val.createdOn
                ? new Date(val.createdOn)
                : new Date(planboard.value.createdOn),
              end: val.endDate
                ? new Date(val.endDate)
                : new Date(planboard.value.endDate),
              name: val.title,
              id: `Task ${key}`,
              // nodeId: val.id,
              type: "task",
              project: planboard?.value?.planboardId,
              progress: val.progress,

              styles: { progressColor: val.metaData.color, barBackgroundColor: "blue" },
            };
          });

          const newData = [board, ...data];
          setTasks(newData);
          setLoading(false)
        } else {
          console.error("Data not available in the response");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [planboard, tasks]);

  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 250;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskChange = async (task) => {
    if (!task.nodeId) return;
    try {
      let matchingTasks = tasks.filter((t) => t.id === task.nodeId);
      if (new Date(task.end) > new Date(planboard.value.endDate)) return toast.error("Date cannot be greater than planboard end date")
      await NodesAPI.updateNode(task.nodeId, id, {
        ...matchingTasks,
        endDate: formatDateToDateString(new Date(task.end)),
      });

      const updatedTasks = tasks.map((t) => {
        if (t.id === task.nodeId) {
          return {
            ...t,
            endDate: formatDateToDateString(new Date(task.end)),
          };
        }
        return t;
      });
      setTasks(updatedTasks);
    } catch (error) {
      if (error.response.status === 401)
        console.error("Failed to update task dates:", error);
    }
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log(`On expander click Id: ${task.id}`);
  };
  const handleSelect = (task, isSelected) => {
    const styleId = "custom-tooltip-style";
    const existingStyle = document.getElementById(styleId);

    if (isSelected) {
      if (!existingStyle) {
        const style = document.createElement("style");
        style.id = styleId;
        style.innerHTML = `
          ._25P-K {
            display: none !important;
          }
        `;
        document.head.appendChild(style);
      }
    } else {
      if (existingStyle) {
        existingStyle.remove();
      }
    }

    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };


  const [isGanttVisible, setIsGanttVisible] = useState(false);

  useEffect(() => {
    if (tasks.length) {
      setIsGanttVisible(true);
    }
  }, [tasks.length]);

  return (
    <div className={`${isDarkMode ? "dark-mode " : ""} max-w-[97.5%] bg-white m-5 shadow-2xl rounded-xl p-4 mt-0 dark:bg-slate-800 dark:text-neutral-200`}>
      <div className="w-full">
        <ComponentHeader title="Timeline" image={TimeIcon} />
      </div>

      {!loading ?
        <div id="gantt" className="p-2 border rounded border-gray-200 dark:border-neutral-500">
          <ViewSwitcher
            onViewModeChange={(viewMode) => setView(viewMode)}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
          />
          <div
            className={`max-w-fit transition-all duration-300 ease-in opacity-0 dark:bg-slate-800  ${isGanttVisible ? "opacity-100" : ""
              }`}
          >
            <Gantt
              viewMode={view}
              tasks={tasks}
              rowHeight={50}
              fontSize={13}
              onSelect={handleSelect}
              listCellWidth={250}
              columnWidth={columnWidth}
              onDateChange={handleTaskChange}
              onExpanderClick={handleExpanderClick}
            />
          </div>
          {/* <div className="alert alert-danger" role="alert">
            <span className="font-bold">Error:</span> {error}
          </div> */}
        </div>
        :
        <div className="w-[95vw]">
          <ul className="mt-5 space-y-3">
            {Array.from({ length: 15 }).map((_, i) => (
              <li key={i} className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700" />
            ))}
          </ul>
        </div>
      }
    </div>
  );
}