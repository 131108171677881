import React, { useEffect, useState } from "react";
import Modal, { ModalBody, ModalHeader } from "../Generic/Modal";
import UserSelect from "../Generic/UserSelect";
import Spinner from "../Spinner";
import { closeAssignActionItemDialog } from "../../redux/slice/ui/dialogSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ChevronLeft, ChevronRight } from "lucide-react";
import PlanboardsAPI from "../../api/services/Planboard";
import { useParams } from "react-router-dom";
import DatePicker from "../Generic/DatePicker";
import { formatDateToDateString } from "../../utils/dateTime";
import useDissapearingError from "../../hooks/useDissapearingError";
import NodesAPI from "../../api/services/Nodes";
import { useToast } from "../../hooks";
import UserSelectSingle from "../Generic/UserSelectSingle";

const dateOptions = {
  // title: "Demo Title",
  autoHide: true,
  todayBtn: true,
  clearBtn: true,
  clearBtnText: "Clear",
  // maxDate: new Date("2030-01-01"),
  minDate: new Date("2000-01-01"),
  theme: {
    background: "",
    todayBtn: "bg-primary-400 hover:bg-primary-500",
    clearBtn: "",
    icons: "",
    text: "",
    disabledText: "bg-gray-900",
    input:
      "bg-white dark:bg-slate-900 border-gray-200 dark:border-gray-700 py-3 text-sm",
    inputIcon: "",
    selected: "bg-primary-400 hover:bg-primary-500 !text-white",
  },
  icons: {
    prev: () => (
      <span>
        <ChevronLeft />
      </span>
    ),
    next: () => (
      <span>
        <ChevronRight />
      </span>
    ),
  },
  datepickerClassNames: "top-12",
  // defaultDate: new Date(),
  language: "en",
  disabledDates: [],
  weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
  inputNameProp: "date",
  inputIdProp: "date",
  inputPlaceholderProp: "Select Date",
  inputDateFormatProp: {
    day: "numeric",
    month: "long",
    year: "numeric",
  },
};

const buttonStyle =
  "py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-500 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600";

interface EditMembersModalProps {
  // members: any;
  // getTeamMembers: () => void;
  // fetchUpdatedTeams: () => void;
  element: {
    id: string;
    data: {
      endDate: null | string;
      assignedTo: {};
    };
  };
  updateNodeDetails: (nodeId: string) => void;
}

const AssignActionItemModal = ({
  element,
  updateNodeDetails,
}: EditMembersModalProps) => {
  const dispatch = useDispatch();
  const [endDate, setEndDate] = useState(() => {
    if (element?.data?.endDate === null) {
      return new Date();
    } else {
      return new Date(element?.data?.endDate);
    }
  });
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState<any>([]);
  const dialog = useSelector((state: RootState) => state.dialog);
  const { id } = useParams();
  const { error, setError, ErrorComponent } = useDissapearingError();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const [show, setShow] = useState<boolean>(false);
  const handleCloseDate = (state: boolean) => {
    setShow(state);
  };
  const handleChangeDate = (selectedDate: Date) => {
    setEndDate(selectedDate);
  };

  const getTeamMembers = async () => {
    if (id === undefined) return;
    try {
      setLoading(true);
      const response = await PlanboardsAPI.getMembers(id);
      setLoading(false);
      console.log({ response });
      if (response.status === 200) {
        return setMembers(response.data.data);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response.status === 404) setMembers([]);
    }
  };

  const handleAssign = async () => {
    if (!handleValidation() || !id) return;
    try {
      const response = await NodesAPI.updateNode(element.id, id, {
        endDate: formatDateToDateString(new Date(endDate)),
        assignedTo: selectedMember.userId,
      });
      if (response.status === 200) {
        handleClose();
        return updateNodeDetails(element.id);
      }
      toast.error("Error assigning user");
    } catch (error: any) {
      handleClose();
      if (error.response.status === 401)
        return toast.error(error.response.data.data);
      return toast.error("Error updating node");
    }
  };

  const handleValidation = () => {
    console.log({ selectedMember });
    if (selectedMember.length === 0) {
      setError("Select a member to assign");
      return false;
    }
    return true;
  };

  const handleClose = () => {
    dispatch(closeAssignActionItemDialog());
  };

  const handleChange = (options: any) => {
    setSelectedMember(options);
  };

  useEffect(() => {
    if (dialog.isAssignActionItemDialogOpen) {
      getTeamMembers();
    }
  }, [dialog.isAssignActionItemDialogOpen]);

  useEffect(() => {
    setSelectedMember(element?.data?.assignedTo ? element.data.assignedTo : "");
    setEndDate(() => {
      if (element?.data?.endDate === null) {
        return new Date();
      } else {
        return new Date(element?.data?.endDate);
      }
    });
  }, [element]);

  return (
    <div>
      <Modal
        open={dialog.isAssignActionItemDialogOpen}
        onClose={handleClose}
        id="edit-team-modal"
      >
        {dialog.isAssignActionItemDialogOpen && (
          <>
            <ModalHeader title="Assign to" onClose={handleClose} />
            {loading ? (
              <div className="flex h-48 justify-center items-center">
                <Spinner />
              </div>
            ) : !loading && members.length === 0 ? (
              <div className="p-5 pt-10 dark:text-gray-100 h-48">
                No team members found. Please add a new member in the "Manage
                Team" section.
              </div>
            ) : (
              <>
                <ModalBody>
                  <div className="mb-20 mt-10">
                    <div id="team-members" className="m-4">
                      <UserSelectSingle
                        onChange={handleChange}
                        selectedValue={selectedMember}
                        options={members}
                      />
                      <div
                        className={`${error ? "visible" : "invisible"} min-h-6`}
                      >
                        {ErrorComponent()}
                      </div>
                    </div>
                    <div className="dark:text-gray-200 px-5">
                      <div className="mb-3">Due Date:&nbsp;</div>
                      <DatePicker
                        date={endDate}
                        handleChange={handleChangeDate}
                      />
                    </div>
                  </div>
                </ModalBody>
                <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
                  <button
                    type="button"
                    className={buttonStyle}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={buttonStyle}
                    onClick={handleAssign}
                  // onClick={saveMemberChanges}
                  // disabled={isSubmitting}
                  >
                    Assign
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default AssignActionItemModal;
