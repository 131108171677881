import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import UsersAPI from "../../../api/services/User";
import User from "../../../types/interfaces/user";

interface UserState {
  value:
    | User
    | {
        // id?:number;
        name: string;
        email: string;
        profilePic: string | null;
        gender: string | null;
      };
  loading: boolean;
  error: any;
}

const initialState: UserState = {
  value: {
    name: "",
    email: "",
    profilePic: null,
    gender: null,
  },
  loading: false,
  error: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.value = action.payload;
    },
    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        console.log("getUser.pending");
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.value = action.payload as User;
        state.loading = false;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.value = initialState.value;
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(modifyUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(modifyUser.fulfilled, (state, action) => {
        state.loading = false;
        state.value = { ...state.value, ...action.payload };
      })
      .addCase(modifyUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const getUser = createAsyncThunk<User, void, {}>(
  "user/getUserAsync",
  async (_, thunkAPI) => {
    try {
      const response = await UsersAPI.getUser();
      return response.data.data as User;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ data: {}, error });
    }
  }
);

export const fetchUserDetails = createAsyncThunk(
  "user/fetchDetails",
  async (id: string, thunkAPI) => {
    try {
      const response = await UsersAPI.get(id);
      const userDetails = response.data.data; // Cast id to string if needed
      return userDetails as User;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ data: {}, error });
    }
  }
);

export const modifyUser = createAsyncThunk(
  "user/update",
  async (
    { id, updateData }: { id: string; updateData: { [key: string]: any } },
    { rejectWithValue }
  ) => {
    try {
      const response = await UsersAPI.updateUser(id, updateData);
      if (response?.data?.success) {
        return { ...updateData };
      }
      throw new Error("Cannot Update User");
    } catch (error) {
      return rejectWithValue({ data: {}, error });
    }
  }
);

export const { setUser, updateUser } = userSlice.actions;
export default userSlice.reducer;
