import React, { ReactNode, useEffect, useRef, useState } from "react";

type MenuProps = {
  children: ReactNode;
  menuButton: ReactNode;
  open: boolean;
  onClose?: () => void;
};
const Menu = ({ children, menuButton, open, onClose }: MenuProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      onClose?.();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);
  return (
    <div
      className="hs-dropdown relative inline-flex self-center content-center z-50"
      ref={wrapperRef}
    >
      {menuButton}
      <div
        className={`${
          !open ? "hidden" : ""
        } absolute top-9 right-0 hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 min-w-[10rem] bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-gray-800 dark:border dark:border-gray-700 z-50`}
        aria-labelledby="hs-dropdown-default"
      >
        {children}
      </div>
    </div>
  );
};

type MenuButtonProps = {
  className?: string;
  icon?: ReactNode;
  showIcon?: boolean;
  iconPosition?: "left" | "right";
  children?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const defaultIcon = (
  <svg
    className="flex-shrink-0 ms-2 w-2.5 h-2.5"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    ></path>
  </svg>
);

export const MenuButton = ({
  className = "",
  icon = defaultIcon,
  iconPosition = "right",
  showIcon = true,
  children,
  ...props
}: MenuButtonProps) => {
  return (
    <>
      <button
        type="button"
        id="hs-dropdown-default"
        className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full text-gray-400 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-gray-700"
        {...props}
      >
        {showIcon && iconPosition === "left" && icon}
        {children}
        {showIcon && iconPosition === "right" && icon}
      </button>
    </>
  );
};

type MenuItemProps = {
  icon?: ReactNode;
  border?: "up" | "down";
  description?: string;
  className?: string;
  children: ReactNode;
  onClick: () => void;
  size?: "md" | "sm" | "lg" | "xl" | "xs";
};

export const MenuItem = ({
  border,
  icon = <></>,
  description = "",
  children,
  className = "",
  size = "md",
  ...props
}: MenuItemProps) => {
  return (
    <>
      {border === "up" && (
        <div className="my-2 border-t border-gray-100 dark:border-gray-900" />
      )}
      <a
        className={
          className +
          " inline-flex items-center cursor-pointer gap-x-5 w-full p-1 text-gray-600 rounded-lg hover:bg-gray-100 focus:ring-2 focus:ring-primary-600 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        }
        {...props}
      >
        {icon}
        <div className="grow">
          <span className="flex grow font-semibold mb-1 text-gray-800 dark:text-gray-200">
            {children}
          </span>
          {description}
        </div>
      </a>
      {border === "down" && (
        <div className="my-2 border-b border-gray-100 dark:border-gray-800" />
      )}
    </>
  );
};

interface SubMenuItemProps {
  icon?: ReactNode;
  children: ReactNode;
  label: ReactNode;
}

export const SubMenuItem = ({
  icon,
  children,
  label,
  ...props
}: SubMenuItemProps) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className="hs-dropdown relative inline-flex self-center content-center z-50 items-center"
      onMouseEnter={() => setOpen(open)}
      onMouseLeave={() => setOpen(false)}
    >
      {icon ? icon : null}
      {label}
      <div
        className={`${
          !open ? "hidden" : ""
        } absolute top-9 right-0 hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 min-w-[10rem] bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-gray-800 dark:border dark:border-gray-700 z-50`}
        aria-labelledby="hs-dropdown-default"
      >
        {children}
      </div>
    </div>
  );
};

export default Menu;
