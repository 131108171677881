import React, { ReactNode } from "react";
import Tooltip, { TooltipPlacementProps } from "../Preline/Tooltip";

interface IconButtonProps {
  tooltipTitle: string;
  tooltipPlacement?: TooltipPlacementProps;
  icon: ReactNode;
  height?: number;
  width?: number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

const IconButton = ({
  tooltipTitle,
  tooltipPlacement = "top",
  icon,
  height = 7,
  width = 7,
  onClick,
  disabled = false,
  type = "button",
}: IconButtonProps) => {
  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
      <button
        type={type}
        className={`ml-2 flex justify-center items-center w-${width} h-${height} text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-gray-600`}
        onClick={onClick ? onClick : undefined}
        disabled={disabled}
      >
        <span className="sr-only">{tooltipTitle}</span>
        {icon}
      </button>
    </Tooltip>
  );
};

export default IconButton;
