import React, { useState } from "react";
import EditLabel from "../Generic/EditLabel";

interface DescriptionProps {
  description: string;
  updateDescription: (value: string) => void;
}

const Description = ({ description, updateDescription }: DescriptionProps) => {
  const [edit, setEdit] = useState(false);

  return (
    <EditLabel
      edit={edit}
      setEdit={setEdit}
      label={description}
      updateLabel={updateDescription}
      iconSize={10}
    />
  );
};

export default Description;
