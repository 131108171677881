import React from 'react'

export default function ErrorMessage({
    touched,
    errors,
    field
}) {
    return (
        <>
            {
                touched[field.name] && errors[field.name] && (
                    <p className="flex text-xs text-red-600 mt-2" id="email-error">{errors[field.name]}</p>
                )
            }
        </>
    )
}
