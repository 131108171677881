import React, { useState } from 'react'
import Dropdown from '../../Dropdown';
import { Plus } from 'lucide-react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Input from '../../Inputs/Input';
import Select from '../../Inputs/Select';
import { COLUMN_TYPES } from '../../../constants';

const initialValues = {
    columnName: '',
    columnType: '',
};
const validationSchema = Yup.object().shape({
    columnName: Yup.string().required('Required'),
    columnType: Yup.string().required('Required'),
});

export default function AddColumn({
    addNewColumn
}) {
    const [open, setOpen] = useState(false);
    const handleSubmit = (values, { setSubmitting }) => {
        const newColumn = {
            columnName: values.columnName,
            columnType: values.columnType
        }
        addNewColumn(newColumn);
        setSubmitting(false);
        setOpen(false);
    }
    return (
        <th scope="col" className="px-6 py-3 pr-0 text-end flex justify-end cursor-pointer">
            <Dropdown onlyIcon btnText={<Plus />} open={open} handleToggle={(val) => setOpen(val)}>
                <div className="relative flex column items-start py-2 px-3">
                    {/* <!-- Form --> */}
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {
                            (props) => {
                                const {
                                    values,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className="grid gap-y-4">
                                            {/* <!-- Form Group --> */}
                                            <Field
                                                label="Column Name"
                                                id="columnName"
                                                name="columnName"
                                                component={Input}
                                                value={values.columnName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {/* <!-- End Form Group --> */}
                                        </div>
                                        <div className="grid gap-y-4 mb-5">
                                            {/* <!-- Form Group --> */}
                                            <Field
                                                label="Column Type"
                                                id="columnType"
                                                name="columnType"
                                                component={Select}
                                                value={values.columnName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                options={COLUMN_TYPES}
                                            />
                                            {/* <!-- End Form Group --> */}
                                        </div>
                                        <button type="submit" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" disabled={isSubmitting}>Submit</button>
                                    </form>
                                )
                            }}
                    </Formik>
                </div>
            </Dropdown>
        </th>
    )
}
