import { useClickAway } from "@uidotdev/usehooks";
import {
  CalendarDays,
  CalendarIcon,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import React, { useState } from "react";
import Datepicker from "tailwind-datepicker-react";
import { formatDateToDateString } from "../../../utils/dateTime";

const DatePicker = ({ value, handleChange }) => {
  const options = {
    //   title: "Demo Title",
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    clearBtnText: "Clear",
    maxDate: new Date("2030-01-01"),
    minDate: new Date(),
    theme: {
      background: "bg-gray-100 dark:bg-slate-900",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "",
    },
    icons: {
      prev: () => (
        <span>
          <ChevronLeft />
        </span>
      ),
      next: () => (
        <span>
          <ChevronRight />
        </span>
      ),
    },
    datepickerClassNames: "top-12",
    defaultDate: new Date(value),
    language: "en",
    disabledDates: [],
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Select Date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  };
  const [show, setShow] = useState(false);

  const handleClose = (state) => {
    setShow(state);
  };

  const ref = useClickAway(() => {
    setShow(false);
  });

  return (
    <div ref={ref} className="relative">
      <Datepicker
        options={options}
        onChange={handleChange}
        show={show}
        setShow={handleClose}
        classNames="absolute"
      >
        <div
          className={`  dark:border-neutral-500 dark:hover:border-neutral-300  flex items-center border border-white hover:border-gray-300 rounded-lg py-1.5 px-3 w-96 ${
            show ? "border-blue-500 ring-blue-500 outline-blue-500" : ""
          }`}
        >
          <CalendarDays size={20} className="mr-2 text-gray-400" />
          <input
            type="text"
            className="w-full focus:outline-none dark:bg-transparent dark:text-neutral-300"
            placeholder="Select Date"
            value={formatDateToDateString(new Date(value))}
            onFocus={() => setShow(true)}
            readOnly
          />
        </div>
      </Datepicker>
    </div>
  );
};

export default DatePicker;
