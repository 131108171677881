import React, { useRef, useState } from "react";
import Users1API from "../../api/services/User1";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/slice/user/userSlice";
import { useToast } from "../../hooks";
import Spinner from "../../components/Spinner";

const Avatar = ({ user }) => {
  const fileInputRef = useRef(null);
  const [profilePic, setProfilePic] = useState(null);
  const [chngProfile, setChngProfile] = useState(null);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const toast = useToast()

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB <= 1) {
        setChngProfile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfilePic(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        console.error("File size exceeds 1MB limit");
      }
    }
  };

  const handleProfileUpdate = async () => {
    try {
      setLoading(true)
      const response = await Users1API.uploadProfile(chngProfile);
      if (response.status === 200) {
        const ans = {
          ...user,
          profilePic: response?.data?.data?.profilePic
        }
        setLoading(false)
        setProfilePic(null)
        toast.success("Profile photo updated successfully")
        dispatch(setUser(ans));
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.response.data.data)
      setLoading(false)
    }
  };
  return (
    <div className="py-6 sm:py-8 space-y-5 border-t border-gray-200 first:border-t-0 dark:border-neutral-500">
      <div className="py-6 sm:py-8 space-y-5 border-t border-gray-200 first:border-t-0 dark:border-neutral-500">
        <div className="grid sm:grid-cols-12 gap-y-1.5 sm:gap-y-0 sm:gap-x-5">
          <div className="sm:col-span-4 xl:col-span-3 2xl:col-span-2">
            <label className="sm:mt-2.5 inline-block text-sm text-gray-500 dark:text-neutral-400">
              Avatar
            </label>
          </div>

          <div className="sm:col-span-8 xl:col-span-4">
            <div className="flex flex-wrap items-center gap-3 sm:gap-5">
              {!loading ? <span
                onClick={handleButtonClick}
                style={{ cursor: "pointer" }}
                className="flex flex-shrink-0 justify-center items-center size-20 border-2 border-dotted border-gray-300 text-gray-400 rounded-full dark:border-neutral-500 dark:text-neutral-600"
              >
                <img
                  className="rounded-full"
                  src={profilePic ? profilePic : user?.profilePic}
                  alt="User Avatar"
                />
              </span> :
                <Spinner />
              }

              <div className="grow">
                <div className="flex items-center gap-x-2">
                  <button
                    type="button"
                    onClick={handleProfileUpdate}
                    disabled={!profilePic && !loading}
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <svg
                      className="flex-shrink-0 size-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                      <polyline points="17 8 12 3 7 8" />
                      <line x1="12" x2="12" y1="3" y2="15" />
                    </svg>
                    Upload photo
                  </button>

                  <button
                    type="button"
                    onClick={() => {
                      setProfilePic(null);
                    }}
                    disabled={!profilePic}
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-gray-200 bg-white text-red-500 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-500 dark:text-red-500 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                  >
                    Delete
                  </button>
                </div>
                <p className="mt-2 text-xs text-gray-500 dark:text-neutral-400">
                  Pick a photo up to 1MB.
                </p>
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(e) => {
            handleFileChange(e);
          }}
        />
      </div>
    </div>
  );
};

export default Avatar;
