import React from "react";
import ErrorIcon from "./ErrorIcon";
import ErrorMessage from "./ErrorMessage";

interface SelectProps {
  field?: any;
  label?: string;
  name: string;
  id: string;
  value: string;
  hideLabel?: boolean;
  className: string;
  placeholder: string;
  form: { touched: any; errors: any };
  options: { value: string; text: string }[];
  noError?: boolean;
  defaultText?: string;
  defaultValue?: string;
}

export default function Select({
  field,
  label,
  name,
  id,
  value,
  hideLabel = false,
  className,
  placeholder,
  form,
  options,
  noError,
  defaultText = "Select an option",
  defaultValue,
  ...props
}: SelectProps) {
  const { touched, errors } = form;
  const hasDefaultValue = typeof defaultValue !== "undefined";
  const selectedValue = hasDefaultValue ? defaultValue : value;
  return (
    <div className="w-full">
      {!hideLabel && (
        <label
          htmlFor="hs-select-label"
          className="flex text-sm mb-2 dark:text-white"
        >
          {label}
        </label>
      )}
      <div className="relative">
        <select
          className="relative py-3 px-4 hover:bg-gray-100 dark:hover:bg-slate-800 dark:focus:bg-slate-800 border pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none bg-white dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
          {...field}
          {...props}
          value={selectedValue}
        >
          <option value="" selected={!hasDefaultValue} disabled hidden>
            {defaultText}
          </option>
          {options?.map((item) => (
            <option value={item.value} key={item.text}>
              {item.text ?? item.value}
            </option>
          ))}
        </select>
        {!noError ? (
          <ErrorIcon touched={touched} errors={errors} field={field} />
        ) : null}
      </div>
      {/* <!-- End Select --> */}
      {!noError ? (
        <ErrorMessage touched={touched} errors={errors} field={field} />
      ) : null}
    </div>
  );
}
