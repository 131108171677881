import apiClient, {
  getAuth,
  postAuth,
  postFormDataAuth,
  putAuth,
} from "../client";
import { PLANBOARD_URLS, URLS } from "../../constants/index.jsx";

const PlanboardsAPI = {
  getPlanboards: ({ ids }: { ids: string[] }) => {
    return postAuth(URLS.getPlanboards, {
      ids,
    });
  },
  getRecentPlanboards: (teamId: string) => {
    return getAuth(URLS.getRecentPlanboards + teamId);
  },
  get: (id: string) => getAuth(URLS.getPlanboard + id),
  getDeletedPlanboards: ({ userId }: { userId: string }) => {
    getAuth(URLS.getDeletedPlanboards + userId);
  },
  update: ({ id, updateData }: { id: string; updateData: {} }) => {
    return putAuth(URLS.updatePlanboard, { id, updateData });
  },
  updateUsers: ({
    id,
    users,
  }: {
    id: string;
    users: {
      changedUsers: string[];
      newUsers: string[];
      unchangedUsers: string[];
    };
  }) => {
    return putAuth(URLS.updatePlanboardUsers, { id, users });
  },
  delete: ({ id }: { id: string }) =>
    apiClient.delete(URLS.deletePlanboard, { data: { id } }),
  updatePlanboard: (updateData: any) => {
    return postAuth(URLS.updatePlanboard, updateData);
  },
  getStrategicNodes: (planboardId: string) => {
    return postAuth(URLS.getStrategicNodes, { planboardId });
  },
  getSummary: (id: string) => {
    return postAuth(URLS.getPlanboardSummary, { planboardId: id });
  },
  getPlanboardNodes: (id: string) => {
    return postAuth(URLS.getPlanboardNodes, { planboardId: id });
  },
  updateMemberRole: ({
    memberId,
    role,
    planboardId,
  }: {
    memberId: string;
    role: string;
    planboardId: string;
  }) => {
    return postAuth(PLANBOARD_URLS.updateRole, { memberId, role, planboardId });
  },
  addMember: ({ member, planboardId }: { member: {}; planboardId: string }) => {
    return postAuth(PLANBOARD_URLS.addMember, { member, planboardId });
  },
  /**
   * Removes a member from a planboard.
   * @param {string} params.memberId - The memberId (can be either emailId or userId).
   * @param {string} params.planboardId - The ID of the planboard.
   * @returns {Promise<void>} A promise that resolves when the member is removed.
   */
  removeMember: ({
    memberId,
    planboardId,
  }: {
    memberId: string;
    planboardId: string;
  }) => {
    return postAuth(PLANBOARD_URLS.removeMember, { memberId, planboardId });
  },
  planboardBuildStatus: (planboardId: string) => {
    return postAuth(PLANBOARD_URLS.planboardBuildStatus, { planboardId });
  },
  getMembers: (planboardId: string) => {
    return postAuth(PLANBOARD_URLS.getMembers, { planboardId });
  },
  validatePrompt: ({
    domainId,
    subdomainId,
    scope,
    geography,
    departmentId,
    roleId,
  }: {
    domainId: string;
    departmentId: string;
    roleId: string;
    subdomainId: string;
    scope: string;
    geography: string;
  }) => {
    return postAuth(URLS.planboardValidatePrompt, {
      domainId,
      subdomainId,
      departmentId,
      roleId,
      scope,
      geography,
    });
  },
  removeAttachment: ({
    attachmentId,
    planboardId,
  }: {
    attachmentId: string;
    planboardId: string;
  }) => {
    return postAuth(URLS.removeAttachment, { attachmentId, planboardId });
  },
  addAttachment: ({
    attachments,
    planboardId,
  }: {
    attachments: File[];
    planboardId: string;
  }) => {
    return postFormDataAuth(URLS.addAttachment, { planboardId }, attachments);
  },
  create: ({
    attachments = [],
    description,
    domainId,
    endDate,
    geography,
    markAsDefault,
    members,
    name,
    planboardId,
    remark,
    scope,
    subdomainId,
    departmentId,
    roleId,
    workspaceId,
  }: {
    attachments: File[];
    description: string;
    domainId: string;
    endDate: string;
    geography: string;
    markAsDefault: boolean;
    members: any[];
    name: string;
    planboardId: string;
    remark: string;
    scope: string;
    subdomainId: string;
    departmentId: string;
    roleId: string;
    workspaceId: string;
  }) => {
    return postFormDataAuth(
      URLS.createPlanboard,
      {
        planboardId,
        description,
        domainId,
        endDate,
        geography,
        markAsDefault,
        members,
        name,
        remark,
        scope,
        subdomainId,
        workspaceId,
        departmentId,
        roleId,
      },
      attachments
    );
  },
  getSharedPlanboards: ({
    index,
    itemsPerIndex,
  }: {
    index: number;
    itemsPerIndex: number;
  }) => postAuth(PLANBOARD_URLS.sharedPlanboards, { index, itemsPerIndex }),
  acceptInvitation: (planboardId: string) =>
    postAuth(PLANBOARD_URLS.acceptInvitation, { planboardId }),
  nameExists: (workspaceId: string, name: string) =>
    postAuth(PLANBOARD_URLS.nameExists, { workspaceId, name }),
  markAsFavourite: (planboardId: string, status: boolean) =>
    postAuth(PLANBOARD_URLS.markAsFavourite, { planboardId, status }),
  favouritePlanboards: () => postAuth(PLANBOARD_URLS.favouritePlanboards, {}),
  recentPlanboards: () => postAuth(PLANBOARD_URLS.recentPlanboards, {}),
};

export default PlanboardsAPI;
