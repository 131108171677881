import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Tooltip } from "@mui/material";

interface Organisation {
  value: {
    organizationId: string;
    organizationName: string;
  }[];
  loading: boolean;
  error: any;
}

const Organisation = () => {
  const organizations: Organisation = useSelector(
    (state: RootState) => state.organizations
  );

  return (
    <>
      <Tooltip title="Organisation">
        <div className="text-primary-400 mr-3 border border-dashed border-gray-700 rounded-md px-8 py-1 pb-1.5 flex items-center min-h-8 gap-x-3 dark:bg-slate-700">
          {organizations.loading ? (
            <div className="flex animate-pulse">
              <p className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-16" />
            </div>
          ) : (
            organizations?.value[0]?.organizationName ?? "Organisation"
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-building-2"
          >
            <path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z" />
            <path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2" />
            <path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2" />
            <path d="M10 6h4" />
            <path d="M10 10h4" />
            <path d="M10 14h4" />
            <path d="M10 18h4" />
          </svg>
        </div>
      </Tooltip>
    </>
  );
};

export default Organisation;
