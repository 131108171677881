import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  value: [],
  loading: false,
  error: null,
};

const canvasSlice = createSlice({
  name: "canvas",
  initialState,
  reducers: {
    addNodes: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addNodes } = canvasSlice.actions;

export default canvasSlice.reducer;
