import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TeamsAPI from "../../../api/services/Teams";

const initialState = {
  value: null,
  loading: false,
  error: null,
};

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeam.pending, (state) => {
        console.log("getTeams.pending");
        state.loading = true;
      })
      .addCase(getTeam.rejected, (state, action) => {
        console.log("getTeams.pending");
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getTeam.fulfilled, (state, action) => {
        if (action.payload.error) {
          console.log("getTeams.failed");
          state.value = [];
          state.loading = false;
          state.error = true;
        } else {
          console.log("getTeams.success");
          state.value = action.payload.data[0];
          state.loading = false;
        }
      });
  },
});

export const getTeam = createAsyncThunk("team/get", async (teamId) => {
  try {
    const response = await TeamsAPI.getTeams(teamId);
    return {
      data: response.data.data,
    };
  } catch (err) {
    console.log(err);
    return {
      data: [],
      error: true,
    };
  }
});

export default teamSlice.reducer;
