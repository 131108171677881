import React, { ReactNode } from "react";
import Spinner from "../Spinner";

interface ButtonProps {
  loading?: Boolean;
  onClick: () => void;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  type?: "submit" | "button" | "reset";
}

export default function Button({
  loading,
  onClick,
  children,
  className,
  disabled,
  type = "button",
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      type={type}
      className={className}
      onClick={onClick}
    >
      {loading ? <Spinner /> : null}
      {children}
    </button>
  );
}
