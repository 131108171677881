import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import WelcomeBgUserVector from "../../assets/images/welcome-bg-user-vector.svg";
import UserAvatar from "../../assets/images/User_avatar.svg";
import Workspace from "../../types/interfaces/workspace";
import Reward from "../../assets/images/reward.png";

const User = () => {
  const user = useSelector((state: RootState) => state.user);
  const workspaces = useSelector((state: RootState) => state.workspaces);
  const isDarkMode = useSelector((state: RootState) => state.dialog.isDarkMode);
  const planboardCount = workspaces.value.reduce(
    (total, workspace: Workspace) => total + Number(workspace.planBoardCount),
    0
  );
  return (
    <div className="bg-gradient-to-tr to-indigo-50 from-blue-100 hover:shadow-md rounded-md dark:bg-slate-900 dark:border-neutral-700 relative">
      <figure className="opacity-90 transform -scale-x-100 dark:bg-slate-700 rounded-xl z-0">
        <img src={WelcomeBgUserVector} alt="vector" />
      </figure>

      <div className="absolute top-6 left-10  font-semibold text-gray-800 dark:text-neutral-200">
        <div className="flex gap-x-4 items-center">
          {user.value.profilePic ? (
            <img
              src={user.value.profilePic}
              alt="user_avatar"
              className="h-10 rounded-full"
            />
          ) : (
            <img
              src={UserAvatar}
              alt="user_avatar"
              className="h-10 rounded-full"
            />
          )}
          <h1 className="text-2xl">Welcome, {user.value.name} to Planbow!</h1>
        </div>
        <div className="mt-5 font-thin flex items-center gap-x-5">
          <img src={Reward} alt="reward" className="w-12 h-12 mt-2" />
          <div className="">
            <div className="font-semibold text-2xl">{planboardCount}</div>
            Planboards
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
