import React, { ReactNode } from "react";
import Tooltip, { TooltipPlacementProps } from "../Preline/Tooltip";

interface IconButtonProps {
  tooltipTitle?: string;
  className?: string;
  tooltipPlacement?: TooltipPlacementProps;
  icon: ReactNode;
  height?: number;
  width?: number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

const IconButton = ({
  tooltipTitle,
  tooltipPlacement = "top",
  icon,
  height = 7,
  width = 7,
  onClick,
  disabled = false,
  type = "button",
  className = "",
}: IconButtonProps) => {
  const renderIconButton = (
    <button
      type={type}
      className={`flex justify-center items-center w-${width} h-${height} font-semibold rounded-full border border-transparent hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-gray-700 dark:focus:outline-none ${className}`}
      onClick={onClick ? onClick : undefined}
      disabled={disabled}
    >
      <span className="sr-only">{tooltipTitle}</span>
      {icon}
    </button>
  );
  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
        {renderIconButton}
      </Tooltip>
    );
  }
  return renderIconButton;
};

export default IconButton;
