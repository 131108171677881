import {
  Check,
  CircleDashed,
  Clock,
  FolderOpen,
  MoreHorizontal,
  Pencil,
  Trash2,
} from "lucide-react";
import React, { ReactNode } from "react";
import CustomMenu from "../Generic/CustomMenu";

interface Option {
  label: ReactNode;
  icon: ReactNode;
}

interface WorkspaceMenuProps {
  setEditTitle: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditDescription: () => void;
  handleDelete: () => void;
}

const options = [
  { label: "Rename Workspace", icon: <FolderOpen size={14} /> },
  { label: "Edit Description", icon: <Pencil size={14} /> },
  { label: "Delete Workspace", icon: <Trash2 size={14} /> },
];

const WorkspaceMenu = ({
  setEditTitle,
  handleEditDescription,
  handleDelete,
}: WorkspaceMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (option: Option) => {
    switch (option.label) {
      case "Rename Workspace":
        setEditTitle(true);
        break;

      case "Edit Description":
        handleEditDescription();
        break;
      case "Delete Workspace":
        handleDelete();
        break;
    }

    handleClose();
  };

  return (
    <>
      <div
        onClick={handleClick}
        className="flex items-center border rounded-md p-1 me-2 hover:shadow-lg hover:bg-gray-200 dark:hover:bg-slate-900 cursor-pointer"
      >
        <MoreHorizontal size={15} />
      </div>

      <CustomMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        onChange={handleChange}
        open={open}
        options={options}
        id="workspace_accordian_menu"
      />
    </>
  );
};

export default WorkspaceMenu;
