import { ChevronRight } from "lucide-react";
import React, { ReactNode } from "react";
import { StatsData } from ".";

interface StatsSectionProps {
  statsData: StatsData;
}

const StatsSection = ({ statsData }: StatsSectionProps) => {
  const { focusArea, actionItem, task, overall } = statsData;
  const dataArray = [
    { title: "Focus Area", ...focusArea },
    { title: "Action Item", ...actionItem },
    { title: "Task", ...task },
  ];

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 ">
      <div className="group p-4 bg-white border border-gray-200 rounded-xl shadow-sm hover:shadow-lg hover:-translate-y-0.5 focus:outline-none focus:shadow-lg focus:-translate-y-0.5 transition dark:bg-slate-900 dark:border-neutral-700 dark:shadow-black">
        <div className="flex gap-x-3">
          <div className="grow">
            <h2 className="text-xs text-gray-600 dark:text-neutral-400">
              Overall Progress
            </h2>
            <p className="text-xl font-semibold text-gray-800 dark:text-white">
              {overall.label}
            </p>
          </div>
          <div className="relative size-20 mr-5">
            <svg
              className="size-full"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="18"
                cy="18"
                r="16"
                fill="none"
                className="stroke-current text-gray-200 dark:text-gray-700"
                stroke-width="2"
              ></circle>
              <g className="origin-center -rotate-90 transform">
                <circle
                  cx="18"
                  cy="18"
                  r="16"
                  fill="none"
                  className="stroke-current text-blue-600 dark:text-blue-500"
                  stroke-width="2"
                  stroke-dasharray="100"
                  stroke-dashoffset={100 - overall.percentage}
                ></circle>
              </g>
            </svg>
            <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
              <span className="text-center text-xl font-bold text-gray-800 dark:text-white">
                {overall.percentage}%
              </span>
            </div>
          </div>
        </div>
      </div>
      {dataArray.map((item) => (
        <div className="group p-4 bg-white border border-gray-200 rounded-xl shadow-sm hover:shadow-lg hover:-translate-y-0.5 focus:outline-none focus:shadow-lg focus:-translate-y-0.5 transition dark:bg-slate-900 dark:border-neutral-700 dark:shadow-black">
          <div className="flex gap-x-3">
            <div className="grow">
              <h2 className="text-xs text-gray-600 dark:text-neutral-400">
                {item.title}
              </h2>
              <p className="text-xl font-semibold text-gray-800 dark:text-white">
                {item.total}
              </p>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-sm text-green-500">
              {item.completed} Completed
            </div>
            <div className="text-sm text-orange-500">
              {item.delayed} Delayed
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-sm text-yellow-600">
              {item.inProgress} In-Progress
            </div>
            <div className="text-sm text-blue-500">
              {item.noAssigned} Not-Assigned
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-sm text-gray-500">{item.todo} To-Do</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatsSection;
