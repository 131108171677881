import React from "react";
import Alert from "../Generic/Alert";
import { AlertCircle } from "lucide-react";

const SessionOut = ({
  open,
  handleClose,
  loggedIn,
}: {
  open: boolean;
  handleClose: () => void;
  loggedIn: boolean;
}) => {
  return (
    <Alert
      id="session-out"
      open={open}
      onClose={handleClose}
      title={loggedIn ? "Sesison Expired" : "Not Logged In"}
      buttonText={loggedIn ? "Continue Session" : "Sign In"}
    >
      <div className="mt-3 py-2 mb-6 dark:text-white">
        <div className="w-full flex justify-center mb-5">
          <AlertCircle size={40} color="#eeb813" />
        </div>
        <div className="flex justify-center text-center text-xl mb-1">
          {loggedIn
            ? "Your session is expired, please login again to continue."
            : "You are not logged in, please sign in to continue"}
        </div>
        {!loggedIn && (
          <div className="flex justify-center text-sm pt-2 italic text-gray-500">
            You will be automatically redirected to the login page...
          </div>
        )}
      </div>
    </Alert>
  );
};

export default SessionOut;
