import { Triangle } from "lucide-react";
import React, { memo } from "react";
import { Handle } from "reactflow";

function StartNode() {
  return (
    <>
      <div className="flex justify-center flex-col items-center">
        <Triangle className="transform rotate-90" strokeWidth={3} size={25} />
        <h5 className="text-lg">Start</h5>
      </div>
      <Handle
        type="source"
        position="right"
        style={{ background: "#555", padding: 8, margin: "0 -5px", opacity: 0 }}
      />
    </>
  );
}
export default memo(StartNode);
