import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { useEffect, useState } from "react";
import Button from "../Generic/Button";
// import Select from "../Generic/Select";
import { UniqueRandomPicker, colors, getRandomColor } from "../../utils/colors";
import NavButtons from "./NavButtons";
import PlanboardsAPI from "../../api/services/Planboard";
import moment from "moment";

interface Node {
  title: string;
  description: string;
  // selected: boolean;
}
let intervalId: any;

const Step3 = ({
  error,
  updateStepData,
  setActiveStep,
  activeStep,
  handleCreatePlanboard,
  planboardData,
}: any) => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [loading, setLoading] = useState(false);
  const planboardId = sessionStorage.getItem("planboardId");

  const getNodes = async () => {
    if (activeStep !== 3) return;
    if (!planboardId) return;
    try {
      setLoading(true);
      const response = await PlanboardsAPI.getStrategicNodes(planboardId);
      setLoading(false);
      if (response.status === 200) {
        if (typeof response.data.data == "string") {
          return;
        } else {
          setNodes(response.data.data as Node[]);
          clearInterval(intervalId);
          return;
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (activeStep !== 3) return;
    getNodes();
    intervalId = setInterval(getNodes, 1500);

    // Cleanup: Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [activeStep]);

  // useEffect(() => {
  //   if (activeStep !== 3) return;
  //   if (planboardId) {
  //     getNodes(planboardId);
  //   }
  // }, [activeStep]);

  const picker = new UniqueRandomPicker(colors);

  const handleSubmit = async () => {
    updateStepData({
      nodes,
    });
    setLoading(true);
    await handleCreatePlanboard();
    setLoading(false);
  };
  const handleBack = () => {
    setActiveStep(2);
    setNodes([]);
  };

  // const handleSelectedChange = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   index: number
  // ) => {
  //   const nodesData = [...nodes].map((node, ind) => {
  //     if (ind === index) {
  //       return {
  //         ...node,
  //         selected: e.target.checked,
  //       };
  //     } else return { ...node };
  //   });
  //   setNodes([...nodesData]);
  // };

  return (
    <div className="flex flex-col justify-center mx-auto pb-16">
      {/* <!-- Title --> */}
      <div className="my-8">
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
          Strategic focus areas
          {planboardData?.subdomain?.id && (
            <>
              &nbsp;for {planboardData.subdomain.name}
              {planboardData?.domain?.id && (
                <>,&nbsp;{planboardData.domain.name}</>
              )}
            </>
          )}
        </h1>
        <span className="grow text-gray-500 dark:text-neutral-400 text-sm flex items-center mt-1">
          Planbow AI generated &nbsp;
          <span className="inline-flex">
            {loading ? (
              <></>
            ) : (
              <span className="relative inline-flex h-4 w-4">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-100 opacity-75"></span>
                <span className="relative text-xs inline-flex rounded-full w-4 h-4 justify-center items-center bg-sky-200  dark:text-neutral-900">
                  {nodes.length}
                </span>
              </span>
            )}
            <span className="ml-1 ">
              &nbsp;strategic focus areas for your business.
            </span>
          </span>
        </span>
        <div className="mt-2 dark:text-neutral-400">
          {planboardData?.scope && (
            <>
              Your scope is to{" "}
              <span className="font-semibold">{planboardData.scope}</span>.
            </>
          )}
          {planboardData?.endDate && (
            <>
              &nbsp;You want to achieve your goal before{" "}
              {/* {moment(planboardData.endDate).format("DD-MM-YYYY")}. */}
              <span className="font-semibold">
                {moment(planboardData.endDate).format("MMMM Do, YYYY (dddd)")}.
              </span>
            </>
          )}
        </div>
      </div>
      {/* <!-- End Title --> */}

      {/* Step 4 Body */}
      <div className="gap-5 grid grid-cols-1 md:grid-cols-2">
        {nodes.length ? (
          nodes.map((item: Node, index: number) => (
            <div
              className={`p-4 px-8 border-t-4 flex flex-col bg-white border border-blue-700 dark:border-blue-900 rounded-xl dark:bg-slate-900`}
              // style={{ borderColor: picker.getRandomUniqueItem() }}
              // style={{ borderTopColor: getRandomColor() }}
            >
              {/* <div className="grid lg:grid-cols-12 gap-y-2 lg:gap-y-0 gap-x-4"> */}
              <div className="">
                <div className="flex">
                  <p className="grow">
                    <div className="inline-flex items-center justify-center rounded-md pb-2 gap-x-1 text-gray-800 decoration-2 text-lg dark:text-neutral-200 ">
                      {index + 1}
                      {")"}.&nbsp;{item.title}
                    </div>
                  </p>
                  {/* <div className="flex lg:flex-col items-start gap-2 border-t border-gray-200 lg:border-t-0 pt-3 lg:pt-0 dark:border-neutral-700">
                  <div className="lg:ms-auto">
                    <label
                      htmlFor={`hs-pro-dupccn1_${index}`}
                      className="relative border py-1 px-2 w-auto block text-center sm:text-start rounded-lg cursor-pointer text-xs font-medium focus:outline-none"
                    >
                      <input
                        onChange={(e) => handleSelectedChange(e, index)}
                        type="checkbox"
                        id={`hs-pro-dupccn1_${index}`}
                        className="peer hidden absolute top-0 start-0 w-full h-full bg-transparent border border-gray-200 text-transparent rounded-lg cursor-pointer focus:ring-white after:block after:w-full after:h-full after:rounded-md checked:after:bg-gray-200 checked:text-transparent checked:border-gray-200 checked:hover:border-gray-200 checked:focus:border-gray-200 checked:bg-none focus:bg-gray-100 checked:focus:after:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:checked:after:bg-neutral-600 dark:checked:border-neutral-700 dark:focus:ring-0 dark:focus:ring-offset-0 dark:focus:bg-neutral-700 dark:checked:focus:after:bg-neutral-700"
                        checked={item.selected}
                      />
                      <span className="relative z-10 peer-checked:hidden text-gray-800 dark:text-white">
                        Select
                      </span>
                      <span className="relative z-10 justify-center items-center gap-x-1.5 hidden peer-checked:flex peer-checked:text-gray-800 text-gray-200 dark:text-white dark:peer-checked:text-white">
                        <svg
                          className="flex-shrink-0 w-3.5 h-3.5 mt-0.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                        </svg>
                        Selected
                      </span>
                    </label>
                  </div>
                </div> */}
                </div>

                <div className="flex items-center">
                  <p className="mt-1 text-sm text-gray-800 dark:text-neutral-400">
                    {item.description}
                  </p>
                </div>

                <div className="flex items-center justify-end"></div>
              </div>
            </div>
          ))
        ) : (
          <>
            <div className="animate-pulse w-full">
              <div className="flex justify-center mb-5">
                Building your Planboard Strategic Nodes...
              </div>
              {[0, 1, 2, 3].map(() => (
                <div className="mb-10 w-full border rounded-lg p-5">
                  <ul className="space-y-3">
                    <li className="w-1/3 h-6 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                    <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                    <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                    <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-gray-700"></li>
                  </ul>
                </div>
              ))}
            </div>
          </>
        )}
        {error && <div className="text-red-600 italic">{error}</div>}
      </div>
      {/* <div aria-disabled={loading}> */}
      <NavButtons
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        isFinal={true}
        isSubmitting={loading || nodes.length === 0}
      />
      {/* </div> */}
    </div>
  );
};

export default Step3;
