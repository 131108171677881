import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { addToast, removeToast } from "../redux/slice/toast/toastSlice";

export const useToast = () => {
  const toastList = useSelector((state) => state.toastList.value);
  const dispatch = useDispatch();
  const createToast = (type, message, ms) => {
    const id = uuidv4();
    dispatch(
      addToast({
        id,
        type,
        message,
      })
    );
    setTimeout(() => {
      closeToast(id);
    }, ms);
  };
  const closeToast = (id) => {
    dispatch(removeToast(id));
  };
  return {
    toastList,
    closeToast,
    success: (message, ms = 5000) => createToast("success", message, ms),
    info: (message, ms = 5000) => createToast("info", message, ms),
    warning: (message, ms = 5000) => createToast("warning", message, ms),
    error: (message, ms = 5000) => createToast("error", message, ms),
  };
};
