import React from "react";

interface Subscribtion {
  currentPlan: {
    subscriptionPlan: string;
    noOfCreators: number;
    noOfContributors: number;
    noOfViewers: number;
    noOfPlanboard: number;
  };
  utilize: {
    noOfCreators: number;
    noOfContributors: number;
    noOfViewers: number;
    noOfPlanboard: number;
  };
}

const StatCards = ({ subscribtion }: { subscribtion: Subscribtion }) => {
  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-5 gap-2 xl:gap-4">
      {/* <!-- Card -->  */}
      <div className="p-4 flex flex-col bg-white border border-gray-200 rounded-xl dark:bg-slate-900 dark:border-slate-700">
        <div className="flex justify-between items-center mb-1">
          <h2 className="text-4xl font-semibold text-gray-800 dark:text-neutral-200">
            {subscribtion.utilize.noOfCreators}{" "}
            <span className="text-base text-gray-500 font-normal">
              of {subscribtion.currentPlan.noOfCreators}
            </span>
          </h2>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-user-round-plus text-teal-400"
            >
              <path d="M2 21a8 8 0 0 1 13.292-6" />
              <circle cx="10" cy="8" r="5" />
              <path d="M19 16v6" />
              <path d="M22 19h-6" />
            </svg>
          </div>
        </div>
        <h3 className="text-gray-500 dark:text-neutral-400">Creator</h3>
      </div>
      {/* <!-- End Card -->  */}

      {/* <!-- Card -->  */}
      <div className="p-4 flex flex-col bg-white border border-gray-200 rounded-xl dark:bg-slate-900 dark:border-slate-700">
        <div className="flex justify-between items-center mb-1">
          <h2 className="text-4xl font-semibold text-gray-800 dark:text-neutral-200">
            {subscribtion.utilize.noOfContributors}{" "}
            <span className="text-base text-gray-500 font-normal">
              of {subscribtion.currentPlan.noOfContributors}
            </span>
          </h2>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-user-round-cog text-blue-500"
            >
              <path d="M2 21a8 8 0 0 1 10.434-7.62" />
              <circle cx="10" cy="8" r="5" />
              <circle cx="18" cy="18" r="3" />
              <path d="m19.5 14.3-.4.9" />
              <path d="m16.9 20.8-.4.9" />
              <path d="m21.7 19.5-.9-.4" />
              <path d="m15.2 16.9-.9-.4" />
              <path d="m21.7 16.5-.9.4" />
              <path d="m15.2 19.1-.9.4" />
              <path d="m19.5 21.7-.4-.9" />
              <path d="m16.9 15.2-.4-.9" />
            </svg>
          </div>
        </div>
        <div className="inline-flex items-center gap-x-2">
          <h3 className="text-gray-500 dark:text-neutral-400">Contributors</h3>

          {/* <!-- Tooltip -->  */}
          <div className="hs-tooltip inline-block">
            <svg
              className="hs-tooltip-toggle flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-400"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
            <div
              className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 inline-block absolute invisible z-20 w-60 py-1.5 px-2.5 bg-gray-900 text-xs text-white rounded dark:bg-slate-700"
              role="tooltip"
            >
              Contributors can only access the projects, tasks, and messages
              explicitly shared with them.
            </div>
          </div>
          {/* <!-- End Tooltip -->  */}
        </div>
      </div>
      {/* <!-- End Card -->  */}

      {/* <!-- Card -->  */}
      <div className="p-4 flex flex-col bg-white border border-gray-200 rounded-xl dark:bg-slate-900 dark:border-slate-700">
        <div className="flex justify-between items-center mb-1">
          <h2 className="text-4xl font-semibold text-gray-800 dark:text-neutral-200">
            {subscribtion.utilize.noOfViewers}{" "}
            <span className="text-base text-gray-500 font-normal">
              of {subscribtion.currentPlan.noOfViewers}
            </span>
          </h2>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-user-round-search text-orange-400"
            >
              <circle cx="10" cy="8" r="5" />
              <path d="M2 21a8 8 0 0 1 10.434-7.62" />
              <circle cx="18" cy="18" r="3" />
              <path d="m22 22-1.9-1.9" />
            </svg>
          </div>
        </div>
        <div className="inline-flex items-center gap-x-2">
          <h3 className="text-gray-500 dark:text-neutral-400">Viewers</h3>

          {/* <!-- Tooltip -->  */}
          <div className="hs-tooltip inline-block">
            <svg
              className="hs-tooltip-toggle flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-400"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
            <div
              className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 inline-block absolute invisible z-20 w-60 py-1.5 px-2.5 bg-gray-900 text-xs text-white rounded dark:bg-slate-700"
              role="tooltip"
            >
              Viewers can only view the planboard.
            </div>
          </div>
          {/* <!-- End Tooltip -->  */}
        </div>
      </div>
      {/* <!-- End Card -->  */}

      {/* <!-- Card -->  */}
      <div className="p-4 flex flex-col bg-white border border-gray-200 rounded-xl dark:bg-slate-900 dark:border-slate-700">
        <div className="flex justify-between items-center mb-1">
          <h2 className="text-4xl font-semibold text-gray-800 dark:text-neutral-200">
            {subscribtion.utilize.noOfContributors +
              subscribtion.utilize.noOfCreators +
              subscribtion.utilize.noOfViewers}
          </h2>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-users-round text-gray-500"
            >
              <path d="M18 21a8 8 0 0 0-16 0" />
              <circle cx="10" cy="8" r="5" />
              <path d="M22 20c0-3.37-2-6.5-4-8a5 5 0 0 0-.45-8.3" />
            </svg>
          </div>
        </div>
        <h3 className="text-gray-500 dark:text-neutral-400">Total members</h3>
      </div>
      {/* <!-- End Card -->  */}

      {/* <!-- Card -->  */}
      <div className="p-4 flex flex-col bg-white border border-gray-200 rounded-xl dark:bg-slate-900 dark:border-slate-700">
        <div className="flex justify-between items-center mb-1">
          <h2 className="text-4xl font-semibold text-gray-800 dark:text-neutral-200">
            {subscribtion.utilize.noOfPlanboard}{" "}
            <span className="text-base text-gray-500 font-normal">
              of {subscribtion.currentPlan.noOfPlanboard}
            </span>
          </h2>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-clipboard-list text-green-700"
            >
              <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
              <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
              <path d="M12 11h4" />
              <path d="M12 16h4" />
              <path d="M8 11h.01" />
              <path d="M8 16h.01" />
            </svg>
          </div>
        </div>
        <div className="inline-flex items-center gap-x-2">
          <h3 className="text-gray-500 dark:text-neutral-400">Planboards</h3>

          {/* <!-- Tooltip -->  */}
          <div className="hs-tooltip inline-block">
            <svg
              className="hs-tooltip-toggle flex-shrink-0 size-3.5 text-gray-500 dark:text-neutral-400"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
            <div
              className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 inline-block absolute invisible z-20 w-44 py-1.5 px-2.5 bg-gray-900 text-xs text-white rounded dark:bg-slate-700"
              role="tooltip"
            >
              Total number of planboards.
            </div>
          </div>
          {/* <!-- End Tooltip -->  */}
        </div>
      </div>
      {/* <!-- End Card -->  */}
    </div>
  );
};

export default StatCards;
