import { DB_BASE_URL, URLS } from "../../constants/index.jsx";
import { deleteAuth, getAuth, postAuth, putAuth } from "../client";

const ONBOARDING_URLS = {
  setUp: DB_BASE_URL + "/onboarding/set-up",
};

const OnboardingAPI = {
  setUp: (updateData: any) => {
    return postAuth(ONBOARDING_URLS.setUp, updateData);
  },
};

export default OnboardingAPI;
