import React, { useEffect, useState } from "react";
import { AlertCircle } from "lucide-react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Input from "../Inputs/Input";
import NavButtons from "./NavButtons";
import DomainSelect from "./DomainSelect";
import SubdomainSelect from "./SubdomainSelect";
import PlanboardsAPI from "../../api/services/Planboard";
import Alert from "../Generic/Alert";
import Checkbox from "../Inputs/Checkbox";
import FormComponent from "./FormComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const initialValues: { [key: string]: string | {} } = {
  domain: {},
  subdomain: {},
  geography: "",
  scope: "",
  department: {},
  role: {},
};

const validationSchema = Yup.object().shape({
  domain: Yup.object().test(
    "domain-required",
    "Domain is required",
    (value) => {
      return Object.keys(value).length > 0;
    }
  ),
  subdomain: Yup.object().test(
    "subdomain-required",
    "Subdomain is required",
    (value) => {
      return Object.keys(value).length > 0;
    }
  ),
  geography: Yup.string().required("Required"),
  scope: Yup.string().required("Required"),
});

const Step1 = ({ planboardData, updateStepData, setActiveStep }: any) => {
  const [error, setError] = useState<any>(null);
  const isDarkMode = useSelector((state: RootState) => state.dialog.isDarkMode);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setSubmitting(true);

    // Optimization: Store existing values for comparison
    const existingPlanboardData = {
      domain: planboardData.domain,
      subdomain: planboardData.subdomain,
      scope: planboardData.scope,
      geography: planboardData.geography,
      department: planboardData?.department,
      role: planboardData?.role,
    };

    // Optimization: Conditional API call
    if (shouldCallAPI(values, existingPlanboardData)) {
      const isValidPrompt = await validatePrompt(values);
      if (!isValidPrompt) {
        setSubmitting(false);
        return;
      } // Stop further execution if validation fails
    }

    // Successful validation or API skipped:
    updateStepData({ ...values });
    setActiveStep(2); // Now confident to move to the next step
    setSubmitting(false);
  };

  // Optimization: Helper to determine if API call needed
  const shouldCallAPI = (values: any, existingData: any) => {
    return (
      values.domain !== existingData.domain ||
      values.subdomain !== existingData.subdomain ||
      values.scope !== existingData.scope ||
      values.geography !== existingData.geography ||
      values.department !== existingData.department ||
      values.role !== existingData.role
    );
  };

  const validatePrompt = async (values: any) => {
    try {
      const response = await PlanboardsAPI.validatePrompt({
        domainId: values.domain.id,
        subdomainId: values.subdomain.id,
        departmentId: values.department?.id,
        roleId: values.role?.id,
        ...values,
      });
      if (response.data?.data?.status.toLowerCase() == "negative") {
        setError(response.data?.data?.reason ?? "Invalid Prompt");
        return false;
      }
      if (response.data?.data?.status?.toLowerCase() === "positive") {
        if (!response?.data?.data?.planboardId) {
          setError("This prompt is already in the database");
          return false;
        }
        const { planboardId } = response.data.data;
        console.log({ planboardId });
        sessionStorage.setItem("planboardId", planboardId);
        setError(null);
        return true;
      }
    } catch (error) {
      console.log({ error });
      setError("Cannot Create Prompt");
      return false;
    }
  };

  const handleBack = () => {
    setActiveStep(1);
  };

  const getInitialValues = () => {
    const valuesObj: { [key: string]: string } = {};
    Object.keys(initialValues).forEach((key) => {
      valuesObj[key] =
        planboardData[key] != "" ? planboardData[key] : initialValues[key];
    });
    return { ...valuesObj };
  };
  useEffect(() => {
    sessionStorage.removeItem("planboardId");
  }, []);

  const renderAlert = () => (
    <Alert
      open={Boolean(error)}
      id="workspace_create_step1_error"
      onClose={() => setError(null)}
      title="Error"
    >
      <div className="mt-3 mb-6 dark:text-white">
        <div className="w-full flex justify-center mb-5">
          <AlertCircle size={40} color="#eeb813" />
        </div>
        <div className="flex justify-center text-xl mb-5 text-center">
          {error}
        </div>
      </div>
    </Alert>
  );
  return (
    <div className="flex flex-col justify-center mx-auto overflow-auto">
      <div
        className={`sticky top-0 my-8 z-50 ${
          isDarkMode ? "bg-slate-800" : "bg-white"
        }`}
      >
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
          Define your business details
        </h1>
        <span className="text-gray-500 dark:text-neutral-400 text-sm ">
          Describe business details to help Planbow AI to craft your
          comprehensive strategy
        </span>
      </div>
      {renderAlert()}
      <Formik
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          handleReset,
          touched,
        }) => (
          <div className="mb-16 ps-10">
            <FormComponent
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
            />
            <NavButtons
              handleBack={handleBack}
              isFirst={true}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              handleReset={handleReset}
            />
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Step1;
