import { Check } from "lucide-react";
import React, { ReactNode, useEffect, useRef, useState } from "react";

interface SelectProps {
  options: {
    value: string;
    label?: ReactNode;
    disabled?: boolean;
  }[];
  value: string;
  onChange: (newValue: string) => void;
  loadingOptions?: boolean;
  placeholder?: string;
}

const Select: React.FC<SelectProps> = ({
  options,
  value,
  onChange,
  loadingOptions = false,
  placeholder = "Choose an option",
}) => {
  // State variables
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (newValue: string) => {
    onChange(newValue);
    handleToggle();
  };

  useEffect(() => {
    // Function to close dropdown when clicked outside
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    // Bind event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref]);
  useEffect(() => {
    console.log({ options });
  }, []);

  return (
    <div className="relative h-full" ref={ref}>
      <button
        type="button"
        onClick={handleToggle}
        className="py-1 px-2 pe-5 w-full h-full block border min-w-[100px] hover:bg-gray-100 border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
      >
        <div className="flex flex-wrap flex-0 items-center text-xs">
          {/* {value ? value : "Choose an option"} */}
          {value
            ? options.find((option) => option.value === value)?.label || value
            : placeholder}
        </div>
      </button>

      <div className="absolute top-1/2 end-1 -translate-y-1/2">
        <svg
          className="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="m7 15 5 5 5-5" />
          <path d="m7 9 5-5 5 5" />
        </svg>
      </div>

      {isOpen && (
        <div className="absolute flex flex-col mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700 top-full">
          {loadingOptions ? (
            <div className="py-1 px-2 w-full text-xs text-gray-800 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:text-gray-200 dark:focus:bg-slate-800 selected">
              Loading...
            </div>
          ) : options.length > 0 ? (
            options.map((option) => (
              <button
                disabled={option.disabled ? option.disabled : false}
                key={option.value}
                onClick={() => handleSelect(option.value)}
                className="cursor-pointer py-1 px-2 w-full text-xs text-gray-800 dark:disabled:text-gray-600 dark:disabled:bg-slate-600 disabled:cursor-not-allowed hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800 selected"
              >
                <div className="flex items-center">
                  <div>
                    <span className="text-xs font-semibold text-gray-800 dark:text-gray-200">
                      {option?.label ?? option.value}
                    </span>
                  </div>
                  <div className="ms-auto">
                    {value === option.value && (
                      <span className="">
                        <Check size={10} color="#46b72f" />
                      </span>
                    )}
                  </div>
                </div>
              </button>
            ))
          ) : (
            <div className="py-1 px-2 w-full text-xs text-gray-800 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:text-gray-200 dark:focus:bg-slate-800 selected">
              No Options to Display
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Select;
