import { Box, Modal } from "@mui/material";
import moment from "moment";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  boxShadow: 24,
};

const EventDetailsDialog = ({ selectedEvent, open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style}>
        {selectedEvent && (
          <div class="sm:max-w-lg sm:w-full m-3 sm:mx-auto">
            <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
              <div class="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
                <h3 class="font-bold text-gray-800 dark:text-white">
                  Event Details
                </h3>
                <button
                  type="button"
                  class="flex justify-center items-center size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700"
                  onClick={handleClose}
                >
                  <span class="sr-only">Close</span>
                  <svg
                    class="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M18 6 6 18"></path>
                    <path d="m6 6 12 12"></path>
                  </svg>
                </button>
              </div>
              <div class="p-4 overflow-y-auto">
                <div className="grid grid-cols-[25%_70%] gap-y-5">
                  <>
                    <div className="">Title</div>
                    <div className="">{selectedEvent.title}</div>
                  </>
                  <>
                    <div className="">Description</div>
                    <div className="">{selectedEvent.description}</div>
                  </>
                  <>
                    <div className="">Recurrence</div>
                    <div className="capitalize">{selectedEvent.recurrence}</div>
                  </>
                  <>
                    <div className="">Created On</div>
                    <div className="">
                      {moment(selectedEvent.createdOn).format(
                        "ddd, DD-MM-YYYY, hh:mm a"
                      )}
                    </div>
                  </>
                  <>
                    <div className="">Date</div>
                    <div className="">
                      {moment(selectedEvent.start)
                        .utcOffset("GMT-00:00")
                        .format("DD-MM-YYYY")}
                    </div>
                  </>
                  <>
                    <div className="">Time</div>
                    <div className="">
                      {moment(selectedEvent.start)
                        .utcOffset("GMT-00:00")
                        .format("hh:mm a")}
                      &nbsp; - &nbsp;
                      {moment(selectedEvent.end)
                        .utcOffset("GMT-00:00")
                        .format("hh:mm a")}
                    </div>
                  </>
                </div>
              </div>
              <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
                <button
                  type="button"
                  class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default EventDetailsDialog;
