import { URLS } from "../../constants";
import { postAuth } from "../client";

interface GetFilesProps {
  planboardId: string;
  index: number;
  itemsPerIndex: number;
  search?: string;
}

const FilesAPI = {
  getFiles: ({ planboardId, index, itemsPerIndex, search }: GetFilesProps) => {
    return postAuth(URLS.getFiles, {
      planboardId,
      index,
      itemsPerIndex,
      search,
    });
  },
};
export default FilesAPI;
