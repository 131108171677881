import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
// import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { MenuItem } from "../Menu/Menu";
import Tooltip from "../Preline/Tooltip";

const options = [
  {
    label: "Create Manually",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14pt"
        height="14pt"
        viewBox="-41 0 512 512"
        id="manual-equipment"
      >
        <path d="m368.0625 0h-368.0625v512h368.0625c34.320312 0 62.242188-27.921875 62.242188-62.242188v-387.515624c0-34.320313-27.921876-62.242188-62.242188-62.242188zm-338.0625 30h30.046875v452h-30.046875zm181.164062 254.511719c-31.019531-13.589844-51.0625-44.195313-51.0625-77.964844 0-18.429687 5.824219-35.976563 16.839844-50.742187 6.339844-8.5 14.273438-15.765626 23.210938-21.375v77.105468h90.046875v-77.105468c8.9375 5.609374 16.871093 12.875 23.210937 21.375 11.015625 14.765624 16.835938 32.3125 16.835938 50.742187 0 33.769531-20.042969 64.375-51.058594 77.964844l-8.980469 3.9375v193.550781h-50.0625v-193.550781zm189.140626 165.246093c0 17.777344-14.464844 32.242188-32.242188 32.242188h-67.855469v-174.414062c36.777344-20.0625 60.039063-58.707032 60.039063-101.039063 0-24.945313-7.878906-48.695313-22.792969-68.683594-14.394531-19.300781-34.902344-33.894531-57.746094-41.09375l-19.507812-6.148437v90.914062h-30.046875v-90.914062l-19.507813 6.148437c-22.839843 7.199219-43.347656 21.792969-57.746093 41.09375-14.914063 19.988281-22.792969 43.738281-22.792969 68.683594 0 42.332031 23.261719 80.976563 60.039062 101.039063v174.414062h-100.097656v-452h278.015625c17.777344 0 32.242188 14.464844 32.242188 32.242188zm0 0"></path>
      </svg>
    ),
  },
  {
    label: "Create with AI",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 64 64"
        id="ai"
      >
        <circle cx="30" cy="30" r="1"></circle>
        <path d="M58,29a1,1,0,0,0,1-1V21.86a4,4,0,1,0-2,0V27H40V24H37V7h5.14a4,4,0,1,0,0-2H36a1,1,0,0,0-1,1V24H33V14H31V24H29V6a1,1,0,0,0-1-1H21.86a4,4,0,1,0,0,2H27V24H24v3H7V21.86a4,4,0,1,0-2,0V28a1,1,0,0,0,1,1H24v2H14v2H24v2H6a1,1,0,0,0-1,1v6.14a4,4,0,1,0,2,0V37H24v3h3V57H21.86a4,4,0,1,0,0,2H28a1,1,0,0,0,1-1V40h2V50h2V40h2V58a1,1,0,0,0,1,1h6.14a4,4,0,1,0,0-2H37V40h3V37H57v5.14a4,4,0,1,0,2,0V36a1,1,0,0,0-1-1H40V33H50V31H40V29ZM33,37H27V35h6Zm-3-4a3,3,0,1,1,3-3A3,3,0,0,1,30,33Zm7,0H35V27h2Z"></path>
      </svg>
    ),
  },
];

export default function AddNewComponent({ handleAddNewActionItem }: any) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    // setOpen((prevOpen) => !prevOpen); //prevented toggling  menu when clicking on the button
    handleAddNewActionItem("manual");
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  const handleClick = (type: string) => {
    handleAddNewActionItem(type);
    handleToggle();
  };

  return (
    <React.Fragment>
      <div className="hs-dropdown relative inline-flex">
        <Tooltip title="Add New Action Item" placement="left">
          <button
            id="hs-dropdown-default"
            type="button"
            onClick={handleToggle}
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            ref={anchorRef}
            className="hs-dropdown-toggle px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          >
            <div className="p-2">Create Action Items</div>
            {/* <div className="h-full border-l flex items-center ps-2">
              <svg
                className="hs-dropdown-open:rotate-180 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m6 9 6 6 6-6" />
              </svg>
            </div> */}
          </button>
        </Tooltip>
      </div>
      <Popper
        sx={{
          zIndex: 1,
          minWidth: "159px",
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <div className="pl-2">
                      <MenuItem
                        onClick={() => handleClick("grid")}
                        key={option.label}
                        icon={
                          <div className="w-4 h-4 flex items-center">
                            {option.icon}
                          </div>
                        }
                        // disabled={index === 2}
                        // selected={index === selectedIndex}
                        // onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        <div className="text-sm font-medium">
                          {option.label}
                        </div>
                      </MenuItem>
                    </div>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
