import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Auth/Sidebar";
import BrandLogo from "../../components/BrandLogo";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

import { Formik, Field, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import Input from "../../components/Inputs/Input";
import Email from "../../components/Inputs/Email";
import Password from "../../components/Inputs/Password";
import PublicAPI from "../../api/services/Public";
import { useToast } from "../../hooks";
import useDissapearingError from "../../hooks/useDissapearingError";
import GoogleSignInButton from "./GoogleSignInButton";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .matches(
      /((?=.*\d{3})(?=.*[a-z]{3})(?=.*[A-Z]{3})(?=.*[!@#$&*]).{10,128})/,
      "Password must contain at least 3 digits, 3 lowercase letters, 3 uppercase letters, and a special character. It must be between 10 and 128 characters long."
    )
    .required("Password is required"),
  phoneNumber: Yup.string()
    .matches(/^\d+$/, "Phone number must be digits only")
    .required("Phone number is required"),
});

const initialValues = {
  name: "",
  email: "",
  password: "",
  phoneNumber: "",
};

const SignUp = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { error, setError, ErrorComponent } = useDissapearingError();
  const { authed, error: loginError } = useSelector(
    (state: RootState) => state.login
  );

  const handleSubmit = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>
  ) => {
    if (error) setError(null);
    try {
      setSubmitting(true);

      const response = await PublicAPI.createAccount({
        emailId: values.email,
        name: values.name,
        contactNo: values.phoneNumber,
        password: values.password,
      });
      setSubmitting(false);
      if (response.status === 200) {
        toast.info("Account created successfully");
        navigate(ROUTES.signin);
      }
    } catch (error: any) {
      setSubmitting(false);
      setError(error.response?.data?.data ?? "Couldn't create account");
    }
  };

  // useEffect(() => {
  //   if (!authed || loginError) return; // Render Signin error page
  //   if (localStorage.getItem("invitationDetails")) {
  //     const prevRoute = localStorage.getItem("prevRoute");
  //     navigate(prevRoute ? prevRoute : ROUTES.dashboard);
  //     return;
  //   }
  //   const prevRoute = sessionStorage.getItem("prevRoute") ?? ROUTES.dashboard;
  //   navigate(prevRoute);
  // }, [authed]);

  return (
<main className="flex min-h-full bg-white fixed dark:bg-slate-900 pb-10 w-full">
  <Sidebar />


  {/* <!-- Content --> */}
  <div className="grow p-5 overflow-y-auto h-screen">
    <div className="h-full min-h-screen sm:w-[448px] flex flex-col justify-center mx-auto space-y-5">
      <div className="flex justify-center items-center mt-5 lg:hidden">
        <BrandLogo collapsed={false} />
      </div>
      {/* <!-- Title --> */}
      <div>
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-slate-200">
          Set up your Planbow account
        </h1>
        <p className="mt-1 text-sm text-gray-500 dark:text-slate-500">
          Build digital products with Planbow.
        </p>
      </div>
      {/* <!-- End Title --> */}

      {/* <!-- Button Group --> */}
      <div className="grid sm:flex gap-2">
        <GoogleSignInButton
          // handleLogin={handleLogin}
          text="Sign up with Google"
        />
      </div>
      {/* <!-- End Button Group --> */}

      <div className="flex items-center text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-slate-500 dark:before:border-slate-700 dark:after:border-slate-700">
        Or
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={SignUpSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, handleSubmit, isValid }) => (
          <Form>
            <div className="space-y-5">
              <Field
                label="Name"
                id="name"
                name="name"
                component={Input}
                value={values.name}
                placeholder="John Doe"
              />
              <Field
                label="Email"
                id="email"
                name="email"
                component={Email}
                value={values.email}
                placeholder="johndoe@planbow.com"
              />
              <Field
                label="Password"
                id="password"
                name="password"
                component={Password}
                value={values.password}
                placeholder="password"
              />
              <Field
                label="Mobile Number"
                id="phoneNumber"
                name="phoneNumber"
                component={Input}
                value={values.phoneNumber}
                placeholder="mobile number"
              />

              <div className="flex space-x-2">
                <input
                  type="checkbox"
                  className="shrink-0 hover:border-blue-500 border-gray-200 size-3.5 mt-[3px] rounded text-blue-600 dark:bg-slate-800 dark:checked:bg-blue-500 dark:border-slate-700"
                  id="hs-pro-dsftac"
                />
                <label
                  htmlFor="hs-pro-dsftac"
                  className="text-sm text-gray-800 ms-1.5 dark:text-slate-200"
                >
                  I accept the
                  <a
                    className="inline-flex items-center gap-x-1.5 font-medium text-blue-600 hover:text-blue-700 decoration-2 hover:underline dark:text-blue-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-slate-600"
                    href="#"
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div>

              <button
                type="button"
                disabled={!isValid || isSubmitting}
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
                className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-slate-600"
              >
                Sign up
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {ErrorComponent()}

      <p className="text-sm text-gray-500 dark:text-slate-500">
        Have an account?&nbsp;
        <a
          className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium focus:outline-none focus:underline dark:text-blue-500 cursor-pointer"
          onClick={() => navigate(ROUTES.signin)}
        >
          Sign in
          <svg
            className="flex-shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="m9 18 6-6-6-6" />
          </svg>
        </a>
      </p>
    </div>
  </div>
  {/* <!-- End Content --> */}
</main>

  );
};

export default SignUp;
