import React, { useEffect } from "react";

const useDissapearingError = () => {
  const [error, setError] = React.useState<null | string>(null);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const ErrorComponent = () => {
    return (
      <div
        className={`${
          Boolean(error) ? "" : "hidden"
        } text-sm italic text-red-600`}
      >
        {error}
      </div>
    );
  };

  return {
    error,
    setError,
    ErrorComponent,
  };
};

export default useDissapearingError;
