import React, { useEffect, useState } from "react";
import { ROUTES } from "../../constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import User from "./User";
import Tabs from "./Tabs";
import {
  addNewWorkspace,
  createWorkspace,
} from "../../redux/slice/workspaces/workspacesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../hooks";
import Plan_ISO_Right30 from "../../assets/3DIcons/Plan_Iso-Right-30.png";
import People_ISO_Right15 from "../../assets/3DIcons/People_Iso-Right-15.png";
import BillFrontLeft from "../../assets/3DIcons/Bill_Front-Left.png";
import Empty_Shopping_Cart from "../../assets/images/empty-shopping-cart.svg";
import Login_BG from "../../assets/images/login-bg.svg";
import PlanboardsAPI from "../../api/services/Planboard";
import PlanboardsList from "./PlanboardsList";
import TeamsAPI from "../../api/services/Teams";
import NewMemberModal from "../Teams/NewMemberModal";
// import { SquareArrowOutUpRight } from "lucide-react";

const buttonStyle =
  "w-full cursor-pointer py-3 px-4 inline-flex justify-center items-center border border-dashed border-gray-300 dark:border-slate-700 hover:border-solid gap-x-2 text-lg hover:font-semibold rounded-lg hover:underline text-primary-400 hover:bg-gray-100 dark:hover:bg-slate-900 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600";
export default function HeroSection() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const toast = useToast();

  const [workspaceLoading, setWorkspaceLoading] = useState(false);
  const [recentPlanBoard, setRecentPlanBoard] = useState([]);
  const [favouritePlanBoard, setFavouritePlanBoard] = useState([]);
  const isDarkMode = useSelector((state) => state.dialog.isDarkMode);

  const [members, setMembers] = useState([]);
  const getMembers = async () => {
    try {
      const response = await TeamsAPI.getMembers({
        index: 0,
        itemsPerIndex: 50,
      });
      if (response.status === 200) {
        setMembers(response.data.data);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    getMembers();
  }, []);


  const handleCreatePlanboard = () => {
    navigate(ROUTES.createPlanboard, { state: { from: location } });
  };
  const handleNewWorkspace = (e) => {
    e.preventDefault();
    setWorkspaceLoading(true);
    dispatch(createWorkspace())
      .then((result) => {
        setWorkspaceLoading(false);
        console.log({ result: result.payload });
        if (result.payload?.status?.code == 200) {
          dispatch(addNewWorkspace(result.payload.data));
          toast.success(
            <div>
              New workspace <strong>{result.payload.data.name}</strong> created
              successfully
            </div>
          );
          navigate("/workspaces/" + result.payload.data.id, {
            state: { new: true },
          });
        }
      })
      .catch((error) => {
        setWorkspaceLoading(false);
        console.log({ error });
        toast.error("Error creating new workspace");
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const recent = await PlanboardsAPI.recentPlanboards();
        setRecentPlanBoard(recent?.data?.data);

        const favourite = await PlanboardsAPI.favouritePlanboards();
        setFavouritePlanBoard(favourite?.data?.data);
      } catch (error) {
        console.error("Error fetching planboards data:", error);
      }
    };
    fetchData();
  }, []);

  const handleNavigation = (id) => {
    navigate(`${ROUTES.planboardDesigner}/${id}`, {
      state: { from: location },
    });
  };
  return (
    <div className="max-w-[85rem] mx-auto px-0 sm:px-0 lg:px-0 text-gray-800 dark:text-gray-400">
      {/* <div className="grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-12 lg:items-center"> */}
      <div className="w-full">
        <div className="">
          <User />
          <div
            className={`rounded-md px-5 mt-2 py-5 hover:shadow-md ${isDarkMode
              ? "dark:bg-slate-700"
              : "bg-gradient-to-r from-blue-50 to-indigo-50"
              }`}
          >
            <p className="mt-3 text-lg dark:text-neutral-200">
              Develop your strategic flows, implement them collaboratively with
              your teams, eliminate gaps, and boost your likelihood of success.
            </p>
            <p className="text-sm mt-2 ps-2 dark:text-neutral-300">
              Choose your action below to start:
            </p>

            <div className="mt-5 lg:mt-8 flex flex-col items-center gap-2 sm:flex-row sm:gap-3 justify-between">
              {/* <div className="w-full sm:w-auto">
                            <label for="hero-input" className="sr-only">Search</label>
                            <input type="text" id="hero-input" name="hero-input" className="py-3 px-4 block w-full xl:min-w-[18rem] border-gray-200 rounded-md text-sm focus:border-primary-600 focus:ring-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" placeholder="Enter work email">
                        </div> */}
              <button
                // to={ROUTES.createPlanboard}
                onClick={handleCreatePlanboard}
                className={`${buttonStyle} ${isDarkMode ? "dark:bg-slate-800" : ""
                  }`}
              >
                <img src={Plan_ISO_Right30} alt="vector_icon" className="w-7" />
                Create Planboard
              </button>
              <button
                data-hs-overlay="#hs-pro-dshm"
                // to={ROUTES.createPlanboard}
                // onClick={handleCreatePlanboard}
                className={`${buttonStyle} ${isDarkMode ? "dark:bg-slate-800" : ""
                  }`}
              >
                <img
                  src={People_ISO_Right15}
                  alt="vector_icon"
                  className="w-7"
                />
                Invite Members
              </button>
              <button
                // to={ROUTES.createPlanboard}
                onClick={handleNewWorkspace}
                className={`${buttonStyle} ${isDarkMode ? "dark:bg-slate-800" : ""
                  }`}
                disabled={workspaceLoading}
              >
                <img src={BillFrontLeft} alt="vector_icon" className="w-7" />
                {workspaceLoading
                  ? "Creating Workspace..."
                  : "Create Workspace"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full">
        <div className="mt-2 text-gray-700 dark:text-gray-200 flex space-x-2 rounded-lg h-full">
          <div className="w-[65%] min-h-full">
            <div
              className={`rounded-lg hover:shadow-md p-5  h-full ${isDarkMode
                ? "dark:bg-slate-700"
                : "bg-gradient-to-r from-sky-50 to-blue-50"
                }`}
            >
              <p className="text-lg ">My recent planboards</p>
              {Array.isArray(recentPlanBoard) && recentPlanBoard.length > 0 ? (
                <PlanboardsList
                  planboards={recentPlanBoard}
                  handleNavigation={handleNavigation}
                />
              ) : (
                <div className="ps-20">
                  <img
                    className="w-full max-w-[200px] rounded-xl opacity-80"
                    src={Empty_Shopping_Cart}
                    alt="Image Description"
                  />
                  <p className="text-xs ps-4 text-gray-400 dark:text-neutral-200">
                    Your recent planboards will show up here
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="grow min-h-full">
            <div
              className={`rounded-lg hover:shadow-md p-5 h-full ${isDarkMode
                ? "dark:bg-slate-700"
                : "bg-gradient-to-r from-blue-50 to-sky-50"
                }`}
            >
              <p className="text-lg">My favourite planboards</p>
              {Array.isArray(favouritePlanBoard) &&
                favouritePlanBoard.length > 0 ? (
                <PlanboardsList
                  planboards={favouritePlanBoard}
                  handleNavigation={handleNavigation}
                />
              ) : (
                <div className="ps-5">
                  <img
                    className="max-w-[200px] rounded-xl opacity-80"
                    // src={BannerImage}
                    src={Login_BG}
                    alt="Image Description"
                  />
                  <p className="text-xs ps-4 text-gray-400 dark:text-neutral-200">
                    Your favourite planboards will show up here
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 text-gray-700 dark:text-gray-200 flex space-x-3">
        <div className="w-full">
          <div
            className={`rounded-lg hover:shadow-md p-5 ${isDarkMode
              ? "dark:bg-slate-700"
              : "bg-gradient-to-r from-purple-50 to-sky-100"
              }`}
          >
            <p className="text-sm">Help</p>
            <Tabs />
          </div>
        </div>
      </div>
      <NewMemberModal updateMemberList={getMembers} members={members} />

    </div>
  );
}
