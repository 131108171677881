import React from "react";

interface BackButtonProps {
  handleBack: () => void;
}

const BackButton = ({ handleBack }: BackButtonProps) => {
  return (
    <button
      type="button"
      onClick={handleBack}
      className="absolute top-4 start-4 inline-flex items-center gap-x-1 text-sm decoration-2 hover:underline font-medium rounded-lg border border-transparent text-gray-500 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:underline focus:text-blue-600 dark:text-neutral-400 dark:hover:text-white dark:focus:text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-chevron-left"
      >
        <path d="m15 18-6-6 6-6" />
      </svg>
      back
    </button>
  );
};

export default BackButton;
