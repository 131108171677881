import { Popover } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import NotificationAPI from "../../../api/services/Notification";
import Notification from "../../../types/interfaces/Notification";
import { getTimeDifference } from "../../../utils/dateTime";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";

interface ContentProps {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
}
const itemsPerIndex = 5;

const Content = ({ anchorEl, handleClose }: ContentProps) => {
  const [index, setIndex] = useState(0);
  const [notifications, setNotifications] = useState<Notification[]>();
  const [lastRequestCount, setLastRequestCount] = useState(itemsPerIndex);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const fetchNotifications = async (append = false) => {
    setLoading(true);
    try {
      const response = await NotificationAPI.getNotifications(
        index,
        itemsPerIndex
      );
      if (response.status === 200) {
        const results = response.data.data;
        setNotifications((prev: any) => {
          if (append && prev) {
            return [...results, ...prev];
          }
          return results;
        });
        setLastRequestCount(results.length);
      } else {
        append && setIndex((prev) => prev - 1);
      }
    } catch (error) {
      console.log({ error });
      setLastRequestCount(0);
      append && setIndex((prev) => prev - 1);
    } finally {
      setLoading(false);
    }

  };

  const handleClick = (notification: Notification) => {
    !notification.read && markAsRead(notification.notificationId);
    // if (notification.subModuleType === "invite") {
    //   navigate(ROUTES.sharedWithMe);
    // }
    if (
      notification.moduleType === "planboard" &&
      (notification.subModuleType === "creation" ||
        notification.subModuleType === "invite")
    )
      navigate(`${ROUTES.planboardDesigner}/${notification.planboardId}`, {
        state: { from: location },
      });
    if (
      notification.moduleType === "planboard" &&
      notification.subModuleType === "nodes"
    )
      navigate(`${ROUTES.planboardDesigner}/${notification.planboardId}`, {
        state: {
          from: location,
          nav: "canvas",
          nodeId: notification.nodeId,
        },
      });
    if (
      notification.moduleType === "planboard" &&
      notification.subModuleType === "actionItems"
    )
      navigate(`${ROUTES.planboardDesigner}/${notification.planboardId}`, {
        state: {
          from: location,
          nav: "canvas",
          actionItemId: notification.actionItemId,
          nodeId: notification.nodeId,
        },
      });
    if (
      notification.moduleType === "planboard" &&
      notification.subModuleType === "deliverables"
    )
      navigate(`${ROUTES.planboardDesigner}/${notification.planboardId}`, {
        state: {
          from: location,
          nav: "canvas",
          actionItemId: notification.actionItemId,
          nodeId: notification.nodeId,
          taskId: notification.taskId,
        },
      });

    handleClose();
    console.log({ notification });
  };

  const markAsRead = async (id: string) => {
    try {
      const response = await NotificationAPI.markAsRead(id);
      if (response.status === 200) {
        setNotifications((prev) =>
          prev?.map((item) => {
            if (item.notificationId === id) {
              return { ...item, read: true };
            }
            return item;
          })
        );
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const open = Boolean(anchorEl);
  useEffect(() => {
    if (Boolean(anchorEl)) {
      console.log("fetch-notifications");
      fetchNotifications();
    }
  }, [anchorEl]);
  useEffect(() => {
    if (index > 0) fetchNotifications(true);
  }, [index]);

  return (

    <Popover
      id="header-notification-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div className="w-full sm:w-96 z-10 bg-white border-t border-gray-200 sm:border-t-0 sm:rounded-sm shadow-md sm:shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-slate-900 dark:border-neutral-700 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]">

        <div className="px-5 pt-3 flex justify-between items-center border-b border-gray-200 dark:border-neutral-800">
          <h4 className="px-2.5 py-1.5 mb-2 relative inline-flex items-center gap-x-2 text-gray-800 text-2xl rounded-lg dark:text-neutral-300">
            Notifications
          </h4>

        </div>


        <div className="h-[480px] overflow-y-auto overflow-hidden [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
          {/*   <!-- Tab Content --> */}
          <div
            id="hs-pro-tabs-dnn-all"
            role="tabpanel"
            aria-labelledby="hs-pro-tabs-dnn-item-all"
          >
            {loading ? <div>
              <div className="flex animate-pulse p-5">
                <div className="flex-shrink-0">
                  <span className="size-12 block bg-gray-200 rounded-full dark:bg-neutral-700"></span>
                </div>
                <div className="ms-4 mt-2 w-full">
                  <p className="h-4 bg-gray-200 rounded-full dark:bg-neutral-700" style={{ width: "40%" }}></p>
                  <ul className="mt-5 space-y-3">
                    <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
                    <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
                  </ul>
                </div>
              </div>

              <div className="flex animate-pulse p-5">
                <div className="flex-shrink-0">
                  <span className="size-12 block bg-gray-200 rounded-full dark:bg-neutral-700"></span>
                </div>
                <div className="ms-4 mt-2 w-full">
                  <p className="h-4 bg-gray-200 rounded-full dark:bg-neutral-700" style={{ width: "40%" }}></p>
                  <ul className="mt-5 space-y-3">
                    <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
                    <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
                  </ul>
                </div>
              </div>

              <div className="flex animate-pulse p-5">
                <div className="flex-shrink-0">
                  <span className="size-12 block bg-gray-200 rounded-full dark:bg-neutral-700"></span>
                </div>
                <div className="ms-4 mt-2 w-full">
                  <p className="h-4 bg-gray-200 rounded-full dark:bg-neutral-700" style={{ width: "40%" }}></p>
                  <ul className="mt-5 space-y-3">
                    <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
                    <li className="w-full h-4 bg-gray-200 rounded-full dark:bg-neutral-700"></li>
                  </ul>
                </div>
              </div>

            </div> :
              <>{notifications && notifications.length ? (
                <ul className="divide-y divide-gray-200 dark:divide-neutral-800 space-y-1">
                  {notifications?.map((item: Notification) => (
                    <li
                      className={`relative group w-full flex gap-x-5 text-start p-5 cursor-pointer ${item.read
                        ? "hover:bg-gray-100 dark:hover:bg-slate-950"
                        : "bg-gray-100 dark:bg-slate-800 hover:bg-gray-200 dark:hover:bg-neutral-950"
                        }`}
                      onClick={() => handleClick(item)}
                    >
                      <div className="relative flex-shrink-0">
                        <img
                          className="flex-shrink-0 w-[38px] h-[38px] rounded-full"
                          src={item.sender.profilePic}
                          alt="Image Description"
                        />
                        {!item.read && (
                          <span className="absolute top-4 -start-3 w-2 h-2 bg-blue-600 rounded-full dark:bg-blue-500"></span>
                        )}
                      </div>
                      <div className="grow">
                        <p className="text-xs text-gray-500 dark:text-neutral-500">
                          {getTimeDifference(new Date(item.createdOn))}
                        </p>
                        <span className="block text-sm font-medium text-gray-800 dark:text-neutral-300">
                          {item.sender.name}
                        </span>
                        <p className="text-sm text-gray-500 dark:text-neutral-500">
                          {item.content}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="flex justify-center py-5 dark:text-gray-400 text-sm font-serif">
                  You don't have any notifications
                </div>

              )}
              </>}
          </div>




          {/*   <!-- End Tab Content --> */}
        </div>
        <div
          className={`  p-2 text-gray-400 cursor-pointer hover:underline ${lastRequestCount < itemsPerIndex ||
            (notifications && notifications.length === 0)
            ? "invisible"
            : "visible"
            }`}
          onClick={() => setIndex((prev) => prev + 1)}
        >
          Load More
        </div>
        {/*   <!-- Footer --> */}
        {/* <div className="text-center border-t border-gray-200 dark:border-neutral-800">
          <a
            className="p-4 flex justify-center items-center gap-x-2 text-sm text-gray-500 font-medium sm:rounded-b-lg hover:text-blue-600 focus:outline-none focus:text-blue-600 dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
            href="#"
          >
            Mark all as read
          </a>
        </div> */}
        {/*   <!-- End Footer --> */}
      </div>
    </Popover>
  );
};

export default Content;
