import { Tooltip } from "@mui/material";
import { ExternalLink, LayoutDashboard } from "lucide-react";
import React from "react";

interface PlanboardsListProps {
  planboards: { planboardId: string; name: string }[];
  handleNavigation: (id: string) => void;
}

const PlanboardsList = ({
  planboards,
  handleNavigation,
}: PlanboardsListProps) => {
  return (
    <ul className="py-2">
      {planboards.map((planboard: { name: string; planboardId: string }) => (
        <li className="relative py-3 border-b group last:border-b-0 border-gray-200 dark:border-neutral-700 hover:bg-sky-100 dark:hover:bg-slate-800 px-2 rounded-lg dark:bg-slate-700">
          <button onClick={() => handleNavigation(planboard.planboardId)}>
            <div className="flex gap-x-3">
              <span className="mt-1 flex flex-shrink-0 justify-center items-center size-[38px] bg-white border border-gray-200 rounded-lg dark:bg-slate-700 dark:border-neutral-700 dark:text-neutral-300">
                <LayoutDashboard size={32} strokeWidth={1} />
              </span>
              <div className="grow flex items-center">
                <div className="font-medium text-gray-800 dark:text-neutral-200 ">
                  {planboard.name}
                </div>
                {/* <p className="text-xs text-gray-500 dark:text-neutral-500">
                Updated 5 days ago
              </p> */}
              </div>
              {/* <div className="absolute top-1/2 end-2 -translate-y-1/2 flex items-center gap-x-1 opacity-0 group-hover:opacity-100">
              <Tooltip title="Open">
                <button
                  type="button"
                  onClick={() => handleNavigation(planboard.planboardId)}
                  className="size-7 flex-shrink-0 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent text-gray-400 hover:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-200 dark:hover:bg-slate-600 dark:text-neutral-500 dark:focus:bg-slate-600"
                >
                  <span className="sr-only">Show/Hide</span>
                  <ExternalLink strokeWidth={1} size={16} />
                </button>
              </Tooltip>
            </div> */}
            </div>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default PlanboardsList;
