import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Trash, Briefcase, Home, Share2, Activity, Users } from "lucide-react";
import { ROUTES } from "../../constants";
import { usePageName } from "../../hooks/usePageName";
import BrandLogo from "../BrandLogo";
import WorkspacesNav from "./WorkspacesNav";
import { Drawer, Tooltip, useMediaQuery } from "@mui/material";

import HomeFrontIcon from "../../assets/3DIcons/Home_Front.png";
import ShareFrontIcon from "../../assets/3DIcons/Share-Front.png";
import BillFrontIcon from "../../assets/3DIcons/Bill_Front-Left.png";
import UsersIcon from "../../assets/3DIcons/People_Iso-Right-15.png";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const iconSize = 24;
const strokeWidth = 1;

const listItems = [
  {
    title: "Home",
    path: ROUTES.dashboard,
    icon: <img src={HomeFrontIcon} alt="home-icon" className="w-5" />,
  },
  // {
  //   title: "Activity",
  //   path: ROUTES.activity,
  //   icon: <Activity size={iconSize} strokeWidth={strokeWidth} />,
  // },
  // {
  //   title: "Shared with me",
  //   path: ROUTES.sharedWithMe,
  //   icon: <img src={ShareFrontIcon} alt="share-icon" className="w-5" />,
  // },
  {
    title: "Teams",
    path: ROUTES.teams,
    icon: <img src={UsersIcon} alt="share-icon" className="w-5" />,
  },
  {
    title: "Workspaces",
    path: ROUTES.workspaces,
    icon: <img src={BillFrontIcon} alt="workspace-icon" className="w-5" />,
    isWorkspace: true,
  },

  // {
  //   title: "Starred",
  //   path: ROUTES.starred,
  //   icon: <Star />,
  // },
  // {
  //   title: "Trash",
  //   path: ROUTES.trash,
  //   icon: <Briefcase size={iconSize} strokeWidth={strokeWidth} />,
  //   icon: <Trash size={iconSize} strokeWidth={strokeWidth} />,
  // },
  // {
  //   title: "Account",
  //   path: ROUTES.account,
  //   icon: <UserCircle2 />,
  // },
  // {
  //   title: "Logout",
  //   path: null,
  //   icon: <Power />,
  //   isLogout: true,
  // },
];

const activeClass = "bg-white text-primary-400 dark:bg-gray-700";
const collapseClass = "hidden";
const hoverClass = "hover:text-primary-400";

export default function Sidebar({
  isSidebarCollapsed,
  setIsSidebarCollapsed,
  isSidebarOpen,
  setIsSidebarOpen,
  isWorkspaceExpanded,
  setIsWorkspaceExpanded,
}) {
  const { activeRoute } = usePageName();
  const [hover, setHover] = useState();
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");
  const isDarkMode = useSelector((state) => state.dialog.isDarkMode);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const toggleDrawer = (isSidebarOpen) => () => {
    setIsSidebarOpen(isSidebarOpen);
  };

  const drawerRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setIsSidebarCollapsed(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!isLargeScreen || screenSize.width < 1000 ? (
        <Drawer
          open={isSidebarOpen}
          onClose={toggleDrawer(false)}
          ref={drawerRef}
        >
          <div
            id="docs-sidebar"
            className={`w-[40vw] h-full overflow-hidden transition-all duration-300 transform top-0 start-0 bottom-0 z-[50] text-black border-e border-gray-200 pt-7 overflow-x-hidden   ${isDarkMode
              ? "dark:bg-gray-800 dark:border-gray-700"
              : " bg-gradient-to-b from-blue-100 from-5% via-sky-100 to-indigo-50"
              }`}
          >
            <div
              className={`px-3
       flex items-center justify-center`}
            >
              <BrandLogo />
            </div>
            <nav
              className="hs-accordion-group p-6 w-full overflow-hidden grow"
              data-hs-accordion-always-open
            >
              <ul className="space-y-1.5 flex flex-col h-full">
                {listItems.map((item, index) => {
                  if (item.isWorkspace) {
                    return (
                      <WorkspacesNav
                        item={item}
                        key={index}
                        isWorkspaceExpanded={isWorkspaceExpanded}
                        setIsWorkspaceExpanded={setIsWorkspaceExpanded}
                      />
                    );
                  } else if (item.title === "Trash") {
                    return (
                      <li key={index}>
                        <Tooltip title={item.title}>
                          <Link
                            className={`${hoverClass} ${activeRoute === item.path ? activeClass : ""
                              } flex items-center gap-x-3.5 py-2 px-2.5 text-lg rounded-lg hover:bg-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300`}
                            to={item.path}
                          >
                            {item.icon}
                            <span>{item.title}</span>
                          </Link>
                        </Tooltip>
                      </li>
                    );
                  } else
                    return (
                      <li key={index}>
                        <Tooltip title={item.title}>
                          <Link
                            className={`${hoverClass} ${activeRoute === item.path ? activeClass : ""
                              } flex items-center gap-x-3.5 py-2 px-2.5 text-lg rounded-lg hover:bg-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300`}
                            to={item.path}
                          >
                            {item.icon}
                            <span>{item.title}</span>
                          </Link>
                        </Tooltip>
                      </li>

                    );
                })}

                <li class=" m-2 py-2">
                  <Link class="flex items-center gap-x-2 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700" to={ROUTES.aiAgents}>
                    <span class="flex justify-center items-center size-6 bg-blue-600 text-white rounded-md dark:bg-blue-500">
                      <svg class="flex-shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" /><path d="m3.3 7 8.7 5 8.7-5" /><path d="M12 22V12" /></svg>
                    </span>
                    {!isSidebarCollapsed && (<div>
                      AI Agents<span className='text-xs '> (Coming soon)</span>
                    </div>)}
                  </Link>
                </li>

                <li class=" m-2 py-2">
                  <Link class="flex items-center gap-x-2 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700" to={ROUTES.integrations}>
                    <span class="flex justify-center items-center size-6 bg-blue-600 text-white rounded-md dark:bg-blue-500">
                      <svg class="flex-shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect width="18" height="7" x="3" y="3" rx="1" /><rect width="9" height="7" x="3" y="14" rx="1" /><rect width="5" height="7" x="16" y="14" rx="1" /></svg>
                    </span>
                    {!isSidebarCollapsed && (<div>
                      Integrations<span className='text-xs '> (Coming soon)</span>

                    </div>)}
                  </Link>
                </li>

                <li class=" m-2 py-2">
                  <Link class="flex items-center gap-x-2 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700" to={ROUTES.template}>
                    <span class="flex justify-center items-center size-6 bg-blue-600 text-white rounded-md dark:bg-blue-500">
                      <svg class="flex-shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="4" width="20" height="16" rx="2" /><path d="M10 4v4" /><path d="M2 8h20" /><path d="M6 4v4" /></svg>
                    </span>
                    {!isSidebarCollapsed && (<div>
                      Templates<span className='text-xs '> (Coming soon)</span>

                    </div>)}
                  </Link>
                </li>

                <li class=" m-2 py-2">
                  <a class="flex items-center gap-x-3 hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="">
                    <svg class="w-[34px] h-[34px]" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="7" width="20" height="20" rx="10" fill="currentColor" class="fill-blue-200 dark:fill-blue-500"></rect>
                      <rect y="10" width="20" height="20" rx="10" fill="currentColor" class="fill-blue-300 dark:fill-blue-600"></rect>
                      <rect x="14" y="10" width="20" height="20" rx="10" fill="currentColor" class="fill-blue-500 dark:fill-blue-700"></rect>
                    </svg>
                    {!isSidebarCollapsed && (
                      <span class="text-lg text-gray-400 dark:text-neutral-500">

                        Freemium

                      </span>
                    )}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </Drawer>
      ) : (
        ""
      )}

      <div
        id="docs-sidebar"
        className={`${isSidebarCollapsed ? "w-24" : "w-[22rem]"
          } overflow-hidden transition-all duration-300 transform hidden top-0 start-0 bottom-0 z-[50]  text-black border-e border-gray-200 pt-7 overflow-x-hidden lg:flex lg:flex-col lg:translate-x-0 lg:end-auto lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 ${isDarkMode
            ? "dark:bg-gray-800 dark:border-gray-700"
            : " bg-gradient-to-b from-blue-100 from-5% via-sky-100 to-indigo-50"
          } `}
      >
        <div
          className={`${isSidebarCollapsed ? "px-1" : "px-3"
            } flex items-center justify-center`}
        >
          <BrandLogo collapsed={isSidebarCollapsed} />
        </div>
        <nav
          className="hs-accordion-group p-6 w-full overflow-hidden grow"
          data-hs-accordion-always-open
        >
          <ul className="space-y-1.5 flex flex-col h-full">
            {listItems.map((item, index) => {
              if (item.isWorkspace) {
                return (
                  <WorkspacesNav
                    isSidebarCollapsed={isSidebarCollapsed}
                    item={item}
                    key={index}
                    isWorkspaceExpanded={isWorkspaceExpanded}
                    setIsWorkspaceExpanded={setIsWorkspaceExpanded}
                  />
                );
              } else if (item.title === "Trash") {
                return (
                  <li key={index}>
                    <Tooltip title={item.title}>
                      <Link
                        className={`${hoverClass} ${activeRoute === item.path ? activeClass : ""
                          } flex items-center gap-x-3.5 py-2 px-2.5 text-lg rounded-lg hover:bg-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300`}
                        to={item.path}
                      >
                        {item.icon}
                        <span
                          className={`${isSidebarCollapsed ? collapseClass : ""
                            }`}
                        >
                          {item.title}
                        </span>
                      </Link>
                    </Tooltip>
                  </li>
                );
              } else
                return (
                  <li key={index}>
                    <Tooltip title={item.title}>
                      <Link
                        className={`${hoverClass} ${activeRoute === item.path ? activeClass : ""
                          } flex items-center gap-x-3.5 py-2 px-2.5 text-lg rounded-lg hover:bg-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300`}
                        to={item.path}
                      >
                        {item.icon}
                        <span
                          className={` ${isSidebarCollapsed ? collapseClass : ""
                            }`}
                        >
                          {item.title}
                        </span>
                      </Link>
                    </Tooltip>
                  </li>
                );
            })}

            <li class=" m-2 py-2">
              <Link class="flex items-center gap-x-2 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700" to={ROUTES.aiAgents}>
                <span class="flex justify-center items-center size-6 bg-blue-600 text-white rounded-md dark:bg-blue-500">
                  <svg class="flex-shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" /><path d="m3.3 7 8.7 5 8.7-5" /><path d="M12 22V12" /></svg>
                </span>
                {!isSidebarCollapsed && (<div>
                  AI Agents<span className='text-xs '> (Coming soon)</span>
                </div>)}
              </Link>
            </li>

            <li class=" m-2 py-2">
              <Link class="flex items-center gap-x-2 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700" to={ROUTES.integrations}>
                <span class="flex justify-center items-center size-6 bg-blue-600 text-white rounded-md dark:bg-blue-500">
                  <svg class="flex-shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect width="18" height="7" x="3" y="3" rx="1" /><rect width="9" height="7" x="3" y="14" rx="1" /><rect width="5" height="7" x="16" y="14" rx="1" /></svg>
                </span>
                {!isSidebarCollapsed && (<div>
                  Integrations<span className='text-xs '> (Coming soon)</span>

                </div>)}
              </Link>
            </li>

            <li class=" m-2 py-2">
              <Link class="flex items-center gap-x-2 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700" to={ROUTES.template}>
                <span class="flex justify-center items-center size-6 bg-blue-600 text-white rounded-md dark:bg-blue-500">
                  <svg class="flex-shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="4" width="20" height="16" rx="2" /><path d="M10 4v4" /><path d="M2 8h20" /><path d="M6 4v4" /></svg>
                </span>
                {!isSidebarCollapsed && (<div>
                  Templates<span className='text-xs '> (Coming soon)</span>

                </div>)}
              </Link>
            </li>

            <li class=" m-2 py-2">
              <a class="flex items-center gap-x-3 hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700" href="">
                <svg class="w-[34px] h-[34px]" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="7" width="20" height="20" rx="10" fill="currentColor" class="fill-blue-200 dark:fill-blue-500"></rect>
                  <rect y="10" width="20" height="20" rx="10" fill="currentColor" class="fill-blue-300 dark:fill-blue-600"></rect>
                  <rect x="14" y="10" width="20" height="20" rx="10" fill="currentColor" class="fill-blue-500 dark:fill-blue-700"></rect>
                </svg>
                {!isSidebarCollapsed && (
                  <span class="text-lg text-gray-400 dark:text-neutral-500">

                    Freemium

                  </span>
                )}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
