import { ChevronRight, PanelRightOpen, X, XCircle } from "lucide-react";
import React from "react";
import { useSelector } from "react-redux";
import Tooltip from "../Preline/Tooltip";
import Breadcrumbs from "../Generic/BreadCrumbs";
import ComponentTitle from "./ComponentTitle";
import { useReactFlow } from "reactflow";
import EditLabel from "../Generic/EditLabel";
import { ROLE_TYPES } from "../../types/types/PlanboardRoles";

const ComponentDrawerHeader = ({
  onClose,
  color,
  count,
  node,
}: // readOnly,
{
  count: number;
  color: string;
  node: { id: string; data: any };
  onClose: () => void;
  // readOnly: boolean;
}) => {
  const planboard = useSelector((state: any) => state.planboard);
  const user = useSelector((state: any) => state.user.value);
  const { getNodes } = useReactFlow();
  const nodes = getNodes();
  const readOnly = !(
    planboard.value?.userRole === ROLE_TYPES.CREATOR ||
    planboard.value?.userRole === ROLE_TYPES.COCREATOR ||
    node.data?.assignedTo?.id == user.id
  );

  // const items = [{ item: planboard?.value?.name }, { item: title }];
  return (
    // <div className="ml-2 w-full flex border-b dark:border-slate-700 pb-5 pt-1">
    <div className=" w-full fixed top-0 overflow-hidden text-white">
      <div
        className="flex mb-2 dark:mb-0 py-4 dark:text-white text-lg pl-6"
        style={{ backgroundColor: color }}
      >
        {/* <Breadcrumbs items={items} /> */}
        <div className="flex grow items-center">
          <div className="mt-1 me-2">
            <PanelRightOpen size={18} strokeWidth={1} />
          </div>
          {/* <span className="ms-3">{planboard?.value?.name}</span>
          <div className="mt-1">
            <ChevronRight size={20} strokeWidth={1} />
          </div> */}

          {readOnly ? (
            node.data.label
          ) : (
            <ComponentTitle onClose={onClose} nodeId={node.id} nodes={nodes} />
          )}
          {/* <EditLabel edit={} label={} setEdit={} updateLabel={}  /> */}
        </div>
        {/* <div className="mr-5 py-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg text-gray-100 shadow-sm">
          Action Items:&nbsp;{count}
        </div> */}
        <Tooltip title="Close" placement="left">
          <button
            type="button"
            className="mr-4 flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            onClick={onClose}
          >
            <span className="sr-only">Close modal</span>
            <svg
              className="flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default ComponentDrawerHeader;
