import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import UserSelect from "../Generic/UserSelect";
import Tooltip from "../Preline/Tooltip";
import PlanboardsAPI from "../../api/services/Planboard";
import UserSelectSingle from "../Generic/UserSelectSingle";
import { useParams } from "react-router-dom";
import useDissapearingError from "../../hooks/useDissapearingError";
import UserChip from "../Generic/UserChip";

const buttonStyle =
  "py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-500 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600";

const AssignedTo = ({ assignedTo, updateAssignedTo, members, readOnly }) => {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { error, setError, ErrorComponent } = useDissapearingError();
  // const [members, setMembers] = useState(activeMembers);

  const handleChange = (options) => {
    setSelectedMembers(options);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (readOnly) return;
    setAnchorEl(event.currentTarget);
  };

  const handleAssign = () => {
    if (selectedMembers.length === 0) {
      return setError("select a member to assign");
    }
    updateAssignedTo(selectedMembers);
    return handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id_popover = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="flex gap-x-2 items-center" onClick={handleClick}>
        Assigned to:
        <Tooltip title={readOnly ? "Assigned to" : "Click to Edit"}>
          <div
            class={`flex -space-x-2 ${
              readOnly ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            {assignedTo !== null ? (
              <UserChip user={assignedTo} />
            ) : (
              "Not Assigned"
            )}
            {/* {users.map((user) => (
              <img
                class="inline-block h-8 w-8 rounded-full ring-2 ring-white dark:ring-gray-800"
                src={user.profilePic}
                alt={user.name}
              />
            ))} */}
          </div>
        </Tooltip>
      </div>
      <Popover
        id={id_popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="dark:bg-slate-700 p-5">
          <div className="min-w-[400px] min-h-[300px] overflow-hidden flex flex-col">
            <div className="grow">
              <UserSelectSingle
                options={members}
                // selectedValue={
                //   assignedTo
                //     ? members.find((member) => member.userId === assignedTo)
                //     : undefined
                // }
                selectedValue={assignedTo}
                onChange={handleChange}
              />
            </div>

            {ErrorComponent()}
          </div>
          <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
            <button type="button" className={buttonStyle} onClick={handleClose}>
              Cancel
            </button>
            <button
              type="submit"
              className={buttonStyle}
              onClick={handleAssign}
              // onClick={saveMemberChanges}
              // disabled={isSubmitting}
            >
              Assign
            </button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default AssignedTo;
