import { TEAM_URLS, URLS } from "../../constants";
import { postAuth, putAuth } from "../client";

const TeamsAPI = {
  createTeam: (name: string) => {
    return postAuth(URLS.createTeam, {
      name,
    });
  },
  getTeams: (ids: string[]) => {
    return postAuth(URLS.getTeams, {
      ids,
    });
  },
  updateTeam: (id: string, updateData: {}) => {
    return putAuth(URLS.updateTeam, { id, updateData });
  },
  inviteMember: (email: string, role: string) => {
    return postAuth(URLS.inviteMember, { email, role });
  },
  getMembers: ({
    index,
    itemsPerIndex,
    search,
  }: {
    index: number;
    itemsPerIndex: number;
    search?: string;
  }) =>
    postAuth(URLS.getMembers, {
      index,
      itemsPerIndex,
      emailId: search,
    }),
    searchMembers: (search: string) => {
      return postAuth(URLS.searchMembers, {  search});
    },
  acceptInvitation: (inviteId: string) =>
    postAuth(URLS.acceptTeamsInvitation, { inviteId }),
  subscribedPlanUsage: () => postAuth(TEAM_URLS.subscribedPlanUsage, {}),
  updateRole: ({ userId, role }: { userId: string; role: string }) =>
    postAuth(TEAM_URLS.updateRole, { userId, role }),
};

export default TeamsAPI;
