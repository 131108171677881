import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlanboards } from "../redux/slice/planboards/planboardsSlice";
import { resetPlanboardState } from "../redux/slice/planboard";
import WorkspacesAPI from "../api/services/Workspace";

const withWorkspaces = (WrappedComponent) => {
  return function WorkspaceContainer(props) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const workspaces = useSelector((state) => state.workspaces);
    const planboards = useSelector((state) => state.planboards);

    // const getWorkspaces = async () => {
    //   const response = await WorkspacesAPI.getWorkspaces({
    //     organizationId: organizations.value[0].organizationId,
    //     index: 0,
    //     itemsPerIndex: 100,
    //     sort: "Created With",
    //     search: "workspace",
    //   });
    //   if (response?.status === 200) {
    //     if (response?.data?.data?.length) {
    //       response.data.data.map((workspace) => {
    //         return dispatch(addNewWorkspace(workspace));
    //       });
    //     }
    //   }
    // };

    useEffect(() => {
      if (!workspaces.loading && workspaces.value.length) {
        workspaces.value.forEach((workspace) => {
          // if (workspace?.planboards.length) {
          //   dispatch(
          //     getPlanboards({
          //       workspaceId: workspace._id,
          //       planboardIds: workspace.planboards,
          //     })
          //   );
          // }
        });
      }
    }, [workspaces]);

    useEffect(() => {
      dispatch(resetPlanboardState());
    }, []);

    const newProps = {
      user,
      workspaces,
      planboards,
    };
    return <WrappedComponent {...props} {...newProps} />;
  };
};

export default withWorkspaces;
