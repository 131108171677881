import React from 'react'
import ErrorMessage from './ErrorMessage'
import ErrorIcon from './ErrorIcon'

export default function Checkbox({
    field,
    label,
    name,
    id,
    value,
    hideLabel,
    className,
    placeholder,
    form: { touched, errors },
    ...props
}) {
    return (
        <div>
            <div class="flex">
                <input
                    type="checkbox"
                    {...field}
                    {...props}
                    className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                />
                <label for="hs-default-checkbox" className="text-sm text-black ms-3 dark:text-gray-400">{label}</label>
                <ErrorIcon touched={touched} errors={errors} field={field} />
            </div>
            <ErrorMessage touched={touched} errors={errors} field={field} />
        </div>
    )
}
