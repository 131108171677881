import React, { ReactNode } from "react";
import Modal, { ModalBody, ModalHeader } from "../Generic/Modal";

interface ConfirmBoxProps {
  open: boolean;
  title: string | ReactNode;
  id: string;
  children: ReactNode;
  onClose: () => void;
  handleConfirm: () => void;
}
const buttonStyle =
  "py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-500 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600";

const ConfirmBox = ({
  open,
  onClose,
  handleConfirm,
  title,
  children,
  id,
}: ConfirmBoxProps) => {
  return (
    <Modal open={open} id={id}>
      <ModalHeader title={title} onClose={onClose} icon={false} />

      <ModalBody>{children}</ModalBody>
      <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
        <button type="button" className={buttonStyle} onClick={onClose}>
          Cancel
        </button>
        <button type="button" className={buttonStyle} onClick={handleConfirm}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmBox;
