export const colors = [
  "#55efc4",
  "#81ecec",
  "#74b9ff",
  "#a29bfe",
  "#dfe6e9",
  "#00b894",
  "#00cec9",
  "#0984e3",
  "#6c5ce7",
  "#b2bec3",
  "#ffeaa7",
  "#fab1a0",
  "#ff7675",
  "#fd79a8",
  "#636e72",
  "#fdcb6e",
  "#e17055",
  "#e84393",
  "#2d3436",
];

export function getRandomColor() {
  // if (colors.length === 0) {
  //   throw new Error('Cannot get a random item from an empty array');
  // }

  //   const randomIndex = Math.floor(Math.random() * colors.length);
  //   return colors[randomIndex];

  let currentIndex = colors.length,
    randomIndex;
  // while (currentIndex !== 0) {
  randomIndex = Math.floor(Math.random() * currentIndex);
  currentIndex--;

  // Swap current element with the randomly chosen one
  [colors[currentIndex], colors[randomIndex]] = [
    colors[randomIndex],
    colors[currentIndex],
  ];
  return colors[randomIndex];
  //   }
}

export class UniqueRandomPicker<T> {
  private items: T[];
  private selectedItems: Set<T>;

  constructor(items: T[]) {
    this.items = items;
    this.selectedItems = new Set<T>();
  }

  public getRandomUniqueItem(): T {
    if (this.selectedItems.size === this.items.length) {
      // Reset the set if all items have been selected
      this.selectedItems.clear();
    }

    // Create an array of items that haven't been selected yet
    const remainingItems = this.items.filter(
      (item) => !this.selectedItems.has(item)
    );

    // if (remainingItems.length === 0) {
    //   // If all items have been selected, return null
    //   return null;
    // }

    // Select a random item from the remaining items
    const randomIndex = Math.floor(Math.random() * remainingItems.length);
    const selectedItem = remainingItems[randomIndex];

    // Add the selected item to the set of selected items
    this.selectedItems.add(selectedItem);

    return selectedItem;
  }
}
