import React from "react";
import { useParams } from "react-router-dom";
import Workspaces from "../components/Workspaces";
import WorkspaceDetail from "../components/Workspaces/WorkspaceDetail";

function WorkspaceRoute() {
  const { id } = useParams();

  return <>{id ? <WorkspaceDetail /> : <Workspaces />}</>;
}

export default WorkspaceRoute;
