import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import BackButton from "./BackButton";
import Spinner from "../../components/Spinner";
import OnboardingAPI from "../../api/services/Onboarding";
import useDissapearingError from "../../hooks/useDissapearingError";
import { useDispatch, useSelector } from "react-redux";
import { updateOrganization } from "../../redux/slice/organisations/slice";
import { RootState } from "../../redux/store";
import { Member } from "../../components/CreatePlanboardSteps/Member";
// import Sidebar from "./Sidebar";

interface EnterPlanboardNameProps {
  onBoardingData: any;
}

const EnterPlanboardName = ({ onBoardingData }: EnterPlanboardNameProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const organization: { organizationId: string }[] = useSelector(
    (state: RootState) => state.organizations.value
  );

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const { error, setError, ErrorComponent } = useDissapearingError();

  const handleBack = () => {
    navigate(-1);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const getMembersData = (members: Member[]) => {
    return members.map((member) => {
      if (member.type == "full") {
        return { userId: member.userId, role: member.role };
      } else {
        return { emailId: member.email, role: member.role };
      }
    });
  };

  const createPlanboard = async (e: any) => {
    e.preventDefault();
    if (name === "") return setError("Enter planboard name");
    const planboardId = sessionStorage.getItem("planboardId");
    const requestData = {
      reasonForVisiting: onBoardingData.reasonForVisiting,
      teamSize: onBoardingData.teamSize,
      domainId: onBoardingData.domain?.id,
      subdomainId: onBoardingData.subdomain?.id,
      departmentId: onBoardingData.department?.id,
      roleId: onBoardingData.role?.id,
      scope: onBoardingData.scope,
      geography: onBoardingData.geography,
      planboardId: planboardId || "undefined",
      endDate: onBoardingData.endDate,
      members: [] as any,
      name,
    };
    if (onBoardingData?.members?.length > 0) {
      requestData.members = getMembersData(onBoardingData?.members);
    }
    try {
      setLoading(true);
      const response = await OnboardingAPI.setUp({ ...requestData });
      setLoading(false);
      if (response.status === 200) {
        setTimeout(
          () =>
            dispatch(
              updateOrganization({
                id: organization[0].organizationId,
                updateData: { onboarded: true },
              })
            ),
          500
        );
        return navigate(
          ROUTES.planboardDesigner + "/" + response.data.data.planboardId,
          { replace: true, state: { from: location, nav: "canvas" } }
        );
      }
    } catch (error: any) {
      setLoading(false);
      setError(error?.response?.data?.data ?? "Error");
    }
  };

  return (
    <div className="w-[500px] space-y-10">
      {/* <!-- Title --> */}
      <div>
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
          Name your first planboard
        </h1>
      </div>
      {/* <!-- End Title --> */}

      <form onSubmit={createPlanboard}>
        <div className="">
          <div>
            <input
              type="text"
              id="hs-pro-dale"
              className="py-2.5 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600"
              placeholder="planboard name"
              onChange={handleInputChange}
              required
            />
          </div>

          <BackButton handleBack={handleBack} />

          <button
            type="submit"
            onClick={createPlanboard}
            disabled={loading}
            className="py-2.5 px-3 mt-5 w-full inline-flex justify-center items-center gap-x-2 text-start bg-blue-600 border border-blue-600 text-white text-sm font-medium rounded-lg shadow-sm align-middle hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-300 dark:focus:ring-blue-500 disabled:bg-blue-400"
          >
            {loading ? (
              <>
                <Spinner />
                Creating...
              </>
            ) : (
              "Get Started"
            )}
          </button>
          {/* <div className="text-sm text-gray-500 dark:text-neutral-400">
            {error}
          </div> */}
          {ErrorComponent()}
        </div>
      </form>
    </div>
  );
};

export default EnterPlanboardName;
