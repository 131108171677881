import React from "react";
import ErrorMessage from "./ErrorMessage";
import ErrorIcon from "./ErrorIcon";

export default function Input({
  field,
  label,
  name,
  id,
  value,
  hideLabel,
  className,
  placeholder,
  optional = false,
  form: { touched, errors },
  ...props
}) {
  return (
    <div>
      {hideLabel ? null : (
        <label htmlFor="name" className="flex text-sm mb-2 dark:text-white">
          {label}{" "}
          {optional && <span className="italic text-gray-400">(optional)</span>}
        </label>
      )}
      <div className="relative">
        <input
          type="text"
          {...field}
          {...props}
          className={
            className ||
            "py-3 px-4 block w-full hover:bg-gray-100 dark:hover:bg-slate-800 border border-gray-200 rounded-lg text-sm dark:focus:bg-slate-800 focus:border-primary-600 focus:ring-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:focus:ring-gray-600"
          }
          required
          placeholder={placeholder}
          aria-describedby={`${id}-error`}
          value={value || ""}
        />
        <ErrorIcon touched={touched} errors={errors} field={field} />
      </div>
      <ErrorMessage touched={touched} errors={errors} field={field} />
    </div>
  );
}
