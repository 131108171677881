import { combineReducers, configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slice/counter/counterSlice";
import loginReducer from "./slice/login/loginSlice";
import planboardReducer from "./slice/planboard";
import planboardsReducer from "./slice/planboards/planboardsSlice";
import toastReducer from "./slice/toast/toastSlice";
import userReducer from "./slice/user/userSlice";
import workspaceReducer from "./slice/workspace";
import workspacesReducer from "./slice/workspaces/workspacesSlice";
import teamsReducer from "./slice/teams";
import teamReducer from "./slice/team";
import loadingReducer from "./slice/loading/loadingSlice";
import recentPlanboards from "./slice/recentPlanboards/recentPlanboards";
import canvasSlice from "./slice/canvas/canvasSlice";
import dialogSlice from "./slice/ui/dialogSlice";
import eventsSlice from "./slice/events/eventsSlice";
import auth from "./slice/auth/authSlice";
// import authSlice from "./slice/auth/authSlice";
import organizations from "./slice/organisations/slice";

import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  counter: counterReducer,
  login: loginReducer,
  planboard: planboardReducer,
  planboards: planboardsReducer,
  recentPlanboards,
  toastList: toastReducer,
  user: userReducer,
  workspace: workspaceReducer,
  workspaces: workspacesReducer,
  teams: teamsReducer,
  team: teamReducer,
  loading: loadingReducer,
  canvas: canvasSlice,
  dialog: dialogSlice,
  events: eventsSlice,
  auth,
  organizations,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
