import React, { useEffect, useRef, useState } from "react";
import NewMemberModal from "../components/Teams/NewMemberModal";
import StatCards from "../components/Teams/StatCards";
import MembersList from "../components/Teams/MembersList";
import { AxiosError } from "axios";
import TeamsAPI from "../api/services/Teams";
import { useDebounce } from "@uidotdev/usehooks";
import useOrganizationRoles from "../hooks/useOrganizationRoles";

const Teams = () => {
  const { fetchSubscription, subscription } = useOrganizationRoles();
  const [search, setSearch] = useState("");

  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    fetchSubscription();
  }, []);

  return (
    <div className="flex h-full dark:bg-slate-900">
      <main
        id="all-workspaces"
        role="main"
        className="w-full h-screen max-h-[98vh] overflow-y-scroll bg-white border border-gray-200 dark:bg-slate-800 dark:border-gray-700"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        <div className="max-w-[100rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10">
          {/*  */}
          {/* <!-- Breadcrumb -->  */}
          <ol className="md:hidden py-3 px-2 sm:px-5 flex items-center whitespace-nowrap">
            <li className="flex items-center text-sm text-gray-600 dark:neutral-slate-500">
              Account
              <svg
                className="flex-shrink-0 mx-1 overflow-visible size-4 text-gray-400 dark:neutral-slate-600"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m9 18 6-6-6-6" />
              </svg>
            </li>

            <li
              className="text-sm font-semibold text-gray-800 truncate dark:text-neutral-200"
              aria-current="page"
            >
              Members
            </li>
          </ol>
          {/* <!-- End Breadcrumb -->  */}

          <div className="space-y-3">
            {/* <!-- Account Nav -->  */}
            {/* <div className="w-full flex flex-row whitespace-nowrap overflow-x-auto overflow-y-hidden pb-1 [&::-webkit-scrollbar]:h-1 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500">
          {/* <a
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm whitespace-nowrap border border-transparent text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:text-gray-500 dark:text-slate-200 dark:hover:text-slate-400 dark:focus:text-slate-400 "
            href="../../pro/dashboard/account-profile.html"
          >
            Profile
          </a> 
          {/* <a
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm whitespace-nowrap border border-transparent text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:text-gray-500 dark:text-slate-200 dark:hover:text-slate-400 dark:focus:text-slate-400 "
            href="../../pro/dashboard/account-notifications.html"
          >
            Notifications
          </a> 
          {/* <a
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm whitespace-nowrap border border-transparent text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:text-gray-500 dark:text-slate-200 dark:hover:text-slate-400 dark:focus:text-slate-400 "
            href="../../pro/dashboard/account-integrations.html"
          >
            Integrations
          </a> 
          {/* <a
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm whitespace-nowrap border border-transparent text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:text-gray-500 dark:text-slate-200 dark:hover:text-slate-400 dark:focus:text-slate-400 "
            href="../../pro/dashboard/account-preferences.html"
          >
            Preferences
          </a> 
          {/* <a
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm whitespace-nowrap border border-transparent text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:text-gray-500 dark:text-slate-200 dark:hover:text-slate-400 dark:focus:text-slate-400 "
            href="../../pro/dashboard/account-workspace.html"
          >
            Workspace
          </a> 
          {/* <a
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm whitespace-nowrap border border-transparent text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:text-gray-500 dark:text-slate-200 dark:hover:text-slate-400 dark:focus:text-slate-400 "
            href="../../pro/dashboard/account-plan-and-billing.html"
          >
            Plan &amp; Billing
          </a> 
          <a
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm whitespace-nowrap border border-transparent text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:text-gray-500 dark:text-slate-200 dark:hover:text-slate-400 dark:focus:text-slate-400 active-link bg-white !border-gray-200  shadow-sm dark:bg-slate-800 dark:!border-slate-700"
            href="../../pro/dashboard/account-members.html"
          >
            Members
          </a>
        </div> */}
            {/* <!-- End Account Nav -->  */}

            {/* <!-- Account Card -->  */}
            <div className=" bg-white shadow-sm rounded-xl dark:bg-slate-800 dark:border-slate-700">
              {/* <!-- Title -->  */}
              <div className="mb-4 xl:mb-8">
                <div className="flex justify-between">
                  <h1 className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                    Members
                  </h1>
                  <div className="dark:text-white flex space-x-2">
                    <div className="dark:bg-gray-400 dark:text-slate-800 px-3 pb-1 rounded-full flex items-center">
                      {subscription.currentPlan.subscriptionPlan}
                    </div>
                    {subscription.currentPlan.subscriptionPlan ===
                      "Freemium" && (
                      <div className="bg-gradient-to-br from-blue-600 via-transparent to-violet-600 p-1 rounded-full text-sm">
                        <button
                          className="flex items-center bg-white dark:bg-slate-800 rounded-full px-2 py-1"
                          disabled={true}
                        >
                          Upgrade Now
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="lucide lucide-arrow-up-right pt-1"
                          >
                            <path d="M7 7h10v10" />
                            <path d="M7 17 17 7" />
                          </svg>
                        </button>
                      </div>
                      // <div>
                      //   <div className="relative flex justify-center items-center size-12 bg-white rounded-xl before:absolute before:-inset-px before:-z-[1] before:bg-gradient-to-br before:from-blue-600 before:via-transparent before:to-violet-600 before:rounded-xl dark:bg-neutral-900">
                      //     <svg
                      //       className="flex-shrink-0 size-6 text-blue-600 dark:text-blue-500"
                      //       xmlns="http://www.w3.org/2000/svg"
                      //       width="24"
                      //       height="24"
                      //       viewBox="0 0 24 24"
                      //       fill="none"
                      //       stroke="currentColor"
                      //       stroke-width="2"
                      //       stroke-linecap="round"
                      //       stroke-linejoin="round"
                      //     >
                      //       <rect width="10" height="14" x="3" y="8" rx="2" />
                      //       <path d="M5 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-2.4" />
                      //       <path d="M8 18h.01" />
                      //     </svg>
                      //   </div>
                      // </div>
                    )}
                  </div>
                </div>
                <p className="text-sm text-gray-500 dark:text-neutral-300">
                  Manage members and users of your organization and set their
                  access level.
                </p>
              </div>
              {/* <!-- End Title -->  */}

              <div className="space-y-5">
                <StatCards subscribtion={subscription} />
                {/* <!-- Filter Group -->  */}
                <div className="flex sm:grid sm:grid-cols-2 gap-x-2 sm:gap-x-5">
                  {/* <!-- Search Input -->  */}
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                      <svg
                        className="flex-shrink-0 size-4 text-gray-500 dark:text-neutral-400"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="11" cy="11" r="8" />
                        <path d="m21 21-4.3-4.3" />
                      </svg>
                    </div>
                    {/* <input
                  type="text"
                  className="py-2 px-3 ps-10 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-700 dark:border-transparent dark:text-slate-400 dark:placeholder:text-slate-400 dark:focus:ring-slate-600"
                  placeholder="Search by name or email"
                  onChange={(e) => setSearch(e.target.value)}
                /> */}
                    <div className="relative">
                      <input
                        type="text"
                        className="py-2 px-3 ps-10 block w-full bg-gray-100 border-transparent rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-700 dark:border-transparent dark:text-neutral-400 dark:placeholder:text-slate-400 dark:focus:ring-slate-600"
                        placeholder="Search by name or email"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      {search && (
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 mr-3 flex items-center "
                          onClick={() => setSearch("")}
                        >
                          <div className="flex bg-gray-200 hover:bg-gray-300 rounded-full p-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-x"
                            >
                              <path d="M18 6 6 18" />
                              <path d="m6 6 12 12" />
                            </svg>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                  {/* <!-- End Search Input -->  */}

                  <div className="flex justify-end items-center gap-x-2">
                    {/* <!-- Button -->  */}
                    <button
                      type="button"
                      className="py-2 px-3 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                      data-hs-overlay="#hs-pro-dshm"
                    >
                      <span className="hidden sm:block">Send</span>Invite
                    </button>
                    {/* <!-- End Button -->  */}
                  </div>
                  {/* <!-- End Col -->  */}
                </div>
                {/* <!-- End Filter Group -->  */}

                <MembersList search={debouncedSearch} />
              </div>
            </div>
            {/* <!-- End Account Card -->  */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Teams;
