import { Tooltip } from "@mui/material";
import { useClickAway } from "@uidotdev/usehooks";
import {
  CalendarDays,
  ChevronLeft,
  ChevronRight,
  MoveRight,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import Datepicker from "tailwind-datepicker-react";
import { formatDateToDateString } from "../../utils/dateTime";
import moment from "moment";

const DueDate = ({ dueDate, updateDueDate, readOnly }) => {
  const options = {
    // title: "Demo Title",
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    clearBtnText: "Clear",
    maxDate: new Date("2030-01-01"),
    minDate: new Date(),
    theme: {
      background: "bg-gray-100 dark:bg-gray-800",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "",
    },
    icons: {
      // () => ReactElement | JSX.Element
      prev: () => (
        <span>
          <ChevronLeft />
        </span>
      ),
      next: () => (
        <span>
          <ChevronRight />
        </span>
      ),
    },
    datepickerClassNames: "top-12",
    defaultDate: new Date(dueDate),
    language: "en",
    disabledDates: [],
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Select Date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  };
  const [show, setShow] = useState(false);

  const ref = useClickAway(() => {
    setShow(false);
  });

  const handleClose = (state) => {
    setShow(state);
  };

  return (
    <div ref={ref} className="flex items-center">
      <span className="w-20">Due&nbsp;Date{readOnly && ":"}&nbsp;</span>
      {readOnly ? (
        moment(dueDate).format("DD-MM-YYYY")
      ) : (
        <Datepicker
          options={options}
          onChange={updateDueDate}
          show={show}
          setShow={handleClose}
        >
          <Tooltip title="Click to edit due-date">
            <div className="flex items-center justify-center space-x-1 border dark:border-neutral-500 p-2 rounded-md hover:bg-gray-100 hover:dark:bg-neutral-800">
              <CalendarDays size={16} />
              <input
                type="text"
                placeholder="Select Date"
                //   value={new Date(dueDate).toLocaleDateString()}
                value={formatDateToDateString(dueDate)}
                onFocus={() => setShow(true)}
                readOnly
                className="cursor-pointer w-[10ch] block bg-transparent dark:bg-transparent border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:border-gray-700 dark:text-gray-200 dark:focus:ring-gray-600"
              />
            </div>
          </Tooltip>
        </Datepicker>
      )}
    </div>
  );
};

export default DueDate;
