import React, { ReactNode, forwardRef } from "react";

type BaseButtonAttributes = React.ComponentPropsWithoutRef<"button">;
type Ref = HTMLButtonElement;

type ButtonVariant = "contained" | "outlined";

type ButtonType = "button" | "submit";

interface ButtonProps extends BaseButtonAttributes {
  label: ReactNode;
  variant?: ButtonVariant;
  type?: ButtonType;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
}
const outlinedStyle = `font-medium bg-white text-gray-800 hover:bg-gray-100`;
const containedStyle = "font-semibold bg-blue-500 text-white hover:bg-blue-600";

const Button = forwardRef<Ref, ButtonProps>(
  ({
    label,
    variant = "contained",
    type = "button",
    startIcon,
    endIcon,
    className,
    ...props
  }: ButtonProps) => {
    return (
      <button
        type="button"
        className={`py-2 px-3 inline-flex items-center gap-x-1 text-sm rounded-lg border border-gray-200 dark:border-0 shadow-sm disabled:opacity-50 disabled:pointer-events-none ${
          variant === "contained" ? containedStyle : outlinedStyle
        } ${className ? className : ""}`}
        {...props}
      >
        {startIcon}
        {label}
        {endIcon}
      </button>
    );
  }
);

export default Button;
