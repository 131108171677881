import { Plus } from "lucide-react";
import React, { memo, useState } from "react";
import { Handle } from "reactflow";
const SkeletonNode = () => {
  const [hover, setHover] = useState(false);
  return (
    <>
      <Handle type="target" position="left" style={{ opacity: 0 }} />
      <div
        className={`w-10 h-10 cursor-pointer ${
          hover ? "text-black dark:text-white" : "text-gray-300"
        } flex items-center justify-center rounded-full border-2`}
      >
        <Plus
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      </div>
    </>
  );
};

export default memo(SkeletonNode);
