import { Check } from "lucide-react";
import React from "react";

const steps = ["Define Business", "Team & Timelines", "Launch"];

interface CreatePlanboardHeaderProps {
  activeStep: number;
}

const CreatePlanboardHeader2 = ({ activeStep }: CreatePlanboardHeaderProps) => {
  const renderIndicator = (index: number, activeStep: number) => {
    if (activeStep > index + 1) {
      return (
        <span className="rounded-full bg-blue-700 text-white p-2">
          <Check size={12} />
        </span>
      );
    } else {
      return (
        <span
          className={`w-7 h-7 flex justify-center items-center flex-shrink-0 ${
            activeStep === index + 1
              ? "bg-blue-700 text-white font-semibold"
              : "bg-gray-100 text-gray-800"
          } font-medium rounded-full`}
        >
          {index + 1}
        </span>
      );
    }
  };

  return (
    <header className="w-full flex flex-wrap md:justify-start md:flex-nowrap z-50 h-[10%] bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div
        className="flex justify-between xl:grid xl:grid-cols-3 basis-full items-center w-full py-2.5 px-2 sm:px-5"
        aria-label="Global"
      >
        <div className="lg:hidden">logo</div>
        <div className="xl:col-span-3 flex items-center md:gap-x-3 w-full">
          <ul className="grow flex flex-row gap-x-2 py-2">
            {steps.map((step, index) => {
              return (
                <li
                  className={`shrink basis-0 ${
                    index === steps.length - 1
                      ? `flex-0 group min-w-40`
                      : "flex-1 group"
                  }
                  `}
                >
                  <div className="min-w-[28px] min-h-[28px] w-full inline-flex items-center text-xs align-middle space-x-3">
                    {renderIndicator(index, activeStep)}
                    <span className="flex text-sm font-medium text-gray-800 dark:text-white">
                      {step} {/* Step Name */}
                    </span>
                    <div
                      className={`ms-2 w-full h-px flex-1 ${
                        activeStep > index + 1 ? "bg-blue-600" : "bg-gray-200 "
                      } group-last:hidden`}
                    ></div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default CreatePlanboardHeader2;
