import axios, { AxiosError, AxiosResponse } from "axios";
import { BASE_URL, PUBLIC_URLS } from "../constants";
import { store } from "../redux/store";
import { updateAuthed, updateTokens } from "../redux/slice/login/loginSlice";
import PublicAPI from "./services/Public";

const apiClient = axios.create({
  baseURL: BASE_URL,
});
// const getNewAccessToken = async () => {
//   const refresh_token_saved = localStorage.getItem("refresh_token");
//   if (!refresh_token_saved) {
//     store.dispatch(updateAuthed(false));
//     return;
//   }
//   const response = await PublicAPI.refreshToken({
//     refreshToken: refresh_token_saved,
//   });
//   if (response?.data?.status?.code === 200) {
//     store.dispatch(updateTokens(response.data.data));
//     store.dispatch(updateAuthed(true));
//   } else {
//     store.dispatch(updateAuthed(false));
//   }
//   return;
// };

let isRefreshing: boolean = false;
let subscribers: ((token: string) => void)[] = [];

interface TokenResponse {
  data: {
    status: {
      code: number;
    };
    data: {
      access_token: string;
      refresh_token: string;
    };
  };
}

const notifySubscribers = (token: string): void => {
  subscribers.forEach((callback) => callback(token));
};

const subscribeTokenRefresh = (cb: (token: string) => void): void => {
  subscribers.push(cb);
};

const getNewAccessToken = async (): Promise<void> => {
  if (!isRefreshing) {
    isRefreshing = true;
    const refresh_token_saved: string | null =
      localStorage.getItem("refresh_token");
    if (!refresh_token_saved) {
      store.dispatch(updateAuthed(false));
      return;
    }
    try {
      const response: AxiosResponse<TokenResponse> =
        await PublicAPI.refreshToken({
          refreshToken: refresh_token_saved,
        });
      if (response.status === 200) {
        store.dispatch(updateTokens(response.data.data));
        store.dispatch(updateAuthed(true));
        // @ts-ignore
        notifySubscribers(response.data.data.access_token);
        subscribers = [];
      } else {
        store.dispatch(updateAuthed(false));
      }
    } catch (error) {
      store.dispatch(updateAuthed(false));
    } finally {
      isRefreshing = false;
    }
  } else {
    return new Promise<void>((resolve) => {
      subscribeTokenRefresh((token: string) => {
        resolve();
      });
    });
  }
};

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      (error?.response?.status === 401 ||
        error?.response?.status === 400 ||
        error?.response?.status === 404) &&
      originalRequest?.url === PUBLIC_URLS.refreshToken
    ) {
      store.dispatch(updateAuthed(false));
      return Promise.reject(error);
    }
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      await getNewAccessToken();
      originalRequest.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "access_token"
      )}`;
      return apiClient(originalRequest);
    }
    return Promise.reject(error);
  }
);

// apiClient.defaults.withCredentials = true;

export const getWithoutAuth = (url: string) => {
  return apiClient.get(url);
};
export const postWithoutAuth = (url: string, data: {}) => {
  return apiClient.post(url, { data });
};

export const getAuth = (url: string) => {
  return apiClient.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token") || ""}`,
    },
  });
};

export const postAuth = (url: string, data: {}) => {
  return apiClient.post(
    url,
    { data },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token") || ""}`,
      },
    }
  );
};
export const putAuth = (url: string, data: {}) => {
  return apiClient.put(url, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token") || ""}`,
    },
  });
};
export const deleteAuth = (url: string, data?: {}) => {
  const requestOptions: any = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token") || ""}`,
    },
  };
  if (data) {
    requestOptions.data = { ...data };
  }

  return apiClient.delete(url, requestOptions);
};

export const postFormDataAuth = async (
  url: string,
  data: any,
  files: File[] | File
) => {
  const formData = new FormData();

  formData.append("data", JSON.stringify({ data }));

  if (Array.isArray(files)) {
    // If files is an array of File objects
    files.forEach((file: File, index: number) => {
      formData.append(`files`, file, file.name);
    });
  } else {
    // If files is a single File object
    formData.append("file", files, files.name);
  }

  // Make the request
  try {
    const response = await apiClient.post(url, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token") || ""}`,
        "Content-Type": "multipart/form-data", // Important for file uploads
      },
    });
    return response;
  } catch (error) {
    // Handle errors appropriately
    console.error("Error sending form data:", error);
    throw error; // Re-throw to allow for error handling in the calling function
  }
};

export default apiClient;
