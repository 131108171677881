import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewWorkspace,
  createWorkspace,
  getWorkspaces,
} from "../../redux/slice/workspaces/workspacesSlice";
import { ChevronDown, ChevronUp, Plus } from "lucide-react";
import { ROUTES } from "../../constants";
import { usePageName, useToast } from "../../hooks";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Spinner from "../Spinner";

const activeClass = "bg-white font-semibold text-primary-400 dark:bg-gray-700";
const collapseClass = "hidden";
const hoverClass = "hover:text-primary-400";

const WorkspacesNav = ({
  isSidebarCollapsed,
  item,
  isWorkspaceExpanded,
  setIsWorkspaceExpanded,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { activeRoute } = usePageName();

  const organizations = useSelector((state) => state.organizations);
  const workspaces = useSelector((state) => state.workspaces);


  // const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleNewWorkspace = (e) => {
    const activeWorkspaces = workspaces.value.filter(
      (workspace) => workspace.active
    );
    if (activeWorkspaces.length > 5)
      return toast.error(
        `You can only create ${Number(activeWorkspaces.length)} workspaces`
      );
    e.preventDefault();
    dispatch(createWorkspace())
      .then((result) => {
        console.log({ result: result.payload });
        if (result.payload?.status?.code == 200) {
          dispatch(addNewWorkspace(result.payload.data));
          toast.success(
            <div>
              New workspace <strong>{result.payload.data.name}</strong> created
              successfully
            </div>
          );
          navigate(ROUTES.workspaces + "/" + result.payload.data.id, {
            state: { new: true },
          });
        }
      })
      .catch((error) => {
        console.log({ error });
        toast.error("Error creating new workspace");
      });
  };

  const handleDropdown = (e) => {
    e.preventDefault();
    if (organizations.value.length > 0) {
      dispatch(
        getWorkspaces({
          organizationId: organizations.value[0].organizationId,
          index: 0,
          itemsPerIndex: 100,
          sort: "Created With",
          search: "",
        })
      );
    }
    setIsWorkspaceExpanded(!isWorkspaceExpanded);
  };

  useEffect(() => {
    if (!activeRoute) return;
    if (activeRoute?.indexOf(ROUTES.workspaces) !== -1) {
      setIsWorkspaceExpanded(true);
    }
    // else {
    //   setDropdownOpen(false);
    // }
  }, [activeRoute]);

  return (
    <li className="hs-accordion grow border-b overflow-y-hidden pb-3 mb-3 ">
      <Tooltip title={item.title}>
        <Link
          to={ROUTES.workspaces}
          className={`${hoverClass} ${
            // activeRoute === item.path ? activeClass : ""
            activeRoute?.includes(item.path) ? activeClass : ""
          } hs-accordion-active:text-black hs-accordion-active:hover:bg-transparent w-full text-start justify-between flex items-center gap-x-3.5 py-2 px-2.5 text-lg rounded-lg hover:bg-white dark:hover:bg-gray-700 dark:text-slate-400 dark:hover:text-slate-300 dark:hs-accordion-active:text-white dark:focus:outline-none`}
        >
          <span className="flex items-center">
            {item.icon}
            <span className={`${isSidebarCollapsed ? "hidden" : ""} ml-3.5`}>
              {item.title}
            </span>
          </span>
          <div className={`flex gap-x-1 ${isSidebarCollapsed ? "hidden" : ""}`}>
            <div
              onClick={handleNewWorkspace}
              // id="workspaces-accordion"
              className="dark:hover:bg-slate-700 rounded-md hs-accordion-toggle"
            >
              <Tooltip title="Add New Workspace">
                <Plus strokeWidth={1} />
              </Tooltip>
            </div>
            {organizations.loading ? (
              <div className="flex items-center">
                <Spinner />
              </div>
            ) : (
              <div
                onClick={handleDropdown}
                className="dark:hover:bg-slate-700 rounded-md flex items-center"
              >
                <Tooltip title={isWorkspaceExpanded ? "Collapse" : "Expand"}>
                  <div
                    className={`transition-all transform duration-300 ${
                      isWorkspaceExpanded ? "" : "rotate-180"
                    }`}
                  >
                    <ChevronUp strokeWidth={1} />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </Link>
      </Tooltip>
      <div
        // id="workspaces-accordion"
        className={` w-full overflow-x-hidden py-3 pb-10 transition-all origin-top ease-in-out duration-300 ${
          isWorkspaceExpanded
            ? "max-h-full overflow-y-auto"
            : "max-h-0 overflow-hidden invisible"
        }`}
      >
        <ul
          id="workspace_list"
          className={`w-full space-y-2 h-full overflow-hidden pb-2 border-s-2 border-white ps-2 ms-5 ${
            isSidebarCollapsed ? "hidden" : ""
          }`}
        >
          {(workspaces.loading && workspaces.value.length == 0) ||
          organizations.loading ? (
            <div className="mt-2 space-y-2">
              {[1, 2, 3, 4, 5].map((item) => (
                <li key={item} className="w-full">
                  <div class="flex animate-pulse ms-2 me-10">
                    <h3 class="h-4 rounded-full dark:bg-gray-700 w-full"></h3>
                  </div>
                </li>
              ))}
            </div>
          ) : workspaces.value.filter((workspace) => workspace.active !== false)
              .length === 0 ? (
            <li>
              <div className="dark:text-gray-400 ms-8 italic text-sm">
                No Workspaces
              </div>
            </li>
          ) : (
            workspaces.value
              .filter((workspace) => workspace.active !== false)
              .map((workspace) => (
                <li key={workspace.id}>
                  <Link
                    className={`${hoverClass} ${
                      activeRoute === `${ROUTES.workspaces}/${workspace.id}`
                        ? activeClass
                        : ""
                    } flex items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-lg hover:bg-white dark:text-slate-400 dark:hover:text-slate-300 dark:hover:bg-gray-900`}
                    to={`${ROUTES.workspaces}/${workspace.id}`}
                  >
                    {workspace.name}
                  </Link>
                </li>
              ))
          )}
        </ul>
      </div>
    </li>
  );
};

export default WorkspacesNav;
