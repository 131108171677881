import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants";
import LogoBlack from "../assets/images/planbow_logo_black.svg";
import LogoWhite from "../assets/images/planbow_logo_white.svg";

export default function Error404() {
  return (
    <main class="flex h-screen dark:bg-gray-900">
      <div class="max-w-[50rem] flex flex-col mx-auto w-full h-full">
        <header class="mb-auto flex justify-center z-50 w-full py-4">
          <nav class="px-4 sm:px-6 lg:px-8" aria-label="Global">
            <img src={LogoBlack} alt="planbow" className="dark:hidden" />
            <img src={LogoWhite} alt="planbow" className="hidden dark:block" />
          </nav>
        </header>

        <div class="text-center py-10 px-4 sm:px-6 lg:px-8">
          <h1 class="block text-7xl font-bold text-gray-800 sm:text-9xl dark:text-white">
            404
          </h1>
          <h1 class="block text-2xl font-bold text-white"></h1>
          <p class="mt-3 text-gray-600 dark:text-gray-400">
            Oops, something went wrong.
          </p>
          <p class="text-gray-600 dark:text-gray-400">
            Sorry, we couldn't find your page.
          </p>
          <div class="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
            <Link
              class="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-primary-400 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-primary-600 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              to={ROUTES.dashboard}
            >
              <svg
                class="flex-shrink-0 w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="m15 18-6-6 6-6" />
              </svg>
              Back to home
            </Link>
          </div>
        </div>

        <footer class="mt-auto text-center py-5">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <p class="text-sm text-gray-500">© All Rights Reserved. 2022.</p>
          </div>
        </footer>
      </div>
    </main>
  );
}
