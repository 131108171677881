import React, { useEffect, useState } from "react";
import SelectWithSearch from "./SelectWithSearch";
import { postAuth } from "../../api/client";
import { URLS } from "../../constants";
import { useFormikContext } from "formik";
import ErrorIcon from "../Inputs/ErrorIcon";
import ErrorMessage from "../Inputs/ErrorMessage";
import { useDebounce } from "@uidotdev/usehooks";

export interface Option {
  domainId: string;
  name: string;
}
interface DomainSelectProps {
  field: any;
  form: any;
}

const DomainSelect = ({ field, form }: DomainSelectProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [search, setSearch] = useState("");
  const [domains, setDomains] = useState([]);
  const debouncedSearch = useDebounce(search, 300);

  const { setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext();

  const handleChange = (domain: {}) => {
    // onChange(domainId);
    setFieldValue(field.name, domain);
    setFieldTouched(field.name, false);
    setError(null);
  };

  const fetchSuggestions = async () => {
    try {
      setLoading(true);
      const response = await postAuth(URLS.getDomains, {
        search: debouncedSearch,
      });
      setLoading(false);
      if (response.status === 200) {
        const values = response.data.data.map(
          (domain: { domainId: string; name: string }) => ({
            id: domain.domainId,
            name: domain.name,
          })
        );
        setDomains(values);
      }
    } catch (error: any) {
      if (error.response.status === 404) {
        setDomains([]);
        return setError(
          error?.response?.data?.data ??
            "No business domains found for given search"
        );
      }
      return setError("Error fetching domains");
    }
  };
  useEffect(() => {
    fetchSuggestions();
  }, [debouncedSearch]);
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <div className="relative">
        <SelectWithSearch
          id="domain_select_search"
          loading={loading}
          onChange={handleChange}
          onInputChange={handleSearchInputChange}
          options={domains}
          value={form.values.domain?.id}
          label="Industry"
          error={error}
          placeholder="Select Industry"
        />
        <div className="absolute end-5 top-6" id="domain-error-icon">
          <ErrorIcon touched={touched} errors={errors} field={field} />
        </div>
      </div>
      <ErrorMessage touched={touched} errors={errors} field={field} />
    </div>
  );
};

export default DomainSelect;
