import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import FormComponent from "../../components/CreatePlanboardSteps/FormComponent";
import { ROUTES } from "../../constants";
import PlanboardsAPI from "../../api/services/Planboard";
import Alert from "../../components/Generic/Alert";
import { AlertCircle } from "lucide-react";
import Spinner from "../../components/Spinner";

const initialValues: { [key: string]: string | {} } = {
  domain: { id: "", name: "" },
  subdomain: { id: "", name: "" },
  geography: "",
  scope: "",
  department: {},
  role: {},
};

const validationSchema = Yup.object().shape({
  domain: Yup.object().test(
    "domain-required",
    "Domain is required",
    (value) => {
      return Object.keys(value).length > 0;
    }
  ),
  subdomain: Yup.object().test(
    "subdomain-required",
    "Subdomain is required",
    (value) => {
      return Object.keys(value).length > 0;
    }
  ),
  geography: Yup.string().required("Required"),
  scope: Yup.string().required("Required"),
});

interface PlanboardFormProps {
  onBoardingData: {
    domain: string;
    subdomain: string;
    geography: string;
    scope: string;
    department: string;
    role: string;
    [key: string]: string;
  };
  setOnboardingData: React.Dispatch<React.SetStateAction<{}>>;
}

const PlanboardForm = ({
  onBoardingData,
  setOnboardingData,
}: PlanboardFormProps) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const existingPlanboardData = {
      domain: onBoardingData?.domain,
      subdomain: onBoardingData?.subdomain,
      scope: onBoardingData?.scope,
      geography: onBoardingData?.geography,
      department: onBoardingData?.department,
      role: onBoardingData?.role,
    };

    setOnboardingData((prev) => ({ ...prev, ...values }));

    // Optimization: Conditional API call
    if (shouldCallAPI(values, existingPlanboardData)) {
      const isValidPrompt = await validatePrompt(values);
      if (!isValidPrompt) {
        setLoading(false);
        return;
      } // Stop further execution if validation fails
    }

    setLoading(false);
    navigate(ROUTES.selectDeadline);
  };

  // Optimization: Helper to determine if API call needed
  const shouldCallAPI = (values: any, existingData: any) => {
    return (
      values.domain !== existingData.domain ||
      values.subdomain !== existingData.subdomain ||
      values.scope !== existingData.scope ||
      values.geography !== existingData.geography ||
      values.department !== existingData.department ||
      values.role !== existingData.role
    );
  };

  const validatePrompt = async (values: any) => {
    try {
      const response = await PlanboardsAPI.validatePrompt({
        domainId: values.domain.id,
        departmentId: values.department?.id,
        roleId: values.role?.id,
        subdomainId: values.subdomain.id,
        ...values,
      });
      if (response.data?.data?.status.toLowerCase() == "negative") {
        setError(response.data?.data?.reason ?? "Invalid Prompt");
        return false;
      }
      if (response.data?.data?.status?.toLowerCase() === "positive") {
        if (!response?.data?.data?.planboardId) {
          setError("This prompt is already in the database");
          return false;
        }
        const { planboardId } = response.data.data;
        // console.log({ planboardId });
        sessionStorage.setItem("planboardId", planboardId);
        setError(null);
        return true;
      }
    } catch (error) {
      console.log({ error });
      setError("Cannot Create Prompt");
      return false;
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const getInitialValues = () => {
    const valuesObj: { [key: string]: string | {} } = {};
    Object.keys(initialValues).forEach((key) => {
      valuesObj[key] =
        onBoardingData && onBoardingData[key] != ""
          ? onBoardingData[key]
          : initialValues[key];
    });
    // Ensure domain and subdomain are objects with id and name properties
    valuesObj.domain = { id: "", name: "", ...(valuesObj.domain as object) };
    valuesObj.subdomain = {
      id: "",
      name: "",
      ...(valuesObj.subdomain as object),
    };
    console.log({ valuesObj });
    return { ...valuesObj };
  };

  const renderAlert = () => (
    <Alert
      open={Boolean(error)}
      id="workspace_create_step1_error"
      onClose={() => setError(null)}
      title="Error"
    >
      <div className="mt-3 mb-6 dark:text-white">
        <div className="w-full flex justify-center mb-5">
          <AlertCircle size={40} color="#eeb813" />
        </div>
        <div className="flex justify-center text-xl mb-5 text-center">
          {error}
        </div>
      </div>
    </Alert>
  );

  return (
    <div className="w-full h-full p-5">
      <button
        type="button"
        onClick={handleBack}
        className="inline-flex items-center gap-x-1 text-sm decoration-2 hover:underline font-medium rounded-lg border border-transparent text-gray-500 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:underline focus:text-blue-600 dark:text-neutral-400 dark:hover:text-white dark:focus:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-chevron-left"
        >
          <path d="m15 18-6-6 6-6" />
        </svg>
        back
      </button>
      {/* <!-- Title --> */}
      <div className="mt-10">
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
          Business Details
        </h1>
      </div>
      {/* <!-- End Title --> */}
      {renderAlert()}
      <Formik
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, handleChange, handleBlur }) => (
          <>
            <div className="mt-10">
              <FormComponent
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <div className="flex items-center justify-center py-5">
              <button
                type="submit"
                disabled={loading}
                //@ts-ignore
                onClick={handleSubmit}
                // onClick={() => handleSubmit(values)}
                className="py-2.5 mt-3 px-6 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
              >
                {loading ? (
                  <>
                    <Spinner />
                    Validating...
                  </>
                ) : (
                  "Next"
                )}
              </button>
            </div>
          </>
        )}
      </Formik>
      {/* <BackButton handleBack={handleBack} /> */}
    </div>
  );
};

export default PlanboardForm;
