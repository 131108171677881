import { SUBTASKS_URLS } from "../../constants";
import { postAuth, postFormDataAuth } from "../client";

interface CreateProps {
  nodeId: string;
  planboardId: string;
  actionItemId: string;
  title: string;
  description?: string;
  parentId?: string;
  endDate?: string;
}

interface UpdateProps {
  taskId: string;
  title?: string;
  progress?: number;
  description?: string;
  priority?: "low" | "medium" | "high" | "critical";
  endDate?: string; // Format: dd-MM-yyyy
  assignedTo?: number;
}

const SubTasksAPI = {
  create: ({
    nodeId,
    planboardId,
    title,
    description,
    parentId,
    endDate,
    actionItemId,
  }: CreateProps) => {
    const requestData: CreateProps = {
      nodeId,
      planboardId,
      title,
      actionItemId,
    };
    if (description !== undefined) requestData.description = description;
    if (parentId !== undefined) requestData.parentId = parentId;
    if (endDate !== undefined) requestData.endDate = endDate;
    return postAuth(SUBTASKS_URLS.add, requestData);
  },
  update: ({
    taskId,
    title,
    description,
    priority,
    endDate,
    progress,
    assignedTo,
  }: UpdateProps) => {
    const requestData: UpdateProps = {
      taskId,
    };
    if (progress !== undefined) requestData["progress"] = progress;
    if (title !== undefined) requestData.title = title;
    if (description !== undefined) requestData.description = description;
    if (priority !== undefined) requestData.priority = priority;
    if (endDate !== undefined) requestData.endDate = endDate;
    if (assignedTo !== undefined) requestData.assignedTo = assignedTo;
    return postAuth(SUBTASKS_URLS.update, requestData);
  },
  get: (actionItemId: string) => {
    return postAuth(SUBTASKS_URLS.get, { actionItemId });
  },
  delete: (taskId: string) => {
    console.log({ taskId });
    return postAuth(SUBTASKS_URLS.delete, { taskId });
  },
  addAttachment: (taskId: string, attachments: File[]) =>
    postFormDataAuth(SUBTASKS_URLS.addAttachment, { taskId }, attachments),
  removeAttachment: ({
    taskId,
    attachmentId,
  }: {
    taskId: string;
    attachmentId: string;
  }) => {
    return postAuth(SUBTASKS_URLS.removeAttachment, { taskId, attachmentId });
  },
  addAIGenerated: (actionItemId: string) => {
    return postAuth(SUBTASKS_URLS.aiGenerativeDeliverables, { actionItemId });
  },
};
export default SubTasksAPI;
