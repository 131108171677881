import React, { useEffect, useState } from "react";
import Modal from "../../Generic/Modal";
import IconButton from "../../Generic/IconButton";
import { UserPlus, X } from "lucide-react";
import UserAutoComplete from "./UserAutoComplete";
import { useDebounce } from "@uidotdev/usehooks";
import UsersAPI from "../../../api/services/User";
import SelectedUser from "./SelectedUser";
import { Member } from "../Member";
import TeamsAPI from "../../../api/services/Teams";
import PlanboardsAPI from "../../../api/services/Planboard";

interface InviteMembersModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (member: Member) => void;
  // onSubmit: (member: Member) => Promise<void>;
}

const InviteMembersModal = ({
  open,
  onClose,
  onSubmit,
}: InviteMembersModalProps) => {
  const [selected, setSelected] = useState<Member>();
  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState<Member[]>([]);
  const debouncedUserSearch = useDebounce(input, 300);

  useEffect(() => {
    let isCurrent = true;

    const getUsers = async () => {
      if (!debouncedUserSearch) return;
      try {
        const response = await TeamsAPI.searchMembers(debouncedUserSearch)
        if (response.data.error) alert("error fetching user data");

        setSuggestions(response.data.data);
      } catch (error) {
        console.log(error)
      }
    };

    if (isCurrent) {
      getUsers();
    }

    return () => {
      isCurrent = false; // Cleanup function for unmounted component
    };
  }, [debouncedUserSearch, input]);

  const handleCancelSelected = () => {
    setSelected(undefined);
    setInput("");
    setSuggestions([])
  };

  const handleModalClose = () => {
    onClose();
    setInput("");
    setSuggestions([])
    setSelected(undefined);
  };

  const handleSubmit = () => {
    if (!selected) return;
    console.log(selected,"selected")
    onSubmit(selected);
    handleModalClose();
  };

  return (
    <>
      <Modal id="" open={open} onClose={handleModalClose}>
        <div className="p-5 rounded-xl dark:text-white">
          <div className="flex justify-end" onClick={handleModalClose}>
            <IconButton icon={<X size={16} color="#969696" />} />
          </div>
          <div className="">
            <div className="flex text-2xl flex-col justify-center items-center mb-5 gap-y-3">
              <UserPlus size={40} color="#979797" />
              Invite
            </div>
            {selected ? (
              <SelectedUser
                setSelected={setSelected}
                handleCancelSelected={handleCancelSelected}
                selected={selected}
              />
            ) : (
              <UserAutoComplete
                setSelected={setSelected}
                input={input}
                setInput={setInput}
                suggestions={suggestions}
              />
            )}
            {selected && <button
              type="button"
              disabled={Boolean(!selected)}
              onClick={handleSubmit}
              className="w-full mt-5 pt-1.5 pb-2 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
              Add Member
            </button>}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InviteMembersModal;
