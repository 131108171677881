import {
  Layers3,
  Maximize2,
  MonitorDot,
  Palette,
  Trash2,
  UserCheck,
} from "lucide-react";
import React, { useCallback, useState } from "react";
import { useReactFlow } from "reactflow";
import { useCanvas } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  openCreateEventDialog,
  openAssignActionItemDialog,
} from "../../redux/slice/ui/dialogSlice";
import CreateEventDialog from "../Events/CreateEventDialog";
import { ROLE_TYPES } from "../../types/types/PlanboardRoles";
export default function ContextMenu({
  id,
  top,
  left,
  right,
  bottom,
  setColor,
  handleClose,
  ...props
}) {
  const { getNode, setNodes, addNodes, setEdges, getNodes } = useReactFlow();

  const dispatch = useDispatch();
  console.log({ props });

  const planboard = useSelector((state) => state.planboard.value);
  const user = useSelector((state) => state.user.value);

  const { deleteMiddleNode } = useCanvas();

  const duplicateNode = useCallback(() => {
    const node = getNode(id);
    const position = {
      x: node.position.x + 250,
      y: node.position.y + 250,
    };
    addNodes({
      ...node,
      id: `${node.id}-copy`,
      position,
      data: { ...node.data, label: node.data.label + "-copy" },
    });
  }, [id, getNode, addNodes]);

  const deleteNode = useCallback(async () => {
    // add confirm box before delete
    // await NodesAPI.delete(id);
    setNodes((nodes) =>
      nodes.filter((node) => node.type !== "skeleton" && node.id !== id)
    );
    setEdges((edges) => edges.filter((edge) => edge.source !== id));
    const node = getNode(id);
    deleteMiddleNode([node]);
    return handleClose();
  }, [id, setNodes, setEdges]);

  const openEventDialog = () => {
    // dispatch(openCreateEventDialog("create:" + id));
    setOpen(true);
    // handleClose();
  };
  const openAssignDialog = () => {
    dispatch(openAssignActionItemDialog());
    handleClose();
  };

  const openColorDialog = () => {
    // dispatch(openAssignActionItemDialog());
    // console.log("clicked")
    setColor(true);
    handleClose();
  };

  const [open, setOpen] = useState(false);

  const handleCreateEventDialogClose = () => {
    setOpen(false);
    handleClose();
  };
  function isVisibleForUser() {
    return (
      planboard.userRole === ROLE_TYPES.CREATOR ||
      planboard.userRole === ROLE_TYPES.COCREATOR ||
      props.selectedNode?.data?.assignedTo?.id === user.id
    );
  }

  const options = [
    { title: "Open Details", icon: <Maximize2 size={20} />, visible: true },
    {
      title: "Delete",
      icon: <Trash2 size={20} />,
      function: deleteNode,
      visible:
        planboard.userRole === ROLE_TYPES.CREATOR ||
        planboard.userRole === ROLE_TYPES.COCREATOR,
    },
    {
      title: "Duplicate",
      icon: <Layers3 size={20} />,
      function: duplicateNode,
      visible:
        planboard.userRole === ROLE_TYPES.CREATOR ||
        planboard.userRole === ROLE_TYPES.COCREATOR,
    },
    {
      title: "Assign to",
      icon: <UserCheck size={20} />,
      function: openAssignDialog,
      visible:
        planboard.userRole === ROLE_TYPES.CREATOR ||
        planboard.userRole === ROLE_TYPES.COCREATOR,
    },
    {
      title: "Schedule Meeting",
      icon: <MonitorDot size={20} />,
      function: openEventDialog,
      visible: isVisibleForUser(),
    },
    {
      title: "Change Color",
      icon: <Palette size={20} />,
      function: openColorDialog,
      visible:
        planboard.userRole === ROLE_TYPES.CREATOR ||
        planboard.userRole === ROLE_TYPES.COCREATOR,
    },
  ];

  return (
    <div
      style={{ top, left, right, bottom }}
      className="absolute hs-dropdown-menu transition-[opacity,margin] hover:shadow-lg hover:shadow-gray-500 duration hs-dropdown-open:opacity-100 min-w-[6rem] max-w-[15rem] bg-white shadow-md rounded-lg p-2 mt-2 dark:border dark:border-gray-700 z-10"
      {...props}
    >
      {options
        .filter((option) => option.visible)
        .map((option) => (
          <a
            onClick={option.function ? option.function : undefined}
            className=" inline-flex items-center cursor-pointer gap-x-5 w-full p-2 text-gray-800 rounded-lg hover:bg-gray-100 focus:ring-2 focus:ring-primary-600 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          >
            {option.icon}
            <div className="grow">
              <span className="block font-semibold mb-1 text-gray-800">
                {option.title}
              </span>
            </div>
          </a>
        ))}
      <CreateEventDialog
        action="create"
        eventId={id}
        open={open}
        handleClose={handleCreateEventDialogClose}
      />
    </div>
  );
}
