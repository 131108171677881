import React, { ReactNode } from "react";

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  id: string;
  onClose?: () => void; // Optional onClose handler
  width?: string;
}

const Modal = ({
  children,
  open,
  onClose,
  id = "modal",
  width,
}: ModalProps) => {
  return (
    <>
      <div
        id={id}
        className={
          "fixed overflow-x-hidden overflow-y-auto inset-0 transform ease-in-out w-full h-full top-0 start-0 z-[100]  disabled:pointer-events-none backdrop-blur-0 bg-opacity-60 bg-gray-600 " +
          (open
            ? " opacity-100 transition-opacity duration-500 translate-x-0"
            : " transition-all duration-500 opacity-0 translate-y-full")
        }
        onClick={onClose ? onClose : undefined}
      >
        <div
          className={`${
            width ? width : ""
          } sm:max-w-xl m-3 mx-auto my-auto fixed inset-0 z-100 transition-all duration-900 delay-900 grid items-center`}
        >
          <div
            className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7] w-full"
            onClick={(e) => e.stopPropagation()} // Prevent bubbling up to close modal
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
export const ModalHeader = ({
  title,
  onClose,
  icon = true,
}: {
  title: string | ReactNode;
  onClose?: () => void;
  icon?: boolean;
}) => {
  return (
    <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
      <h3 className="font-bold text-gray-800 dark:text-white w-full">
        {title}
      </h3>
      {icon && (
        <button
          onClick={onClose ? onClose : undefined}
          type="button"
          className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        >
          <span className="sr-only">Close</span>
          <svg
            className="flex-shrink-0 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
        </button>
      )}
    </div>
  );
};
export const ModalBody = ({ children }: { children: ReactNode }) => {
  return (
    <div className="p-2 m-2 border rounded-md overflow-y-auto">{children}</div>
  );
};
const ModalActions = ({ children }: { children: ReactNode }) => {
  //use this for reference and add this below ModalBody
  return (
    <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
      <button
        type="button"
        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      >
        Close
      </button>
      <button
        type="button"
        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      >
        Save changes
      </button>
    </div>
  );
};
export default Modal;
