import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { LinkedinIcon } from 'lucide-react';
import withAuth from '../../containers/AuthenticationContainer';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Email from '../../components/Inputs/Email';
import LogoBlack from '../../assets/images/planbow_logo_black.svg';
import LogoWhite from '../../assets/images/planbow_logo_white.svg';

const initialValues = {
    email: '',
};
const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter valid email').required('Required'),
});

function RegisterEmail({
    handleGoogleSignIn,
    handleLinkedInSignIn,
    handleRegisterEmail
}) {
    const navigate = useNavigate();
    const handleSubmit = (values, { setSubmitting }) => {
        handleRegisterEmail(values).then((response) => {
            setSubmitting(false);
            if (response.data.message === 'email sent') {
                navigate(ROUTES.verifyEmail);
            }
        })
    }
    return (
        <div className="relative overflow-hidden">
            <img src={LogoBlack} alt="planbow" className="absolute top-4 left-4 dark:hidden" />
            <img src={LogoWhite} alt="planbow" className="absolute hidden top-4 left-4 dark:block" />
            <div className="h-screen w-1/2 py-12 px-4 sm:px-6 md:max-w-1/2 md:py-20 lg:py-32 md:px-8 dark:bg-gray-900">
                <div className="mx-auto md:pe-8 md:w-4/5 xl:pe-8 xl:w-4/5 flex flex-col justify-center">
                    {/* <!-- Title --> */}
                    <h1 className="text-center text-3xl text-gray-800 font-bold md:text-4xl md:leading-tight lg:text-5xl lg:leading-tight dark:text-gray-200">
                        Sign up
                    </h1>
                    <p className="mt-3 text-base text-gray-500 text-center">
                        Already have an account?
                        <Link className="text-primary-400 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" to={ROUTES.login}>
                            &nbsp;Sign in here
                        </Link>
                    </p>
                    {/* <!-- End Title --> */}

                    <div className="mt-8 grid">
                        <div className="flex flex-col justify-between">
                            <button type="button" className="mb-4 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                onClick={handleGoogleSignIn}
                            >
                                <svg className="w-4 h-auto" width="46" height="47" viewBox="0 0 46 47" fill="none">
                                    <path d="M46 24.0287C46 22.09 45.8533 20.68 45.5013 19.2112H23.4694V27.9356H36.4069C36.1429 30.1094 34.7347 33.37 31.5957 35.5731L31.5663 35.8669L38.5191 41.2719L38.9885 41.3306C43.4477 37.2181 46 31.1669 46 24.0287Z" fill="#4285F4" />
                                    <path d="M23.4694 47C29.8061 47 35.1161 44.9144 39.0179 41.3012L31.625 35.5437C29.6301 36.9244 26.9898 37.8937 23.4987 37.8937C17.2793 37.8937 12.0281 33.7812 10.1505 28.1412L9.88649 28.1706L2.61097 33.7812L2.52296 34.0456C6.36608 41.7125 14.287 47 23.4694 47Z" fill="#34A853" />
                                    <path d="M10.1212 28.1413C9.62245 26.6725 9.32908 25.1156 9.32908 23.5C9.32908 21.8844 9.62245 20.3275 10.0918 18.8588V18.5356L2.75765 12.8369L2.52296 12.9544C0.909439 16.1269 0 19.7106 0 23.5C0 27.2894 0.909439 30.8731 2.49362 34.0456L10.1212 28.1413Z" fill="#FBBC05" />
                                    <path d="M23.4694 9.07688C27.8699 9.07688 30.8622 10.9863 32.5344 12.5725L39.1645 6.11C35.0867 2.32063 29.8061 0 23.4694 0C14.287 0 6.36607 5.2875 2.49362 12.9544L10.0918 18.8588C11.9987 13.1894 17.25 9.07688 23.4694 9.07688Z" fill="#EB4335" />
                                </svg>
                                Sign up with Google
                            </button>
                            <button type="button" className="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                onClick={handleLinkedInSignIn}
                            >
                                <LinkedinIcon />
                                Sign up with LinkedIn
                            </button>
                        </div>
                    </div>

                    <div className="py-6 flex items-center text-sm text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:me-6 after:flex-[1_1_0%] after:border-t after:ms-6 dark:text-gray-500 dark:before:border-gray-600 dark:after:border-gray-600">Or</div>

                    {/* <!-- Form --> */}
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {
                            (props) => {
                                const {
                                    values,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className="grid gap-y-4">
                                            {/* <!-- Form Group --> */}
                                            <Field
                                                label="Email address"
                                                id="email"
                                                name="email"
                                                component={Email}
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {/* <!-- End Form Group --> */}
                                            {/* <!-- Checkbox --> */}
                                            <div className="flex items-center">
                                                <div className="flex">
                                                    <input id="remember-me" name="remember-me" type="checkbox" className="shrink-0 mt-0.5 border-gray-200 rounded text-primary-400 pointer-events-none focus:ring-primary-600 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-primary-600 dark:checked:border-primary-400Active dark:focus:ring-offset-gray-800" />
                                                </div>
                                                <div className="ms-3">
                                                    <label htmlFor="remember-me" className="text-sm dark:text-white">I accept the <a className="text-primary-400 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">Terms and Conditions</a></label>
                                                </div>
                                            </div>
                                            {/* <!-- End Checkbox --> */}

                                            <button disabled={isSubmitting} type="submit" className="w-full py-4 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">Sign up</button>
                                        </div>
                                    </form>
                                )
                            }}
                    </Formik>
                    {/* <!-- End Form --> */}
                </div>
            </div>
            <div className="hidden md:block md:absolute md:top-0 md:start-1/2 md:end-0 h-full bg-[url('../public/icons/auth_bg.svg')] bg-no-repeat bg-center bg-cover"></div>
            {/* <!-- End Col --> */}
        </div>
    )
}

export default withAuth(RegisterEmail);