import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import ButtonGroup from "./ButtonGroup";
import BackButton from "./BackButton";
interface SelectSpecializationProps {
  setOnboardingData: React.Dispatch<React.SetStateAction<{}>>;
}

const options = [
  {
    title: "industry",
    icon: "",
  },
  {
    title: "department",
    icon: "",
  },
  {
    title: "role",
    icon: "",
  },
];

const SelectSpecialization = ({
  setOnboardingData,
}: SelectSpecializationProps) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleOptionChange = (option: any) => {
    setSelectedOption(option);
    setTimeout(() => handleNext(option), 300);
  };

  const handleNext = (selectedOption: string) => {
    setOnboardingData((prev) => ({ ...prev, proceedOptions: selectedOption }));
    if (selectedOption === options[0].title) {
      navigate(ROUTES.selectIndustry);
    } else if (selectedOption === options[1].title) {
      navigate(ROUTES.selectDepartment);
    } else if (selectedOption === options[2].title) {
      navigate(ROUTES.whatIsYourRole);
    } else alert("select");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div>
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
          Select the category of the problem you want to address
        </h1>
      </div>

      <form>
        <div className="grid grid-cols-2 gap-3 mb-5 max-h-[65vh] overflow-auto">
          <ButtonGroup
            options={options}
            handleChange={handleOptionChange}
            selectedOption={selectedOption}
            size="large"
          />
        </div>
        <BackButton handleBack={handleBack} />
      </form>
    </>
  );
};

export default SelectSpecialization;
