import React from "react";
import { useSelector } from "react-redux";

export default function LoadingProgress() {
  const loading = useSelector((state) => state.loading);
  return (
    <>
      {loading?.value ? (
        <div class="flex justify-center items-center h-screen flex-col dark:bg-gray-700 dark:text-white">
          <div class="relative inline-flex">
            <div class="w-8 h-8 bg-primary-400 rounded-full"></div>
            <div class="w-8 h-8 bg-primary-400 rounded-full absolute top-0 left-0 animate-ping"></div>
            <div class="w-8 h-8 bg-primary-400 rounded-full absolute top-0 left-0 animate-pulse"></div>
          </div>
          <div className="text-3xl mt-8">Loading...</div>
        </div>
      ) : null}
    </>
  );
}
