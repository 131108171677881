import { Field, Form } from "formik";
import DomainSelect from "../../components/CreatePlanboardSteps/DomainSelect";
import Checkbox from "../../components/Inputs/Checkbox";
import SubdomainSelect from "../../components/CreatePlanboardSteps/SubdomainSelect";
import Input from "../../components/Inputs/Input";
import DepartmentSelect from "./DepartmentSelect";
import RoleSelect from "./RoleSelect";
import { useEffect } from "react";

interface FormComponentProps {
  values: any;
  handleChange: (event: React.ChangeEvent<any>) => void;
  handleBlur: (event: React.FocusEvent<any>) => void;
  touched?: any;
}

const FormComponent = ({
  values,
  handleChange,
  handleBlur,
  touched,
}: FormComponentProps) => {
  useEffect(() => {
    console.log({ touched });
  }, [touched]);
  return (
    <Form>
      <div className="divide-y divide-gray-200 dark:divide-gray-700">
        <div className="grid grid-cols-[20%_76%] gap-y-8 gap-x-8 dark:focus:bg-slate-900">
          <>
            <label
              htmlFor="domain-select"
              className="flex items-center font-medium mb-2 dark:text-white"
            >
              Industry
            </label>
            <div>
              <div className="flex">
                <div className="w-5/12">
                  <Field name="domain" component={DomainSelect} />
                </div>
                {values?.domain?.id && (
                  <div className="flex items-center text-green-600 ms-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#05cb0f"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-circle-check-big"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                      <path d="m9 11 3 3L22 4" />
                    </svg>
                  </div>
                )}
              </div>
              <p className="text-xs mt-1 text-gray-500 dark:text-neutral-400">
                Select an appropriate industry
              </p>
            </div>
          </>
          <div className="col-span-2 border-t border-gray-200 dark:border-neutral-700" />
          <>
            <label
              htmlFor="domain-select"
              className="flex items-center font-medium mb-2 dark:text-white"
            >
              Sub-domain
            </label>
            <div>
              <div className="flex">
                <div className="w-5/12">
                  <Field name="subdomain" component={SubdomainSelect} />
                </div>
                {values?.subdomain?.id && (
                  <div className="flex items-center text-green-600 ms-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#05cb0f"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-circle-check-big"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                      <path d="m9 11 3 3L22 4" />
                    </svg>
                  </div>
                )}
              </div>
              <p className="text-xs mt-1 text-gray-500 dark:text-neutral-400">
                Select a sub-domain
              </p>
            </div>
          </>
          <div className="col-span-2 border-t border-gray-200 dark:border-neutral-700" />
          <>
            <label
              htmlFor="domain-select"
              className="flex items-center font-medium mb-2 dark:text-white"
            >
              Department &nbsp;
              <span className="italic text-gray-400 text-xs">(optional)</span>
            </label>
            <div>
              <div className="flex">
                <div className="w-5/12">
                  <Field name="department" component={DepartmentSelect} />
                </div>
                {values?.department?.id && (
                  <div className="flex items-center text-green-600 ms-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#05cb0f"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-circle-check-big"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                      <path d="m9 11 3 3L22 4" />
                    </svg>
                  </div>
                )}
              </div>
              <p className="text-xs mt-1  text-gray-500 dark:text-neutral-400">
                Select department
              </p>
            </div>
          </>
          <div className="col-span-2 border-t border-gray-200 dark:border-neutral-700" />
          <>
            <label
              htmlFor="domain-select"
              className="flex items-center font-medium mb-2 dark:text-white"
            >
              Role&nbsp;
              <span className="italic text-gray-400 text-xs">(optional)</span>
            </label>
            <div>
              <div className="flex">
                <div className="w-5/12">
                  <Field name="role" component={RoleSelect} />
                </div>
                {values?.role?.id && (
                  <div className="flex items-center text-green-600 ms-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#05cb0f"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-circle-check-big"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                      <path d="m9 11 3 3L22 4" />
                    </svg>
                  </div>
                )}
              </div>
              <p className="text-xs mt-1 text-gray-500 dark:text-neutral-400">Select role</p>
            </div>
          </>
          <div className="col-span-2 border-t border-gray-200 dark:border-neutral-700" />
          <>
            <label
              htmlFor="domain-select"
              className="flex items-center font-medium mb-2 dark:text-white"
            >
              What is your objective ?
            </label>
            <div>
              <div className="flex">
                <div className="w-5/12">
                  <Field
                    label="What is your objective ?"
                    id="scope"
                    name="scope"
                    component={Input}
                    value={values.scope}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hideLabel={true}
                    placeholder="Development, Marketing, Sales, Growth, R&D"
                  />
                </div>
                {values?.scope && touched?.scope && (
                  <div className="flex items-center text-green-600 ms-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#05cb0f"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-circle-check-big"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                      <path d="m9 11 3 3L22 4" />
                    </svg>
                  </div>
                )}
              </div>
              <p className="text-xs mt-1 text-gray-500 dark:text-neutral-400">
                Explain the purpose or goal associated with your business
              </p>
            </div>
          </>
          <div className="col-span-2 border-t border-gray-200 dark:border-neutral-700" />
          <>
            <label
              htmlFor="domain-select"
              className="flex items-center font-medium mb-2 dark:text-white"
            >
              Geography (Country)
            </label>
            <div>
              <div className="flex">
                <div className="w-5/12">
                  <Field
                    label="Geography (Country)"
                    id="geography"
                    name="geography"
                    component={Input}
                    value={values.geography}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hideLabel={true}
                    placeholder="India, Australia, Japan, or Global"
                  />
                </div>
                {values?.geography && touched?.geography && (
                  <div className="flex items-center text-green-600 ms-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#05cb0f"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-circle-check-big"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                      <path d="m9 11 3 3L22 4" />
                    </svg>
                  </div>
                )}
              </div>
              <p className="text-xs mt-1 text-gray-500 dark:text-neutral-400">
                Enter the geographical location relevant to your business
              </p>
            </div>
          </>
        </div>
      </div>
    </Form>
  );
};

export default FormComponent;
