import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import BackButton from "./BackButton";
import moment from "moment";

interface SelectDeadlineProps {
  setOnboardingData: React.Dispatch<React.SetStateAction<{}>>;
}

const options = ["1 month", "3 months", "6 months", "1 year"];

const SelectDeadline = ({ setOnboardingData }: SelectDeadlineProps) => {
  const navigate = useNavigate();

  const handleSelection = (value: string) => {
    const endDate = calculateDate(value);
    setOnboardingData((prev) => ({ ...prev, endDate, deadline: value }));
    setTimeout(handleNext, 300);
  };

  const handleNext = () => {
    navigate(ROUTES.enterPlanboardName);
    // navigate(ROUTES.enterPlanboardName);
  };
  const handleBack = () => {
    navigate(-1);
  };

  function calculateDate(option: string) {
    const currentDate = moment();
    switch (option) {
      case "1 month":
        return currentDate.clone().add(1, "months").format("DD-MM-YYYY");
      case "3 months":
        return currentDate.clone().add(3, "months").format("DD-MM-YYYY");
      case "6 months":
        return currentDate.clone().add(6, "months").format("DD-MM-YYYY");
      case "1 year":
        return currentDate.clone().add(1, "years").format("DD-MM-YYYY");
      default:
        return "Invalid option";
    }
  }

  return (
    <>
      <div>
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
          Expected time to achieve your goal
        </h1>
      </div>

      <form>
        <div className="flex flex-wrap gap-2 my-5">
          {options.map((option) => (
            <label
              htmlFor={"hs-pro-donpf1" + option}
              className="relative flex cursor-pointer text-sm focus:outline-none"
            >
              <input
                type="radio"
                id={"hs-pro-donpf1" + option}
                name="hs-pro-donpf"
                onChange={() => handleSelection(option)}
                className="peer absolute hidden top-0 start-0 size-full bg-transparent border-gray-200 text-transparent rounded-xl cursor-pointer focus:ring-0 focus:ring-offset-0 after:relative after:-z-[1] after:block after:size-full after:rounded-lg checked:after:bg-blue-50 checked:text-transparent checked:border-blue-600 checked:hover:border-blue-600 checked:focus:border-blue-600 checked:bg-none disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:checked:after:bg-blue-500/10 dark:checked:border-blue-500"
              />
              <span className="peer-checked:text-blue-600 border peer-checked:border-blue-500 peer-checked:bg-blue-50 dark:text-white dark:peer-checked:text-blue-500 py-3 px-4 rounded-xl capitalize">
                {option}
              </span>
            </label>
          ))}
        </div>
        <BackButton handleBack={handleBack} />
      </form>
    </>
  );
};

export default SelectDeadline;
