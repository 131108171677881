import React, { useEffect } from "react";
import HeroSection from "../components/HeroSection";
import withWorkspaces from "../containers/WorkspaceContainer";
import { getWorkspaces } from "../redux/slice/workspaces/workspacesSlice";
import { useDispatch, useSelector } from "react-redux";

const MemoizedHome = React.memo(function Home() {
  const dispatch = useDispatch();

  const organizations = useSelector((state) => state.organizations);

  useEffect(() => {
    dispatch(
      getWorkspaces({
        organizationId: organizations?.value[0]?.organizationId,
        index: 0,
        itemsPerIndex: 100,
      })
    );
  }, [organizations]);
  return (
    <div className="flex">
      <main
        id="content"
        role="main"
        className="w-full h-[95vh] overflow-y-scroll transition-all duration-300 ease-in shadow-2xl"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        <div className="mx-auto shadow-2xl bg-white border-x-gray-200 pt-2 pb-10 px-2 sm:px-1 lg:px-2 xl:border-x dark:bg-gray-800 dark:border-x-gray-700 border-t-2 border-t-primary-400">
          <HeroSection />
        </div>
      </main>
    </div>
  );
});

export default MemoizedHome;
