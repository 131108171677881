import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../constants";
import { userLogin } from "../../redux/slice/login/loginSlice";

const SignInCallback = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { authed, error } = useSelector((state) => state.login);
  const code = searchParams.get("code");

  useEffect(() => {
    const getToken = async () => {
      dispatch(userLogin({ code })).then((result) => {
        if (result.payload.organisation === "404") {
          navigate(ROUTES.createOrganisation);
        }
        if (result.payload.organisation === "not-onboarded") {
          navigate(ROUTES.whatBringsYoutoPlanbow);
        }
        setLoading(false);
      });
    };
    if (code) getToken();
  }, [code]);

  useEffect(() => {
    if (loading) return;
    if (!authed || error) return; // Render Signin error page
    if (localStorage.getItem("invitationDetails")) {
      const prevRoute = localStorage.getItem("prevRoute");
      navigate(prevRoute);
      return;
    }
    const prevRoute = sessionStorage.getItem("prevRoute") ?? ROUTES.dashboard;
    navigate(prevRoute);
  }, [authed, loading]);

  return <div className="dark:bg-slate-900 h-screen w-screen"></div>;
};

export default SignInCallback;
