import {
  AlarmClock,
  Check,
  CheckCircle2,
  CircleDashed,
  MoreVertical,
  ShieldAlert,
  Tally5,
} from "lucide-react";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  Handle,
  getConnectedEdges,
  getIncomers,
  useReactFlow,
} from "reactflow";
import IconButton from "../Generic/IconButton";
import UserDetailsPopover from "../Generic/UserDetailsPopover";
import { getDaysRemaining } from "../../utils/dateTime";
import UserChip from "../Generic/UserChip";
import { CircularProgress, Tooltip } from "@mui/material";
import { useHover } from "@uidotdev/usehooks";
import { grey } from "@mui/material/colors";
import CustomHandle from "./CustomHandle";
import { useSelector } from "react-redux";
import { ROLE_TYPES } from "../../types/types/PlanboardRoles";

const gridItemStyle =
  "text-left dark:text-white rounded-md py-3 px-2 text-xs bg-gray-100 dark:bg-gray-800";

function CustomNode({ id, data, selected }) {
  const planboard = useSelector((state) => state.planboard.value);
  const { getEdges, getNodes } = useReactFlow();
  const [ref, hovering] = useHover();
  const isHandleConnectable = useMemo(() => {
    const edges = getEdges();
    const nodes = getNodes();
    const node = nodes.filter((item) => item.id === id);
    const incomingEdges = getIncomers(node, nodes, edges);
    return incomingEdges.length < 1;
  });
  return (
    <div ref={ref}>
      {/* <NodeToolbar isVisible={true} position="right">
        <button className="px-1 flex items-center justify-center dark:text-white border rounded-full">
          +
        </button>
      </NodeToolbar> */}
      <Handle
        type="target"
        position="left"
        isConnectable={isHandleConnectable}
        style={{
          opacity: hovering ? 100 : 0,
          padding: 5,
          left: -20,
          background: "#ababab",
          zIndex: -10,
          visibility:
            planboard.userRole === ROLE_TYPES.CREATOR ||
            planboard.userRole === ROLE_TYPES.COCREATOR
              ? "visible"
              : "hidden",
        }}
        className="hover:p-3 hover:-left-7"
      />
      <div className="max-w-96 hover:shadow-2xl hover:shadow-gray-500 transition rounded-xl shadow-sm dark:text-gray-200">
        <div
          class="flex flex-col bg-white border border-t-8 shadow-sm rounded-xl dark:bg-slate-900 dark:shadow-slate-700/[.7]"
          style={{ borderColor: data.color ? data.color : "#118DD9" }}
        >
          <div class="p-4 md:p-5">
            <div className="flex">
              {/* <img
                class="inline-block h-[3.875rem] w-[3.875rem] rounded-lg"
                src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                alt="Image Description"
              /> */}
              <div className="ml-3">
                <div className="flex text-gray-800 dark:text-white">
                  <div className="flex grow relative">
                    <h3 class="text-lg font-bold grow">{data.label}</h3>
                    <div className="absolute flex items-center end-0">
                      {data.building ? (
                        <Tooltip title="Building Action Items">
                          <CircularProgress color="success" size={25} />
                        </Tooltip>
                      ) : (
                        <div className="hover:scale-110 transition cursor-pointer delay-200  ">
                          <Tooltip title="In Progress">
                            <CircleDashed
                              size={20}
                              strokeWidth={1}
                              color="#144afc"
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <span id="node-more-icon" onCLick={openMenu}>
                    <IconButton icon={<MoreVertical size={18} />} />
                  </span> */}
                </div>
                <p class="mt-2 text-gray-500 dark:text-gray-400">
                  {data.description ? (
                    data.description
                  ) : (
                    <>
                      Describe, captivate, and enrich your work with a
                      thoughtful description.
                    </>
                  )}
                </p>
              </div>
            </div>
            {data.assignedTo !== null ? (
              <>
                <div class="flex items-center gap-x-3 whitespace-nowrap mt-5">
                  <div
                    class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      class="flex flex-col justify-center rounded-full overflow-hidden bg-primary-200 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
                      style={{ width: "15%" }}
                    ></div>
                  </div>
                </div>
                <div className="flex justify-between items-center mt-4">
                  <div class="text-end">
                    <span class="text-sm items-center flex gap-x-2 text-gray-800 dark:text-white">
                      <AlarmClock size={14} strokeWidth={1} />
                      {getDaysRemaining(new Date(data.endDate))}
                    </span>
                  </div>
                  {/* <div>{data.assignedTo.name}</div> */}
                  <div className="inline-flex flex-nowrap items-center bg-white dark:bg-slate-900 dark:border-gray-700">
                    <img
                      className="me-1.5 inline-block size-6 rounded-full"
                      src={
                        data?.assignedTo?.profilePic ??
                        "https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                      }
                      alt="AV"
                    />
                    <div className="whitespace-nowrap text-sm font-medium text-gray-800 dark:text-white">
                      {data?.assignedTo?.name ?? "Username"}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="italic end-0 mt-2 w-28 text-center text-sm bg-yellow-100 rounded-full p-1 text-black">
                Not Assigned
              </div>
            )}

            <div className="grid grid-cols-3 gap-4 mt-5 opacity-80">
              <div className={gridItemStyle}>
                <div>{data?.actionItems ?? 0}</div>
                Action Items
              </div>
              <div className={gridItemStyle}>
                <div>{data?.subTasks ?? 0}</div>Deliverables
              </div>
              <div className={gridItemStyle}>
                <div>{data?.events ?? 0}</div>Meetings
              </div>
            </div>
          </div>
        </div>
      </div>
      <Handle
        type="source"
        position="right"
        style={{
          opacity: hovering ? 100 : 0,
          padding: 5,
          right: -20,
          background: "#ababab",
          zIndex: -10,
          visibility:
            planboard.userRole === ROLE_TYPES.CREATOR ||
            planboard.userRole === ROLE_TYPES.COCREATOR
              ? "visible"
              : "hidden",
        }}
        className="hover:p-3 hover:-right-7"
      />
    </div>
  );
}

export default memo(CustomNode);
