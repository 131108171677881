import React, { useState } from "react";
import IconButton from "../Generic/IconButton";
import { AlertCircle, Trash2 } from "lucide-react";
import ConfirmBox from "../ConfirmBox";

interface DeleteElementIconProps {
  removeElement: (id: string) => void;
  elementId: string;
}

const DeleteElementIcon = ({
  removeElement,
  elementId,
}: DeleteElementIconProps) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleConfirm = () => removeElement(elementId);
  return (
    <div>
      <IconButton
        aria-label="Add to favorites"
        icon={<Trash2 width={16} height={16} />}
        tooltipTitle="Delete"
        onClick={handleOpen}
        className="hover:text-red-600"
      />
      <ConfirmBox
        id="delete-element-confirm"
        handleConfirm={handleConfirm}
        open={open}
        onClose={handleClose}
        title={<div className="flex justify-center">Delete Element</div>}
      >
        <div className="mt-3 mb-6 dark:text-white">
          <div className="w-full flex justify-center mb-5">
            <AlertCircle size={40} color="#eeb813" />
          </div>
          <div className="flex justify-center text-center text-xl mb-5">
            Are you sure you want to delete this element &nbsp;?
          </div>
          <div className="flex justify-center">
            You will not be able to restore this action !
          </div>
        </div>
      </ConfirmBox>
    </div>
  );
};

export default DeleteElementIcon;
