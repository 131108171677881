import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getTeams } from "../redux/slice/teams";
import { getTeam } from "../redux/slice/team";
import { resetPlanboardState } from "../redux/slice/planboard";
import { fetchRecentPlanboards } from "../redux/slice/recentPlanboards/recentPlanboards";
import Header2 from "../components/Header2";

const withLayout = (WrappedComponent) => {
  return function MasterLayoutContainer(props) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [isWorkspaceExpanded, setIsWorkspaceExpanded] = useState(false);

    useEffect(() => {
      if (!user.loading && user.value.teams) {
        dispatch(getTeams({ teamIds: user.value.teams }));
      }
    }, [user]);

    useEffect(() => {
      if (user.value?.activeTeamId) {
        dispatch(getTeam(user.value.activeTeamId));
        dispatch(fetchRecentPlanboards(user.value.activeTeamId));
      }
    }, [user.value?.activeTeamId]);

    useEffect(() => {
      dispatch(resetPlanboardState());
    }, []);

    return (
      <main className="flex h-screen max-h-screen overflow-y-hidden">
        <Sidebar
          isSidebarCollapsed={isSidebarCollapsed}
          isWorkspaceExpanded={isWorkspaceExpanded}
          setIsWorkspaceExpanded={setIsWorkspaceExpanded}
        />
        <div className="bg-slate-50 dark:bg-slate-900 w-full">
          {/* <Header /> */}
          <Header2
            setIsSidebarCollapsed={setIsSidebarCollapsed}
            isSidebarCollapsed={isSidebarCollapsed}
          />
          <WrappedComponent {...props} />
        </div>
      </main>
    );
  };
};

export default withLayout;
