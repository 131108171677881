import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import OrganizationAPI from "../../api/services/Organisation";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewOrganization,
  addOrganizations,
  getOrganizations,
} from "../../redux/slice/organisations/slice";
import { useToast } from "../../hooks";
import useDissapearingError from "../../hooks/useDissapearingError";
import { CircularProgress } from "@mui/material";
import Users1API from "../../api/services/User1";
import { boolean, number } from "yup";
import { RootState } from "../../redux/store";
// import Sidebar from "./Sidebar";

const CreateOrganization = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { ErrorComponent, error, setError } = useDissapearingError();
  const [orgError, setOrgError] = useState<boolean>(false);
  const [invitationId, setInvitationId] = useState("");

  const organizations = useSelector(
    (state: RootState) => state.organizations.value
  );

  const handleInputChange = (e: any) => {
    setValue(e.target.value);
    if (!!error && e.target.value !== "") setError(null);
  };

  const handleCreateOrganization = async (event: any) => {
    event.preventDefault();
    if (value === "") return setError("Please enter an organization name");
    try {
      setLoading(true);
      const response = await OrganizationAPI.createOrganization({
        name: value,
      });
      setLoading(false);
      if (response.status == 200) {
        dispatch(addNewOrganization(response.data.data));
        navigate(ROUTES.whatBringsYoutoPlanbow);
        return;
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response.status === 409)
        return setError(
          error?.response?.data?.data ??
            "Provided organization name already exists"
        );
      if (error.response.status === 400) {
        toast.error(
          error?.response?.data?.data ??
            "Can't create more than one organization."
        );
        //@ts-ignore
        dispatch(getOrganizations());
        navigate(ROUTES.whatBringsYoutoPlanbow);
        return;
      }
      setError(error.response?.data?.data ?? "Error creating Organisation");
      console.log({ error });
      return;
    }
  };

  const fetch = async () => {
    try {
      const response = await Users1API.orgnaizationBelonging();
      // const response = {
      //   data: {
      //     product: {
      //       name: "planbow",
      //       version: "v1.0"
      //     },
      //     status: {
      //       code: 200,
      //       value: "OK"
      //     },
      //     data: {
      //       invitationAccepted: false,
      //       organization: {
      //         organizationId: "66688c289ac134c21c9d",
      //         organizationName: "Sumit Edited",
      //         onboarded: true
      //       }
      //     },
      //     error: false
      //   }
      // };

      if (response?.data?.status?.code === 200) {
        if (!response.data.data.invitationAccepted) {
          setOrgError(true);
          setInvitationId(response.data.data.inviteId);
        }
        if (response.data.data.invitationAccepted) {
          response.data.data.organization &&
            dispatch(addOrganizations([response.data.data.organization]));
          navigate(ROUTES.dashboard);
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleRoute = () => {
    navigate(ROUTES.invite, { state: { inviteId: invitationId } });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <div className="w-[500px] space-y-10">
        <div>
          <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-slate-200">
            Create an Organization
          </h1>
        </div>

        <form onSubmit={handleCreateOrganization}>
          <div className="space-y-5">
            <div>
              <input
                type="text"
                id="hs-pro-dale"
                className="py-2.5 px-3 block w-full border border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:border-slate-700 dark:text-slate-300 dark:placeholder:text-white/60 dark:focus:ring-slate-600"
                placeholder="your-business-name"
                onChange={handleInputChange}
              />
            </div>

            <button
              type="submit"
              onClick={handleCreateOrganization}
              disabled={loading}
              className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-start bg-blue-600 border border-blue-600 text-white text-sm font-medium rounded-lg shadow-sm align-middle hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-300 dark:focus:ring-blue-500 disabled:bg-blue-500"
            >
              {loading ? (
                <>
                  <CircularProgress color="primary" size={20} /> Creating...
                </>
              ) : (
                <>Create Organisation</>
              )}
            </button>
            <div className="">{ErrorComponent()}</div>
          </div>
        </form>
      </div>

      {orgError && (
        <div
          id="hs-pro-chhdl"
          className="hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto [--close-when-click-inside:true] pointer-events-none open"
          style={{
            backgroundColor: "#00000040",
          }}
        >
          <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-md sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center">
            <div className="relative w-full max-h-full overflow-hidden flex flex-col bg-white rounded-xl pointer-events-auto shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-slate-800">
              <div className="p-4">
                <h3 className="text-lg font-medium text-gray-800 dark:text-slate-200">
                  Invitation!
                </h3>
                <p className="mt-1 text-sm text-gray-600 dark:text-slate-400">
                  You have been invited to Planbow, please confirm your
                  acceptance to proceed.
                </p>

                <div className="mt-4 flex justify-end gap-x-3">
                  <button
                    type="button"
                    onClick={handleRoute}
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-gray-200 bg-gray-100 text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-slate-800 dark:border-slate-700 dark:text-slate-300 dark:hover:bg-slate-700 dark:focus:bg-slate-700"
                    data-hs-overlay="#hs-pro-chhdl"
                  >
                    Accepted
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateOrganization;
