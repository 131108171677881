import { Pencil, RotateCw, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useReactFlow } from "reactflow";
import { useToast } from "../../hooks";
import IconButton from "../Generic/IconButton2";
import NodesAPI from "../../api/services/Nodes";
import { useParams } from "react-router-dom";

interface Node<T> {
  id: string;
  data: T;
  position: { x: number; y: number };
}
const ComponentTitle = ({
  nodeId,
  onClose,
  nodes,
}: {
  nodeId: string;
  onClose: () => void;
  nodes: Node<any>[];
}) => {
  const { setNodes } = useReactFlow();
  const toast = useToast();

  const [hover, setHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newLabel, setNewLabel] = useState(
    nodes.find((node) => node.id === nodeId)?.data.label
  );
  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState("");

  function renameNode(): Node<any>[] | null {
    // Find the node to rename
    const nodeToRenameIndex = nodes.findIndex((node) => node.id === nodeId);

    if (nodeToRenameIndex === -1) {
      return null; // Node not found
    }

    // Check if the new label is unique
    const duplicateLabelIndex = nodes.findIndex(
      (node, index) =>
        node.data.label === newLabel && index !== nodeToRenameIndex
    );

    if (duplicateLabelIndex !== -1) {
      return null; // Label already exists
    }

    // Update the label and return the new list of nodes
    nodes[nodeToRenameIndex].data.label = newLabel;
    const existingPosition = nodes[nodeToRenameIndex].position;
    nodes[nodeToRenameIndex].position = existingPosition;
    return nodes;
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Validate the input and update error state
    const duplicateLabelIndex = nodes.findIndex(
      (node, index) =>
        node.data.label === inputValue &&
        index !== nodes.findIndex((n) => n.id === nodeId)
    );

    if (duplicateLabelIndex !== -1) {
      setErrorMessage("Component Name already exists.");
    } else {
      setErrorMessage("");
    }

    setNewLabel(inputValue);
  };

  // const handleRename = () => {
  //   const renamedNodes = renameNode();

  //   if (renamedNodes) {
  //     // Update the state with the renamed nodes (or dispatch an action if using Redux)
  //     updateTitle();
  //     setNodes(renamedNodes); // Assuming a `setNodes` function exists to update state
  //     toast.success("Component Name renamed to " + newLabel);
  //     setIsEditing(false); // Exit edit mode after successful rename
  //   } else {
  //     setErrorMessage("Component Name already exists.");
  //   }
  // };

  const updateTitle = async () => {
    if (!id) return;
    const renamedNodes = renameNode();
    const oldNodes = nodes;
    if (!renamedNodes) return setErrorMessage("Component Name already exists.");
    setNodes(renamedNodes);
    setIsEditing(false);
    try {
      const response = await NodesAPI.updateNode(nodeId, id, {
        title: newLabel,
      });
      if (response.status === 200)
        return toast.success("Component Name renamed to " + newLabel);
    } catch (error) {
      setNodes(oldNodes);
      toast.error("Failed to update node.");
    }
  };

  const toggleEditMode = () => {
    setHover(false);
    setIsEditing(!isEditing);
    setNewLabel(nodes.find((node) => node.id === nodeId)?.data.label);
    setErrorMessage(""); // Clear error message when entering edit mode
  };

  useEffect(() => {
    setIsEditing(false);
  }, [onClose]);

  return (
    <div>
      {isEditing ? (
        <form onSubmit={updateTitle}>
          <div className="flex items-center">
            <input
              type="text"
              value={newLabel}
              onChange={handleInputChange}
              autoFocus
              className="border-gray-500 border-b outline-none focus:border-blue-500 mr-2 text-black bg-gray-100 px-2 rounded-md dark:bg-secondary-500 dark:text-white"
            />
            <IconButton
              type="submit"
              icon={<RotateCw size={16} strokeWidth={1.5} />}
              tooltipTitle="Update"
              tooltipPlacement="left"
              disabled={Boolean(errorMessage)}
            />

            <IconButton
              icon={<X size={16} strokeWidth={1.5} />}
              tooltipTitle="Cancel"
              tooltipPlacement="right"
              onClick={toggleEditMode}
            />
            {errorMessage && (
              <div className="text-xs text-red-500 ml-5">{errorMessage}</div>
            )}
          </div>
        </form>
      ) : (
        <div
          className="flex items-center pr-5"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {newLabel}
          {hover && (
            <IconButton
              icon={<Pencil size={16} strokeWidth={1.5} />}
              tooltipTitle="Edit"
              tooltipPlacement="right"
              onClick={toggleEditMode}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ComponentTitle;
