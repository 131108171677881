import { URLS } from "../../constants/index.jsx";
import apiClient, { getAuth, postAuth } from "../client";

const createURL =
  process.env.REACT_APP_GATEWAY_URL + "/app/global/create-organization";

const getURL =
  process.env.REACT_APP_GATEWAY_URL + "/app/global/get-organizations";

const OrganizationAPI = {
  createOrganization: ({ name }: { name: string }) => {
    return postAuth(createURL, {
      organizationName: name,
    });
  },
  getOrganisations: () => {
    return postAuth(getURL, {});
  },
};

export default OrganizationAPI;
