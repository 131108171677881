import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "./authActions";

// interface UserInfo {
//   email: string;
//   id: number;
//   name: string;
//   roles: string[];
// }

// initialize userToken from local storage
// const userToken = localStorage.getItem("userToken")
//   ? localStorage.getItem("userToken")
//   : null;

// interface AuthState {
//   loading: boolean;
//   userInfo: UserInfo | null; // Adjust the type based on your data
//   userToken: string | null;
//   error: any; // Adjust the type based on your error structure
//   success: boolean;
// }

const initialState = {
  loading: false,
  value: {},
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateAuth: (state, action) => {
      state.value = action.payload;
    },
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    // Key change: builder callback
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
        state.success = true;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { updateAuth, logout } = authSlice.actions;
export default authSlice.reducer;
