
import React, { useState } from "react";
import PlanboardsList from "./PlanboardsList";
import { ChevronRight, Pin, Plus } from "lucide-react";
import IconButton from "../Generic/IconButton";
import WorkspaceAccordianMenu from "./WorkspaceAccordianMenu";
import { useDispatch, useSelector } from "react-redux";
import EditLabel from "../Generic/EditLabel";
import { useToast, useWorkspace } from "../../hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../constants";
import BG from "../../assets/images/bg2.svg";

export default function WorkspaceAccordion({ workspace, planboards }) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [open, setOpen] = useState(true);
  const [editTitle, setEditTitle] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const user = useSelector((state) => state.user.value.email);
  const organizations = useSelector((state) => state.organizations.value[0].owner.email);


  const {
    updateDescription,
    updateName,
    handleDelete,
    togglePinned,
    renderDeleteConfirmBox,
    renderAlert,
  } = useWorkspace();

  const handleEditDescription = () => {
    setOpen(true);
    setEditDescription(true);
  };

  const handleToggleClick = () => {
    togglePinned(workspace.id, workspace.name, workspace.pinned);
  };

  const handleDeleteClick = () => {
    if (workspace.name === "My Workspace") setOpenAlert(true);
    else setOpenConfirm(true);
  };

  const handleDeleteConfirm = async () => {
    setOpenConfirm(false);
    handleDelete(workspace.id).then(() => {
      toast.info("Workspace deleted successfully");
    });
  };
  const handleDeleteCancel = () => {
    setOpenAlert(false);
    setOpenConfirm(false);
  };

  const handleDeleteUpdate = () => {
    handleDelete(workspace.id);
  };

  const handleDescriptionUpdate = (value) => {
    updateDescription(value, workspace.id);
  };
  const handleTitleUpdate = (value) => {
    updateName(value, workspace.id);
  };

  const handleCreatePlanboardNavigate = () => {
    navigate(`${ROUTES.createPlanboard}?workspace=${workspace.id}`, {
      state: { from: location },
    });
  };

  return (
    <div className="flex w-full items-center">
      <div className="hs-accordion-group w-full my-4 pr-8 ">
        <div
          className={`hs-accordion border border-gray-100 border-l-primary-400 border-l-1 rounded hover:shadow-xl transition shadow-md flex flex-col dark:bg-slate-900 dark:border-neutral-700`}
          id="hs-basic-with-title-and-arrow-stretched-heading-one"
        >
          <div
            className={`relative pt-5 px-5 ${open ? "" : "pb-5"
              } transition-all duration-300`}
          >
            <div className="dark:text-white flex items-start gap-x-4">
              <div className="h-full">
                <IconButton
                  icon={
                    <ChevronRight
                      color="#c0c0c0"
                      size={20}
                      className="hover:stroke-black dark:hover:stroke-white dark:stroke-gray-500"
                    />
                  }
                  tooltipTitle="Toggle"
                  onClick={() => setOpen((prev) => !prev)}
                  className={`transition-transform transform ${open ? "rotate-90" : ""
                    }`}
                />
              </div>
              <div className="grow">
                <div className="text-xl leading-8">
                  {workspace.name === "My Workspace" ? (
                    workspace.name
                  ) : (
                    <EditLabel
                      edit={editTitle}
                      label={workspace.name}
                      setEdit={setEditTitle}
                      updateLabel={handleTitleUpdate}
                    />
                  )}
                </div>
                {(open && workspace.description) || editDescription ? (
                  <div className="text-xs leading-8">
                    <EditLabel
                      edit={editDescription}
                      label={workspace.description}
                      setEdit={setEditDescription}
                      updateLabel={handleDescriptionUpdate}
                      iconSize={12}
                    />
                  </div>
                ) : null}
              </div>
              <div
                className={`py-1 px-5 flex gap-x-2 text-xs rounded-md cursor-pointer text-white bg-primary-400 hover:bg-primary-300 ${workspace.planBoardCount ? "visible" : "invisible"
                  }`}
                onClick={handleCreatePlanboardNavigate}
              >
                <Plus size={16} strokeWidth={2} /> Create planboard
              </div>
              <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-white/10 dark:text-white">
                Planboards:
                <span className="inline-flex items-center px-1 rounded-full text-xs font-medium bg-primary-400 text-white">
                  {workspace.planBoardCount}
                </span>
              </span>
              <IconButton
                icon={
                  <Pin
                    color="#c0c0c0"
                    size={20}
                    className={` ${workspace.pinned ? "stroke-blue-600" : ""}`}
                  />
                }
                tooltipTitle={workspace.pinned ? "Unpin" : "Pin"}
                onClick={handleToggleClick}
              />
              {user === organizations && (<WorkspaceAccordianMenu
                setEditTitle={setEditTitle}
                handleEditDescription={handleEditDescription}
                handleDelete={handleDeleteClick}
              />)}

            </div>
            {renderDeleteConfirmBox({
              handleConfirm: handleDeleteConfirm,
              open: openConfirm,
              title: (
                <div className="flex justify-center">
                  Delete {workspace.name}
                </div>
              ),
              onClose: handleDeleteCancel,
              id: "delete-team-confirm-1",
            })}
            {renderAlert({
              onClose: handleDeleteCancel,
              id: "team-delete-alert",
              open: openAlert,
              title: "Alert - Delete Team",
            })}
          </div>
          <div
            className={`${open
              ? `relative max-h-[500px] mt-5 h-full ${planboards?.length !== 0 ? "overflow-y-scroll" : ""
              }`
              : "max-h-0 overflow-hidden"
              } ${open ? "visible" : "invisible"
              } origin-top transition-all ease-in-out duration-300`}
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}

          >
            {/* {planboards?.length === 0 && ( */}
            <div
              className={`absolute top-0 left-1/2 opacity-5 z-10 w-full h-full transform -translate-x-1/2 overflow-hidden rounded-lg object-fit ${open ? "" : "hidden h-0"
                }`}
            >
              {/* <img
                  className="h-[200px]"
                  src={BgEmptyWorkspace}
                  alt="background_workspace"
                /> */}
              <img className="" src={BG} alt="background_workspace" />
            </div>
            {/* // )} */}

            <div className="relative z-20 p-5">
              <PlanboardsList planboards={planboards} workspace={workspace} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
