import React from "react";
import Skeleton from "../Preline/Skeleton";

const widths = [
  ["w-4/12", "w-4/12", "w-2/12", "w-2/12"],
  ["w-3/12", "w-4/12", "w-2/12", "w-3/12"],
  ["w-4/12", "w-2/12", "w-3/12", "w-3/12"],
  ["w-2/12", "w-3/12", "w-3/12", "w-4/12"],
];

const DataGridSkeleton = ({ index = 0 }: { index?: 0 | 1 | 2 | 3 }) => {
  let columnWidths = widths[0];
  if (index >= 0 && index < 4) columnWidths = widths[index];
  return (
    <div className="height-10 animate-pulse space-y-2 px-5 py-5 border border-white">
      <div className="flex flex-row space-x-1 w-full ">
        {columnWidths.map((width, index) => (
          <Skeleton
            animation="pulse"
            variant="rectangle"
            key={index}
            width={width}
            height="h-10"
          />
        ))}
      </div>

      {Array.from({ length: 5 }).map((_, index) => (
        <div className="flex flex-row space-x-1 w-full " key={index}>
          {columnWidths.map((width, index) => (
            <Skeleton
              animation="pulse"
              variant="rounded"
              key={index}
              width={width}
              height="h-5"
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default DataGridSkeleton;
