import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Input from "../components/Inputs/Input";
import Button from "../components/Button";
import OrganizationAPI from "../api/services/Organisation";
import { Plus } from "lucide-react";
import { updateUser } from "../redux/slice/user/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/index.jsx";
import { useToast } from "../hooks";

const initialValues = {
  name: "",
};
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Organization name minimum length should be 3")
    .required("Required"),
});

const NewOrganisation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [error, setError] = useState({});
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await OrganizationAPI.createOrganization({
        name: values.name,
      });
      if (response?.data?.success) {
        const organzationId = response.data.data._id;

        dispatch(
          updateUser({
            organisations: [organzationId],
          })
        );
        navigate(ROUTES.dashboard);
      } else {
        setError({ name: response.response.data.message });
      }
    } catch (error) {
      toast.error(error.message);
    }
    setSubmitting(false);
  };
  return (
    <div>
      {/* <!-- Hero --> */}
      <div className="relative overflow-hidden dark:bg-gray-800 h-screen">
        <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-20 sm:py-40">
          <div className="text-center">
            <h1 className="text-4xl sm:text-6xl font-bold text-gray-800 dark:text-gray-200">
              Organisation
            </h1>

            <p className="mt-3 text-gray-600 dark:text-gray-400">
              Create a new Organisation.
            </p>

            <div className="mt-7 sm:mt-12 mx-auto max-w-xl relative">
              {/* <!-- Form --> */}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(props) => {
                  const {
                    values,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    dirty,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="relative z-10 flex space-x-3 p-3 bg-white border rounded-lg shadow-lg shadow-gray-100 dark:bg-slate-900 dark:border-gray-700 dark:shadow-gray-900/[.2]">
                        <div className="flex-[1_0_0%]">
                          <label
                            for="hs-search-article-1"
                            className="block text-sm text-gray-700 font-medium dark:text-white"
                          >
                            <span className="sr-only">Search Organisation</span>
                          </label>
                          <Field
                            label="name"
                            id="name"
                            name="name"
                            component={Input}
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={"Search Organisation"}
                            hideLabel
                          />
                        </div>

                        <div className="flex-[0_0_auto]">
                          <Button
                            disabled={!dirty || isSubmitting}
                            type="submit"
                            loading={false}
                            className="w-[46px] h-[46px] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                          >
                            <Plus />
                          </Button>
                        </div>
                      </div>
                      {error?.name && (
                        <span className="error-text">{error.name}</span>
                      )}
                    </form>
                  );
                }}
              </Formik>
              {/* <!-- End Form --> */}

              {/* <!-- SVG Element --> */}
              <div className="hidden md:block absolute top-0 end-0 -translate-y-12 translate-x-20">
                <svg
                  className="w-16 h-auto text-orange-500"
                  width="121"
                  height="135"
                  viewBox="0 0 121 135"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164"
                    stroke="currentColor"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5"
                    stroke="currentColor"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874"
                    stroke="currentColor"
                    strokeWidth="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              {/* <!-- End SVG Element --> */}

              {/* <!-- SVG Element --> */}
              <div className="hidden md:block absolute bottom-0 start-0 translate-y-10 -translate-x-32">
                <svg
                  className="w-40 h-auto text-cyan-500"
                  width="347"
                  height="188"
                  viewBox="0 0 347 188"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426"
                    stroke="currentColor"
                    strokeWidth="7"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              {/* <!-- End SVG Element --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Hero --> */}
    </div>
  );
};

export default NewOrganisation;
