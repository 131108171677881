import React from "react";
import { useToast } from "../../hooks/useToast";

const ToastComponent = () => {
  const { toastList, closeToast } = useToast();
  return (
    <div className="absolute end-5 z-50">
      {Object.values(toastList).map((toastItem) => (
        <Toast
          key={toastItem.id}
          type={toastItem.type}
          message={toastItem.message}
          closeToast={closeToast}
          id={toastItem.id}
        />
      ))}
    </div>
  );
};

function Toast({ message, type, ...props }) {
  return (
    <div
      class="max-w-xs bg-white border border-gray-200 rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700 my-4"
      role="alert"
    >
      {type === "info" ? (
        <InfoToast message={message} {...props} />
      ) : type === "success" ? (
        <SuccessToast message={message} {...props} />
      ) : type === "error" ? (
        <ErrorToast message={message} {...props} />
      ) : type === "warning" ? (
        <WarningToast message={message} {...props} />
      ) : null}
    </div>
  );
}

function InfoToast({ message, ...props }) {
  return (
    <div class="flex p-4">
      <div class="flex-shrink-0">
        <svg
          class="flex-shrink-0 h-4 w-4 text-primary-600 mt-0.5"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
        </svg>
      </div>
      <div class="ms-3">
        <p class="text-sm text-gray-700 dark:text-gray-400">{message}</p>
      </div>
      <div class="ms-auto">
        <button
          type="button"
          class="inline-flex flex-shrink-0 justify-center items-center h-5 w-5 rounded-lg text-gray-800 opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100 dark:text-white"
          onClick={() => props.closeToast(props.id)}
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
        </button>
      </div>
    </div>
  );
}

function SuccessToast({ message, ...props }) {
  return (
    <div class="flex p-4">
      <div class="flex-shrink-0">
        <svg
          class="flex-shrink-0 h-4 w-4 text-teal-500 mt-0.5"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>
      </div>
      <div class="ms-3">
        <p class="text-sm text-gray-700 dark:text-gray-400">{message}</p>
      </div>
      <div class="ms-auto">
        <button
          type="button"
          class="inline-flex flex-shrink-0 justify-center items-center h-5 w-5 rounded-lg text-gray-800 opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100 dark:text-white"
          onClick={() => props.closeToast(props.id)}
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
        </button>
      </div>
    </div>
  );
}

function ErrorToast({ message, ...props }) {
  return (
    <div class="flex p-4">
      <div class="flex-shrink-0">
        <svg
          class="flex-shrink-0 h-4 w-4 text-red-500 mt-0.5"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
        </svg>
      </div>
      <div class="ms-3">
        <p class="text-sm text-gray-700 dark:text-gray-400">{message}</p>
      </div>
      <div class="ms-auto">
        <button
          type="button"
          class="inline-flex flex-shrink-0 justify-center items-center h-5 w-5 rounded-lg text-gray-800 opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100 dark:text-white"
          onClick={() => props.closeToast(props.id)}
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
        </button>
      </div>
    </div>
  );
}

function WarningToast({ message, ...props }) {
  return (
    <div class="flex p-4">
      <div class="flex-shrink-0">
        <svg
          class="flex-shrink-0 h-4 w-4 text-yellow-500 mt-0.5"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
        </svg>
      </div>
      <div class="ms-3">
        <p class="text-sm text-gray-700 dark:text-gray-400">{message}</p>
      </div>
      <div class="ms-auto">
        <button
          type="button"
          class="inline-flex flex-shrink-0 justify-center items-center h-5 w-5 rounded-lg text-gray-800 opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100 dark:text-white"
          onClick={() => props.closeToast(props.id)}
        >
          <span class="sr-only">Close</span>
          <svg
            class="flex-shrink-0 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default ToastComponent;
