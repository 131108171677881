import React, { useEffect, useRef, useState } from "react";
import Select from "../Generic/Select";
import TeamsAPI from "../../api/services/Teams";
import { useToast } from "../../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Organization from "../../types/interfaces/Organization";
import useOrganizationRoles from "../../hooks/useOrganizationRoles";
// let initialOptions = [
//   { value: "Creator" },
//   { value: "Contributor" },
//   { value: "Viewer" },
// ];
interface NewMemberModalProps {
  updateMemberList: () => void;
  members: any[];
}

const NewMemberModal = ({ updateMemberList, members }: NewMemberModalProps) => {
  const { options, fetchSubscription } = useOrganizationRoles();
  const organizations: Organization[] = useSelector(
    (state: RootState) => state.organizations.value
  );
  const [selectedRole, setSelectedRole] = useState(options[2].value);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();
  const [error, setError] = useState<any>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  // const [subscription, setSubscribtion] = useState({
  //   currentPlan: {
  //     subscriptionPlan: "",
  //     noOfCreators: 0,
  //     noOfContributors: 0,
  //     noOfViewers: 0,
  //     noOfPlanboard: 0,
  //   },
  //   utilize: {
  //     noOfCreators: 0,
  //     noOfContributors: 0,
  //     noOfViewers: 0,
  //     noOfPlanboard: 0,
  //   },
  // });

  // const fetchSubscribtion = async () => {
  //   try {
  //     const response = await TeamsAPI.subscribedPlanUsage();
  //     if (response.status === 200) {
  //       setSubscribtion(response.data.data);
  //     }
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };
  const handleRoleChange = (value: string) => setSelectedRole(value);

  const handleSubmit = async () => {
    const inputValue = inputRef.current?.value;
    if (!inputValue) {
      return setError({ input: "required" });
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputValue)) return setError("Enter valid email");

    const isOwnerEmail = inputValue === organizations[0].owner.email;
    const isMemberEmail = members.some(
      (member) => member.member.emailId === inputValue
    );

    if (isOwnerEmail || isMemberEmail) {
      return setError("Email already in use");
    }

    try {
      setLoading(true);
      const response = await TeamsAPI.inviteMember(inputValue, selectedRole);
      setLoading(false);
      if (response.status === 200) {
        toast.success("Invited successfully");
        buttonRef.current?.click();
        handleClose();
        setTimeout(updateMemberList, 3000);
        // updateMemberList();
      } else if (response.status == 409) return;
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      setError(error.response.data.data);
    }
  };

  const handleInputChange = () => {
    if (error) {
      setError(null);
    }
  };

  const handleClose = () => {
    setError(null);
    inputRef?.current?.value && (inputRef.current.value = "");
  };

  useEffect(() => {
    const modal = modalRef.current;

    // Observer for mutation events
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          const target = mutation.target as HTMLElement;
          if (target.classList.contains("open")) {
            fetchSubscription();
          }
        }
      });
    });

    // Function to handle click outside
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setError(null);
      }
    };

    // Set up the observer if modal exists
    if (modal) {
      observer.observe(modal, { attributes: true });
    }

    // Set up the event listener for click outside
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function
    return () => {
      if (observer) {
        observer.disconnect();
      }
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   const modal = modalRef.current;

  //   const observer = new MutationObserver((mutations) => {
  //     mutations.forEach((mutation) => {
  //       if (
  //         mutation.type === "attributes" &&
  //         mutation.attributeName === "class"
  //       ) {
  //         const target = mutation.target as HTMLElement;
  //         if (target.classList.contains("open")) {
  //           fetchSubscribtion();
  //         }
  //       }
  //     });
  //   });

  //   if (modal) {
  //     observer.observe(modal, { attributes: true });
  //   }

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (
  //       modalRef.current &&
  //       !modalRef.current.contains(event.target as Node)
  //     ) {
  //       setError(null);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <div
      id="hs-pro-dshm"
      ref={modalRef}
      className="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none [--close-when-click-inside:true] dark:hs-overlay-backdrop-open:bg-slate-900/90"
    >
      <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center">
        <div className="w-full max-h-full flex flex-col bg-white rounded-xl pointer-events-auto shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:bg-slate-800 dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)]">
          {/* <!-- Header -->  */}
          {/*  */}
          <div className="py-3 px-4 flex justify-between items-center border-b dark:border-slate-700">
            <h3 className="font-semibold text-gray-800 dark:text-slate-200">
              Invite
            </h3>
            <button
              type="button"
              className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-700 dark:hover:bg-slate-600 dark:text-slate-400 dark:focus:bg-slate-600"
              data-hs-overlay="#hs-pro-dshm"
              ref={buttonRef}
              onClick={handleClose}
            >
              <span className="sr-only">Close</span>
              <svg
                className="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </button>
          </div>
          {/* <!-- End Header -->  */}

          {/* <!-- Header -->  */}
          <div className="px-4 py-8 border-b border-gray-200 dark:border-slate-700">
            {/* <!-- Input Form -->  */}
            <div className="flex items-center gap-x-2">
              <div className="relative w-full">
                <input
                  type="text"
                  className={`py-2 px-3 pe-24 block border w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 ${
                    error
                      ? "focus:border-red-500 focus:ring-red-500 dark:focus:ring-red-600 border-red-500 outline-none"
                      : "focus:border-blue-500 focus:ring-blue-500 dark:focus:ring-slate-600 dark:border-slate-700"
                  }  dark:bg-slate-800 dark:text-slate-400 dark:placeholder:text-slate-500`}
                  placeholder="Enter Email"
                  ref={inputRef}
                  onChange={handleInputChange}
                />

                <div className="absolute inset-y-0 end-0 flex items-center z-20 pe-[5px] text-gray-400">
                  <div className="p-3">
                    <Select
                      onChange={handleRoleChange}
                      options={options}
                      value={selectedRole}
                    />
                  </div>
                </div>
              </div>

              <button
                type="button"
                onClick={handleSubmit}
                disabled={loading}
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Send
              </button>
            </div>
            <div
              className={`${
                error ? "visible" : "invisible"
              } text-xs text-red-500 mt-2 ms-2`}
            >
              *{error}
            </div>
            {/* <!-- End Input Form -->  */}
          </div>

          {/* <!-- End Header -->  */}

          {/* <!-- Footer -->  */}
          <div className="p-4 border-t border-gray-200 dark:border-slate-700">
            <a
              className="inline-flex items-center gap-x-1.5 text-xs text-gray-500 hover:underline font-medium focus:outline-none focus:underline dark:text-blue-400 dark:hover:text-blue-500"
              href="#"
            >
              <svg
                className="flex-shrink-0 size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M12 16v-4" />
                <path d="M12 8h.01" />
              </svg>
              Read more about share
            </a>
          </div>
          {/* <!-- End Footer -->  */}
        </div>
      </div>
    </div>
  );
};

export default NewMemberModal;
