import React from "react";
import BrowseInput from "./BrowseInput";

interface NoFilePlaceholderProps {
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  selectedFiles: File[];
}

const NoFilePlaceholder = ({
  setSelectedFiles,
  selectedFiles,
}: NoFilePlaceholderProps) => {
  return (
    <div className="text-center m-5">
      <svg
        className="w-16 text-stone-400 mx-auto dark:text-neutral-400"
        width="70"
        height="46"
        viewBox="0 0 70 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.05172 9.36853L17.2131 7.5083V41.3608L12.3018 42.3947C9.01306 43.0871 5.79705 40.9434 5.17081 37.6414L1.14319 16.4049C0.515988 13.0978 2.73148 9.92191 6.05172 9.36853Z"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="2"
          className="fill-white stroke-stone-400 dark:fill-slate-900 dark:stroke-neutral-500"
        />
        <path
          d="M63.9483 9.36853L52.7869 7.5083V41.3608L57.6982 42.3947C60.9869 43.0871 64.203 40.9434 64.8292 37.6414L68.8568 16.4049C69.484 13.0978 67.2685 9.92191 63.9483 9.36853Z"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="2"
          className="fill-white stroke-stone-400 dark:fill-slate-900 dark:stroke-neutral-500"
        />
        <rect
          x="17.0656"
          y="1.62305"
          width="35.8689"
          height="42.7541"
          rx="5"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="2"
          className="fill-white stroke-stone-400 dark:fill-slate-900 dark:stroke-neutral-500"
        />
        <path
          d="M47.9344 44.3772H22.0655C19.3041 44.3772 17.0656 42.1386 17.0656 39.3772L17.0656 35.9161L29.4724 22.7682L38.9825 33.7121C39.7832 34.6335 41.2154 34.629 42.0102 33.7025L47.2456 27.5996L52.9344 33.7209V39.3772C52.9344 42.1386 50.6958 44.3772 47.9344 44.3772Z"
          stroke="currentColor"
          stroke-width="2"
          className="stroke-stone-400 dark:stroke-neutral-500"
        />
        <circle
          cx="39.5902"
          cy="14.9672"
          r="4.16393"
          stroke="currentColor"
          stroke-width="2"
          className="stroke-stone-400 dark:stroke-neutral-500"
        />
      </svg>

      <div className="mt-4 flex flex-wrap justify-center text-sm leading-6 text-stone-600">
        <BrowseInput
          setSelectedFiles={setSelectedFiles}
          selectedFiles={selectedFiles}
        />
      </div>

      <p className="mt-1 text-xs text-stone-400 dark:text-neutral-400">
        CSV, XLS, DOCX, PDF
      </p>
    </div>
  );
};

export default NoFilePlaceholder;
