import { URLS } from "../../constants/index.jsx";
import apiClient, { getAuth, postAuth, putAuth } from "../client";

const UsersAPI = {
  get: (id: string) => {
    return getAuth(URLS.getUserDetails + id);
  },
  getUser: () => {
    return getAuth(URLS.getUser);
  },
  getUsersByEmail: (email: string) => {
    return postAuth(URLS.getUsers, { email });
  },
  updateUser: (id: string, updateData: {}) => {
    return putAuth(URLS.updateUser, { id, updateData });
  },
};

export default UsersAPI;
