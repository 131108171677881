import {
  Activity,
  ActivitySquare,
  CalendarDays,
  ChevronRight,
  ClipboardList,
  File,
  GanttChartSquare,
  Info,
  Plus,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import AddMoreButton from "./AddMoreButton";
import { useSelector } from "react-redux";
import { ROLE_TYPES } from "../../types/types/PlanboardRoles";

const iconSize = 18;
const strokeWidth = 1;

const tabData = [
  {
    text: "Summary",
    route: "summary",
    icon: <Info size={iconSize} strokeWidth={strokeWidth} />,
  },
  {
    text: "Strategy Board",
    route: "canvas",
    icon: <ClipboardList size={iconSize} strokeWidth={strokeWidth} />,
  },
  {
    text: "Meetings",
    route: "events",
    icon: <CalendarDays size={iconSize} strokeWidth={strokeWidth} />,
  },
  {
    text: "Files",
    route: "content",
    icon: <File size={iconSize} strokeWidth={strokeWidth} />,
  },
  {
    text: "Analytics",
    route: "analytics",
    icon: <Activity size={iconSize} strokeWidth={strokeWidth} />,
  },
  // {
  //   text: "Activity",
  //   route: "activity",
  //   icon: <ActivitySquare size={iconSize} />,
  // },
  {
    text: "Timeline",
    route: "timeline",
    icon: <GanttChartSquare size={iconSize} />,
  },
];

const inactiveTabStyle =
  " font-medium text-gray-800 hover:text-primary-400 sm:border-b-2 border-b-transparent  dark:text-gray-400 cursor-pointer";
const activeTabStyle = " font-semibold text-primary-400 ";

export default function DesignerNavigation({ selectedNav, setSelectedNav }) {
  const planboard = useSelector((state) => state.planboard.value);
  const [tabs, setTabs] = useState(tabData);
  const handleNavigation = (value) => {
    setSelectedNav(value);
  };
  useEffect(() => {
    if (planboard.userRole === ROLE_TYPES.VIEWER) {
      setTabs(
        tabs.map((tab) => {
          if (tab.text === "Meetings" || tab.text === "Files") {
            return {
              ...tab,
              disabled: true,
            };
          }
          return tab;
        })
      );
    }
  }, [planboard.userRole]);
  return (
    <nav className="px-4 sm:px-6 lg:px-8">
      <div className="relative flex flex-row justify-between items-center gap-x-8 py-4 sm:py-0 dark:border-slate-700">
        <div
          id="secondary-nav-toggle"
          className="hs-collapse hidden overflow-hidden transition-all duration-300 absolute top-16 end-0 w-full rounded-lg bg-white sm:block sm:static sm:top-0 sm:w-full sm:max-h-full sm:bg-transparent sm:overflow-visible"
        >
          <div className="flex flex-col py-2 sm:flex-row sm:justify-start sm:gap-y-0 sm:gap-x-6 sm:py-0">
            {tabs.map((nav) => (
              <div
                className={`flex py-2 sm:py-3.5 text-sm dark:text-nuetral-200 dark:hover:text-white items-center gap-x-1 ${
                  selectedNav === nav.route
                    ? activeTabStyle
                    : nav.disabled
                    ? "text-gray-400 cursor-not-allowed"
                    : inactiveTabStyle
                }`}
                key={nav.route}
                onClick={() => !nav.disabled && handleNavigation(nav.route)}
              >
                {nav.icon}
                {nav.text}
              </div>
            ))}
            {/* <AddMoreButton /> */}
          </div>
        </div>
      </div>
    </nav>
  );
}
