import { NODES_URLS, URLS } from "../../constants";
import { deleteAuth, postAuth } from "../client";

const NodesAPI = {
  // create: ({
  //   titles,
  //   planboardId,
  // }: {
  //   titles: string[];
  //   planboardId: string;
  // }) => {
  //   return postAuth(URLS.createNodes, {
  //     titles,
  //     planboardId,
  //   });
  // },
  setNodesMetaData: ({
    planboardId,
    nodes,
  }: {
    planboardId: string;
    nodes: {}[];
  }) => {
    return postAuth(NODES_URLS.setNodesMetaData, { planboardId, nodes });
  },
  updateNode: (
    nodeId: string,
    planboardId: string,
    updateData: {
      title?: string;
      description?: string;
      endDate?: string;
      assignedTo?: number;
      metaData?: {
        color?: string;
        height?: number;
        width?: number;
        x_position?: number;
        y_position?: number;
      };
    }
  ) => {
    return postAuth(NODES_URLS.updateNode, {
      nodeId,
      planboardId,
      ...updateData,
    });
  },
  disconnectEdge: ({
    planboardId,
    sourceNodeId,
    targetNodeId,
  }: {
    planboardId: string;
    sourceNodeId: string;
    targetNodeId: string;
  }) => {
    return postAuth(NODES_URLS.disconnectEdge, {
      planboardId,
      sourceNodeId,
      targetNodeId,
    });
  },
  connectEdge: ({
    planboardId,
    sourceNodeId,
    targetNodeId,
  }: {
    planboardId: string;
    sourceNodeId: string;
    targetNodeId: string;
  }) => {
    return postAuth(NODES_URLS.connectEdge, {
      planboardId,
      sourceNodeId,
      targetNodeId,
    });
  },
  add: ({
    planboardId,
    title,
    parentId,
    description,
    color,
    height,
    width,
    x_position,
    y_position,
  }: {
    planboardId: string;
    title: string;
    parentId: string;
    color?: string;
    height?: number;
    width?: number;
    x_position: number;
    y_position: number;
    description?: string;
  }) => {
    const requestData: any = { planboardId, title, parentId };
    if (description !== undefined) requestData["description"] = description;
    const metaData: any = {
      x_position,
      y_position,
    };
    if (height !== undefined) metaData["height"] = height;
    if (width !== undefined) metaData["width"] = width;
    if (color !== undefined) metaData["color"] = color;
    requestData["metaData"] = metaData;
    return postAuth(URLS.addNode, requestData);
  },
  delete: ({
    nodeId,
    planboardId,
  }: {
    nodeId: string;
    planboardId: string;
  }) => {
    return postAuth(URLS.deleteNode, { nodeId, planboardId });
  },
  getNodeDetails: (nodeId: string) => {
    return postAuth(NODES_URLS.getNodeDetails, { nodeId });
  },
};
export default NodesAPI;
