import React, { useEffect, useState } from "react";
import DataGridSkeleton from "../Skeletons/DataGrid";
import { useSelector } from "react-redux";
import { useGlobal, useToast } from "../../hooks";
import NodeElementsAPI from "../../api/services/NodeElements";
import Accordion from "../Accordion";
import ActionItemsTable from "../ActionItemsTable";
import AddComponent from "../AddComponent";
import Tooltip from "../Preline/Tooltip";
import NavigationTabs from "./NavigationTabs";
import SortIconMenu from "./SortIconMenu";
import SearchIcon from "./SearchIcon";
import FilterIconMenu from "./FilterIconMenu";
import AddNewComponent from "../Accordion/AddNewComponent";
import AccordianContent from "./AccordianContent";
import ActionItemsAPI from "../../api/services/ActionItems";
import useDissapearingError from "../../hooks/useDissapearingError";
import SubTasks from "../SubTasks";
import { convertDateStringToDate } from "../../utils/dateTime";
import PlanboardsAPI from "../../api/services/Planboard";
import { useParams } from "react-router-dom";
import { ROLE_TYPES } from "../../types/types/PlanboardRoles";
import { useReactFlow } from "reactflow";

const dataGridConfiguration = {
  columns: [
    {
      columnName: "Order",
      columnType: "text",
    },
    {
      columnName: "Date",
      columnType: "date",
    },
    {
      columnName: "Amount",
      columnType: "number",
    },
  ],
  rows: [
    { Order: "12345", Date: "1997-11-11", Amount: "10" },
    { Order: "23456", Date: "1997-10-11", Amount: "11" },
    { Order: "23457", Date: "1997-12-11", Amount: "13" },
  ],
};

const ActionItem = ({
  loading,
  elements,
  setElements,
  fetchActionItems,
  node,
}) => {
  const planboard = useSelector((state) => state.planboard);
  const user = useSelector((state) => state.user.value);
  const [members, setMembers] = useState([]);
  const { debounce } = useGlobal();
  const toast = useToast();
  const { id } = useParams();
  // const { getNodes } = useReactFlow();
  // const node = getNodes().

  const handleAddNewActionItem = async (type) => {
    const response = await ActionItemsAPI.create({
      nodeId: node.id,
      planboardId: planboard.value.planboardId,
      title: `Action Item #${elements.length}`,
    });
    if (response.status === 200) {
      toast.info("New Action Item added");
      fetchActionItems();
    } else toast.error("Error creating action item");
  };

  const updateElement = async (id, data) => {
    const currElements = elements;
    setElements((prev) =>
      prev.map((element) => {
        if (element.id === id) {
          if (data.endDate) {
            return {
              ...element,
              ...data,
              endDate: convertDateStringToDate(data.endDate),
            };
          } else return { ...element, ...data };
        } else return element;
      })
    );
    let modifiedUpdateData = data;
    if (modifiedUpdateData.assignedTo)
      modifiedUpdateData = {
        ...modifiedUpdateData,
        assignedTo: modifiedUpdateData.assignedTo.userId,
      };
    try {
      const response = await ActionItemsAPI.update({
        actionItemId: id,
        ...modifiedUpdateData,
      });
      if (response.status === 200) {
      } else throw new Error("failed to update");
    } catch (error) {
      setElements(currElements);
      toast.error("Failed to Update Action Item");
    }
  };

  const [hover, setHover] = useState(false);

  const deleteActionItem = async (id) => {
    const currElements = elements;
    setElements((prev) => prev.filter((item) => item.id !== id));
    const response = await ActionItemsAPI.delete(id);
    if (response.status !== 200) {
      setElements(currElements);
      toast.error(`Failed to delete Action Item`);
    } else toast.info("Action Item Deleted Successfully");
  };

  const getTeamMembers = async () => {
    if (id === undefined) return;
    try {
      const response = await PlanboardsAPI.getMembers(id);
      console.log({ response });
      if (response.status === 200) {
        return setMembers(response.data.data);
      }
    } catch (error) {
      if (error.response.status === 404) setMembers([]);
    }
  };

  useEffect(() => {
    console.log({ node });
    getTeamMembers();
  }, []);

  return (
    <main className="flex mt-[60px] h-[100vh] bg">
      <div className="bg-slate-50 dark:bg-slate-900 w-full h-full">
        <div className="flex m-2 h-full">
          <main id="content" role="main" className="w-full h-full">
            <div className="h-full overflow-y-scroll m-5 rounded-xl shadow-2xl bg-white border-x-gray-200 pb-20 pt-2 px-4 ps-10 dark:bg-slate-900 dark:border-x-neutral-700 ">
              <div className="flex border items-center border-gray-100 dark:border-neutral-600 p-2 rounded-lg shadow-md me-8 ">
                <div className="flex grow items-center gap-x-8 ps-3 ">
                  <span className="font-semibold pb-1 dark:text-gray-200">
                    Action Items:&nbsp;{elements.length}
                  </span>
                  {(planboard.value?.userRole === ROLE_TYPES.CREATOR ||
                    planboard.value?.userRole === ROLE_TYPES.COCREATOR ||
                    node.data?.assignedTo?.id == user.id) && (
                    <div
                      className={`${
                        elements.length === 0 || node.data.building
                          ? "invisible"
                          : "visible"
                      } inline-flex gap-x-2 shadow-sm `}
                    >
                      {/* <AddComponent handleAddNewActionItem={handleAddNewActionItem} /> */}
                      <AddNewComponent
                        handleAddNewActionItem={handleAddNewActionItem}
                      />
                    </div>
                  )}
                </div>
                {/* <div className="flex gap-x-3">
                  <SortIconMenu />
                  <SearchIcon />
                  <FilterIconMenu />
                </div> */}
              </div>
              {node.data.building && (
                <div className="flex justify-center mt-5 animate-bounce">
                  Building your Action Items...
                </div>
              )}

              {loading || node.data.building ? (
                <div className="space-y-2 ">
                  <DataGridSkeleton index={0} />
                  <DataGridSkeleton index={2} />
                  <DataGridSkeleton index={3} />
                </div>
              ) : elements.length === 0 ? (
                <div className="flex mt-5">
                  <div className="dark:text-gray-300 mt-4 ps-4 w-full flex items-center flex-col space-y-10 border rounded-lg py-10 me-8">
                    <p>There are no Action Items for this step.</p>

                    {(planboard.value?.userRole === ROLE_TYPES.CREATOR ||
                      planboard.value?.userRole === ROLE_TYPES.COCREATOR ||
                      node.data?.assignedTo?.id == user.id) && (
                      <AddNewComponent
                        handleAddNewActionItem={handleAddNewActionItem}
                      />
                    )}
                  </div>
                </div>
              ) : (
                elements.map((element, index) => {
                  return (
                    <>
                      <Accordion
                        key={element.id}
                        element={element}
                        deleteActionItem={deleteActionItem}
                        hover={hover}
                        setHover={setHover}
                        updateElement={updateElement}
                        members={members}
                        readOnly={
                          planboard.value?.userRole === ROLE_TYPES.VIEWER ||
                          (planboard.value?.userRole ===
                            ROLE_TYPES.CONTRIBUTOR &&
                            node.data?.assignedTo?.id != user.id)
                        }
                      >
                        <SubTasks
                          actionItem={element}
                          nodeId={node.id}
                          count={element?.taskCount ?? 0}
                          members={members}
                          setElements={setElements}
                          readOnly={
                            planboard.value?.userRole === ROLE_TYPES.VIEWER ||
                            (planboard.value?.userRole ===
                              ROLE_TYPES.CONTRIBUTOR &&
                              node.data?.assignedTo?.id != user.id)
                          }
                          actionItemDate={{
                            createdOn: new Date(element.createdOn),
                            endDate: element?.endDate
                              ? new Date(element.endDate)
                              : new Date(),
                          }}
                        />
                      </Accordion>
                    </>
                  );
                })
              )}
            </div>
          </main>
        </div>
      </div>
    </main>
  );
};

export default ActionItem;
