import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../constants";
// import { isValidAccessToken } from "../api/client";

export const useAuth = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const validateAccessToken = async () => {
  //     try {
  //       await isValidAccessToken();
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   };
  //   validateAccessToken();
  // }, [location]);

  // const IDPLogout = async (id_token: string) => {
  //   console.log("idp logout");
  //   sessionStorage.clear();
  //   try {
  //     if (!id_token) return;
  //     const url =
  //       process.env.REACT_APP_IDP_URL +
  //       "/connect/logout?post_logout_redirect_uri=" +
  //       process.env.REACT_APP_PB_POST_LOGOUT_REDIRECT_URI +
  //       "&id_token_hint=" +
  //       id_token;
  //     window.location.href = url;
  //     return;
  //   } catch (error) {
  //     console.log({ error });
  //     return;
  //   }
  // };

  const clearLocalToken = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    // localStorage.removeItem("id_token");
    localStorage.removeItem("routeState");
  };

  const logout = () => {
    // const id_token = localStorage.getItem("id_token");
    clearLocalToken();
    // navigate(ROUTES.signin);
    // id_token && IDPLogout(id_token);
  };

  return {
    // authed,
    loading,
    logout,
    // login(token) {
    //   return new Promise((res) => {
    //     dispatch(
    //       setAuthed({
    //         authed: true,
    //         token,
    //       })
    //     );
    //     res();
    //   });
    // },
    // logout() {
    //   return new Promise((res) => {
    //     dispatch(
    //       setAuthed({
    //         authed: false,
    //         token: null,
    //       })
    //     );
    //     localStorage.removeItem("access_token");
    //     Cookies.remove("session");
    //     document.cookie = "";
    //     res();
    //   });
    // },
  };
};
