import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { useEffect, useState } from "react";
import Button from "../Generic/Button";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import Input from "../Inputs/Input";
import NavButtons from "./NavButtons";
import { SUPPORTED_FILE_TYPES } from "../../constants/file";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Workspace from "../../types/interfaces/workspace";
import Textarea from "../Inputs/Textarea";
import Select from "../Inputs/Select";
import DatePicker from "../Inputs/DatePicker";
import Checkbox from "../Inputs/Checkbox";
import InviteMembers from "./InviteMembers";
import Attachments from "./Attachments";
import { getWorkspaces } from "../../redux/slice/workspaces/workspacesSlice";
import { Member } from "./Member";
import Events from "./Events";
import { useToast } from "../../hooks";
import PlanboardsAPI from "../../api/services/Planboard";
import Organization from "../../types/interfaces/Organization";
import moment from "moment";

// interface File {
//   name: string;
//   size: number;
//   type: string;
// }

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),

  description: Yup.string()
    .required("Required")
    .max(255, "Description must be less than 255 characters"), // Optional description length limit

  workspaceId: Yup.string().required("Required"), // Assuming workspaceId is required

  endDate: Yup.date().typeError("Invalid date format").required("Required"),

  schedule: Yup.object()
    .nullable()
    .test(
      "is-schedule-required",
      "All fields are required when scheduling an event.",
      (value, context) => {
        if (!context.parent.scheduleEvent) return true; // Pass if scheduleEvent is false
        if (!value) return false; // Fail if no schedule data exists

        // Create a schema reference using Yup.lazy
        const schema = Yup.lazy((val) =>
          Yup.object().shape({
            meetingTypeId: Yup.string().required("Required"),
            date: Yup.date()
              .typeError("Invalid date format")
              .required("Required"),
            start: Yup.string().required("Required"),
            end: Yup.string().required("Required"),
          })
        );

        // Perform validation and consistently return a boolean
        try {
          schema.validateSync(value);
          return true; // Pass if validation succeeds
        } catch (err) {
          return false; // Fail if validation throws an error
        }
      }
    ),
});
const Step2 = ({
  planboardData,
  updateStepData,
  setActiveStep,
  handleCreatePlanboard,
}: any) => {
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const toast = useToast();

  const organizations: { value: Organization[] } = useSelector(
    (state: RootState) => state.organizations
  );
  const workspaces = useSelector((state: RootState) => state.workspaces) as {
    value: Workspace[];
  };
  const workspaceOptions = workspaces.value
    .filter((workspace) => workspace.active)
    .map((workspace) => ({
      value: workspace.id,
      text: workspace.name,
    }));

  const initialValues: { [key: string]: string | any[] | boolean | {} } = {
    name: "",
    description: "",
    workspaceId: planboardData.workspaceId,
    endDate: new Date(),
    members: [], // Empty array of objects
    attachments: [], // Empty array of files
    schedule: {
      meetingTypeId: "",
      date: new Date(),
      start: "",
      end: "",
    },
    scheduleEvent: false,
  };

  const getMembersData = () => {
    return selectedMembers.map((member) => {
      if (member.type == "full") {
        return { userId: member.userId, role: member.role };
      } else {
        return { emailId: member.email, role: member.role };
      }
    });
  };

  const handleSubmit = async (
    values: any,
    { setSubmitting, setErrors, errors }: any
  ) => {
    if (!values.scheduleEvent) {
      setErrors({ ...errors, schedule: undefined });
    }
    let stepData = { ...values };
    if (selectedMembers.length) {
      stepData.members = getMembersData();
    }
    if (selectedFiles.length) {
      stepData.attachments = selectedFiles;
    }
    // handleCreatePlanboard(stepData);
    const checkName = await validatePlanboardName(
      values.name,
      values.workspaceId
    );
    if (!checkName)
      return setErrors({ ...errors, name: "Planboard name already exists" });
    updateStepData(stepData);
    setSubmitting(false);
    setActiveStep(3);
  };
  const handleBack = () => {
    setActiveStep(1);
  };

  const validatePlanboardName = async (name: string, workspaceId: string) => {
    try {
      const response = await PlanboardsAPI.nameExists(workspaceId, name);
      if (response.status === 200) {
        return true;
      } else return false;
    } catch (error: any) {
      if (error.response.status === 409) return false;
      toast.error("Something went wrong, please try again later!");
      return false;
    }
  };

  const getInitialValues = () => {
    const valuesObj: { [key: string]: string } = {};
    Object.keys(initialValues).forEach((key) => {
      valuesObj[key] =
        planboardData[key] != "" ? planboardData[key] : initialValues[key];
    });
    return { ...valuesObj };
  };

  useEffect(() => {
    if (!workspaceOptions.length) {
      dispatch(
        getWorkspaces({
          organizationId: organizations.value[0].organizationId,
          index: 0,
          itemsPerIndex: 100,
          sort: "Created With",
          search: "workspace",
        }) as any
      );
    }
  }, []);

  return (
    <div className="flex flex-col justify-center mx-auto space-y-5">
      <div className="my-8">
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
          Planboard details & settings
        </h1>
        <span className="text-gray-500 text-sm">
          Configure & furnish planboard details
        </span>
      </div>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        // enableReinitialize
      >
        {(props) => {
          const {
            values,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setValues,
            setErrors,
            isValid,
            touched,
          } = props;
          return (
            <>
              <div className="mt-8 ps-10 sm:mt-10 divide-y divide-gray-200 dark:divide-gray-700">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-[20%_76%] gap-y-8 mb-16">
                    <>
                      <label
                        htmlFor="name"
                        className="font-medium mb-2 dark:text-white"
                      >
                        Planboard Name
                      </label>
                      <div>
                        <div className="flex">
                          <div className="w-5/12">
                            <Field
                              label="Planboard Name"
                              id="name"
                              name="name"
                              component={Input}
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              hideLabel={true}
                              placeholder="Enter Planboard Name"
                            />
                          </div>
                          {values?.name && touched?.name && (
                            <div className="flex items-center text-green-600 ms-5">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#05cb0f"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="lucide lucide-circle-check-big"
                              >
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                                <path d="m9 11 3 3L22 4" />
                              </svg>
                            </div>
                          )}
                        </div>
                        <p className="text-xs mt-1 ps-4 text-gray-500">
                          Enter a unique name for your planboard
                        </p>
                      </div>
                    </>

                    <>
                      <label
                        htmlFor="endDate"
                        className="font-medium mb-2 dark:text-white"
                      >
                        Planboard End Date
                      </label>
                      <div>
                        <div className="flex">
                          <div className="w-5/12">
                            <Field
                              label="Planboard End Date"
                              id="endDate"
                              name="endDate"
                              component={DatePicker}
                              minDate={moment().subtract(1, 'days')}
                              value={values.endDate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              hideLabel={true}
                              placeholder="Planboard End Date"
                            />
                          </div>
                          {values?.endDate && (
                            <div className="flex items-center text-green-600 ms-5">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#05cb0f"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="lucide lucide-circle-check-big"
                              >
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                                <path d="m9 11 3 3L22 4" />
                              </svg>
                            </div>
                          )}
                        </div>
                        <p className="text-xs mt-1 ps-4 text-gray-500">
                          Select a deadline for your strategy
                        </p>
                      </div>
                    </>

                    <>
                      <label
                        htmlFor="description"
                        className="font-medium mb-2 dark:text-white"
                      >
                        Description
                      </label>
                      <div>
                        <div className="flex">
                          <div className="w-5/12">
                            <Field
                              label="Description"
                              id="description"
                              name="description"
                              component={Textarea}
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              hideLabel={true}
                              placeholder="a short description"
                            />
                          </div>
                          {values?.description && touched?.description && (
                            <div className="flex items-center text-green-600 ms-5">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#05cb0f"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="lucide lucide-circle-check-big"
                              >
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                                <path d="m9 11 3 3L22 4" />
                              </svg>
                            </div>
                          )}
                        </div>
                        <p className="text-xs mt-1 ps-4 text-gray-500">
                          Give a short description for your planboard
                        </p>
                      </div>
                    </>

                    <>
                      <label
                        htmlFor="workspaceId"
                        className="font-medium mb-2 dark:text-white"
                      >
                        Choose Workspace
                      </label>
                      <div>
                        <div className="flex">
                          <div className="w-5/12">
                            <Field
                              label="Choose Workspace"
                              id="workspaceId"
                              name="workspaceId"
                              component={Select}
                              value={values.workspaceId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Workspace"
                              hideLabel={true}
                              options={workspaceOptions}
                            />
                          </div>
                          {values?.workspaceId && touched?.workspaceId && (
                            <div className="flex items-center text-green-600 ms-5">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#05cb0f"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                className="lucide lucide-circle-check-big"
                              >
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                                <path d="m9 11 3 3L22 4" />
                              </svg>
                            </div>
                          )}
                        </div>
                        <p className="text-xs mt-1 ps-4 text-gray-500">
                          Select a workspace
                        </p>
                      </div>
                    </>

                    <div className="col-span-2 border-t border-gray-200 dark:border-neutral-700" />
                    <InviteMembers
                      selectedMembers={selectedMembers}
                      setSelectedMembers={setSelectedMembers}
                    />
                    <div className="col-span-2 border-t border-gray-200 dark:border-neutral-700" />
                    <Attachments
                      setSelectedFiles={setSelectedFiles}
                      selectedFiles={selectedFiles}
                    />
                    {/* <Events /> */}
                    <div className="col-span-2 border-t border-gray-200 dark:border-neutral-700" />
                    <div className="space-y-2 col-span-2">
                      <Field
                        label="Schedule an Event"
                        id="scheduleEvent"
                        name="scheduleEvent"
                        component={Checkbox}
                        value={values.scheduleEvent}
                        // onChange={handleChange}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                          if (!e.target.checked) {
                            setErrors({ schedule: undefined }); // Remove schedule errors when scheduleEvent is toggled
                            const { schedule, ...rest } = values;
                            //@ts-ignore
                            setValues({ ...rest, scheduleEvent: false });
                          } else {
                            setValues({
                              ...values,
                              //@ts-ignore
                              scheduleEvent: true,
                              //@ts-ignore
                              schedule: {
                                date: new Date(),
                                start: "09:00 AM",
                                end: "10:00 AM",
                              },
                            });
                          }
                        }}
                        onBlur={handleBlur}
                      />
                    </div>

                    <div
                      className={`${
                        values.scheduleEvent ? "" : "hidden"
                      } col-span-2 grid grid-cols-[20%_76%] gap-y-8 gap-x-8`}
                    >
                      <>
                        <label
                          htmlFor="domain-select"
                          className="font-medium mb-2 dark:text-white"
                        >
                          Schedule Event&nbsp;
                          <span className="italic text-gray-400 text-xs">
                            (optional)
                          </span>
                        </label>
                        <div>
                          <Field
                            component={Events}
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors}
                            setErrors={setErrors}
                          />
                          {errors?.schedule && (
                            <p
                              className="flex text-xs text-red-600 mt-2"
                              id="email-error"
                            >
                              {errors?.schedule}
                            </p>
                          )}
                        </div>
                      </>
                    </div>
                  </div>
                </form>
              </div>
              <NavButtons
                handleSubmit={handleSubmit}
                handleBack={handleBack}
                // isFinal={true}
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Step2;
