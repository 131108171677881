import React, { useEffect, useRef } from "react";
import { useDebounce, useThrottle } from "@uidotdev/usehooks";
import { Slider, Typography } from "@mui/material";

interface ProgressProps {
  progress: number;
  status: string;
  updateProgress: (progress: number) => void;
  readOnly: boolean;
}

const Progress = ({
  progress,
  status,
  updateProgress,
  readOnly,
}: ProgressProps) => {
  const [value, setValue] = React.useState<number>(progress);
  const debouncedValue = useDebounce(value, 500);
  const color =
    status === "todo"
      ? "#d3d3d3"
      : status === "in progress"
      ? "#0733e6"
      : status === "completed"
      ? "#30b002"
      : "#ad0511";
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setValue(newValue);
    }
  };
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    updateProgress(debouncedValue);
  }, [debouncedValue]);

  return (
    <div className="min-w-60">
      <div id="non-linear-slider" className="text-sm">
        {status === "delayed"
          ? "Delayed"
          : status === "todo"
          ? "To Do"
          : status === "completed"
          ? "Completed"
          : "In Progress"}
        :&nbsp;{value}%
      </div>
      <Slider
        // defaultValue={50}
        disabled={readOnly}
        value={value}
        onChange={handleChange}
        aria-label="Default"
        valueLabelDisplay="auto"
        // color={color}
        sx={{ color }}
      />
    </div>
  );
};

export default Progress;
