export function formatFileSize(
  bytes: number,
  decimalPoint: number = 2
): string {
  if (bytes === 0) return "0 Bytes";

  const k: number = 1024;
  const sizes: string[] = [
    "Bytes",
    "KB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB",
  ];

  const i: number = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint))} ${
    sizes[i]
  }`;
}
