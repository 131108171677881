import React, { useEffect } from "react";
import withAuth from "../../../containers/AuthenticationContainer";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { ROUTES } from "../../../constants";
import { useToast } from "../../../hooks/useToast";

const LoginSuccess = ({
  getSocialAuth,
  fetchUserDetails,
  handleRedirection,
  user,
}) => {
  const { login } = useAuth();
  const toast = useToast();
  const { state } = useLocation();
  useEffect(() => {
    getSocialAuth().then((response) => {
      localStorage.setItem("access_token", response.data.token);
      login(response.data.token).then(() => {
        toast.success("Logged in successfully!");
        fetchUserDetails();
      });
    });
  }, []);
  useEffect(() => {
    if (!user.loading && user.value.email)
      handleRedirection(user, state?.path || ROUTES.dashboard);
  }, [user]);

  return (
    // <div className="dark:bg-slate-900 bg-gray-100 flex h-screen items-center py-16">
    <div className="relative bg-gradient-to-bl from-blue-100 via-transparent dark:from-blue-950 dark:via-transparent flex h-screen items-center py-16">
      <div class="max-w-[50rem] flex flex-col mx-auto w-full h-full">
        <header class="mb-auto flex justify-center z-50 w-full py-4">
          <nav class="px-4 sm:px-6 lg:px-8" aria-label="Global">
            <a
              class="flex-none text-xl font-semibold sm:text-3xl dark:text-gray-700"
              href="#"
              aria-label="Brand"
            >
              Brand
            </a>
          </nav>
        </header>

        <div class="text-center py-10 px-4 sm:px-6 lg:px-8">
          <h1 class="block text-6xl font-bold text-gray-800 sm:text-7xl m-10 dark:text-gray-600">
            Login Success
          </h1>
          <h1 class="block text-2xl font-bold text-white"></h1>
          <p class="mt-3 text-gray-600 dark:text-gray-800">
            You will be redirected automatically.
          </p>
        </div>

        <footer class="mt-auto text-center py-5">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <p class="text-sm text-gray-500">
              © All Rights Reserved. Planbow. 2022.
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default withAuth(LoginSuccess);
