import React from "react";
import PlanboardFile from "../../types/interfaces/PlanboardFile";
import IconButton from "../Generic/IconButton";
import { Download, Trash2 } from "lucide-react";
import { useToast } from "../../hooks";
import { useDispatch } from "react-redux";
import { removeAttachment } from "../../redux/slice/planboard";
import { LinearProgress } from "@mui/material";
import { formatFileSize } from "../../utils/fileSize";
import { ROLE_TYPES } from "../../types/types/PlanboardRoles";
import moment from "moment";

interface ListViewProps {
  files: PlanboardFile[];
  planboardId: string;
  selectedFiles: File[];
  userRole: string;
}

const AttachmentListView = ({
  files,
  planboardId,
  selectedFiles,
  userRole,
}: ListViewProps) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const handleFileRemove = async (attachmentId: string) => {
    try {
      // @ts-ignore
      dispatch(removeAttachment({ planboardId, attachmentId }));
    } catch (error) {
      toast.error("Error deleting file");
    }
  };
  return (
    <div className="space-y-3">
      {files.length > 0 &&
        files.map((file: PlanboardFile) => (
          <div
            className="grid grid-cols-5 w-full p-3 dark:hover:bg-slate-800 border rounded-lg group"
            key={file.id}
          >
            <div className="flex col-span-2 items-center dark:text-slate-500">
              {file.name}&nbsp;
            </div>
            <span className="text-xs flex items-center">({file.size})</span>
            <div className="flex items-center justify-end text-sm dark:text-slate-500">
              {/* {moment(file.uploadedOn).format("ddd, MMMM Do YYYY hh:mm a")} */}
              {moment().diff(moment(file.uploadedOn), "days") < 7
                ? moment(file.uploadedOn).format("dddd, hh:mm a")
                : moment(file.uploadedOn).format("MMMM Do YYYY, hh:mm a")}
            </div>
            <div className="flex justify-end items-center">
              <div className="border rounded-lg shadow flex px-1.5 py-0.5 pt-1 invisible group-hover:visible space-x-3">
                <a
                  href={file.mediaUrl}
                  download={file.name}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <IconButton
                    icon={<Download size={16} />}
                    width={5}
                    height={5}
                    tooltipTitle={`Download ${file.name}`}
                    tooltipPlacement="left"
                  />
                </a>
                {(userRole === ROLE_TYPES.COCREATOR ||
                  userRole === ROLE_TYPES.CREATOR) && (
                  <div onClick={() => handleFileRemove(file.id)}>
                    <IconButton
                      icon={<Trash2 size={16} />}
                      width={5}
                      height={5}
                      tooltipTitle={`Delete ${file.name}`}
                      tooltipPlacement="right"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      {selectedFiles.length > 0 &&
        selectedFiles.map((file: File) => (
          <div className="grid grid-cols-3 text-xs">
            <div className="flex items-center text-xs text-gray-500 dark:text-slate-500">
              {file.name}&nbsp;
              <span className="text-xs">({formatFileSize(file.size)})</span>
            </div>
            <div className="col-span-2 flex justify-center my-auto">
              <div className="w-[60%]">
                <LinearProgress />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default AttachmentListView;
