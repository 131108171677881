import React, { useEffect, useState } from "react";
import SelectWithSearch from "./SelectWithSearch";
import { postAuth } from "../../api/client";
import { URLS } from "../../constants";
import { useFormikContext } from "formik";
import ErrorIcon from "../Inputs/ErrorIcon";
import ErrorMessage from "../Inputs/ErrorMessage";
import { Option } from "./DomainSelect";

interface DomainSelectProps {
  field: any;
  form: any;
  // values: { domain: string; subdomain: string };
}

interface RequestData {
  domainId: string;
  search?: string;
}

const SubdomainSelect = ({ field, form }: DomainSelectProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [search, setSearch] = useState("");
  const [domains, setDomains] = useState([]);

  const {
    setFieldValue,
    setFieldTouched,
    setFieldError,
    setErrors,
    validateField,
    errors,
    touched,
  } = useFormikContext();

  const handleChange = (subdomain: {}) => {
    setFieldValue(field.name, subdomain);
    setFieldTouched(field.name, false);
    setError(null);
  };

  const fetchSuggestions = async () => {
    try {
      if (!form.values.domain) return setError("Select a Domain");
      setLoading(true);
      const requestData: RequestData = {
        domainId: form.values.domain.id,
      };
      if (search) requestData.search = search;
      const response = await postAuth(URLS.getSubdomains, requestData);
      setLoading(false);
      if (response.status === 200) {
        const subDomains = response.data.data.map(
          (subDomain: { domainId: string; name: string }) => ({
            id: subDomain.domainId,
            name: subDomain.name,
          })
        );
        setDomains(subDomains);
      }
    } catch (error: any) {}
  };
  useEffect(() => {
    fetchSuggestions();
  }, [search, form.values.domain]);
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  return (
    <div>
      <div className="relative">
        <SelectWithSearch
          id="domain_select_search"
          loading={loading}
          onChange={handleChange}
          onInputChange={handleSearchInputChange}
          options={domains}
          value={form.values.subdomain?.id}
          label="Subdomain"
          placeholder="Subdomain"
          error={error}
        />
        <div className="absolute end-5 top-6">
          <ErrorIcon touched={touched} errors={errors} field={field} />
        </div>
      </div>
      <ErrorMessage touched={touched} errors={errors} field={field} />
    </div>
  );
};

export default SubdomainSelect;
