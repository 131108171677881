import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const messages = [
  "Setting up your organisation…",
  "Creating your workspace…",
  "Creating your first planboard…",
  "Fetching Focus areas for your business problem…",
  "Finishing it up…",
  "Almost done…",
];

const CanvasLoading = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => prevIndex + 1);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col gap-y-5 w-[50%]">
      {messages.slice(0, currentMessageIndex).map((message, index) => (
        <p
          key={index}
          className="text-lg  grid grid-cols-[90%_10%] items-center dark:text-neutral-400"
        >
          {message}
          {index !== messages.length - 1 ? (
            <div className="text-green-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#05cb0f"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-circle-check-big"
              >
                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                <path d="m9 11 3 3L22 4" />
              </svg>
            </div>
          ) : (
            <CircularProgress size={20} />
          )}
        </p>
      ))}
    </div>
  );
};

export default CanvasLoading;
