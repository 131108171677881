import React from "react";
import PlanboardCardGrid from "../PlanboardCardGrid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../constants";
import { Plus } from "lucide-react";

interface PlanboardsListProps {
  planboards: any;
  workspace: { id: string; name: string };
}

const PlanboardsList = ({ planboards, workspace }: PlanboardsListProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleCreatePlanboardNavigate = () => {
    navigate(`${ROUTES.createPlanboard}?workspace=${workspace.id}`, {
      state: { from: location },
    });
  };
  return (
    <>
      {planboards?.length > 0 ? (
        <PlanboardCardGrid planboards={planboards} />
      ) : (
        <div className="max-w-xl mx-auto">
          <div className="text-center p-4 mb-5 space-y-5">
            <div className="mt-4">
              <button
                onClick={handleCreatePlanboardNavigate}
                type="button"
                className=" py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-300 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                <Plus size={18} strokeWidth={2} />
                Create planboard
              </button>
            </div>
            <h1 className="text-gray-800 dark:text-white mb-5">
              This workspace is empty
            </h1>
            {/* <p className="mt-3 text-gray-600 dark:text-gray-400">
              Bases and interfaces in this workspace will appear here.
            </p> */}
          </div>
        </div>
      )}
    </>
  );
};

export default PlanboardsList;
