import React from "react";
interface Option {
  title: string;
  icon: string;
}

interface ButtonGroupProps {
  handleChange: (value: string) => void;
  selectedOption: string | null;
  options: Option[];
  size?: "small" | "large";
}

const ButtonGroup = ({
  handleChange,
  selectedOption,
  options,
  size = "small",
}: ButtonGroupProps) => {
  return (
    <>
      {options.map((option) => (
        <label
          htmlFor={"hs-pro-donpf1" + option.title}
          className="relative flex cursor-pointer text-sm focus:outline-none"
        >
          <input
            type="radio"
            id={"hs-pro-donpf1" + option.title}
            name="hs-pro-donpf"
            className="peer absolute hidden top-0 start-0 size-full bg-transparent border-gray-200 text-transparent rounded-xl cursor-pointer focus:ring-0 focus:ring-offset-0 after:relative after:-z-[1] after:block after:size-full after:rounded-lg checked:after:bg-blue-50 checked:text-transparent checked:border-blue-600 checked:hover:border-blue-600 checked:focus:border-blue-600 checked:bg-none disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:checked:after:bg-blue-500/10 dark:checked:border-blue-500"
            onChange={() => handleChange(option.title)}
          />
          <span
            className={`${
              size === "large" ? "w-full min-h-40" : ""
            } peer-checked:text-blue-600 border peer-checked:border-blue-500 peer-checked:bg-blue-50 dark:text-white dark:peer-checked:text-blue-500 py-3 px-4 rounded-xl capitalize`}
          >
            {size === "large" && (
              <span
                className={`absolute top-4 end-4 ${
                  selectedOption === option.title ? "visible" : "invisible"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-circle-check-big"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <path d="m9 11 3 3L22 4" />
                </svg>
              </span>
            )}
            {option.icon && <img src={option.icon} className="" width="70%" />}
            <span
              className={`${
                size === "large" && !option.icon && "h-full"
              } flex justify-center items-center font-semibold text-xl`}
            >
              {option.title}
            </span>
          </span>
        </label>
      ))}
    </>
  );
};

export default ButtonGroup;
