import React, { useEffect, useState } from "react";
import Sidebar from "../pages/Onboarding/Sidebar";
import { ROUTES } from "../constants";
import CreateOrganization from "../pages/Onboarding/CreateOrganization";
import { useNavigate, useParams } from "react-router-dom";
import WhatIsYourRole from "../pages/Onboarding/WhatIsYourRole";
import WhatBringsYoutoPlanbow from "../pages/Onboarding/WhatBringsYoutoPlanbow";
import WhatIsTeamSize from "../pages/Onboarding/WhatIsTeamSize";
import SelectIndustry from "../pages/Onboarding/SelectIndustry";
import { Error404 } from "../pages";
import SelectSpecialization from "../pages/Onboarding/SelectSpecialization";
import SelectDepartment from "../pages/Onboarding/SelectDepartment";
import SelectSubIndustry from "../pages/Onboarding/SelectSubIndustry";
import SelectObjective from "../pages/Onboarding/SelectObjective";
import SelectSubDepartment from "../pages/Onboarding/SelectSubDepartment";
import SelectSubRole from "../pages/Onboarding/SelectSubRole";
import SelectDeadline from "../pages/Onboarding/SelectDeadline";
import EnterPlanboardName from "../pages/Onboarding/EnterPlanboardName";
import PlanboardForm from "../pages/Onboarding/PlanboardForm";
import InviteUsers from "../pages/Onboarding/InviteUsers";
import { useSelector } from "react-redux";

const ONBOARDING_ROUTES = {
  createOrganisation: "create-organization",
  selectDepartment: "select-department",
  selectSubDepartment: "select-sub-department",
  selectSubRole: "select-sub-role",
  selectIndustry: "select-industry",
  selectSubIndustry: "select-sub-industry",
  selectSpecialization: "select-specialization",
  whatBringsYoutoPlanbow: "what-brings-you-to-planbow",
  whatIsTeamSize: "what-is-team-size",
  whatIsYourRole: "what-is-your-role",
  selectObjective: "select-objective",
  selectDeadline: "select-duration",
  planboardName: "enter-planboard-name",
  problemDetails: "problem-details",
  inviteMembers: "invite-members",
};

const OnboardingRoutes = () => {
  const { step } = useParams();
  const navigate = useNavigate();
  const [onBoardingData, setOnboardingData] = useState();
  const organisation = useSelector((state) => state.organizations.value);

  useEffect(() => {
    if (organisation.length > 0) {
      if (organisation[0].onboarded) {
        return navigate(ROUTES.dashboard, { replace: true });
      } else return navigate(ROUTES.whatBringsYoutoPlanbow, { replace: true });
    }
  }, [organisation]);

  return (
    <main class="flex min-h-full">
      <Sidebar onBoardingData={onBoardingData} />
      <div className="relative grow px-5 dark:bg-slate-900">
        <div className="h-screen w-full flex flex-col items-center justify-center mx-auto overflow-auto">
          {step === ONBOARDING_ROUTES.createOrganisation ? (
            <CreateOrganization />
          ) : step === ONBOARDING_ROUTES.whatIsYourRole ? (
            <WhatIsYourRole setOnboardingData={setOnboardingData} />
          ) : step === ONBOARDING_ROUTES.whatIsTeamSize ? (
            <WhatIsTeamSize setOnboardingData={setOnboardingData} />
          ) : step === ONBOARDING_ROUTES.selectIndustry ? (
            <SelectIndustry setOnboardingData={setOnboardingData} />
          ) : step === ONBOARDING_ROUTES.whatBringsYoutoPlanbow ? (
            <WhatBringsYoutoPlanbow setOnboardingData={setOnboardingData} />
          ) : step === ONBOARDING_ROUTES.selectSpecialization ? (
            <SelectSpecialization setOnboardingData={setOnboardingData} />
          ) : step === ONBOARDING_ROUTES.selectDepartment ? (
            <SelectDepartment setOnboardingData={setOnboardingData} />
          ) : step === ONBOARDING_ROUTES.selectSubIndustry ? (
            <SelectSubIndustry setOnboardingData={setOnboardingData} />
          ) : step === ONBOARDING_ROUTES.selectObjective ? (
            <SelectObjective setOnboardingData={setOnboardingData} />
          ) : step === ONBOARDING_ROUTES.selectSubDepartment ? (
            <SelectSubDepartment setOnboardingData={setOnboardingData} />
          ) : step === ONBOARDING_ROUTES.selectSubRole ? (
            <SelectSubRole setOnboardingData={setOnboardingData} />
          ) : step === ONBOARDING_ROUTES.selectDeadline ? (
            <SelectDeadline setOnboardingData={setOnboardingData} />
          ) : step === ONBOARDING_ROUTES.inviteMembers ? (
            <InviteUsers
              setOnboardingData={setOnboardingData}
              onBoardingData={onBoardingData}
            />
          ) : step === ONBOARDING_ROUTES.planboardName ? (
            <EnterPlanboardName onBoardingData={onBoardingData} />
          ) : step === ONBOARDING_ROUTES.problemDetails ? (
            <PlanboardForm
              onBoardingData={onBoardingData}
              setOnboardingData={setOnboardingData}
            />
          ) : (
            <Error404 />
          )}
        </div>
      </div>
    </main>
  );
};

export default OnboardingRoutes;
