import { Backdrop, Paper, Popover } from "@mui/material";
import TouchRipple, {
  TouchRippleActions,
} from "@mui/material/ButtonBase/TouchRipple";
import React, { useState, useRef, useEffect, ReactNode } from "react";
import ReactDOM from "react-dom";

interface Option {
  name: string;
  id: string;
}

interface SelectWithSearchProps {
  options: Option[];
  value: string;
  id: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  onChange: (option: Option) => void;
  label: string;
  error: null | string;
  placeholder: string;
  optional?: boolean;
}

const SelectWithSearch: React.FC<SelectWithSearchProps> = ({
  options,
  value,
  id,
  onInputChange,
  loading,
  onChange,
  label,
  error,
  placeholder,
  optional = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [anchorElWidth, setAnchorElWidth] = useState<number>(510);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onInputChange(event);
  };

  const handleOptionClick = (option: { id: string; name: string }) => {
    onChange(option);
    setAnchorEl(null);
  };

  const handleSelectClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setAnchorElWidth(event.currentTarget.offsetWidth);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const dropdownId = open ? "simple-popover" : undefined;

  const rippleRef = React.useRef<TouchRippleActions>(null);
  const onRippleStart = (e: any) => {
    rippleRef?.current?.start(e);
  };
  const onRippleStop = (e: any) => {
    rippleRef?.current?.stop(e);
  };

  return (
    <>
      {/* <label
        htmlFor="domain-select"
        className="block text-sm font-medium mb-2 dark:text-white"
      >
        {label}{" "}
        {optional && (
          <span className="italic text-gray-400 text-xs">(optional)</span>
        )}
      </label> */}
      <div className="relative">
        <div
          id="domain-select"
          className="py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer hover:bg-gray-50 focus:bg-gray-50 bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          onClick={handleSelectClick}
          onMouseDown={onRippleStart}
          onMouseUp={onRippleStop}
        >
          {options.find((opt) => opt.id === value)?.name || (
            <div className="text-gray-400">{placeholder}</div>
          )}
          <TouchRipple ref={rippleRef} center={false} />
        </div>
        <div className="absolute top-1/2 end-4 -translate-y-1/2">
          <svg
            className="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="m7 15 5 5 5-5" />
            <path d="m7 9 5-5 5 5" />
          </svg>
        </div>
      </div>
      <Backdrop
        open={open}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Popover
          id={dropdownId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          hideBackdrop={false}
          sx={{
            "& .MuiPaper-root": {
              width: anchorElWidth,
              marginTop: 1,
            },
          }}
        >
          <div
            id="select-dropdown"
            className={`max-h-72 pb-1 px-1 space-y-0.5 w-full bg-white border-gray-200 overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700`}
          >
            <div className="bg-white p-2 -mx-1 sticky top-0 dark:bg-slate-900">
              <input
                type="text"
                className="block border w-full text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 py-2 px-3"
                placeholder="Search..."
                onChange={handleInputChange}
              />
            </div>
            <ul className="">
              {loading ? (
                <li className="cursor-pointer py-2 px-4 w-full text-sm text-gray-800  rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 italic dark:text-gray-200 dark:focus:bg-slate-800">
                  Loading...
                </li>
              ) : options.length ? (
                options.map((option) => (
                  <li
                    key={option.id}
                    className="cursor-pointer py-2 px-4 w-full text-sm text-gray-800 hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.name}
                  </li>
                ))
              ) : (
                <li className="cursor-pointer py-2 px-4 w-full text-sm text-gray-800  rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 italic dark:text-gray-200 dark:focus:bg-slate-800">
                  {error ? error : "No results found"}
                </li>
              )}
            </ul>
          </div>
        </Popover>
      </Backdrop>
    </>
  );
};

export default SelectWithSearch;
