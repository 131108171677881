import React, { useEffect, useState } from "react";
import AttachmentListView from "../Attachments/AttachmentListView";
import NoFilePlaceholder from "../Attachments/NoFilePlaceholder";
import BrowseInput from "../Attachments/BrowseInput";
import { useParams } from "react-router-dom";
import PlanboardsAPI from "../../api/services/Planboard";
import PlanboardFile from "../../types/interfaces/PlanboardFile";
import { useDispatch, useSelector } from "react-redux";
import { updateActivePlanboard } from "../../redux/slice/planboard";
import { RootState } from "../../redux/store";
import Planboard from "../../types/interfaces/Planboard";
import { ROLE_TYPES } from "../../types/types/PlanboardRoles";
import { Paperclip } from "lucide-react";

interface AttachmentsProps {
  attachments: PlanboardFile[];
}

const Attachments = ({ attachments }: AttachmentsProps) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const planboard = useSelector((state: RootState) => state.planboard)
    .value as Planboard;

  const handleFileUpload = async () => {
    try {
      const response = await PlanboardsAPI.addAttachment({
        attachments: selectedFiles,
        planboardId: id ?? "",
      });
      if (response.status === 200) {
        console.log({ response });
        dispatch(
          updateActivePlanboard({
            attachments: [...attachments, ...response.data.data],
          })
        );
        setSelectedFiles([]);
      }
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  // useEffect to listen to any newly added files
  useEffect(() => {
    console.log({ selectedFiles });
    if (selectedFiles.length === 0) return;
    handleFileUpload();
  }, [selectedFiles]);

  return (
    <div>
      {attachments?.length > 0 ? (
        <div className="p-5">
          <AttachmentListView
            files={attachments}
            planboardId={id ?? ""}
            selectedFiles={selectedFiles}
            userRole={planboard.userRole}
          />
          {(planboard.userRole === ROLE_TYPES.COCREATOR ||
            planboard.userRole === ROLE_TYPES.CREATOR) && (
            <div className="text-sm mt-4 flex justify-center">
              <div className="border p-3 py-2 rounded-lg shadow flex items-center gap-x-2">
                <Paperclip
                  size={16}
                  strokeWidth={1.5}
                  className="text-blue-500"
                />
                <BrowseInput
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          {planboard.userRole === ROLE_TYPES.COCREATOR ||
          planboard.userRole === ROLE_TYPES.CREATOR ? (
            <div className="dark:text-slate-500 text-sm italic">
              <NoFilePlaceholder
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
              />
            </div>
          ) : (
            <div className="flex justify-center italic text-gray-500 mb-5">
              No attachments
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Attachments;
