import React from "react";

import PriorityMenu from "./PriorityMenu";
import StatusMenu from "./StatusMenu";
import AccordianDateRange from "./AccordianDateRange";
import AccordianUsers from "../Accordion/AccordianUsers";
import {
  convertDateStringToDate,
  formatDateToDateString,
} from "../../utils/dateTime";
import DueDate from "./DueDate";
import AssignedTo from "../SubTasks/AssignedTo";
import { PlanboardUser } from "../../types/interfaces/PlanboardUser";
import UserChip from "../Generic/UserChip";

interface SecondBandProps {
  element: any;
  updateElement: (id: string, data: {}) => void;
  members: PlanboardUser[];
  readOnly: boolean;
}

const SecondBand = ({
  element,
  updateElement,
  members,
  readOnly,
}: SecondBandProps) => {
  const updatePriority = (value: string) => {
    updateElement(element.id, { priority: value });
  };
  const updateDueDate = (newDate: Date) => {
    updateElement(element.id, {
      endDate: formatDateToDateString(new Date(newDate)),
    });
  };
  const updateAssignedTo = (user: PlanboardUser) => {
    updateElement(element.id, { assignedTo: user });
  };
  return (
    <div className="border dark:border-neutral-500 mt-2 rounded-md items-center p-3 flex justify-between text-xs dark:text-white">
      <PriorityMenu
        updatePriority={updatePriority}
        priority={element?.priority ?? "low"}
        readOnly={readOnly}
      />
      <StatusMenu status={element?.status ?? "todo"} />

      <DueDate
        dueDate={element?.endDate ? new Date(element.endDate) : new Date()}
        updateDueDate={updateDueDate}
        readOnly={readOnly}
      />
      {/* <AccordianUsers /> */}
      <AssignedTo
        assignedTo={element.assignedTo}
        updateAssignedTo={updateAssignedTo}
        members={members}
        readOnly={readOnly}
      />

      <div className="capitalize flex items-center">
        created by:&nbsp;
        <UserChip user={element.createdBy} />
      </div>

      {/* <FileChip /> */}
      {/* <div
            id="icon-list"
            className={`flex gap-x-2 text-gray-400 ${
              hover || open ? "visible" : "invisible"
            }`}
          >
            <IconsSet removeElement={removeElement} elementId={id} />
          </div> */}
    </div>
  );
};

export default SecondBand;
