import React, { useEffect, useState } from "react";
import UsersAPI from "../../api/services/User";
import Spinner from "../Spinner";
import SkeletonAvatar from "./SkeletonAvatar";

type AvatarProps =
  | {
      type: "user";
      id: string;
    }
  | {
      type: "info";
      text: string;
    }
  | {
      type: "image";
      src: string;
      alt: string;
    }
  | {
      type: "skeleton";
    }
  | {
      type: "name";
      name: string;
    };

function generateUserIcon(name: string) {
  const initials = getInitials(name || "");
  const bgColor = createRandomColor();
  return (
    <div
      className={`flex items-center justify-center font-semibold rounded-full text-white w-full h-full ${bgColor}`}
    >
      {initials}
    </div>
  );
}

function getInitials(name: string) {
  // Extract first letter of each word in the name
  return name
    .split(/\s+/)
    .map((word) => word[0]?.toUpperCase())
    .join("");
}

function createRandomColor() {
  const readableColors = [
    "bg-blue-500",
    "bg-green-500",
    "bg-yellow-500",
    "bg-indigo-500",
    "bg-purple-500",
    "bg-pink-500",
    "bg-teal-500",
    "bg-orange-500",
    "bg-red-500",
    "bg-gray-400",
    "bg-blue-400",
    "bg-green-400",
    "bg-yellow-400",
    "bg-indigo-400",
    "bg-purple-400",
    "bg-pink-400",
    "bg-teal-400",
    "bg-orange-400",
    "bg-red-400",
  ];
  return readableColors[Math.floor(Math.random() * readableColors.length)];
}

const Avatar = (props: AvatarProps) => {
  const [loading, setLoading] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [name, setName] = useState("");

  const fetchDetails = async (id: string) => {
    if (id) {
      try {
        const response = await UsersAPI.get(id);
        const user = response.data?.data;
        setProfilePic(user.profilePic || "");
        setName(user.fullName || "");
      } catch (error) {
        console.log({ error });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (props.type === "user") {
      setLoading(true);
      fetchDetails(props.id);
    }
  }, []);

  const renderIcon = () => {
    if (profilePic) {
      return (
        <img
          src={profilePic}
          alt="User Profile Picture"
          className="ring-2 ring-white"
        />
      );
    } else if (props.type === "skeleton") {
      return <SkeletonAvatar />;
    } else if (props.type === "info") {
      return <InfoAvatar text={props.text} />;
    } else if (props.type === "image") {
      return (
        <img src={props.src} alt={props.alt} className="ring-2 ring-white" />
      );
    } else if (props.type === "name") {
      return generateUserIcon(props.name);
    } else {
      return generateUserIcon(name);
    }
  };

  return (
    <div className="w-8 h-8 text-xs rounded-full overflow-hidden">
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Spinner />
        </div>
      ) : (
        renderIcon()
      )}
    </div>
  );
};

const InfoAvatar = ({ text }: { text: string }) => {
  return (
    <button
      id="hs-avatar-group-dropdown"
      className="hs-dropdown-toggle inline-flex items-center justify-center h-full w-full rounded-full border border-gray-300 font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-300 focus:outline-none focus:bg-blue-100 focus:text-blue-600 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-800 dark:text-gray-400 dark:hover:text-white dark:focus:bg-blue-100 dark:focus:text-blue-600 dark:focus:ring-offset-gray-800"
    >
      <span className="font-medium leading-none">{text}</span>
    </button>
  );
};

export default Avatar;
