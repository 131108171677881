import React from "react";
import NotificationLabel from "./Label";
import Content from "./Content";

const Notification = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="hs-dropdown relative inline-flex [--auto-close:inside]">
      <NotificationLabel handleClick={handleClick} />

      <Content anchorEl={anchorEl} handleClose={handleClose} />
    </div>
  );
};

export default Notification;
