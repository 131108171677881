import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const withPlanboardDesigner = (WrappedComponent) => {
  return function PlanboardDesignerContainer() {
    const props = {};
    return <WrappedComponent {...props} />;
  };
};

export default withPlanboardDesigner;
