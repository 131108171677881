import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../constants';
import Email from '../components/Inputs/Email';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import withAuth from '../containers/AuthenticationContainer';
import ForgotPasswordSuccess from '../components/ForgotPasswordSuccess';

const initialValues = {
    email: '',
};
const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter valid email').required('Required'),
});

function ForgotPassword({
    handleForgotPassword
}) {
    const [responseData, setResponseData] = useState({
        response: null,
        submitted: false,
        email: null
    })
    const handleSubmit = (values, { setSubmitting }) => {
        handleForgotPassword(values).then((response) => {
            if (response.data?.success) {
                setResponseData({
                    email: values.email,
                    submitted: true,
                    response
                })
            }
            setSubmitting(false);
        })
    }
    return (
        <div className="dark:bg-slate-900 bg-gray-100 flex h-screen items-center py-16">
            <main className="w-full max-w-md mx-auto p-6">
                <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-4 sm:p-7">
                        <div className="text-center">
                            <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">Forgot password?</h1>
                            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                                Remember your password?
                                <Link className="text-primary-400 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" to={ROUTES.login}>
                                    &nbsp;Sign in here
                                </Link>
                            </p>
                        </div>
                        {
                            responseData?.submitted ?
                                <ForgotPasswordSuccess responseData={responseData} />
                                :
                                <div className="mt-5">
                                    {/* <!-- Form --> */}
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {
                                            (props) => {
                                                const {
                                                    values,
                                                    isSubmitting,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                } = props;
                                                return (
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="grid gap-y-4">
                                                            {/* <!-- Form Group --> */}
                                                            <Field
                                                                label="email"
                                                                id="email"
                                                                name="email"
                                                                component={Email}
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {/* <!-- End Form Group --> */}

                                                            <button type="submit" disabled={isSubmitting} className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">Reset password</button>
                                                        </div>
                                                    </form>
                                                )
                                            }
                                        }
                                    </Formik>
                                    {/* <!-- End Form --> */}
                                </div>
                        }
                    </div>
                </div>
            </main>
        </div>
    )
}

export default withAuth(ForgotPassword);