import { post, postAuth } from "../client2";

const redirectUri = process.env.REACT_APP_PB_IDP_CALL_BACK_URL;
const clientName = "planbow";
const gatewayUrl =
  process.env.REACT_APP_GATEWAY_URL || "https://dev.gateway.planbow.com";

const LoginAPI = {
  getToken: (code: string) => {
    return post(gatewayUrl + "/core/get-token", {
      data: {
        redirectUri,
        clientName,
        code,
      },
    });
  },
};
export default LoginAPI;
