import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import ButtonGroup from "./ButtonGroup";
import BackButton from "./BackButton";

interface WhatIsTeamSizeProps {
  setOnboardingData: React.Dispatch<React.SetStateAction<{}>>;
}

const options = [
  {
    title: "only me",
    icon: "",
  },
  {
    title: "1-50",
    icon: "",
  },
  {
    title: "51-200",
    icon: "",
  },
  {
    title: "200+",
    icon: "",
  },
];

const WhatIsTeamSize = ({ setOnboardingData }: WhatIsTeamSizeProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    setTimeout(() => handleNext(value), 300);
  };

  const handleNext = (value: string) => {
    setOnboardingData((prev) => ({ ...prev, teamSize: value }));
    // navigate(ROUTES.selectSpecialization);
    navigate(ROUTES.problemDetails);
  };
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!location.state.selectedOption) {
      navigate(ROUTES.whatBringsYoutoPlanbow);
    }
  }, [location]);

  return (
    <div className="w-[500px] space-y-10">
      <div>
        <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
          What is your company size?
        </h1>
      </div>

      <form>
        <div className="grid grid-cols-2 gap-3 mb-5">
          <ButtonGroup
            options={options}
            selectedOption={selectedOption}
            handleChange={handleOptionChange}
            size="large"
          />
        </div>

        <BackButton handleBack={handleBack} />
      </form>
    </div>
  );
};

export default WhatIsTeamSize;
