import React from "react";
import { useSelector } from "react-redux";
import PersonalInfo from "./PersonalInfo";
import Avatar from "./Avatar"
import Password from "./Password";
import ComponentHeader from "../../components/componentHeader";

const Profile = () => {
    const isDarkMode = useSelector((state) => state.dialog.isDarkMode);
    const user = useSelector((state) => state.user.value);
    return (
        <div className="flex h-full">
            <main
                role="main"
                className="w-full shadow-2xl h-screen max-h-[98vh] overflow-y-scroll bg-white border border-gray-200 dark:bg-slate-800 dark:border-gray-700"
                style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            >
                <div className="max-w-[100rem] mx-auto p-5 ">

                    <div className="w-full">
                        <ComponentHeader title="Profile" />
                    </div>

                    <div className=" bg-transparent p-1">
                        <div className="mb-3 ">
                            <p className="text-sm text-gray-500 dark:text-neutral-400">
                                Manage your name, password and account settings.
                            </p>
                        </div>
                        <PersonalInfo user={user} />
                        <Avatar user={user} />
                        <Password user={user} />
                    </div>
                </div>
            </main >
        </div >
    );
};

export default Profile;