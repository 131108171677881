import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import withAuth from '../../containers/AuthenticationContainer';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Password from '../../components/Inputs/Password';
import { ROUTES } from '../../constants';

const initialValues = {
    password: '',
    confirmPassword: ''
};
const validationSchema = Yup.object().shape({
    password: Yup.string().min(6, 'Password minimum length should be 6').required('Required'),
    confirmPassword: Yup.string().min(6, 'Password minimum length should be 6').required('Required'),
});

function RegisterPassword({
    handleRegisterPassword
}) {
    const navigate = useNavigate();
    const location = useLocation();
    const { name, id, email } = location?.state || {};
    const handleSubmit = (values, { setSubmitting }) => {
        handleRegisterPassword(id, values).then((response) => {
            setSubmitting(false);
            if (response.data.message === 'success') {
                navigate(ROUTES.login);
            }
        })
    }

    // password validation code to be added
    return (
        <div className="dark:bg-slate-900 bg-gray-100 flex h-screen items-center py-16">
            <main className="w-full max-w-md mx-auto p-6">
                <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-4 sm:p-7">
                        <div className="text-center mb-6">
                            <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">Create your Password</h1>
                        </div>
                        {/* <!-- Form --> */}
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {
                                (props) => {
                                    const {
                                        values,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="grid gap-y-4">
                                                {/* <!-- Form Group --> */}
                                                <Field
                                                    label="Password"
                                                    id="password"
                                                    name="password"
                                                    component={Password}
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    showForgotPassword={false}
                                                />
                                                {/* <!-- End Form Group --> */}

                                                {/* <!-- Form Group --> */}
                                                <Field
                                                    label="Confirm Password"
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    component={Password}
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    showForgotPassword={false}
                                                />
                                                {/* <!-- End Form Group --> */}

                                                <button disabled={isSubmitting} type="submit" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">Submit</button>
                                            </div>
                                        </form>
                                    )
                                }}
                        </Formik>
                        {/* <!-- End Form --> */}
                    </div>
                </div>
            </main >
        </div>
    )
}

export default withAuth(RegisterPassword);