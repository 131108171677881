import React, { useState } from "react";
import IconButton from "../Generic/IconButton";
import { Pencil, RotateCw, X } from "lucide-react";

interface AccordianProps {
  element: any;
  // index?: number;
  updateElement: (id: string, data: any) => void;
}

const iconSize = 12;

const AccordianTitle = ({ element, updateElement }: AccordianProps) => {
  const [hovered, setHovered] = useState(false);
  const [edit, setEdit] = useState(false);
  const [newTitle, setNewTitle] = useState(element.title);
  const handleInputChange = (e: any) => {
    setNewTitle(e.target.value);
  };
  const handleUpdateClick = async () => {
    updateElement(element.id, { title: newTitle });
    setEdit(false);
  };
  const handleEditClick = () => {
    setEdit(true);
  };

  const handleCancelClick = () => {
    setNewTitle(element.title ?? "Title");
    setEdit(false);
    setHovered(false);
  };

  return (
    <>
      {edit ? (
        <form onSubmit={handleUpdateClick} className="w-full">
          <div className="flex items-center grow">
            <input
              type="text"
              value={newTitle}
              onChange={handleInputChange}
              autoFocus
              className="border-gray-500 flex grow border-b outline-none focus:border-blue-500 mr-2 text-black bg-gray-100 px-2 rounded-md dark:bg-secondary-500 dark:text-white"
            />
            <IconButton
              type="submit"
              icon={<RotateCw size={iconSize} strokeWidth={1.5} />}
              tooltipTitle="Update"
              tooltipPlacement="left"
            />
            <IconButton
              icon={<X size={iconSize} strokeWidth={1.5} />}
              tooltipTitle="Cancel"
              tooltipPlacement="right"
              onClick={handleCancelClick}
            />
          </div>
        </form>
      ) : (
        <div
          id="workspace-title"
          className="flex items-center pr-20 gap-x-4"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {newTitle}
          {hovered && (
            <IconButton
              icon={<Pencil size={iconSize} strokeWidth={1.5} />}
              tooltipTitle="Edit"
              tooltipPlacement="right"
              onClick={handleEditClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AccordianTitle;
