import React, { useState } from "react";
import UserAvatar from "../../../assets/images/user.webp";
import { FullMember } from "../Member";

const UserAutoComplete = ({
  suggestions,
  maxSuggestions = 4,
  setInput,
  input,
  setSelected,
}: any) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      // Enter key
      setInput(suggestions[activeSuggestion]);
      setActiveSuggestion(0);
      setShowSuggestions(false);
    } else if (e.keyCode === 38) {
      // Up arrow
      if (activeSuggestion === 0) return;
      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.keyCode === 40) {
      // Down arrow
      if (activeSuggestion === suggestions.length - 1) return;
      setActiveSuggestion(activeSuggestion + 1);
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    setShowSuggestions(true);
  };

  const handleNoSuggestion = () => {
    setSelected({ email: input });
    setShowSuggestions(false);
    setActiveSuggestion(0);
  };

  const onClick = (suggestion: FullMember) => {
    setSelected({ ...suggestion, type: "full" });
    setInput(suggestion.name);
    setActiveSuggestion(0);
    setShowSuggestions(false);
  };


  return (
    <div className="autocomplete">
      <input
        type="text"
        value={input}
        onChange={onChange}
        onKeyDown={onKeyDown}
        className="py-3 px-4 block border dark:border-none w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
        placeholder="Start typing email..."
      />
      {showSuggestions && suggestions.length > 0 ? (

        <ul className="dark:bg-gray-900 dark:text-white p-2 mt-2 rounded-lg space-y-3">
          {suggestions.map((suggestion: any, index: any) => (
           
              <li
                key={suggestion}
                className={` ${index === activeSuggestion
                  ? "dark:bg-gray-800"
                  : "hover:bg-gray-200 dark:hover:bg-slate-800"
                  }  cursor-pointer rounded-md px-2 py-2`}
                onClick={() => onClick(suggestion)}
              >

                <div className="flex hover:bg-gray-100 dark:hover:bg-gray-900 p-2 rounded-md">
                  <img
                    src={
                      suggestion.profilePic ? suggestion.profilePic : UserAvatar
                    }
                    className="w-10 h-10 rounded-full dark:bg-white"
                  />
                  <div className="flex flex-col ms-3">
                    <span className="text-sm">{suggestion.name}</span>
                    <span className="text-xs text-gray-500">
                      {suggestion.email}
                    </span>
                  </div>
                </div>

              </li>
            
          ))}
        </ul>
      )
        // : input.length ? (
        //   <ul className="dark:bg-gray-900 bg-gray-200 dark:text-white p-2 rounded-lg space-y-3">
        //     <li
        //       className={` ${0 === activeSuggestion
        //         ? "dark:bg-gray-800"
        //         : "hover:bg-gray-200 dark:hover:bg-slate-800"
        //         }  cursor-pointer rounded-md px-2 py-2`}
        //       onClick={handleNoSuggestion}
        //     >
        //       <div className="flex hover:bg-gray-100 dark:hover:bg-gray-900 p-2 rounded-md">
        //         <img
        //           src={EmailDark}
        //           className="w-10 h-10 rounded-full hidden dark:block"
        //         />
        //         <img
        //           src={EmailLight}
        //           className="w-10 h-10 rounded-full dark:hidden"
        //         />
        //         <div className="flex flex-col ms-3">
        //           <span className="dark:text-gray-100">{input}</span>
        //           <span className="text-xs text-gray-500">
        //             Invite to your planboard
        //           </span>
        //         </div>
        //       </div>
        //     </li>
        //   </ul>
        // )
          : null}
    </div>
  );
};

export default UserAutoComplete;
