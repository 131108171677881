import React, { useEffect, useState } from "react";
import EventCard from "./EventCard";
import EventsAPI from "../../api/services/Events";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useToast } from "../../hooks";
import { formatDateToDateString } from "../../utils/dateTime";
import CreateEventDialog from "./CreateEventDialog";
import EventDetailsDialog from "./EventDetailsDialog";

const sideEvents = [
  {
    name: "Kick off meeting",
    description: "Planboard - Startup India Recognition",
    timestamp: "9:30AM",
  },
  {
    name: "Brainstorming workshop",
    description: "Planboard - Financial Planning",
    timestamp: "12:00PM",
  },
  {
    name: "Follow-up meeting",
    description: "Planboard - New Product Launch",
    timestamp: "9:30AM",
  },
  {
    name: "Status Check",
    description: "Planboard - New Product Launch",
    timestamp: "9:30AM",
  },
  {
    name: "Daily Catch-up",
    description: "Planboard - New Product Launch",
    timestamp: "9:30AM",
  },
];

const UpcomingEvents = ({ readOnly }) => {
  const [events, setEvents] = useState({});
  const [selectedEventEdit, setSelectedEventEdit] = useState();
  const { id } = useParams();
  const toast = useToast();

  const fetchUpcomingEvents = async () => {
    if (!id) return;
    try {
      const response = await EventsAPI.upcomingEvents({
        // start: formatDateToDateString(
        //   new Date(moment(new Date()).startOf("month"))
        // ),
        start: formatDateToDateString(new Date()),
        planboardId: id,
      });
      if (response.status === 200) {
        console.log({ response });
        setEvents(response.data.data);
      } else {
        toast.error("Error while fetching upcoming events");
      }
      return;
    } catch (error) {
      console.log({ error });
      toast.error("Error while fetching upcoming events");
    }
  };

  const handlePostSubmit = () => {
    fetchUpcomingEvents();
  };

  const handleRemoveEvent = async (id) => {
    try {
      const response = await EventsAPI.cancelEvent(id);
      if (response.status === 200) {
        toast.success("Event removed successfully");
        fetchUpcomingEvents();
      } else {
        toast.error("Error removing the event");
      }
    } catch (error) {
      console.log({ error });
      toast.error("Error removing the event");
    }
  };

  useEffect(() => {
    fetchUpcomingEvents();
  }, []);
  // useEffect(() => {
  //   console.log({ events });
  // }, [events]);
  return (
    <>
      {/* <div className="border rounded-md mt-2 h-full overflow-auto"> */}
      <div className="p-2">
        <div className="mb-2 dark:text-neutral-300">Today</div>
        {events?.today?.length ? (
          events?.today?.map((event, index) => {
            return (
              <EventCard
                key={index}
                data={event}
                setSelectedEventEdit={setSelectedEventEdit}
                handleRemoveEvent={handleRemoveEvent}
                readOnly={readOnly}
              />
            );
          })
        ) : (
          <div className="text-center italic text-sm dark:text-neutral-300">
            No events today
          </div>
        )}
      </div>
      <div className="border-t mt-4" />
      <div className="p-2">
        <div className="my-2 dark:text-neutral-300">Tomorrow</div>
        {events?.tomorrow?.length ? (
          events?.tomorrow?.map((event, index) => {
            return (
              <EventCard
                key={index}
                data={event}
                setSelectedEventEdit={setSelectedEventEdit}
                handleRemoveEvent={handleRemoveEvent}
                readOnly={readOnly}
              />
            );
          })
        ) : (
          <div className="text-center italic text-sm dark:text-neutral-300">
            No events tomorrow
          </div>
        )}
      </div>
      {/* </div> */}
      {Boolean(selectedEventEdit) && (
        <CreateEventDialog
          action="update"
          eventId={selectedEventEdit.id}
          handleClose={() => setSelectedEventEdit(null)}
          open={Boolean(selectedEventEdit)}
          selectedEvent={selectedEventEdit}
          postSubmit={handlePostSubmit}
        />
      )}
    </>
  );
};

export default UpcomingEvents;
