import React, { useEffect, useState } from "react";
// import { Scheduler } from "@aldabil/react-scheduler";
import { useDispatch, useSelector } from "react-redux";
// import { getAllEvents } from "../../redux/slice/events/eventsSlice";
import EventCalendar from "./EventCalendar";
import EventFilter from "./EventFilter";
import EventsAPI from "../../api/services/Events";
import moment from "moment";
import { formatDateToDateString } from "../../utils/dateTime";
import { useParams } from "react-router-dom";
import { addDays, addWeeks, addMonths, addYears } from "date-fns";
import UpcomingEvents from "./UpcomingEvents";
import ComponentHeader from "../componentHeader";
import Time from "../../assets/3DIcons/Time.png";
import { ROLE_TYPES } from "../../types/types/PlanboardRoles";

export default function Events() {
  const { id } = useParams();
  // const events = useSelector((state) => state.events);
  // const dispatch = useDispatch();
  const [meetingFilter, setMeetingFilter] = useState([]);
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState();
  const [rawData, setRawData] = useState([]);

  const planboard = useSelector((state) => state.planboard);
  const user = useSelector((state) => state.user.value);

  const readOnly =
    planboard.value?.userRole === ROLE_TYPES.CREATOR ||
    planboard.value?.userRole === ROLE_TYPES.COCREATOR;

  // const allEvents = events?.value?.map((item) => {
  //   return {
  //     title: item.configuration?.eventType ?? item?.title ?? "No Title",
  //     event_id: item._id,
  //     start: new Date(item.configuration.date),
  //     end: new Date(item.configuration.date),
  //     allDay: "All Day",
  //   };
  // });
  // console.log(allEvents);

  const fetchEvents = async (start, end) => {
    try {
      const response = await EventsAPI.getEvents({
        start: formatDateToDateString(new Date(start)),
        end: formatDateToDateString(new Date(end)),
        meetingTypeIds: meetingFilter,
        planboardId: id,
      });
      if (response.status === 200) {
        setRawData(response.data.data);
        setCurrentDate({ start: new Date(start), end: new Date(end) });
        createEvents(response.data.data);
      }
      console.log({ response });
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchEvents(
      moment(new Date()).startOf("month"),
      moment(new Date()).endOf("month")
    );
  }, []);

  const createEvents = (data) => {
    const events = [];
    const recurrenceTypes = Object.keys(data);

    recurrenceTypes.forEach((type) => {
      data[type].forEach((event) => {
        if (planboard.value.userRole === ROLE_TYPES.CONTRIBUTOR) {
          if (event.createdBy != user.id) return;
        }
        const startUTC = moment(event.start).utcOffset("GMT-05:30");
        const start = new Date(startUTC._d);

        const endUTC = moment(event.end).utcOffset("GMT-05:30");
        const end = new Date(endUTC._d);

        switch (type) {
          case "neverRepeat":
            events.push({
              title: event.title,
              start: start,
              end: end,
              id: event.id,
              type: "neverRepeat",
            });
            break;
          case "daily":
            for (let i = 0; i < 365; i++) {
              events.push({
                title: event.title,
                start: addDays(start, i),
                end: addDays(end, i),
                id: event.id,
                type: "daily",
              });
            }
            break;
          case "weekly":
            for (let i = 0; i < 52; i++) {
              events.push({
                title: event.title,
                start: addWeeks(start, i),
                end: addWeeks(end, i),
                id: event.id,
                type: "weekly",
              });
            }
            break;
          case "monthly":
            for (let i = 0; i < 12; i++) {
              events.push({
                title: event.title,
                start: addMonths(start, i),
                end: addMonths(end, i),
                id: event.id,
                type: "monthly",
              });
            }
            break;
          case "yearly":
            for (let i = 0; i < 10; i++) {
              events.push({
                title: event.title,
                start: addYears(start, i),
                end: addYears(end, i),
                id: event.id,
              });
            }
            break;
          default:
            break;
        }
      });
    });
    // if(planboard.value.userRole === ROLE_TYPES.CONTRIBUTOR){
    //   events.filter
    // }

    console.log({ events });
    setEvents(events);
    return events;
  };

  return (
    <div className="w-full text-gray-800 h-full m-5 shadow-2xl rounded-xl bg-white mt-0 p-3 flex flex-col dark:text-nuetral-200 dark:bg-slate-800 ">
      <div className="w-full">
        <ComponentHeader title="Meetings" image={Time} />
      </div>
      <div className="flex justify-between h-full">
        <div className="w-[24%] border rounded-md p-2 overflow-hidden">
          <UpcomingEvents readOnly={readOnly} />
        </div>
        <div className="w-[75%] flex flex-col h-full overflow-auto">
          {/* <EventFilter setMeetingFilter={setMeetingFilter} /> */}
          <EventCalendar
            fetchEvents={fetchEvents}
            events={events}
            currentDate={currentDate}
            rawData={rawData}
            readOnly={readOnly}
          />
          {/* <Scheduler
          view="month"
          events={allEvents}
          sx={{
            "& .css-ncbr6b": {
              zIndex: "10",
            },
          }}
        /> */}
        </div>
      </div>
    </div>
  );
}
