import React, { ReactNode } from "react";
interface DrawerProps {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
}
export default function Drawer({ children, isOpen, onClose }: DrawerProps) {
  return (
    <main
      className={
        " fixed z-10 bg-gray-900 bg-opacity-90 inset-0 transform ease-in-out disabled:pointer-events-none " +
        (isOpen
          ? " transition-opacity opacity-100 duration-500 translate-x-0  "
          : " transition-all delay-500 opacity-0 translate-x-full  ")
      }
    >
      <section
        className={
          " w-[90vw] right-0 absolute bg-slate-50 dark:bg-slate-900 h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
          (isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        <article className="relative pb-2 flex flex-col h-full">
          {children}
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer "
        onClick={onClose ? onClose : undefined}
      ></section>
    </main>
  );
}

export const DrawerHeader = ({ children }: { children: ReactNode }) => {
  return (
    // <header className="pt-4 px-6 font-bold text-lg dark:text-white">
    <header>{children}</header>
  );
};
