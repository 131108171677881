import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import PlanboardsAPI from "../../../api/services/Planboard";

const initialState = {
  error: false,
  loading: false,
  value: {},
};

const planboardsSlice = createSlice({
  name: "planboards",
  initialState,
  reducers: {
    setPlanboards: (state, action) => {
      state.value = { ...action.payload };
    },
    addPlanboard: (state, action) => {
      const newPlanboard = action.payload.data;
      const newState = current(state);
      state.value = {
        ...newState.value,
        [newPlanboard.workspaceId]: [
          ...newState.value[newPlanboard.workspaceId],
          newPlanboard,
        ],
      };
    },
    removePlanboard: (state, action) => {
      const { workspaceId, id } = action.payload;
      const newState = current(state);

      state.value = {
        ...newState.value,
        [workspaceId]: newState.value[workspaceId].filter(
          (planboard) => planboard._id !== id
        ),
      };
    },
    updatePlanboard: (state, action) => {
      const { workspaceId, id, updatedData } = action.payload;
      const newState = current(state);
      state.value = {
        ...newState.value,
        [workspaceId]: newState.value[workspaceId].map((planboard) =>
          planboard._id === id ? { ...planboard, ...updatedData } : planboard
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlanboards.pending, (state) => {
        console.log("getPlanboards.pending");
        state.loading = true;
      })
      .addCase(getPlanboards.fulfilled, (state, action) => {
        if (action.payload.error) {
          console.log("getPlanboards.failed");
          state.value = {};
          state.loading = false;
          state.error = true;
        } else {
          console.log("getPlanboards.success");
          const { workspaceId, data } = action.payload;
          state.value[workspaceId] = [...data];
          state.loading = false;
        }
      });
  },
});

export const getPlanboards = createAsyncThunk(
  "planboards/get",
  async ({ workspaceId, planboardIds }) => {
    try {
      const response = await PlanboardsAPI.getPlanboards({ ids: planboardIds });
      return {
        data: response.data.data,
        workspaceId,
      };
    } catch (error) {
      console.log(error);
      return {
        data: {},
        error: true,
      };
    }
  }
);

export const { setPlanboards, addPlanboard, removePlanboard, updatePlanboard } =
  planboardsSlice.actions;

export default planboardsSlice.reducer;
