import { useDispatch } from "react-redux";
import { ROUTES, loginPageUrl } from "../../constants";
import { logout } from "../../redux/slice/auth/authSlice";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clearLocalToken = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("routeState");
  };

  clearLocalToken();
  dispatch(logout());
  navigate(ROUTES.signin);

  // const navigate = useNavigate();
  // React.useEffect(() => {
  //   const revokeToken = async () => {
  //     console.log("revoke token");
  //     const access_token = localStorage.getItem("access_token");

  //     if (!access_token) return;
  //     try {
  //       const url = process.env.REACT_APP_GATEWAY_URL + "/secured/revoke-token";
  //       let config = {
  //         headers: {
  //           authorization: "Bearer " + access_token,
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //       };
  //       const requestData = { data: { clientName: "planbow" } };
  //       await axios.post(url, requestData, config);
  //       return;
  //     } catch (error) {
  //       console.log({ error });
  //       return;
  //     }
  //   };
  //   revokeToken();
  //   localStorage.clear();
  //   navigate(ROUTES.root);
  // });
};

export default Logout;
