import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
interface ComponentHeaderProps {
  title: string;
}

const ComponentHeader = ({ title }: ComponentHeaderProps) => {
  const isDarkMode = useSelector((state: RootState) => state.dialog.isDarkMode);

  return (
    <div>
      <div
        className={`mb-3 rounded-t flex justify-between items-center shadow-sm dark:shadow-2xl ${
          isDarkMode
            ? "dark:bg-blue-950"
            : "bg-gradient-to-r from-blue-100 to-sky-100"
        }`}
      >
        <div className="grow py-1 px-5 ">
          <h2 className="text-lg text-blue-800 dark:text-gray-200">{title}</h2>
        </div>
      </div>
    </div>
  );
};

export default ComponentHeader;
