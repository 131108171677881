import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PlanboardsAPI from "../../../api/services/Planboard";
import { addPlanboard } from "../planboards/planboardsSlice";
import {
  formatDateToDateString,
  getDaysRemaining,
} from "../../../utils/dateTime";
import { ROLE_TYPES } from "../../../types/types/PlanboardRoles";

const initialState = {
  error: null,
  loading: false,
  value: {},
};

const planboardSlice = createSlice({
  name: "planboard",
  initialState,
  reducers: {
    resetPlanboardState: () => initialState,
    updateActivePlanboard: (state, action) => {
      state.value = {
        ...state.value,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPlanboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPlanboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createPlanboard.fulfilled, (state, action) => {
        state.loading = false;
        // if (action.payload.error) {
        //   state.error = action.payload;
        // } else {
        state.value = action.payload.data;
        // }
      })
      .addCase(getPlanboardSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlanboardSummary.rejected, (state, action) => {
        console.log({ action });
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPlanboardSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      });
  },
});

export const removeAttachment = createAsyncThunk(
  "planboard/removeAttachment",
  async ({ planboardId, attachmentId }, { dispatch, getState }) => {
    try {
      // Optimistically remove the attachment from state
      const state = getState();
      const planboard = state.planboard.value;
      const updatedAttachments = planboard.attachments.filter(
        (file) => file.id !== attachmentId
      );
      dispatch(
        updateActivePlanboard({ ...planboard, attachments: updatedAttachments })
      );

      const response = await PlanboardsAPI.removeAttachment({
        attachmentId,
        planboardId,
      });

      if (response.data.error) {
        // If there's an error, add the attachment back to state
        dispatch(updateActivePlanboard(planboard));
        throw new Error(response.data.error);
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createPlanboard = createAsyncThunk(
  "planboard/create",
  async ({
    category,
    description,
    endDate,
    name,
    users,
    workspaceId,
    business,
    strategy,
  }) => {
    try {
      const response = await PlanboardsAPI.create({
        category,
        description,
        endDate,
        name,
        users,
        workspaceId,
        business,
        strategy,
      });
      if (response.data?.success) addPlanboard(response.data.data);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const getUserRole = (planboard, user) => {
  if (planboard?.createdBy?.id === user?.value?.id) return ROLE_TYPES.CREATOR;

  const member = planboard?.members.find(
    (member) => member.userId == user?.value?.id
  );
  return String(member?.role).toLowerCase() || ROLE_TYPES.VIEWER;
};

export const getPlanboardSummary = createAsyncThunk(
  "planboard/get",
  async (id, { rejectWithValue, getState }) => {
    try {
      const response = await PlanboardsAPI.getSummary(id);
      console.log({ response });
      if (response.data.error)
        return rejectWithValue("Failed to load planboard summary");
      if (response.status === 200) {
        const planboard = response.data.data;
        const user = getState().user;
        const userRole = getUserRole(planboard, user);

        return { ...planboard, userRole };
      }
    } catch (error) {
      console.log({ error });
      return rejectWithValue("Error getting planboard summary");
    }
  }
);

// export const addMember = createAsyncThunk(
//   "planboard/addMember/action",
//   async ({ member, planboardId }, { rejectWithValue }) => {
//     try {
//       const response = await PlanboardsAPI.addMember({ member, planboardId });
//       if (response.data.error)
//         return rejectWithValue("Failed to load planboard summary");
//       if (response.status === 200) {
//         return response.data.data;
//       }
//     } catch (error) {
//       return rejectWithValue("Error adding member");
//     }
//   }
// );

export const updatePlanboard = createAsyncThunk(
  "planboard/update",
  async (updateData, { dispatch }) => {
    try {
      let response, daysLeft;
      if (updateData.endDate) {
        // Convert end date from  Date Object to stringt in dd-mm-yyyy format
        daysLeft = getDaysRemaining(new Date(updateData.endDate));
        response = await PlanboardsAPI.updatePlanboard({
          ...updateData,
          endDate: formatDateToDateString(new Date(updateData.endDate)),
        });
      } else {
        response = await PlanboardsAPI.updatePlanboard(updateData);
      }

      if (response.status === 200) {
        if (updateData.endDate)
          dispatch(updateActivePlanboard({ ...updateData, daysLeft }));
        else dispatch(updateActivePlanboard(updateData)); // Update state
        return response.data;
      } else {
        throw new Error(response.data.error || "Update failed");
      }
    } catch (error) {
      throw error; // Re-throw error so it can be handled in your component
    }
  }
);
export const { resetPlanboardState, updateActivePlanboard } =
  planboardSlice.actions;
export default planboardSlice.reducer;
