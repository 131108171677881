import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import {
  ArrowDownFromLine,
  ArrowUpFromLine,
  CalendarCheck,
  CalendarClock,
  ClipboardList,
  Copy,
  Filter,
  Maximize2,
  Minimize2,
  MoreVertical,
  Paperclip,
  Pencil,
  Share,
  Tag,
  Trash2,
  Users,
  X,
} from "lucide-react";
import IconButton from "../Generic/IconButton";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: 12,
      marginRight: 4,
      marginLeft: 4,
      "& .lucide": {
        width: 14,
        height: 14,
        marginRight: 6,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 14,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface AccordianMenuProps {
  deleteActionItem: (id: string) => void;
  id: string;
}

export default function AccordianMenu({
  deleteActionItem,
  id,
}: AccordianMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteActionItem(id);
    handleClose();
  };

  return (
    <div>
      <div onClick={handleClick}>
        <IconButton icon={<MoreVertical size={16} color="#c0c0c0" />} />
      </div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Pencil color="#808080" />
          Rename Title
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <Pencil color="#808080" />
          Edit Description
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <Trash2 color="#808080" />
          Delete
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <Minimize2 color="#808080" />
          Collapse
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Maximize2 color="#808080" />
          Expand
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>
          <Pencil color="#808080" />
          Rename
        </MenuItem> */}
        {/* <Divider sx={{ my: 0.5 }} /> */}
        {/* <MenuItem onClick={handleClose}>
          <Copy color="#808080" />
          Duplicate
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>
          <ArrowUpFromLine color="#808080" />
          Move Up
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ArrowDownFromLine color="#808080" />
          Move Down
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>
          <Share color="#808080" />
          Share
        </MenuItem> */}
      </StyledMenu>
    </div>
  );
}
