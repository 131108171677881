import React, { useEffect, useState } from "react";
import Select from "../Generic/Select";
import GlobalAPI from "../../api/services/Global";
import { Field } from "formik";
import DatePicker from "../Inputs/DatePicker";

const generateTimeOptions = (
  start_time = "00:00",
  end_time = "23:30",
  interval = 15
) => {
  /**
   * Generates an array of time options for a dropdown menu.
   *
   * Args:
   *  start_time (str, optional): The starting time in "HH:MM" format. Defaults to "09:00".
   *  end_time (str, optional): The ending time in "HH:MM" format. Defaults to "17:00".
   *  interval (int, optional): The interval between time options in minutes. Defaults to 15.
   *
   * Returns:
   *  list: An array of objects with a "value" property representing the time in "HH:MM AM/PM" format.
   */

  const timeOptions = [];

  const [startHour, startMinute] = start_time.split(":").map(Number);
  const [endHour, endMinute] = end_time.split(":").map(Number);

  let currentDate = new Date();
  currentDate.setHours(startHour, startMinute, 0, 0);

  while (
    currentDate.getHours() < endHour ||
    (currentDate.getHours() === endHour &&
      currentDate.getMinutes() <= endMinute)
  ) {
    let hours = currentDate.getHours().toString().padStart(2, "0"); //% 12 || 12; // 12-hour format
    let minutes = currentDate.getMinutes().toString().padStart(2, "0");
    let ampm = currentDate.getHours() >= 12 ? "PM" : "AM";

    timeOptions.push({
      value: `${hours}:${minutes} ${ampm}`,
    });

    currentDate.setMinutes(currentDate.getMinutes() + interval);
  }

  return timeOptions;
};
const timeOptions = generateTimeOptions();

const Events = ({
  values,
  handleChange,
  handleBlur,
  errors,
  setErrors,
}: any) => {
  const [event, setEvent] = useState({
    eventType: "",
    date: new Date(),
    timeStart: timeOptions[36].value,
    timeEnd: timeOptions[40].value,
  });
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  // console.log({ values });
  // console.log({ errors });

  const handleEventTypeChange = (value: string) => {
    handleChange({ target: { name: "schedule.meetingTypeId", value } });
  };

  const handleDateChange = (value: any) => {
    handleChange({
      target: { name: "schedule.date", value: value.target.value },
    });
  };

  const handleTimeChange = (val: string, value: string) => {
    handleChange({ target: { name: "schedule." + val, value } });
  };

  useEffect(() => {
    const getOptions = async () => {
      const response = await GlobalAPI.getMeetingTypes();
      if (response.status !== 200) return alert("Failed to fetch Event Types");
      if (response.status === 200) {
        setOptions(() =>
          response.data.data.map(
            ({ id, name }: { id: string; name: string }) => ({
              value: id,
              label: name,
            })
          )
        );
      }
    };
    getOptions();
  }, []);

  return (
    <div>
      {/* <label
        htmlFor="create-planboard-event"
        className="text-sm dark:text-white"
      >
        Schedule event
        <span className="italic text-sm text-gray-500">&nbsp;(optional)</span>
      </label> */}
      <div
        id="create-planboard-event"
        className={`mt-2 border ${
          errors.schedule ? "border-red-300" : "border-gray-200"
        } rounded-lg p-5 flex items-center justify-center dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400`}
      >
        <div className="grid grid-cols-3 gap-4 w-full">
          <div className="col-span-2">
            <label className="text-sm">Meeting Type</label>
            <div className="h-[45px]">
              <Select
                onChange={handleEventTypeChange}
                options={options}
                value={values?.schedule?.meetingTypeId}
                loadingOptions={loadingOptions}
                placeholder="Choose a meeting type..."
              />
            </div>
            {errors?.schedule?.meetingTypeId && (
              <p className="flex text-xs text-red-600 mt-2" id="email-error">
                {errors?.schedule?.meetingTypeId}
              </p>
            )}
          </div>
          <div>
            <label className="text-sm">Start Time</label>
            <div className="h-[45px]">
              <Select
                onChange={(value) => handleTimeChange("start", value)}
                options={timeOptions}
                value={values?.schedule?.start}
              />
            </div>
            {errors?.schedule?.start && (
              <p className="flex text-xs text-red-600 mt-2" id="email-error">
                {errors?.schedule?.start}
              </p>
            )}
          </div>
          <div className="col-span-2">
            <label className="text-sm">Date</label>
            <Field
              // label="Planboard End Date"
              id="schedule.date"
              name="schedule.date"
              component={DatePicker}
              hideLabel={true}
              value={values?.schedule?.date}
              onChange={handleDateChange}
              placeholder="Planboard End Date"
            />
            {errors?.schedule?.date && (
              <p className="flex text-xs text-red-600 mt-2" id="email-error">
                {errors?.schedule?.date}
              </p>
            )}
          </div>
          <div className="">
            <label className="text-sm">End Time</label>
            <div className="h-[45px]">
              <Select
                onChange={(value) => handleTimeChange("end", value)}
                options={timeOptions}
                value={values?.schedule?.end}
              />
            </div>
            {errors?.schedule?.end && (
              <p className="flex text-xs text-red-600 mt-2" id="email-error">
                {errors?.schedule?.end}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
