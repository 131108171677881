import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  getIncomers,
  getOutgoers,
  Panel,
  useReactFlow,
  MiniMap,
} from "reactflow";
import "./flow.css";
import "reactflow/dist/style.css";
import CustomNode from "./CustomNode";
import SkeletonNode from "./SkeletonNode";
import StartNode from "./StartNode";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ContextMenu from "./ContextMenu";
import { useCanvas, useGlobal, useToast } from "../../hooks";
import ComponentDrawer from "../ComponentDrawer";
import { useDispatch, useSelector } from "react-redux";
import { addNodes } from "../../redux/slice/canvas/canvasSlice";
import AssignActionItemModal from "../Modals/AssignActionItemModal";
import NodesAPI from "../../api/services/Nodes";
import { ROUTES } from "../../constants";
import FloatingEdge from "./FloatingEdge";
import FloatingConnectionLine from "./FloatingConnectionLine";
import ComponentHeader from "../componentHeader";
import ColorModel from "../Modals/ColorModel";
import { FLOATING_EDGE_TYPE } from "../../hooks/useCanvas";
import PlanboardsAPI from "../../api/services/Planboard";
import CanvasLoading from "./CanvasLoading";
import { ROLE_TYPES } from "../../types/types/PlanboardRoles";

const nodeTypes = {
  custom: CustomNode,
  start: StartNode,
  skeleton: SkeletonNode,
};
const edgeTypes = {
  floating: FloatingEdge,
};

const onInit = (rfInstance) => {};

let intervalId;

const Flow = ({ selectedNav, ...props }) => {
  const { id } = useParams();
  const {
    addNewNode,
    deleteMiddleNode,
    fetchCanvas,
    getPureNodes,
    handleEdgeConnect,
    resizeObserverErrorHandler,
    updateNodePosition,
  } = useCanvas();

  const { fitView, zoomTo } = useReactFlow();
  const { debounce } = useGlobal();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [menu, setMenu] = useState(null);
  const navigate = useNavigate();
  const ref = useRef(null);
  const contextMenuRef = useRef(null);
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();

  const planboard = useSelector((state) => state.planboard);

  useEffect(() => {
    if (loading) return;
    if (!nodes) return;
    if (nodes?.length === 0) return;
    if (location?.state?.nodeId) {
      const element = nodes.find((node) => node.id === location.state.nodeId);
      if (element.id) {
        fitView({ nodes: [element] });
        zoomTo(0.67);
      }
    }
    if (location?.state?.actionItemId) {
      const element = nodes.find((node) => node.id === location.state.nodeId);
      if (element) {
        setSelectedNode(element);
        setIsDrawerOpen(element);
      }
    }
  }, [location, loading, nodes]);

  const onConnect = useCallback((params) => {
    const newEdge = {
      source: params.source,
      target: params.target,
      type: FLOATING_EDGE_TYPE,
      id: `reactflow__edge-${params.source}-${params.target}`,
      style: { strokeWidth: 3 },
    };
    // handleEdgesDelete({ type: "new-edge", data: newEdge });
    handleEdgeConnect(newEdge);
  }, []);

  const edgesWithUpdatedTypes = edges?.map((edge) => {
    if (edge.sourceHandle) {
      const edgeType = nodes.find((node) => node.type === "custom").data
        .selects[edge.sourceHandle];
      edge.type = edgeType;
    }

    return edge;
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCloseDrawer = (nodeId) => {
    if (location?.state?.actionItemId) {
      navigate(ROUTES.planboardDesigner + "/" + id, {
        state: { from: location.state.from },
      });
    }
    getNodeDetails(nodeId);
    setIsDrawerOpen(false);
    setTimeout(() => {
      setSelectedNode(null);
    }, 500);
  };

  const onNodeClick = useCallback((event, element) => {
    console.log({ element });
    if (menu) setMenu(false);
    if (element.type === "skeleton") {
      const parent = getIncomers(element, nodes, edges);
      console.log({ parent });
      if (parent.length > 0) {
        addNewNode(parent[0]);
      }
      return;
    } else if (element.type === "custom") {
      setSelectedNode(element);
      setIsDrawerOpen(element);
    }
  });

  const onEdgeClick = useCallback((event, element) => {
    if (menu) setMenu(false);
  });

  const onNodesDelete = useCallback(
    (deleted) => {
      deleteMiddleNode(deleted);
    },
    [nodes, edges]
  );

  const onNodeDragStop = (e, node) => {
    console.log({ e, node });

    // if (node.type === "skeleton") {
    //   const parent = getIncomers(node, nodes, edges);
    //   addNewNode(parent[0]);
    //   return;
    // }
    const skeletonChildren = getOutgoers(node, nodes, edges).filter(
      (node) => node.type === "skeleton"
    );
    if (skeletonChildren.length > 0) {
      updateChildSkeleton(node, skeletonChildren[0]);
    }
    if (node.type === "custom") {
      return updateNodePosition(node);
    }
  };

  const updateChildSkeleton = (parent, skeletonChildren) => {
    console.log({ parent });
    console.log("update child skeleton");
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === skeletonChildren.id
          ? {
              ...node,
              position: {
                x: parent.position.x + 450,
                // y: parent.position.y + 107.2,
                y: parent.position.y + parent.height * 0.5,
              },
            }
          : node
      )
    );
  };

  const onNodeContextMenu = useCallback(
    (event, node) => {
      event.preventDefault();
      if (node.type !== "custom") return;
      setSelectedNode(node);
      const pane = ref.current.getBoundingClientRect();
      setMenu({
        id: node.id,
        top:
          event.clientY < pane.height - 200 &&
          (event.pageY - event.clientY < 100
            ? event.clientY - 200
            : event.clientY),
        left: event.clientX < pane.width - 200 && event.clientX,
        right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
        bottom:
          event.clientY >= pane.height - 200 && pane.height - event.clientY,
      });
    },
    [setMenu]
  );

  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  const getNodeDetails = async (nodeId) => {
    try {
      const response = await NodesAPI.getNodeDetails(nodeId);
      setNodes((prev) =>
        prev.map((node) => {
          if (node.id === nodeId) {
            const newNodeData = response.data.data;
            return {
              id: node.id,
              type: "custom",
              position: {
                x: newNodeData.metaData.x_position,
                y: newNodeData.metaData.y_position,
              },
              data: {
                label: newNodeData.title,
                description: newNodeData.description,
                background: newNodeData.metaData.color,
                actionItems: newNodeData?.actionItems ?? 0,
                subTasks: newNodeData?.subTasks ?? 0,
                events: newNodeData?.events ?? 0,
                assignedTo: newNodeData?.assignedTo ?? null,
                endDate: newNodeData?.endDate ?? null,
                color: node?.metaData?.color,
              },
            };
          } else {
            return node;
          }
        })
      );
    } catch (error) {
      if (error.response.status === 404) {
        return toast.error("Provided Node Id does not exists");
      } else return toast.error("Unable to get node details");
    }
  };

  const handleColorChange = async () => {
    const updatedNode = { ...selectedNode };

    updatedNode.data = {
      ...updatedNode.data,
      color: selectedColor,
    };

    try {
      // await NodesAPI.updateNode(updatedNode.id, id, {
      //   metaData: {
      //     x_position: updatedNode?.position?.x,
      //     y_position: updatedNode?.position?.y,
      //     color: selectedColor,
      //   },
      // });
      // setNodes((prevNodes) => {
      //   return prevNodes.map((node) => {
      //     if (node.id === updatedNode.id) {
      //       return updatedNode;
      //     }
      //     return node;
      //   });
      // });
      // const updatedTasks = tasks.map((t) => {
      //   if (t.id === task.nodeId) {
      //     return {
      //       ...t,
      //       endDate: formatDateToDateString(new Date(task.end)),
      //     };
      //   }
      //   return t;
      // });
      // console.log({ updatedTasks });
      // setTasks(updatedTasks);
    } catch (error) {
      console.log(error);
      // if (error.response.status === 401)
      //   console.error("Failed to update task dates:", error);
    }

    setNodes((prevNodes) => {
      return prevNodes.map((node) => {
        if (node.id === updatedNode.id) {
          return updatedNode;
        }
        return node;
      });
    });
  };

  useEffect(() => {
    handleColorChange();
  }, [selectedColor]);

  useEffect(() => {
    if (planboard.loading === true) return;
    console.log({ planboard });
    if (id !== "new") {
      setLoading(true);
      if (
        location?.state?.from?.pathname !== "/onboarding/enter-planboard-name"
      ) {
        fetchCanvas(setNodes, setEdges).then(() => setLoading(false));
      } else {
        intervalId = setInterval(async () => {
          try {
            const response = await PlanboardsAPI.planboardBuildStatus(id);
            if (response.data.data.status == "completed") {
              console.log("completed build");
              navigate(ROUTES.planboardDesigner + "/" + id);
              clearInterval(intervalId);
              fetchCanvas(setNodes, setEdges).then(() => setLoading(false));
            }
          } catch (error) {
            clearInterval(intervalId);
            setLoading(false);
            console.log({ error });
          }
        }, 3500);
      }
    }
  }, [id, planboard.loading]);

  useEffect(() => {
    const { nodes: pureNodes, edges: pureEdges } = getPureNodes({
      nodes,
      edges,
    });
    dispatch(
      addNodes(
        pureNodes.map((node) => {
          return { id: node.id, label: node.data.label };
        })
      )
    );
  }, [nodes.length]);

  useEffect(() => {
    console.log({ nodes });
  }, [nodes]);

  const handleClickOutsideContextMenu = (event) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target)
    ) {
      setMenu(null);
    }
  };

  useEffect(() => {
    zoomTo(0.5);
    document.addEventListener("mousedown", handleClickOutsideContextMenu);
    window.addEventListener("error", resizeObserverErrorHandler);
    return () => {
      window.removeEventListener("error", resizeObserverErrorHandler);
      document.removeEventListener("mousedown", handleClickOutsideContextMenu);
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return (
    <>
      {loading ? (
        <div className="grow flex items-center justify-center animate-pulse dark:text-neutral-300">
          {location?.state?.from?.pathname === ROUTES.enterPlanboardName ? (
            <CanvasLoading />
          ) : (
            "Loading your canvas..."
          )}
        </div>
      ) : (
        <>
          <div
            className={`${
              selectedNav === "canvas" ? "flex grow" : "hidden grow"
            } overflow-auto m-5 my-0 border-gray-300 shadow-2xl rounded-xl  bg-white dark:bg-slate-800`}
          >
            <div className="w-full">
              {/* <div className="w-full">
                <ComponentHeader title="Analytics" image={TimeIcon} />
              </div> */}
              <ReactFlow
                {...props}
                // className="h-screen"
                ref={ref}
                nodes={nodes}
                edges={edgesWithUpdatedTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={onInit}
                // fitView
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                proOptions={{ hideAttribution: true }}
                onNodeClick={onNodeClick}
                onEdgeClick={onEdgeClick}
                onNodesDelete={onNodesDelete}
                // onEdgesDelete={onEdgesDelete}
                onNodeDragStop={onNodeDragStop}
                onPaneClick={onPaneClick}
                onNodeContextMenu={onNodeContextMenu}
                minZoom={0.125}
                // defaultViewport={zoomTo(0.67)}
                panOnScroll={true}
                panOnDrag={true}
                panOnScrollMode="free"
                deleteKeyCode={null}
                connectionLineComponent={FloatingConnectionLine}
                nodesDraggable={
                  planboard.value?.userRole === ROLE_TYPES.COCREATOR ||
                  planboard.value?.userRole === ROLE_TYPES.CREATOR
                    ? true
                    : false
                }
              >
                <Controls />
                <MiniMap />
                <Background color="pink" gap={16} />
                {menu && (
                  <div
                    className=" flex items-center justify-between"
                    ref={contextMenuRef}
                  >
                    <ContextMenu
                      // onClick={onPaneClick}
                      {...menu}
                      // colorChangeInputRef={colorChangeInputRef}
                      setColor={setColor}
                      handleClose={() => setMenu(null)}
                      selectedNode={selectedNode}
                    />
                  </div>
                )}
                {/* <input
                  id="colorpicker"
                  type="color"
                  value={
                    selectedNode?.data?.color
                      ? selectedNode.data.color
                      : "#2563eb" ?? "#2563eb"
                  }
                  className="hidden"
                  ref={colorChangeInputRef}
                  onChange={handleColorChange}
                /> */}
              </ReactFlow>
            </div>
            <AssignActionItemModal
              element={selectedNode}
              updateNodeDetails={getNodeDetails}
            />
            <ColorModel
              color={color}
              setColor={setColor}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
            <ComponentDrawer
              node={selectedNode}
              open={isDrawerOpen}
              onClose={() => handleCloseDrawer(selectedNode.id)}
            />
          </div>
        </>
      )}
    </>
  );
};

const Canvas = (props) => {
  return (
    <ReactFlowProvider>
      <Flow {...props} />
    </ReactFlowProvider>
  );
};

export default Canvas;
