const Label = ({ label, htmlFor }: { label: string; htmlFor: string }) => (
  <div className="flex items-center" id={"label-" + htmlFor}>
    <label
      htmlFor={htmlFor}
      className="inline-block dark:text-neutral-400 text-black"
    >
      {label}
    </label>
  </div>
);
export default Label;
