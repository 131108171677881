import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const usePageName = () => {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState(null);
  useEffect(() => {
    const pathname = location.pathname;
    // console.log(pathname);
    setActiveRoute(pathname);
  }, [location.pathname]);
  return {
    activeRoute,
  };
};
