import { USERS_URLS } from "../../constants/index.jsx";
import { postAuth, postFormDataAuth } from "../client";

const Users1API = {
  getUser: (userId: string) => {
    return postAuth(USERS_URLS.getUser, { userId });
  },
  updateUser: (name?: string, gender?: string, contactNo?: string) => {
    return postAuth(USERS_URLS.updateUser, {
      name,
      gender,
      contactNo,
    });
  },
  changePassword: (oldPassword: string, newPassword: string) => {
    return postAuth(USERS_URLS.changePassword, { oldPassword, newPassword });
  },

  setPassword: (password: string) => {
    return postAuth(USERS_URLS.setPassword, { password });
  },

  uploadProfile: (file: File) => {
    return postFormDataAuth(USERS_URLS.uploadProfile, {}, file);
  },

  orgnaizationBelonging: () => {
    return postAuth(USERS_URLS.orgnaizationBelonging, {});
  },
};

export default Users1API;
