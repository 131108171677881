import React from 'react'

export default function ForgotPasswordSuccess({
    responseData
}) {
    return (
        <div className="mt-5">
            <p class="dark:text-white">
                If we found a user with email address {responseData.email} in the account, you will receive an
                email from us shortly.
            </p>
            <p class="dark:text-white">
                If you do not receive the email within a few minutes, please check your junk/spam
                email folder or try our Email FAQs & troubleshooting page.
            </p>
            <p class="dark:text-white">
                Unsure which email you used for your planbow.cloud account, or not sure what account you
                are associated with? Contact us
            </p>
            {
                responseData.response === 'google' ?
                    <p class="dark:text-white">
                        It seems like you have logged In using your Google Account. Please try logging In
                        using Google.
                    </p>
                    : null
            }
        </div>
    )
}
