function Fallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p className="dark:text-white">
        Something went wrong while rendering this component:
      </p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}
export default Fallback;
