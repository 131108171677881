import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Planboard from "../../types/interfaces/Planboard";
import { Send, ShieldCheck, Trash2, UserPlus } from "lucide-react";
import Button from "../Generic/Button";
import InviteMembersModal from "../CreatePlanboardSteps/components/InviteMembersModal";
import { PlanboardUser } from "../../types/interfaces/PlanboardUser";
import UserAvatar from "../../assets/images/user.webp";
import Select from "../Generic/Select";
import IconButton from "../Generic/IconButton";
import PlanboardsAPI from "../../api/services/Planboard";
import {
  getPlanboardSummary,
  updateActivePlanboard,
} from "../../redux/slice/planboard";
import { useToast } from "../../hooks";
import { ROLE_TYPES } from "../../types/types/PlanboardRoles";
import moment from "moment";

const Members = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const planboardValue: Planboard = useSelector(
    (state: RootState) => state.planboard
  ).value as Planboard;
  const ViewerRoleTypes = [{ value: "Viewer", label: "Can View" }]
  const roleTypes = [
    { value: "Viewer", label: "Can View" }, { value: "Contributor", label: "Can Contribute" }, { value: "Creator", label: "Can Edit" }
  ]

  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleAddMember = async (member: any) => {
    console.log(member, "asdf")

    // setSelectedMembers([...selectedMembers, { ...member, role: "Viewer" }]);
    if (member.email === planboardValue?.createdBy?.email) {
      return setError("Cannot add the creator");
    }
    let newMember: any = {
      role: member.role || "Viewer",
      userId: member.id
    };

    try {
      const response = await PlanboardsAPI.addMember({
        member: newMember,
        planboardId: planboardValue.planboardId,
      });
      if (response.status === 200) {
        const newUser: PlanboardUser = {
          userId: member.id ? member.id : null,
          email: member.email,
          profilePic: member.profilePic ? member.profilePic : null,
          role: member.role,
          name: member.name ? member.name : null,
          gender: member.gender ? member.gender : null,
        };
        dispatch(
          updateActivePlanboard({
            ...planboardValue,
            members:
              planboardValue.members !== null
                ? [...planboardValue.members, newUser]
                : [newUser],
          })
        );
        return;
        //@ts-ignore
        //   dispatch(getPlanboardSummary(planboardValue.planboardId));
      } else return setError("error adding member");
    } catch (error: any) {
      if (error.response?.data?.data) {
        toast.error(error.response.data.data);
        return setError(error.response.data.data);
      }
      return setError("An error occurred while trying to add the member");
    }
  };
  const handleRemoveUser = async (member: PlanboardUser) => {
    const memberId = member.userId ? member.userId : member.email;
    try {
      const response = await PlanboardsAPI.removeMember({
        memberId,
        planboardId: planboardValue.planboardId,
      });
      if (response.status === 200) {
        dispatch(
          //@ts-ignore
          updateActivePlanboard({
            ...planboardValue,
            members: planboardValue.members?.filter(
              (item: PlanboardUser) => item.email !== member.email
            ),
          })
        );
        return;
      } else return setError("Failed to remove user from planboard.");
    } catch (error) {
      return setError("Failed to remove user");
    }
  };
  const updateRole = async (member: PlanboardUser, role: string) => {
    if (member.role === role) return;
    const memberId = member.userId ? member.userId : member.email;
    try {
      const response = await PlanboardsAPI.updateMemberRole({
        memberId,
        role,
        planboardId: planboardValue.planboardId,
      });
      if (response.status === 200) {
        const updatedMembers = planboardValue.members!.map((m) =>
          m.email === member.email ? { ...m, role } : m
        );
        dispatch(
          updateActivePlanboard({ ...planboardValue, members: updatedMembers })
        );
      } else return setError("Updating role failed.");
    } catch (error) {
      return setError("Could not update role");
    }
  };
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);
  console.log(planboardValue)
  return (
    <div>
      <div
        id="invite-members"
        className={` ${planboardValue?.members?.length ? "mt-2 p-5" : "p-5 pt-0"
          }  dark:border-gray-700 dark:text-gray-400 space-y-3`}
      >
        <div
          className={`flex items-center justify-between bg-white p-3 border rounded-lg dark:bg-slate-900 dark:border-neutral-500 ${planboardValue?.createdBy ? "" : "hidden"
            }`}
        >
          <div className="flex">
            <img
              src={
                planboardValue?.createdBy?.profilePic
                  ? planboardValue.createdBy.profilePic
                  : UserAvatar
              }
              className="w-10 h-10 rounded-full dark:bg-white dark:border-black"
            />
            <div className="flex flex-col ps-5">
              {planboardValue?.createdBy?.name ? (
                <>
                  <span className="">
                    {planboardValue.createdBy.name}{" "}
                    {/* <span className="italic text-gray-500">
                      &nbsp;&nbsp;(Creator)
                    </span> */}
                    <span className="hidden md:inline-flex items-center gap-x-1.5 py-1 px-2.5 text-xs font-medium bg-green-100 text-green-700 rounded-full dark:bg-neutral-700 dark:text-neutral-200">
                      Owner
                    </span>
                  </span>
                  <span className="text-xs">
                    ({planboardValue.createdBy.email})
                  </span>
                </>
              ) : (
                <>
                  <span className="text-sm">
                    {planboardValue?.createdBy?.email}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        {planboardValue?.members?.length ? (
          <>
            {planboardValue.members.map((member: PlanboardUser) => (
              <div
                key={`${member.email}-user`}
                className="flex items-center justify-between bg-white p-3 border rounded-lg group dark:bg-slate-900 dark:border-neutral-500"
              >
                <div className="flex">
                  <img
                    src={member.profilePic ? member.profilePic : UserAvatar}
                    className="w-10 h-10 rounded-full dark:bg-white dark:border-black"
                  />
                  <div className="flex flex-col ps-5">
                    {member.name ? (
                      <>
                        <span className="">{member.name}</span>
                        <span className="text-xs">({member.email})</span>
                      </>
                    ) : (
                      <>
                        <span className="text-sm">{member.email}</span>
                      </>
                    )}
                  </div>
                </div>
                {/* <div
                  className={`text-sm justify-center items-center gap-x-1.5 flex dark:text-white px-3 pt-1.5 pb-2.5 rounded-lg ${member.status === "Pending" || member.status === "pending"
                    ? "bg-yellow-100 text-yellow-600"
                    : "bg-blue-100 text-blue-600"
                    }`}
                >
                  {member.status === "Pending" ||
                    member.status === "pending" ? (
                    <div className="flex items-center gap-x-3">
                      Pending <Send size={16} strokeWidth={1} />
                    </div>
                  ) : (
                    <div className="flex items-center gap-x-3">
                      Active
                      <ShieldCheck size={16} strokeWidth={1} />
                    </div>
                  )}
                </div> */}
                <div className="text-sm">
                  Added On: {moment(new Date()).format("DD-MM-yyyy")}
                </div>
                {planboardValue.userRole === ROLE_TYPES.CREATOR ||
                  planboardValue.userRole === ROLE_TYPES.COCREATOR ? (
                  <div className="flex items-center space-x-5">
                    <Select
                      onChange={(newRole: string) =>
                        updateRole(member, newRole)
                      }
                      options={member?.role === ROLE_TYPES.VIEWER ? ViewerRoleTypes : roleTypes}
                      value={member?.role ?? "Viewer"}
                    />
                    <div className="invisible group-hover:visible border rounded-lg shadow flex px-1.5 py-0.5">
                      <IconButton
                        icon={<Trash2 size={16} />}
                        tooltipTitle="Remove"
                        onClick={() => handleRemoveUser(member)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="text-sm text-gray-500">
                    (&nbsp;{member?.role ?? "Viewer"}&nbsp;)
                  </div>
                )}
              </div>
            ))}
            {(planboardValue.userRole === ROLE_TYPES.CREATOR ||
              planboardValue.userRole === ROLE_TYPES.COCREATOR) && (
                <div className="flex justify-center">
                  <p
                    className="py-2 px-3 mt-5 inline-flex items-center gap-x-1 text-sm rounded-lg bg-white border border-gray-200 dark:border-0 shadow-sm disabled:opacity-50 disabled:pointer-events-none font-semibold text-primary-400 cursor-pointer hover:underline "
                    onClick={() => setOpen(!open)}
                  >
                    <UserPlus size={16} />
                    Add People
                  </p>
                </div>
              )}
          </>
        ) : (
          <>
            <div className="flex flex-col items-center space-y-5 ps-10">
              <UserPlus size={40} color="#979797" />
              You haven't invited any collaborators yet
              <Button
                startIcon={<UserPlus size={16} />}
                label="Add People"
                onClick={() => setOpen(!open)}
              />
            </div>
          </>
        )}
      </div>
      {error && <p className="text-sm italic text-red-600">{error}</p>}
      <InviteMembersModal
        open={open}
        onClose={handleClose}
        onSubmit={handleAddMember}
      />
    </div>
  );
};

export default Members;
