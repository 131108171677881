import { UserPlus } from "lucide-react";
import React, { useEffect, useState } from "react";
import IconButton from "../Generic/IconButton";
import Button from "../Generic/Button";
import InviteMembersModal from "./components/InviteMembersModal";
import SelectedUsersList from "./components/SelectedUsersList";
import { Member } from "./Member";

interface InviteMembersProps {
  selectedMembers: Member[];
  setSelectedMembers: React.Dispatch<React.SetStateAction<Member[]>>;
}

const InviteMembers = ({
  setSelectedMembers,
  selectedMembers,
}: InviteMembersProps) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleAddMember = (member: any) => {
    setSelectedMembers([...selectedMembers, { ...member, role: "Viewer" }]);
  };
  return (
    <>
      <div className="flex">
        <label htmlFor="invite-members" className="dark:text-white grow">
          Invite Members
          <span className="italic text-xs text-gray-500">&nbsp;(optional)</span>
        </label>
      </div>
      <div className="space-y-3">
        <div
          id="invite-members"
          className="mt-2 border border-gray-200 rounded-lg p-5 flex items-center justify-center dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
        >
          {selectedMembers.length ? (
            <>
              <SelectedUsersList
                members={selectedMembers}
                setSelectedMembers={setSelectedMembers}
              />
            </>
          ) : (
            <>
              <div className="flex flex-col items-center space-y-5">
                <UserPlus size={40} color="#979797" />
                You haven't invited any collaborators yet
                <Button
                  startIcon={<UserPlus size={16} />}
                  label="Add People"
                  onClick={() => setOpen(!open)}
                />
              </div>
            </>
          )}
        </div>
        {selectedMembers.length > 0 && (
          <div className="flex justify-end">
            <button
              className="py-1 px-3 inline-flex items-center gap-x-1 text-sm rounded-lg border border-gray-200 dark:border-0 shadow-sm disabled:opacity-50 disabled:pointer-events-none font-semibold bg-primary-400 text-white hover:bg-primary-300 "
              onClick={() => setOpen(!open)}
              type="button"
            >
              <UserPlus size={16} />
              Add People
            </button>
          </div>
        )}
      </div>
      <InviteMembersModal
        open={open}
        onClose={handleClose}
        onSubmit={handleAddMember}
      />
    </>
  );
};

export default InviteMembers;
