import { useCallback } from "react";
import {
  useStore,
  getBezierPath,
  BaseEdge,
  EdgeLabelRenderer,
  useReactFlow,
} from "reactflow";

import { getEdgeParams } from "./utils.js";
import { useHover } from "@uidotdev/usehooks";
import { Trash2 } from "lucide-react";
import { Tooltip } from "@mui/material";
import useCanvas from "../../hooks/useCanvas.jsx";

function FloatingEdge({ id, source, target, markerEnd, style, selected }) {
  const { setEdges } = useReactFlow();
  const [ref, hovering] = useHover();
  const { handleEdgeDelete } = useCanvas();

  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode
  );

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  const onEdgeClick = () => {
    handleEdgeDelete(id, source, target);
    // setEdges((edges) => edges.filter((edge) => edge.id !== id));
  };

  return (
    // <path
    //   id={id}
    //   className="react-flow__edge-path"
    //   d={edgePath}
    //   markerEnd={markerEnd}
    //   style={style}
    // />
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: "all",
          }}
          className={`nodrag nopan ${selected ? "" : "hidden"}`}
        >
          <Tooltip title="Delete Edge">
            <Trash2
              onClick={onEdgeClick}
              size={30}
              strokeWidth={1.5}
              className="bg-white hover:stroke-[2] hover:scale-110 rounded-full text-black p-0.5 cursor-pointer hover:text-red-500"
            />
          </Tooltip>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export default FloatingEdge;
