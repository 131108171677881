import React, { createContext, useContext, useState } from "react";
import TestModal from "../components/Modals/TestModal";
import Invite from "../components/Modals/Invite";
import CreateTeam from "../components/Modals/CreateTeam";
// import BaseModal from "../components/Modals";

export const MODAL_TYPES = {
  TEST_MODAL: "TEST_MODAL",
  INVITE_MEMBER: "INVITE_MEMBER",
  CREATE_TEAM: "CREATE_TEAM",
  // BASE_MODAL: "BASE_MODAL",
};

const MODAL_COMPONENTS = {
  [MODAL_TYPES.TEST_MODAL]: TestModal,
  [MODAL_TYPES.INVITE_MEMBER]: Invite,
  [MODAL_TYPES.CREATE_TEAM]: CreateTeam,
  // [MODAL_TYPES.BASE_MODAL]: BaseModal,
};

const initialState = {
  showModal: () => { },
  hideModal: () => { },
  modalId: "",
  modalButtonId: "",
  store: {},
  isDarkMode: false,
};

const GlobalModalContext = createContext(initialState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal = ({ children }) => {
  const [store, setStore] = useState();
  const { modalType, modalProps } = store || {};

  const showModal = (modalType, modalProps = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    });
    document.querySelector(`#${modalProps.modalButtonId}`).click();
  };

  const hideModal = (modalProps) => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
    });
    document.querySelector(`#${modalProps.modalButtonId}`).click();
  };

  const handleDarkMode = () => {
    setStore({
      ...store,
      isDarkMode: !store.isDarkMode, 
    });
  };




  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];
    // if (!modalType || !ModalComponent) {
    //     return null;
    // }
    return (
      <>
        <TestModal {...modalProps} />
        <Invite {...modalProps} />
        <CreateTeam {...modalProps} />
        {/* <BaseModal {...modalProps} /> */}
      </>
    );
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal,handleDarkMode }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
