import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePlanboard } from "../../redux/slice/planboard";
import { useHover } from "@uidotdev/usehooks";
import {
  convertDateStringToDate,
  formatDateToDateString,
  getTimeInHHMMAMPM,
} from "../../utils/dateTime";
// import DatePicker from "../Generic/DatePicker";
import moment from "moment";
import ReadOnlyField from "./ui/ReadOnlyField";
import Label from "./ui/Label";
import UserChip from "../Generic/UserChip";
import { ROLE_TYPES } from "../../types/types/PlanboardRoles";
import { Pencil } from "lucide-react";
import DatePicker from "./ui/DatePicker";

const Info = () => {
  const planboard = useSelector((state) => state.planboard);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const nodeRef = useRef(null);
  const descriptionRef = useRef();
  const [endDateEditable, setEndDateEditable] = useState(false);
  const [name, setName] = useState("");

  const handleBlur = (value, field) => {
    if (planboard.value[field] !== value) {
      dispatch(
        updatePlanboard({
          planboardId: planboard.value.planboardId,
          [field]: value,
        })
      ).catch((error) => console.error("Update Error:", error));
    }
  };

  const handleEndDateChange = (date) => {
    if (new Date(planboard.value.endDate) !== new Date(date)) {
      dispatch(
        updatePlanboard({
          planboardId: planboard.value.planboardId,
          endDate: date,
        })
      ).catch((error) => console.error("Update Error:", error));
    }
    setEndDateEditable(false);
  };

  const handleClickOutside = (event) => {
    if (nodeRef.current && !nodeRef.current.contains(event.target)) {
      setEndDateEditable(false);
    }
  };

  useEffect(() => {
    // if (planboard.value.name) nameRef.current.value = planboard.value.name;
    if (planboard.value.name) setName(planboard.value.name);
    // if (planboard?.value.description)
    //   descriptionRef.current.value = planboard.value.description;
  }, [planboard.value]);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="grid lg:grid-cols-2 gap-y-8 gap-x-8 dark:focus:bg-slate-900 ms-3 pb-5">
      <div className="grid grid-cols-[20%_76%]">
        <Label htmlFor="summary-name" label="Planboard Name" />
        <div className="" id="summary-name">
          {planboard.loading ? (
            <div className="w-60 h-4 ms-4 animate-pulse bg-gray-200 rounded-full dark:bg-slate-700" />
          ) : (
            <>
              {planboard.value?.userRole === ROLE_TYPES.CREATOR ||
              planboard.value?.userRole === ROLE_TYPES.COCREATOR ? (
                <div className="group flex items-center">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="py-2 px-3 block w-96 border border-white group-hover:border-gray-300 rounded-lg text-black focus:text-black focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent  dark:text-neutral-300 dark:border-neutral-500 dark:placeholder-neutral-400 dark:focus:ring-neutral-600"
                    onBlur={(e) => handleBlur(e.target.value, "name")}
                  />
                  <p className="invisible ms-5 group-hover:visible text-gray-400">
                    <Pencil size={16} />
                  </p>
                  {/* <p className="mt-2 text-xs text-gray-500 dark:text-neutral-400">
                    Enter planboard name
                  </p> */}
                </div>
              ) : (
                <ReadOnlyField
                  value={planboard?.value?.name || "Planboard Name"}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="grid grid-cols-[20%_76%]">
        <Label htmlFor="summary-objective" label="Objective" />
        <div className="" id="summary-objective">
          {planboard.loading ? (
            <div className="w-60 h-4 ms-4 animate-pulse bg-gray-200 rounded-full dark:bg-gray-700" />
          ) : (
            <>
              {planboard.value?.userRole === ROLE_TYPES.CREATOR ||
              planboard.value?.userRole === ROLE_TYPES.COCREATOR ? (
                <div className="group flex items-center">
                  <input
                    ref={descriptionRef}
                    onBlur={(e) => handleBlur(e.target.value, "description")}
                    placeholder="Enter your objective"
                    className="py-2 px-3 block w-96 border border-white group-hover:border-gray-300 rounded-lg focus:text-black focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-transparent dark:text-neutral-300 dark:border-neutral-500 dark:placeholder-neutral-400 dark:focus:ring-neutral-600"
                  />
                  <p className="invisible ms-5 group-hover:visible text-gray-400">
                    <Pencil size={16} />
                  </p>
                  {/* <p className="mt-2 text-xs text-gray-500 dark:text-neutral-400">
                    Enter your Objective
                  </p> */}
                </div>
              ) : (
                <ReadOnlyField
                  value={
                    planboard?.value?.description || (
                      <p className="italic text-gray-500">No description</p>
                    )
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="grid grid-cols-[20%_76%]">
        <div className="h-[24px] flex items-center">
          <Label htmlFor="summary-endDate" label="End Date" />
        </div>
        <div className="" id="summary-endDate">
          {planboard.loading ? (
            <div className="w-60 h-4 ms-4 animate-pulse bg-gray-200 rounded-full dark:bg-gray-700" />
          ) : (
            <>
              {planboard.value?.userRole === ROLE_TYPES.CREATOR ||
              planboard.value?.userRole === ROLE_TYPES.COCREATOR ? (
                <>
                  <DatePicker
                    value={new Date(planboard?.value?.endDate)}
                    handleChange={handleEndDateChange}
                  />
                </>
              ) : (
                <ReadOnlyField
                  value={moment(planboard.value.endDate).format(
                    "dddd, MMMM Do YYYY"
                  )}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="grid grid-cols-[20%_76%] mt-1">
        <Label htmlFor="summary-createdOn" label="Created On" />
        <div className="" id="summary-createdOn">
          {planboard.loading ? (
            <>
              <div className="w-60 h-4 ms-4 animate-pulse bg-gray-200 rounded-full dark:bg-gray-700" />
            </>
          ) : (
            <ReadOnlyField
              value={moment(planboard.value.createdOn).format(
                "dddd, MMMM Do YYYY, h:mm a"
              )}
            />
          )}
        </div>
      </div>
      <div className="grid grid-cols-[20%_76%] mt-1">
        <Label htmlFor="summary-modifiedOn" label="Modified On" />
        <div className="" id="summary-modifiedOn">
          {planboard.loading ? (
            <>
              <div className="w-60 h-4 ms-4 animate-pulse bg-gray-200 rounded-full dark:bg-gray-700" />
            </>
          ) : (
            <ReadOnlyField
              value={moment(planboard.value.createdOn).format(
                "dddd, MMMM Do YYYY, h:mm a"
              )}
            />
          )}
        </div>
      </div>
      {planboard?.value?.createdBy?.email !== user?.value?.email ? (
        <div className="grid grid-cols-[20%_76%]">
          <Label htmlFor="summary-createdBy" label="Created By" />
          <div
            className={`min-w-[20ch] w-[${
              planboard?.value?.createdBy?.email?.length - 4
            }ch]`}
            // className={`w-[20ch]`}
            id="summary-createdBy"
          >
            {planboard.loading ? (
              <div className="w-60 h-4 ms-4 animate-pulse bg-gray-200 rounded-full dark:bg-gray-700" />
            ) : (
              <ReadOnlyField
                value={<UserChip user={planboard.value?.createdBy} />}
              />
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {/* <!-- End Col --> */}
    </div>
  );
};

export default Info;
