import React, { useState } from "react";
import UserAvatar from "../../../assets/images/user.webp";
import IconButton from "../../Generic/IconButton";
import { X } from "lucide-react";
import { Member } from "../Member";
import Select from "../../Generic/Select";
import { Label } from "@mui/icons-material";

const SelectedUser = ({
  setSelected,
  selected,
  handleCancelSelected,
}: {
  setSelected: any;
  selected: any;
  handleCancelSelected: () => void;
}) => {

  const options = [
    { value: "Viewer", label: "Can View" }, { value: "Contributor", label: "Can Contribute" }, { value: "Creator", label: "Can Edit" }
  ]


  const [selectedRole, setSelectedRole] = useState("Can View");
  const handleRoleChange = (value: string) => {
    setSelectedRole(value)
    setSelected({ ...selected, role: value ? value : "Viewer" })
  };
  const renderCancelSelected = () => {
    return (
      <div className="flex">
        <Select
          onChange={handleRoleChange}
          options={options}
          value={selectedRole}
        />
        <IconButton
          icon={<X size={10} />}
          tooltipTitle="Cancel"
          onClick={handleCancelSelected}
        />
      </div>
    );
  };
  return (
    <div className="flex items-center border border-gray-200 dark:border-gray-700 rounded-lg p-1">
      {selected.type === "full" ? (
        <>
          <img
            src={selected.profilePic ? selected.profilePic : UserAvatar}
            className="w-10 h-10 rounded-full dark:bg-white dark:border-black"
          />
          <div className="flex flex-col grow ms-3">
            <span className="text-sm">{selected.name}</span>
            <span className="text-xs text-gray-500">
              {selected.email}&nbsp;
            </span>
          </div>
          {renderCancelSelected()}
        </>
      ) : (
        <>
          <img
            src={UserAvatar}
            className="w-10 h-10 rounded-full dark:bg-white"
          />
          <div className="flex flex-col grow ms-3">
            <span className="text-sm">{selected.email}</span>
            <span className="text-xs text-gray-500 italic">
              Invite to your planboard
            </span>
          </div>
          {renderCancelSelected()}
        </>
      )}
    </div>
  );
};
export default SelectedUser;
