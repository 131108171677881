import { Field, Formik } from "formik";
import React, { useState } from "react";
import DatePicker from "../Inputs/DatePicker";
import Modal, { ModalBody, ModalHeader } from "../Generic/Modal";
import DatePickerCustom from "../Inputs/DatePickerCustom";
// import Tooltip from "../Preline/Tooltip";
import { formatDateToDateString, getDaysRemaining } from "../../utils/dateTime";
import { Tooltip } from "@mui/material";

const buttonStyle =
  "py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary-400 text-white hover:bg-primary-500 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600";

const PlanboardEndDate = ({
  progress,
  daysLeft,
  endDate,
}: {
  progress: number;
  daysLeft: number;
  endDate: string;
}) => {
  return (
    <div className="flex w-52 me-10 items-center gap-x-3">
      <div className="grow">
        <Tooltip title={`${progress}% Complete`}>
          <div
            className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            <div
              className="flex flex-col justify-center rounded-full overflow-hidden bg-primary-400 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </Tooltip>
      </div>
      <Tooltip
        title={`End Date: ${
          endDate === undefined ? "" : formatDateToDateString(new Date(endDate))
        }`}
      >
        <div className="w-10 text-end">
          <span className="text-xs text-gray-500  whitespace-nowrap dark:text-neutral-400">
            {/* {getDaysRemaining(new Date(endDate))} */}
            {daysLeft === undefined
              ? "Loading"
              : daysLeft < 0
              ? progress !== 100
                ? "Delayed"
                : "Completed"
              : daysLeft < 1
              ? "last day"
              : daysLeft < 2
              ? `1 day left`
              : `${daysLeft} days left`}
          </span>
        </div>
      </Tooltip>
    </div>
  );
};

export default PlanboardEndDate;
