import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import Avatar from "../Generic/Avatar";
import { LogOut } from "lucide-react";
import { useAuth } from "../../hooks";
import { closeDarkmode, openDarkmode } from "../../redux/slice/ui/dialogSlice";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import Teams from "../../pages/Teams";
import TeamsAPI from "../../api/services/Teams";
import NewMemberModal from "../Teams/NewMemberModal";

const UserProfile = () => {
  const { value: user } = useSelector((state: RootState) => state.user);
  const isDarkMode = useSelector((state: RootState) => state.dialog.isDarkMode);
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [members, setMembers] = useState([]);

  const getMembers = async () => {
    try {
      const response = await TeamsAPI.getMembers({
        index: 0,
        itemsPerIndex: 50,
      });
      if (response.status === 200) {
        setMembers(response.data.data);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    getMembers();
  }, []);

  const handleLogout = (e: any) => {
    e.preventDefault();
    navigate(ROUTES.logout);
    // logout();
  };

  const handleMode = (event: any) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      dispatch(openDarkmode(isChecked));
    } else {
      dispatch(closeDarkmode(isChecked));
    }
  };


  const getAvatar = () => {
    if (user?.profilePic) {
      return <Avatar src={user.profilePic} alt={user.name} type="image" />;
    } else {
      return <Avatar type="name" name={(user?.name as string) ?? "User"} />;
    }
  };

  return (
    <>
      <div className="hs-dropdown inline-flex   [--strategy:absolute] [--auto-close:inside] [--placement:bottom-right] relative text-start">
        <button
          id="hs-pro-dnad"
          type="button"
          className="inline-flex flex-shrink-0 items-center gap-x-3 text-start rounded-full focus:outline-none focus:bg-gray-100 dark:focus:bg-neutral-800"
        >
          {getAvatar()}
        </button>

        <div
          className="hs-dropdown-menu hs-dropdown-open:opacity-100 w-60 transition-[opacity,margin] duration opacity-0 hidden z-50 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-900"
          aria-labelledby="hs-pro-dad"
        >
          <div className="p-1 border-b border-gray-200 dark:border-neutral-800">
            <Link
              className="py-2 px-3 flex items-center gap-x-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
              to={isDisabled ? "#" : ""}
              onClick={(e) => isDisabled && e.preventDefault()}
            >
              {getAvatar()}

              <div className="grow">
                <span className="text-sm font-semibold text-gray-800 dark:text-neutral-300">
                  Hi,&nbsp;{user.name || "Name"}
                </span>
                <p className="text-xs text-gray-500 dark:text-neutral-500">
                  {user.email || "email@email"}
                </p>
              </div>
            </Link>
          </div>
          <div className="p-1">
            <Link
              className={`flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 ${
                isDisabled ? "opacity-50 pointer-events-none" : ""
              }`}
              to={isDisabled ? "#" : ""}
              onClick={(e) => isDisabled && e.preventDefault()}
            >
              <svg
                className="flex-shrink-0 mt-0.5 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect width="20" height="14" x="2" y="5" rx="2" />
                <line x1="2" x2="22" y1="10" y2="10" />
              </svg>
              Billing
            </Link>
            <Link
              className={`flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 ${
                isDisabled ? "opacity-50 pointer-events-none" : ""
              }`}
              to={isDisabled ? "#" : ""}
              onClick={(e) => isDisabled && e.preventDefault()}
            >
              <svg
                className="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
                <circle cx="12" cy="12" r="3" />
              </svg>
              Settings
            </Link>
            <Link
              className="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
              to={ROUTES.profile}
            >
              <svg
                className="flex-shrink-0 mt-0.5 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
                <circle cx="12" cy="7" r="4" />
              </svg>
              My Profile
            </Link>
          </div>
          <div className="px-4 py-3.5 border-y border-gray-200 dark:border-neutral-800">
            <div className="flex justify-between items-center">
              <label className="text-sm text-gray-800 dark:text-neutral-300">
                Dark mode
              </label>
              <div className="relative inline-block">
                <input
                  data-hs-theme-switch=""
                  className="relative w-[3.25rem] h-7 bg-gray-100 checked:bg-none checked:bg-blue-600 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 ring-1 ring-transparent focus:border-blue-600 focus:ring-blue-600 focus:outline-none appearance-none before:inline-block before:size-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 after:absolute after:end-1.5 after:top-[calc(50%-0.40625rem)] after:w-[.8125rem] after:h-[.8125rem] after:bg-no-repeat after:bg-[right_center] after:bg-[length:.8125em_.8125em] after:transform after:transition-all after:ease-in-out after:duration-200 after:opacity-70 checked:after:start-1.5 checked:after:end-auto"
                  type="checkbox"
                  onChange={handleMode}
                  checked={isDarkMode}
                />
              </div>
            </div>
          </div>
          <div className="p-1">
            {/* <Link className="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800" to="#">
              Customization
              <div className="ms-auto">
                <span className="ms-auto inline-flex items-center gap-1.5 py-px px-1.5 rounded text-[10px] leading-4 font-medium bg-gray-100 text-gray-800 dark:bg-neutral-700 dark:text-neutral-300">
                  New
                </span>
              </div>
            </Link> */}
            <Link
              className="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
              to={ROUTES.teams}
            >
              Manage team
            </Link>
            <Link
              className="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 text-red-700 dark:text-red-500"
              to="#"
              onClick={handleLogout}
            >
              Sign out
            </Link>
          </div>
          <div className="p-1 border-t border-gray-200 dark:border-neutral-800">
            <button
              type="button"
              className="flex mt-0.5 gap-x-3 py-2 px-3 w-full rounded-lg text-sm text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
              data-hs-overlay="#hs-pro-dshm"
            >
              <svg
                className="flex-shrink-0 size-4 mt-0.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M8 12h8" />
                <path d="M12 8v8" />
              </svg>
              Add Team Member
            </button>
          </div>
        </div>
      </div>
      <NewMemberModal updateMemberList={getMembers} members={members} />
    </>
  );
};

export default UserProfile;
