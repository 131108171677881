export function convertDateStringToDate(dateString: string): Date {
  const dateComponents = dateString.split("-");
  const day = parseInt(dateComponents[0], 10);
  const month = parseInt(dateComponents[1], 10) - 1; // Adjust for 0-indexed months
  const year = parseInt(dateComponents[2], 10);

  // Create a new Date object
  const convertedDate = new Date(year, month, day);

  return convertedDate;
}

// export function getDate&TimeString(date:string):string{

// }

export function formatDateToDateString(endDate: Date): string {
  const day = ("0" + endDate.getDate()).slice(-2);
  const month = ("0" + (endDate.getMonth() + 1)).slice(-2);
  const year = endDate.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
  // returns data in format dd-mm-yyyy
}
export function getLesserDate(...dates: Date[]): Date {
  const parsedDates = dates.map((date) => new Date(date).getTime());
  const minTimestamp = Math.min(...parsedDates);
  return new Date(minTimestamp);
}

export function getHigherDate(...dates: Date[]): Date {
  const parsedDates = dates.map((date) => new Date(date).getTime());
  const maxTimestamp = Math.max(...parsedDates);
  return new Date(maxTimestamp);
}
export function getTimeInHHMMAMPM(date: Date): string {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "pm" : "am";
  const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
}
export function getDaysRemaining(endDate: Date): number {
  const today = new Date();
  const end = new Date(endDate);

  // Calculate the difference in milliseconds
  //@ts-ignore
  const differenceMs = end - today;

  // Convert milliseconds to days
  return Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
}

export function getTimeDifference(date: Date): string {
  const now = new Date();
  const timeDifference = now.valueOf() - date.valueOf();

  // Convert milliseconds to seconds, minutes, and hours
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return `${seconds} second${seconds === 1 ? "" : "s"} ago`;
  } else if (minutes < 60) {
    return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else if (days < 30) {
    return `${days} day${days === 1 ? "" : "s"} ago`;
  } else {
    return "a while ago";
  }
}
