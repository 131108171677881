import React, { useEffect, useRef, useState } from "react";
import IconButton from "./IconButton";
import { Pencil, RotateCw, X } from "lucide-react";

interface EditLabelProps {
  label: string;
  edit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  updateLabel: (value: string) => void;
  iconSize?: number;
  cancelOnOutsideClick?: boolean;
}
const EditLabel = ({
  label,
  edit,
  setEdit,
  updateLabel,
  iconSize = 16,
  cancelOnOutsideClick = false,
}: EditLabelProps) => {
  const [inputValue, setInputValue] = useState("");
  const [hovered, setHovered] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (formRef.current && !formRef.current.contains(event.target as Node)) {
      handleCancelClick();
    }
  };

  const handleInputChange = (e:any) => {
    setInputValue(e.target.value.trim());
  };  

  const handleUpdateClick = async () => {
    // if (!cancelOnOutsideClick) return;
    if (inputValue == label) return handleCancelClick();
    updateLabel(inputValue);
    setEdit(false);
  };

  const handleEditClick = () => {
    setEdit(true);
  };

  const handleCancelClick = () => {
    setInputValue(label ?? "Title");
    setEdit(false);
    setHovered(false);
  };

  useEffect(() => {
    setInputValue(label);
  }, [label]);

  useEffect(() => {
    if (edit) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [edit]);
  return (
    <div>
      {edit ? (
        <form onSubmit={handleUpdateClick} className="w-full">
          <div className="flex items-center grow">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              autoFocus={true}
              className={` border-gray-500 flex grow border-b outline-none focus:border-blue-500 mr-2 text-black bg-gray-100 px-2 rounded-md dark:bg-secondary-500 dark:text-white`}
            />
            <IconButton
              type="submit"
              icon={<RotateCw size={iconSize} strokeWidth={1.5} />}
              tooltipTitle="Update"
              tooltipPlacement="left"
            />
            <IconButton
              icon={<X size={iconSize} strokeWidth={1.5} />}
              tooltipTitle="Cancel"
              tooltipPlacement="right"
              onClick={handleCancelClick}
            />
          </div>
        </form>
      ) : (
        <div
          id="workspace-title"
          className="flex items-center pr-10 relative "
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {inputValue}
          {hovered && (
            // <div className={`ms-2 absolute start-[${inputValue.length}ch]`}>
            <div className={`ms-2 absolute end-2`}>
              <IconButton
                icon={<Pencil size={iconSize} strokeWidth={1.5} />}
                tooltipTitle="Edit"
                tooltipPlacement="right"
                onClick={handleEditClick}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EditLabel;
