import React, { useState } from "react";
import AccordianTitle from "../Accordion/AccordianTitle";
import Badge from "../Generic/Badge";
import { AlignRight } from "lucide-react";
import Button from "../Button";

interface AccordianContentProps {
  nodeId: string;
  accordianHover: boolean;
}

interface Subtask {
  id: string;
  title: string;
  completed: boolean;
}

const AccordianContent = ({
  nodeId,
  accordianHover,
}: AccordianContentProps) => {
  const [hover, setHover] = useState(false);
  const [tasks, setTasks] = useState<Subtask[]>([]);
  const handleRemoveSubTask = (id: string) => {
    setTasks((prevTasks) => prevTasks.filter((t) => t.id !== id));
  };
  const handleAddSubtask = () => {
    setTasks((prev) => [
      ...prev,
      { id: Math.random().toString(), title: "New Sub Task", completed: false },
    ]);
  };
  return (
    <div>
      {tasks.map((task, index) => (
        <div key={task.id} className="mb-2 flex items-center justify-end ">
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className="relative w-[90%] border rounded-md px-2 pb-2 border-gray-100 hover:shadow-md transition shadow-sm"
          >
            <div className="flex">
              <div className="flex grow">
                <AccordianTitle
                  element={{ title: task.title }}
                  updateElement={() => {}}
                />
              </div>
              <div className="flex items-center gap-x-2">
                <Badge
                  label="1/20"
                  icon={<AlignRight color="#c0c0c0" />}
                  variant="white"
                />
              </div>
            </div>
            {/* <AccordianDetails
              hover={hover}
              removeElement={handleRemoveSubTask}
              id={task.id}
            /> */}
          </div>
        </div>
      ))}
      <li className="flex items-center">
        {/* <button
          className="ml-2 p-2 rounded-md bg-blue-500 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={() => handleAddSubtask()}
        >
          +
        </button> */}
        <Button
          onClick={handleAddSubtask}
          className={`border rounded-md px-2 py-1 hover:bg-gray-100 text-xs font-medium ${
            accordianHover ? "visible" : "invisible"
          }`}
        >
          Add sub task
        </Button>
      </li>
    </div>
  );
};

export default AccordianContent;
