import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ROUTES } from "../constants";
import Sidebar from "../components/Sidebar";
import Header2 from "../components/Header2";
import { Error404, Home, WorkspaceRoute } from "../pages";
import SharedWithMe from "../pages/SharedWithMe";
import Teams from "../pages/Teams";
import Profile from "../pages/Profile/index";
import AiAgents from "../pages/sidebar/AiAgents";
import Templates from "../pages/sidebar/Templates";
import Integrations from "../pages/sidebar/Integrations";

const LayoutRoutes = () => {
  let isWorkspaceDropdownExpanded = false;
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [isWorkspaceExpanded, setIsWorkspaceExpanded] = useState(
    isWorkspaceDropdownExpanded
  );
  const handleWorkspaceExpanded = (value) => {
    setIsWorkspaceExpanded(value);
    isWorkspaceDropdownExpanded = value;
  };

  return (
    <main className="flex h-screen max-h-screen overflow-y-hidden">
      <Sidebar
        isSidebarCollapsed={isSidebarCollapsed}
        setIsSidebarCollapsed={setIsSidebarCollapsed}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        isWorkspaceExpanded={isWorkspaceExpanded}
        setIsWorkspaceExpanded={handleWorkspaceExpanded}
      />
      <div className="bg-slate-50 dark:bg-slate-900 w-full">
        <Header2
          setIsSidebarCollapsed={setIsSidebarCollapsed}
          isSidebarCollapsed={isSidebarCollapsed}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Routes>
          <Route
            path={ROUTES.root}
            element={<Navigate to={ROUTES.dashboard} />}
          />
          <Route path={ROUTES.dashboard} element={<Home />} />
          <Route path={ROUTES.profile} element={<Profile />} />
          <Route path={ROUTES.teams} element={<Teams />} />
          <Route path={ROUTES.aiAgents} element={<AiAgents />} />
          <Route path={ROUTES.template} element={<Templates />} />
          <Route path={ROUTES.integrations} element={<Integrations />} />
          {/* <Route path={ROUTES.sharedWithMe} element={<SharedWithMe />} /> */}
          <Route path={ROUTES.workspaces} element={<WorkspaceRoute />} />
          <Route
            path={`${ROUTES.workspaces}/:id`}
            element={<WorkspaceRoute />}
          />
          <Route path="*" element={<Error404 />} />

          {/* Add other routes here */}
        </Routes>
      </div>
    </main>
  );
};

export default LayoutRoutes;
