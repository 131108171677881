import React from "react";
import ConfirmBox from ".";
import { AlertCircle } from "lucide-react";

interface DeletePlanboardConfirmBoxProps {
  handleCancel: () => void;
  handleConfirm: () => void;
  open: boolean;
  name: string;
}

const DeletePlanboardConfirmBox = ({
  handleCancel,
  handleConfirm,
  open,
  name,
}: DeletePlanboardConfirmBoxProps) => {
  return (
    <ConfirmBox
      handleConfirm={handleConfirm}
      open={open}
      title={<div className="text-red-600 flex justify-center">Delete</div>}
      onClose={handleCancel}
      id="delete-team-confirm-1"
    >
      <div className="mt-3 mb-6 dark:text-white text-center">
        <div className="w-full flex justify-center mb-5">
          <AlertCircle size={40} color="#eeb813" />
        </div>
        <div className="flex justify-center text-xl mb-5">
          Are you sure you want to delete planboard - {name}&nbsp;?
        </div>
        <div className="flex justify-center">
          You will not be able to restore this action !
        </div>
      </div>
    </ConfirmBox>
  );
};

export default DeletePlanboardConfirmBox;
