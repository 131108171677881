import { useEffect, useState } from "react";
import "./App.css";
import "preline/preline";
import RoutesComponent from "./routes";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import ToastComponent from "./components/Toast";
import { GlobalModal, useGlobalModalContext } from "./contexts/ModalProvider";
import LoadingProgress from "./components/LoadingProgress";
import { useLocation } from "react-router-dom";
import { IStaticMethods } from "preline/preline";
import { PersistGate } from "redux-persist/integration/react";
import { RootState } from "./redux/store";
import { useSelector } from "react-redux";
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

function App() {
  const location = useLocation();
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount((prev) => prev + 1);
    console.log("Location changed!", location.pathname, count + 1);
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  const isDarkMode = useSelector((state: RootState) => state.dialog.isDarkMode);
  return (
    // <Provider store={store}>
    //   <GlobalModal>
    //     <PersistGate persistor={persistor}>
    //       <div
    //         className={`App h-full ${isDarkMode ? "dark" : ""}`}
    //         // className="App h-full"
    //         >
    //         <LoadingProgress />
    //         <ToastComponent />
    //         <RoutesComponent />
    //       </div>
    //     </PersistGate>
    //   </GlobalModal>
    // </Provider>


    <>
      <div className={`App h-full ${isDarkMode ? "dark" : ""}`}>
        <LoadingProgress />
        <ToastComponent />
        <RoutesComponent />
      </div>
    </>
  );
}

export default App;
