import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import CircularProgress from "../CircularProgress";
import PlanboardCardMoreMenu from "./PlanboardCardMoreMenu";
import { getDaysRemaining } from "../../utils/dateTime";
import { Frown, LayoutDashboard, Smile } from "lucide-react";
import { useHover } from "@uidotdev/usehooks";
import { Tooltip } from "@mui/material";
import { formatDecimal } from "../../utils/numberFormat";
import UserChip from "../Generic/UserChip";
import { useSelector } from "react-redux";
import { Focus } from "lucide-react";

const gridItemStyle = "rounded-md py-3 px-2 text-sm";

export default function PlanboardCard({ planboard, showCreatedBy = false }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [ref, hovering] = useHover();
  const isDarkMode = useSelector((state) => state.dialog.isDarkMode);

  const handleNavigation = () => {
    navigate(`${ROUTES.planboardDesigner}/${planboard.planboardId}`, {
      state: { from: location },
    });
  };
  return (
    <div>
      <div
        class="bg-white border border-gray-200 cursor-pointer rounded-lg hover:rounded-none transition-all duration-500 shadow-lg dark:bg-slate-800 dark:border-gray-700 hover:shadow-xl overflow-hidden"
        ref={ref}
        onClick={handleNavigation}
      >
        <div class="flex gap-x-5 bg-primary-100 dark:bg-slate-900 dark:text-neutral-200 p-5 rounded-t-lg relative">
          <div className="m-1">
            <LayoutDashboard size={32} strokeWidth={1} />
          </div>

          <div class="grow">
            <div className="justify-between flex items-center">
              <h2 class="text-lg font-semibold text-gray-800 dark:text-white truncate w-[90%]">
                <Tooltip title={planboard.name}>{planboard.name}</Tooltip>
              </h2>
              {planboard.description && (
                <h2 class="text-gray-600 dark:text-white w-10 h-5 flex items-center">
                  <Tooltip title={planboard.description}>
                    <svg
                      className="flex-shrink-0 size-3 text-gray-500 dark:text-neutral-500"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </Tooltip>
                </h2>
              )}
            </div>
          </div>
          <div
            className={`absolute end-2 ${hovering ? "visible" : "invisible"}`}
            onClick={(e) => e.stopPropagation()}
          >
            <PlanboardCardMoreMenu planboard={planboard} />
          </div>
        </div>
        <div className="block">
          <div className="p-5 flex items-center">
            {/* <CircularProgress progress={20} /> */}
            <div className="grow">
              <div
                className={`inline-block mb-2 ms-[calc(${planboard.progress}%-1.25rem)] py-0.5 px-1.5 bg-blue-50 border border-blue-200 text-xs font-medium text-primary-300 rounded-lg dark:bg-blue-800/30 dark:border-blue-800 dark:text-blue-500`}
              >
                {/* {formatDecimal(planboard.progress)}% */}
                {Math.floor(planboard.progress)}%
              </div>
              <div
                class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
                role="progressbar"
                aria-valuenow={planboard.progress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  class="flex flex-col justify-center rounded-full overflow-hidden bg-primary-200 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
                  style={{ width: `${planboard.progress}%` }}
                ></div>
              </div>
            </div>
          </div>

          {/* created by & deadline */}
          <div class="text-xs text-gray-500 dark:text-white p-5 pt-0 flex justify-between items-center text-end">
            <div className={`${showCreatedBy ? "visible" : "invisible"} flex`}>
              <div className="dark:text-gray-400 flex items-center">
                Shared By:&nbsp;&nbsp;
                <UserChip user={planboard.createdBy} />
              </div>
            </div>

            {planboard.daysLeft < 0 ? (
              planboard.progress !== 100 ? (
                <span
                  className={`flex ${
                    isDarkMode ? "dark:text-red-900" : "text-red-600"
                  }`}
                >
                  <Frown size={16} strokeWidth={1} />
                  &nbsp;Delayed
                </span>
              ) : (
                <span
                  className={`flex ${
                    isDarkMode ? "dark:text-green-900" : "text-green-600"
                  }`}
                >
                  <Smile size={16} strokeWidth={1} />
                  &nbsp;Completed
                </span>
              )
            ) : planboard.daysLeft < 2 ? (
              `${planboard.daysLeft} day left`
            ) : (
              `${planboard.daysLeft} days left`
            )}
          </div>
          {/* created by & deadline ends */}

          {/* Footer */}
          <div class="border-t px-5 py-1 pb-2 dark:text-neutral-300">
            <div class="flex justify-between gap-3">
              <div class={gridItemStyle}>
                <p className="flex justify-center">
                  {planboard?.focusAreas ?? 0}
                </p>
                <p>Focus Area</p>
              </div>

              <div class={gridItemStyle}>
                <p className="flex justify-center">
                  {planboard?.actionItems ?? 0}
                </p>
                <p>Action Items</p>
              </div>

              <div class={gridItemStyle}>
                <p className="flex justify-center">{planboard?.events ?? 0}</p>
                <p>Meetings</p>
              </div>
            </div>
          </div>
          {/* Footer end */}
        </div>
      </div>
    </div>
  );
}
