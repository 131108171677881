import { Tooltip } from "@mui/material";
import { ChevronLeftCircle, ChevronRightCircle } from "lucide-react";
import React from "react";

import TriangleIcon from "../../assets/3DIcons/Triangle.png";

interface SidebarToggleButtonProps {
  setIsSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  isSidebarCollapsed: boolean;
}

const SidebarToggleButton = ({
  setIsSidebarCollapsed,
  isSidebarCollapsed,
}: SidebarToggleButtonProps) => {
  return (
    <div
      className="rounded-full cursor-pointer dark:bg-gray-600 dark:hover:bg-gray-900 dark:text-white"
      onClick={() => setIsSidebarCollapsed((prev) => !prev)}
    >
      {/* <button
        type="button"
        className="w-7 h-[38px] inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
        data-hs-overlay="#hs-pro-sidebar"
        aria-controls="hs-pro-sidebar"
        aria-label="Toggle navigation"
        
      >
        <svg
          className="flex-shrink-0 w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="m9 18 6-6-6-6" />
        </svg>
      </button> */}
      <Tooltip title={isSidebarCollapsed ? "Expand" : "Collapse"}>
        <div className="hover:text-primary-400">
          {isSidebarCollapsed ? (
            <ChevronRightCircle size={24} strokeWidth={1} />
          ) : (
            <ChevronLeftCircle size={24} strokeWidth={1} />
          )}
          {/* <img
            src={TriangleIcon}
            className={`w-5 ${isSidebarCollapsed ? "rotate-90" : "-rotate-90"}`}
          /> */}
        </div>
      </Tooltip>
    </div>
  );
};

export default SidebarToggleButton;
