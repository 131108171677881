import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: []
}

const toastSlice = createSlice({
    name: 'toastList',
    initialState,
    reducers: {
        addToast: (state, action) => {
            state.value = [
                ...state.value,
                { ...action.payload }
            ]
        },
        removeToast: (state, action) => {
            state.value = state.value.filter((toast) => toast.id !== action.payload);
        }
    }
})

export const { addToast, removeToast } = toastSlice.actions;

export default toastSlice.reducer;