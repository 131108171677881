import { GLOBAL_URLS } from "../../constants";
import { postAuth } from "../client";

const GlobalAPI = {
  getMeetingTypes: () => {
    return postAuth(GLOBAL_URLS.getMeetingTypes, {});
  },
  searchDepartments: () => {
    return postAuth(GLOBAL_URLS.searchDepartments, {});
  },
  searchSubDepartments: () => {
    return postAuth(GLOBAL_URLS.searchSubDepartments, {});
  },
  searchRoles: () => {
    return postAuth(GLOBAL_URLS.searchRoles, {});
  },
  searchSubRoles: () => {
    return postAuth(GLOBAL_URLS.searchSubRoles, {});
  },
};
export default GlobalAPI;
