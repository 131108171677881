import React, { useEffect } from "react";
import { usePageName } from "../../hooks/usePageName";
import { useActionData } from "react-router-dom";
import { useSelector } from "react-redux";
import { Bell } from "lucide-react";
import { ROUTES } from "../../constants";
import RightContents from "./RightContents";

export default function Header() {
  const { activeRoute } = usePageName();
  useEffect(() => {
    const HSThemeAppearance = {
      init() {
        const defaultTheme = "default";
        let theme = localStorage.getItem("hs_theme") || defaultTheme;

        if (document.querySelector("html").classList.contains("dark")) return;
        this.setAppearance(theme);
      },
      _resetStylesOnLoad() {
        const $resetStyles = document.createElement("style");
        $resetStyles.innerText = `*{transition: unset !important;}`;
        $resetStyles.setAttribute("data-hs-appearance-onload-styles", "");
        document.head.appendChild($resetStyles);
        return $resetStyles;
      },
      setAppearance(theme, saveInStore = true, dispatchEvent = true) {
        const $resetStylesEl = this._resetStylesOnLoad();

        if (saveInStore) {
          localStorage.setItem("hs_theme", theme);
        }

        if (theme === "auto") {
          theme = window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "default";
        }

        document.querySelector("html").classList.remove("dark");
        document.querySelector("html").classList.remove("default");
        document.querySelector("html").classList.remove("auto");

        document
          .querySelector("html")
          .classList.add(this.getOriginalAppearance());

        setTimeout(() => {
          $resetStylesEl.remove();
        });

        if (dispatchEvent) {
          window.dispatchEvent(
            new CustomEvent("on-hs-appearance-change", { detail: theme })
          );
        }
      },
      getAppearance() {
        let theme = this.getOriginalAppearance();
        if (theme === "auto") {
          theme = window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "default";
        }
        return theme;
      },
      getOriginalAppearance() {
        const defaultTheme = "default";
        return localStorage.getItem("hs_theme") || defaultTheme;
      },
    };
    HSThemeAppearance.init();

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => {
        if (HSThemeAppearance.getOriginalAppearance() === "auto") {
          HSThemeAppearance.setAppearance("auto", false);
        }
      });

    window.addEventListener("load", () => {
      const $clickableThemes = document.querySelectorAll(
        "[data-hs-theme-click-value]"
      );
      const $switchableThemes = document.querySelectorAll(
        "[data-hs-theme-switch]"
      );

      $clickableThemes.forEach(($item) => {
        $item.addEventListener("click", () =>
          HSThemeAppearance.setAppearance(
            $item.getAttribute("data-hs-theme-click-value"),
            true,
            $item
          )
        );
      });

      $switchableThemes.forEach(($item) => {
        $item.addEventListener("change", (e) => {
          HSThemeAppearance.setAppearance(
            e.target.checked ? "dark" : "default"
          );
        });

        $item.checked = HSThemeAppearance.getAppearance() === "dark";
      });

      window.addEventListener("on-hs-appearance-change", (e) => {
        $switchableThemes.forEach(($item) => {
          $item.checked = e.detail === "dark";
        });
      });
    });
  }, []);

  return (
    <header className="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full bg-white border-b border-gray-200 text-sm py-2.5 dark:bg-gray-800 dark:border-gray-700">
      <nav
        className="relative w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
        aria-label="Global"
      >
        {/* <div className="flex items-center justify-between py-6">
          {activeRoute?.indexOf(ROUTES.workspaces) !== -1 ? (
            <WorkspaceHeader />
          ) : activeRoute?.indexOf(ROUTES.teams) !== -1 ? (
            <TeamsHeader />
          ) : (
            <UserGreetingsHeader />
          )}

          <div className="sm:hidden">
            <button
              type="button"
              className="hs-collapse-toggle w-9 h-9 flex justify-center items-center text-sm font-semibold rounded-lg border border-gray-200 text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              data-hs-collapse="#navbar-collapse-with-animation"
              aria-controls="navbar-collapse-with-animation"
              aria-label="Toggle navigation"
            >
              <svg
                className="hs-collapse-open:hidden w-4 h-4"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
              <svg
                className="hs-collapse-open:block flex-shrink-0 hidden w-4 h-4"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </div>
        </div> */}
        <div
          id="navbar-collapse-with-animation"
          className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block"
        >
          <div className="flex w-full flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:gap-y-0 sm:gap-x-7 sm:mt-0 ">
            <div className="grow">
              <input
                type="text"
                className="py-3 px-4 block w-80 border border-gray-200 rounded-lg text-sm focus:border-primary-600 focus:ring-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                placeholder="Search workspaces or planboards"
              />
            </div>
            {/* {activeRoute?.indexOf(ROUTES.workspaces) === -1 &&
            activeRoute?.indexOf(ROUTES.teams) === -1 ? (
              <Bell
                className={`${
                  showNotifications ? "text-primary-400" : "text-gray-600"
                }  cursor-pointer hover:text-black`}
                onClick={() => setShowNotifications((prev) => !prev)}
              />
            ) : null} */}
            <RightContents />
          </div>
        </div>
      </nav>
    </header>
  );
}

const UserGreetingsHeader = () => {
  const user = useSelector((state) => state.user);
  return (
    <a
      className="flex-none text-xl font-semibold dark:text-white flex flex-col"
      aria-label="Brand"
    >
      <span>Good afternoon, {user?.value?.fullName ?? "User"}!</span>
      <span>Create, manage and operate your planboards here</span>
    </a>
  );
};
