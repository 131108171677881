import { LifeBuoy, Power, Tv } from "lucide-react";
import React from "react";

const Tabs = () => {
  return (
    <div class="flex flex-wrap mt-2">
      <div class="border-e border-gray-200 dark:border-gray-700 ">
        <nav
          class="flex flex-col space-y-2"
          aria-label="Tabs"
          role="tablist"
          data-hs-tabs-vertical="true"
        >
          <button
            type="button"
            class="hs-tab-active:border-blue-500 hs-tab-active:text-blue-600 dark:hs-tab-active:text-blue-600 py-1 pe-4 inline-flex items-center gap-x-2 border-e-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-300 dark:hover:text-blue-500 active"
            id="vertical-tab-with-border-item-1"
            data-hs-tab="#vertical-tab-with-border-1"
            aria-controls="vertical-tab-with-border-1"
            role="tab"
          >
            <Power strokeWidth={1} size={16} />
            Getting started videos
          </button>
          <button
            type="button"
            class="hs-tab-active:border-blue-500 hs-tab-active:text-blue-600 dark:hs-tab-active:text-blue-600 py-1 pe-4 inline-flex items-center gap-x-2 border-e-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-300 dark:hover:text-blue-500"
            id="vertical-tab-with-border-item-2"
            data-hs-tab="#vertical-tab-with-border-2"
            aria-controls="vertical-tab-with-border-2"
            role="tab"
          >
            <LifeBuoy size={16} strokeWidth={1} /> Support
          </button>
          <button
            type="button"
            class="hs-tab-active:border-blue-500 hs-tab-active:text-blue-600 dark:hs-tab-active:text-blue-600 py-1 pe-4 inline-flex items-center gap-x-2 border-e-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-300 dark:hover:text-blue-500"
            id="vertical-tab-with-border-item-3"
            data-hs-tab="#vertical-tab-with-border-3"
            aria-controls="vertical-tab-with-border-3"
            role="tab"
          >
            <Tv size={16} strokeWidth={1} />
            Video tutorials
          </button>
        </nav>
      </div>

      <div class="ms-3">
        <div
          id="vertical-tab-with-border-1"
          role="tabpanel"
          aria-labelledby="vertical-tab-with-border-item-1"
        >
          <p class="text-gray-500 dark:text-gray-300">Coming Soon...</p>
        </div>
        <div
          id="vertical-tab-with-border-2"
          class="hidden"
          role="tabpanel"
          aria-labelledby="vertical-tab-with-border-item-2"
        >
          <p class="text-gray-500 dark:text-gray-300">Coming Soon...</p>
        </div>
        <div
          id="vertical-tab-with-border-3"
          class="hidden"
          role="tabpanel"
          aria-labelledby="vertical-tab-with-border-item-3"
        >
          <p class="text-gray-500 dark:text-gray-300">Coming Soon...</p>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
