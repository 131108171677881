import { ChevronDown } from "lucide-react";
import React, { useState, useRef, useEffect } from "react";

interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  options: Option[];
  selectedValue: string;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
}

const Select: React.FC<SelectProps> = ({
  options,
  selectedValue,
  setSelectedValue,
}) => {
  //   const [selectedValue, setSelectedValue] = useState<string | null>(
  //     defaultValue || null
  //   );
  const [isOpen, setIsOpen] = useState(false);
  const selectButtonRef = useRef<HTMLButtonElement>(null);
  const optionsListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    // Close options if clicking outside the select component
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectButtonRef.current &&
        !selectButtonRef.current.contains(event.target as Node) &&
        optionsListRef.current &&
        !optionsListRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleOptions = () => setIsOpen(!isOpen);

  const selectOption = (value: string) => {
    setSelectedValue(value);
    toggleOptions();
  };

  return (
    <div className="relative text-gray-900 dark:text-gray-300">
      <button
        ref={selectButtonRef}
        className="text-sm rounded-lg hover:bg-gray-100 dark:hover:bg-slate-700 focus:bg-gray-100 dark:focus:bg-slate-700 flex gap-x-2 px-2.5 py-1.5"
        onClick={toggleOptions}
      >
        <div className="grow text-start">
          {selectedValue
            ? options.find((opt) => opt.value === selectedValue)?.label
            : "Select an option"}
        </div>
        <span className="flex items-center">
          <ChevronDown size={12} />
        </span>
      </button>

      <ul
        ref={optionsListRef}
        className={`absolute p-2 mt-2 z-10 w-full bg-white dark:bg-slate-700 space-y-1 text-sm rounded-lg shadow-sm 
                   ${isOpen ? "block" : "hidden"}`}
      >
        {options.map((option) => (
          <li
            key={option.value}
            value={option.value}
            className={`pt-1 pb-1.5 px-2 cursor-pointer hover:bg-gray-100 relative rounded-lg dark:hover:bg-slate-900 ${
              selectedValue === option.value
                ? "dark:bg-slate-900 bg-gray-100"
                : ""
            }`}
            onClick={() => selectOption(option.value)}
          >
            {option.label}
            {selectedValue === option.value && (
              <span className="absolute inset-y-0 right-0 flex items-center mr-4">
                ✓
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Select;
