import { Pencil, Trash2 } from "lucide-react";
import React, { useState } from "react";
import IconButton from "../Generic/IconButton";
import { openCreateEventDialog } from "../../redux/slice/ui/dialogSlice";
import { useDispatch } from "react-redux";
import { getTimeInHHMMAMPM } from "../../utils/dateTime";
import { useToast } from "../../hooks";
import EventsAPI from "../../api/services/Events";
import moment from "moment";

export default function EventCard({
  data,
  setSelectedEventEdit,
  handleRemoveEvent,
  readOnly,
}: {
  data: any;
  setSelectedEventEdit: any;
  handleRemoveEvent: (id: string) => void;
  readOnly: boolean;
}): JSX.Element {
  const dispatch = useDispatch();
  const toast = useToast();
  const [hover, setHover] = useState(false);

  const openEventDialog = () => {
    // dispatch(openCreateEventDialog("id"));
  };

  return (
    <div
      className="p-2 mb-2 bg-gray-100 border rounded-r-md border-l-2 border-l-primary-400 hover:shadow-lg"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="flex justify-between items-center">
        <h4 className="text-sm dark:text-neutral-200">{data.title}</h4>
        {/* <span className="text-xs">{data.timestamp}</span> */}
        <span className="text-xs uppercase">
          {moment(data.start).utcOffset("GMT-00:00").format("hh:mm a")}
        </span>
      </div>
      <div className="flex">
        <h6 className="text-xs grow dark:text-neutral-200">
          {data.description}
        </h6>
        {readOnly && (
          <div className={`flex gap-x-2 ${hover ? "visible" : "invisible"}`}>
            <div className="hover:scale-110 transition duration-300 cursor-pointer">
              <Pencil
                size={14}
                color="#574de8"
                onClick={() => setSelectedEventEdit(data)}
              />
            </div>
            <div className="hover:scale-110 transition duration-300 cursor-pointer">
              <Trash2
                size={14}
                color="#d95264"
                onClick={() => handleRemoveEvent(data.id)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
