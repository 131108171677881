import React from "react";
import { Routes, Route } from "react-router-dom";
import { ROUTES } from "../constants";
import PrivateRoute from "./PrivateRoute";

import {
  Error404,
  ForgotPassword,
  InviteRedirect,
  Login,
  LoginFailure,
  LoginSuccess,
  Logout,
  NewOrganisation,
  PlanboardDesigner,
  RegisterEmail,
  RegisterName,
  RegisterPassword,
  Teams,
  VerifyEmail,
  CreatePlanboard,
  SignIn,
  SignUp,
} from "../pages";
import Demo from "../pages/Demo";
import Root from "../pages/Root";
import SignInCallback from "../pages/Auth/SignInCallback";
import Activity from "../pages/Activity";
import Trash from "../pages/Trash";
import Starred from "../pages/Starred";
import LayoutRoutes from "./LayoutRoutes";
import OnboardingRoutes from "./OnboardingRoutes";
import ForgetPassword from "../pages/Password/ForgetPassword";
import SetPassword from "../pages/Password/SetPassword";

const RoutesComponent = () => {
  return (
    <Routes>
      {/* <Route path={ROUTES.registerEmail} element={<RegisterEmail />} /> */}
      {/* <Route path="/demo" element={<Demo />} /> */}
      {/* <Route path="/create" element={<CreatePlanboard1 />} /> */}
      {/* <Route path={ROUTES.verifyEmail} element={<VerifyEmail />} /> */}
      {/* <Route
        path={`${ROUTES.registerName}/:id/:token`}
        element={<RegisterName />}
      /> */}
      {/* <Route path={ROUTES.registerPassword} element={<RegisterPassword />} /> */}
      {/* <Route path={ROUTES.forgotPassword} element={<ForgotPassword />} /> */}

      {/* Protected pages */}
      <Route element={<PrivateRoute />}>
        <Route path="*" element={<LayoutRoutes />} />
        <Route path="/onboarding/:step" element={<OnboardingRoutes />} />

        {/* <Route path={ROUTES.teams} element={<Teams />} /> */}
        <Route path={ROUTES.activity} element={<Activity />} />
        <Route path={ROUTES.trash} element={<Trash />} />
        <Route path={ROUTES.starred} element={<Starred />} />
        <Route path={ROUTES.createPlanboard} element={<CreatePlanboard />} />
        <Route
          path={`${ROUTES.planboardDesigner}/:id`}
          element={<PlanboardDesigner />}
        />
      </Route>

      {/* Root page */}
      {/* <Route path={ROUTES.root} element={<Root />} /> */}
      {/* <Route path={ROUTES.login} element={<Login />} /> */}
      <Route path={ROUTES.invite} element={<InviteRedirect />} />

      {/* 404 page */}
      <Route path="*" element={<Error404 />} />

      {/* Social Sign In Sucess/Errors */}
      {/* <Route path={ROUTES.socialLoginSuccess} element={<LoginSuccess />} />
      <Route path={ROUTES.socialLoginFailure} element={<LoginFailure />} /> */}

      <Route path={ROUTES.logout} element={<Logout />} />
      <Route path={ROUTES.signup} element={<SignUp />} />
      <Route path={ROUTES.signin} element={<SignIn />} />
      <Route path={ROUTES.forgetPassword} element={<ForgetPassword />} />
      <Route path={ROUTES.setPassword} element={<SetPassword />} />
      <Route path={ROUTES.signInCallback} element={<SignInCallback />} />
    </Routes>
  );
};
export default RoutesComponent;
