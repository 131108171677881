import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TeamsAPI from "../../../api/services/Teams";
import Team from "../../../types/interfaces/team";

interface TeamsState {
  value: Team[] | any;
  loading: boolean;
  error: boolean | null | {};
}

interface InitialState extends TeamsState {
  value: any;
  loading: boolean;
}

const initialState: InitialState = {
  value: [],
  loading: false,
  error: null,
};

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.pending, (state) => {
        console.log("getTeams.pending");
        state.loading = true;
      })
      .addCase(getTeams.fulfilled, (state, action) => {
        if (action.payload.error) {
          console.log("getTeams.failed");
          state.value = [];
          state.loading = false;
          state.error = true;
        } else {
          console.log("getTeams.success");
          state.value = [...action.payload.data];
          state.loading = false;
        }
      });
  },
});

export const getTeams = createAsyncThunk<any, any, any>(
  "teams/get-all",
  async ({ teamIds }: { teamIds: string[] }, thunkAPI) => {
    try {
      const response = await TeamsAPI.getTeams(teamIds);
      return {
        data: response.data.data as Team[], // Assuming response.data.data is an array of Team objects
      };
    } catch (err) {
      console.log(err);
      // return {
      //   data: [],
      //   error: true,
      // };
      return thunkAPI.rejectWithValue({ data: [], error: err });
    }
  }
);

export default teamsSlice.reducer;
