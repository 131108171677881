import { LinearProgress, Popover, Tooltip } from "@mui/material";
import { AlertCircle, Download, Paperclip, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import IconButton from "../Generic/IconButton";
import SubTasksAPI from "../../api/services/Tasks";
import BrowseInput from "../Attachments/BrowseInput";
import PlanboardFile from "../../types/interfaces/PlanboardFile";
import { useToast } from "../../hooks";
import { formatFileSize } from "../../utils/fileSize";
import ConfirmBox from "../Generic/ConfirmBox";

interface FileChipProps {
  taskId: string;
  attachments: PlanboardFile[];
  updateElement: (id: string, data: {}) => void;
  readOnly: boolean;
}

const FileChip = ({
  taskId,
  attachments,
  updateElement,
  readOnly,
}: FileChipProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [removeFileLoading, setRemoveFileLoading] = useState(false);
  const toast = useToast();
  const [fileHover, setFileHovered] = useState("");
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (readOnly) return;
    setAnchorEl(event.currentTarget);
  };
  const [selectedFile, setSelectedFile] = useState("");

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleAddAttachments = async () => {
    console.log({ newFiles });
    try {
      const response = await SubTasksAPI.addAttachment(taskId, newFiles);
      if (response.status === 200) {
        console.log([...attachments, ...response.data.data]);
        updateElement(taskId, {
          attachments: [...attachments, ...response.data.data],
        });
        setNewFiles([]);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleFileRemove = (id: string) => {
    handleClose();
    setSelectedFile(id);
  };

  const cancelFileDelete = () => {
    setSelectedFile("");
  };

  const confirmFileDelete = async () => {
    try {
      setRemoveFileLoading(true);
      const response = await SubTasksAPI.removeAttachment({
        attachmentId: selectedFile,
        taskId,
      });
      setRemoveFileLoading(false);
      if (response.status === 200) {
        toast.info("File removed successfully");
        cancelFileDelete();
        updateElement(taskId, {
          attachments: attachments.filter((file) => file.id !== selectedFile),
        });
      }
      console.log({ response });
    } catch (error) {
      setRemoveFileLoading(false);
      console.log({ error });
    }
    return;
  };

  useEffect(() => {
    if (newFiles.length === 0) return;
    handleAddAttachments();
  }, [newFiles]);

  return (
    <div>
      <span
        onClick={handleClick}
        className={`${
          readOnly
            ? ""
            : "hover:bg-gray-200 dark:hover:bg-slate-900 cursor-pointer"
        } inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-white/10 dark:text-white`}
      >
        <Paperclip size={16} />
        Files
        <span className="inline-flex items-center px-1 rounded-full text-xs font-medium bg-primary-400 text-white">
          {attachments?.length ?? 0}
        </span>
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="dark:bg-slate-800 p-2 overflow-hidden block">
          <div className="border rounded-md py-2 overflow-x-hidden block">
            {attachments?.length > 0 ? (
              attachments.map((file: PlanboardFile, index) => (
                <div
                  className="grid grid-cols-2 whitespace-nowrap py-1 px-2 rounded relative hover:bg-gray-100 dark:hover:bg-slate-700"
                  key={file.id}
                  onMouseEnter={() => setFileHovered(file.id)}
                  onMouseLeave={() => setFileHovered("")}
                >
                  <div className="text-sm/[1rem] text-gray-800 dark:text-slate-300 max-w-60">
                    <div className="flex">
                      {/* {index + 1}.{")"}&nbsp; */}
                      <Tooltip title={file.name}>
                        <p className="truncate">{file.name}&nbsp;</p>
                      </Tooltip>
                    </div>
                    {/* <span className="text-xs/[0.6rem] text-gray-500 font-sans italic ms-5 dark:text-slate-500">
                      ({file.size})
                    </span> */}
                  </div>
                  {/* <div className="me-14 flex items-center justify-end text-xs text-gray-500 dark:text-slate-500">
                    {formatDate(file.uploadedOn)}&nbsp;
                  </div> */}
                  <div
                    className={`${
                      fileHover === file.id ? "visible" : "invisible"
                    } absolute end-0 dark:text-gray-300 h-full flex items-center justify-end `}
                  >
                    <div className="bg-slate-200 dark:bg-slate-900 rounded-md px-3 py-1 me-1 flex space-x-3">
                      <a
                        href={file.mediaUrl}
                        download={file.name}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <IconButton
                          icon={<Download size={14} />}
                          width={5}
                          height={5}
                          tooltipTitle={`Download ${file.name}`}
                          tooltipPlacement="top"
                          className="hover:text-blue-600"
                        />
                      </a>
                      <div onClick={() => handleFileRemove(file.id)}>
                        <IconButton
                          icon={<Trash2 size={14} />}
                          width={5}
                          height={5}
                          tooltipTitle={`Delete ${file.name}`}
                          tooltipPlacement="top"
                          className="hover:text-red-600"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-5 dark:text-gray-300">
                <span>You don't have any attachments.</span>
                <p>Add some files to get started!</p>
              </div>
            )}
            {newFiles.length > 0 &&
              newFiles.map((file: File) => (
                <div className="grid grid-cols-2 py-1">
                  <div className="text-sm text-gray-500 dark:text-slate-500">
                    <p> {file.name}&nbsp;</p>
                    <span className="text-xs italic">
                      ({formatFileSize(file.size)})
                    </span>
                  </div>
                  <div className="">
                    <LinearProgress />
                  </div>
                </div>
              ))}
          </div>
          <BrowseInput
            selectedFiles={newFiles}
            setSelectedFiles={setNewFiles}
            text="Add file..."
          />
        </div>
      </Popover>
      <ConfirmBox
        handleConfirm={confirmFileDelete}
        id="delete-file-confirm-box"
        onClose={cancelFileDelete}
        open={Boolean(selectedFile)}
        title="Delete File"
        loading={removeFileLoading}
      >
        <div className="mt-3 mb-6 dark:text-white">
          <div className="w-full flex justify-center mb-5">
            <AlertCircle size={40} color="#eeb813" />
          </div>
          <div className="flex justify-center text-xl mb-5">
            Are you sure you want to delete this file?
          </div>
          <div className="flex justify-center">
            You will not be able to restore this action !
          </div>
        </div>
      </ConfirmBox>
    </div>
  );
};

export default FileChip;
