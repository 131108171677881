// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDIBEdcm_mnX-mATLW7DNvhar_pc7huZ4Y",
  authDomain: "planbow-dev-da3bd.firebaseapp.com",
  projectId: "planbow-dev-da3bd",
  storageBucket: "planbow-dev-da3bd.appspot.com",
  messagingSenderId: "801425845620",
  appId: "1:801425845620:web:9f935491a129ef78b41021",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: "select_account ",
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
