import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WorkspacesAPI from "../../../api/services/Workspace";
import { addNewWorkspace } from "../workspaces/workspacesSlice";

const initialState = {
  value: null,
  loading: false,
  error: null,
};

const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createWorkspace.pending, (state) => {
        state.loading = true;
      })
      .addCase(createWorkspace.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data;
      })
      .addCase(createWorkspace.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload.data;
      })
      .addCase(getWorkspace.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWorkspace.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data;
      })
      .addCase(getWorkspace.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload.data[0];
      });
  },
});

export const createWorkspace = createAsyncThunk(
  "workspace/create",
  async (_, thunkAPI) => {
    const currentState = thunkAPI.getState();
    const workspaceName = generateUniqueWorkspaceName(
      currentState.workspaces?.value
    );
    try {
      const response = await WorkspacesAPI.create(workspaceName);
      thunkAPI.dispatch(addNewWorkspace(response.data.data));
      return response.data;
    } catch (error) {
      return { data: error };
    }
  }
);

export const getWorkspace = createAsyncThunk("workspace/get", async (id) => {
  try {
    const response = await WorkspacesAPI.getWorkspaces([id]);
    return response.data;
  } catch (error) {
    return { data: error };
  }
});

const generateUniqueWorkspaceName = (workspaces) => {
  const baseName = "My Workspace";

  const matchingWorkspaces =
    workspaces?.filter((workspace) => workspace.name.includes(baseName)) ?? [];
  const count = matchingWorkspaces.length;
  if (count > 0) {
    return `${baseName}${count}`;
  }
  return baseName;
};

// export const {} = workspaceSlice.actions;
export default workspaceSlice.reducer;
