import React, { useEffect, useState } from "react";
import PlanboardFile from "../../types/interfaces/PlanboardFile";
import FilesAPI from "../../api/services/Files";
import { useParams } from "react-router-dom";
import Select from "../Generic/Select";
import { useDebounce } from "@uidotdev/usehooks";
import { formatDateToDateString } from "../../utils/dateTime";
import ComponentHeader from "../componentHeader";

// https://preline.co/examples/application-tables.html      #reference

const rowData = [
  {
    record_id: "Annual_Report_2023.pdf",
    timestamp: "March 17, 2024, 5:48 (ET)",
    name: "Varun Gaur",
    status: "Conducting a Comprehensive Market Analysis",
    amount: "Market Research",
  },
  {
    record_id: "Budget_Sheet.xlsx",
    timestamp: "Feb 18, 2024, 6:48 (JST)",
    name: "Sumit Chouksey",
    status: "Understanding Customer Needs",
    amount: "Product Development",
  },
  {
    record_id: "Financial_Statement.pdf",
    timestamp: "Jan 07, 2024, 10:48 (BST)",
    name: "Sejanas Joshua",
    status: "Planning for Product Launch",
    amount: "Sales",
  },
  {
    record_id: "Meeting_Minutes.docx",
    timestamp: "Mar 28, 2024, 07:23 (AEST)",
    name: "Varun Gaur",
    status: "Building a Product Development Team",
    amount: "Strategy", // Assuming "..." was an indication of missing data
  },
  {
    record_id: "Marketing_Plan.docx",
    timestamp: "Mar 17, 2024, 11:48 (SAST)",
    name: "Sumit Chouksey",
    status: "Conducting a Feasibility Study",
    amount: "Services",
  },
];

const columnHeader = [
  "Filename",
  "Size",
  "Focus Area",
  "Action Item",
  "Deliverable",
  "Uploaded By",
  "Uploaded On",
];
const itemsCountOptions = [5, 10, 20, 50];

const ContentsTab = () => {
  const [files, setFiles] = useState<DesignerFile[]>([]);
  const [itemsCount, setItemsCount] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { id } = useParams();

  useEffect(() => {
    const fetchFiles = async () => {
      if (!id) return;
      try {
        const response = await FilesAPI.getFiles({
          planboardId: id,
          index: pageIndex,
          itemsPerIndex: itemsCount,
          search: debouncedSearchTerm,
        });
        setFiles(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchFiles();
  }, [id, itemsCount, debouncedSearchTerm, pageIndex]);

  const handleNext = () => {
    setPageIndex((prevPageIndex) => prevPageIndex + 1);
  };

  const handlePrev = () => {
    setPageIndex((prevPageIndex) => prevPageIndex - 1);
  };
  return (
    <div className="w-full m-5 shadow-2xl rounded-xl p-5 h-full  mt-0 bg-white rounded-m dark:bg-slate-800">
      <div className="w-full">
        <ComponentHeader title="Files" />
      </div>
      {/* <h1 className="text-2xl mb-6">Contents & Files</h1> */}
      <div className="w-full mx-auto">
        {/* <!-- Card --> */}
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-slate-800 dark:border-gray-700">
                {/* <!-- Header --> */}
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-gray-700">
                  {/* <!-- Input --> */}
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="hs-as-table-product-review-search"
                      className="sr-only"
                    >
                      Search
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        id="hs-as-table-product-review-search"
                        name="hs-as-table-product-review-search"
                        className="py-2 border px-3 ps-11 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-4">
                        <svg
                          className="size-4 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Input --> */}
                </div>
                {/* <!-- End Header --> */}

                {/* <!-- Table --> */}
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-slate-800">
                    <tr>
                      <th scope="col" className="ps-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold capitalize tracking-wide text-gray-800 dark:text-gray-200"></span>
                        </div>
                      </th>
                      {columnHeader.map((column) => (
                        <th scope="col" className="pe-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold capitalize tracking-wide text-gray-800 dark:text-gray-200">
                              {column}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700 dark:text-gray-200">
                    {files.length > 0 ? (
                      files.map((file, index) => (
                        <tr>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 py-2">
                              <label
                                htmlFor="hs-at-with-checkboxes-1"
                                className="text-xs text-gray-500"
                              >
                                {index + 1}.
                              </label>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="pe-6 py-2">
                              <a
                                className="text-sm text-blue-600 decoration-2 hover:underline dark:text-blue-500"
                                href="#"
                              >
                                {file.name}
                              </a>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="pe-6 py-2">
                              <div className="flex items-center gap-x-2">
                                <span className="text-sm text-gray-600 dark:text-gray-400">
                                  {file.size}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="pe-6 py-2">
                              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                                {file.focusArea}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="pe-6 py-2">
                              <span className="text-sm text-gray-600 dark:text-gray-400">
                                {file.actionItem}
                              </span>
                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                            <div className="pe-6 py-2">
                              <span className="text-sm text-gray-600 dark:text-gray-400">
                                {file.deliverable}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="pe-6 py-2">
                              <span className="text-sm text-gray-600 dark:text-gray-400">
                                {file.uploadedBy.name}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="pe-6 py-2">
                              <span className="text-sm text-gray-600 dark:text-gray-400">
                                {formatDateToDateString(
                                  new Date(file.uploadedOn)
                                )}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-1.5 flex justify-end">
                              <div className="group inline-flex items-center divide-x divide-gray-300 bg-white shadow-sm rounded-lg transition-all dark:divide-gray-700 dark:bg-slate-700 dark:border-gray-700">
                                <div className="hs-tooltip inline-block">
                                  <a
                                    className="hs-tooltip-toggle py-1.5 px-2 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-s-md bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                    href={file.mediaUrl}
                                    download={file.name}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    <svg
                                      className="size-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                    </svg>
                                    <span
                                      className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700"
                                      role="tooltip"
                                    >
                                      Download PDF
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          <div className="flex justify-center dark:text-neutral-400 py-10">
                            No files uploaded yet for this project.
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* <!-- End Table --> */}

                {/* <!-- Footer --> */}
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-gray-700">
                  <div className="max-w-sm space-y-3">
                    <select
                      value={itemsCount}
                      onChange={(e) => setItemsCount(Number(e.target.value))}
                      className="py-2 px-3 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 cursor-pointer"
                    >
                      {itemsCountOptions.map((count: number) => (
                        <option value={count}>{count}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                      <button
                        type="button"
                        onClick={handlePrev}
                        disabled={pageIndex === 0}
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      >
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="m15 18-6-6 6-6" />
                        </svg>
                        Prev
                      </button>

                      <button
                        type="button"
                        onClick={handleNext}
                        disabled={files.length < itemsCount}
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      >
                        Next
                        <svg
                          className="flex-shrink-0 size-4"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="m9 18 6-6-6-6" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                {/* <!-- End Footer --> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Card --> */}
      </div>
    </div>
  );
};

export default ContentsTab;
