import { createSlice } from "@reduxjs/toolkit";

interface DialogSliceState {
  isCreateEventDialogOpen: string;
  isAssignActionItemDialogOpen: boolean;
  isColorDialogOpen: boolean;
  isDarkMode: boolean;
}

const initialState: DialogSliceState = {
  isCreateEventDialogOpen: "",
  isAssignActionItemDialogOpen: false,
  isColorDialogOpen : false,
  isDarkMode: false
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openCreateEventDialog: (state, action) => {
      state.isCreateEventDialogOpen = String(action.payload);
    },
    closeCreateEventDialog: (state) => {
      state.isCreateEventDialogOpen = "";
    },
    openAssignActionItemDialog: (state) => {
      state.isAssignActionItemDialogOpen = true;
    },
    closeAssignActionItemDialog: (state) => {
      state.isAssignActionItemDialogOpen = false;
    },
    openColorDialog: (state) => {
      state.isColorDialogOpen = true;
    },
    closeColorDialog: (state) => {
      state.isColorDialogOpen = false
    },
    openDarkmode: (state, action) => {
      state.isDarkMode = true
    },
    closeDarkmode: (state, action) => {
      state.isDarkMode = false
    },
  },
});

export const {
  openCreateEventDialog,
  closeCreateEventDialog,
  openAssignActionItemDialog,
  closeAssignActionItemDialog,
  openColorDialog,
  closeColorDialog,
  openDarkmode,
  closeDarkmode
} = dialogSlice.actions;

export default dialogSlice.reducer;
