import React from "react";
import SearchInput from "./SearchInput";
import Help from "./Help";
import Notification from "./Notification";
import Activity from "./Activity";
import SearchModal from "./SearchModal";
import UserProfile from "./UserProfile";
import SidebarToggleButton from "./SidebarToggleButton";
import SearchIcon from "./SearchIcon";
import Organisation from "./Organisation";
import { useMediaQuery } from "@mui/material";
import Button from "../Button";
import { ChevronRightCircle } from "lucide-react";

interface HeaderProps {
  setIsSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  isSidebarCollapsed: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header2 = ({
  setIsSidebarCollapsed,
  isSidebarCollapsed,
  setIsSidebarOpen,
}: HeaderProps) => {
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");

  return (
    <>
      <header className="w-full flex flex-wrap md:justify-start md:flex-nowrap z-50 bg-indigo-50 border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div
          className="flex justify-between xl:grid xl:grid-cols-3 basis-full items-center w-full py-2.5 px-2 sm:px-5"
          aria-label="Global"
        >
          <div className="xl:col-span-1 flex items-center md:gap-x-3">
            {isLargeScreen ? (
              <SidebarToggleButton
                setIsSidebarCollapsed={setIsSidebarCollapsed}
                isSidebarCollapsed={isSidebarCollapsed}
              />
            ) : (
              <>
                <div
                  className="rounded-full cursor-pointer dark:bg-gray-600 dark:hover:bg-gray-900 dark:text-white hover:text-primary-400"
                  onClick={() => setIsSidebarOpen((prev) => !prev)}
                >
                  <ChevronRightCircle size={24} strokeWidth={1} />
                </div>
              </>
            )}

            {/* <SearchInput /> */}
          </div>
          <div className="xl:col-span-2 flex justify-end items-center gap-x-2">
            <div className="flex items-center">
              {/* <SearchIcon /> */}
              <Organisation />
              {/* <Help /> */}
              <Notification />
              {/* <Activity /> */}
            </div>
            <div className="hidden sm:block border-e border-gray-200 w-px h-6 mx-1.5 dark:border-neutral-700"></div>
            <UserProfile />
          </div>
        </div>
      </header>
      <SearchModal />
    </>
  );
};

export default Header2;
