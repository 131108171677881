import React, { useEffect, useState } from "react";
import {
  SUPPORTED_FILE_TYPES,
  MAX_FILE_SIZE,
  MAX_FILES,
} from "../../constants/file";

interface BrowseInputProps {
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  selectedFiles: File[];
  text?: string;
}

const BrowseInput = ({
  setSelectedFiles,
  selectedFiles,
  text,
}: BrowseInputProps) => {
  //   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const files = event.target.files;
  //     if (!files) return; // Handle potential null value of event.target.files

  //     let newFiles: File[] = [];

  //     // Check file limits, type, and size
  //     if (selectedFiles.length + files.length <= 5) {
  //       for (let i = 0; i < files.length; i++) {
  //         if (
  //           SUPPORTED_FILE_TYPES.includes(files[i].type) &&
  //           files[i].size <= MAX_FILE_SIZE
  //         ) {
  //           newFiles.push(files[i]);
  //         } else {
  //           // You might display an error for invalid files here
  //         }
  //       }
  //     } else {
  //       // You might display an error for exceeding the file limit
  //     }

  //     setSelectedFiles([...selectedFiles, ...newFiles]);
  //     // console.log({ files: [...selectedFiles, ...newFiles] });
  //   };
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return; // Handle potential null value of event.target.files

    let newFiles: File[] = [];
    // Check file limits, type, and size
    if (selectedFiles.length < MAX_FILES) {
      for (let i = 0; i < files.length; i++) {
        if (
          SUPPORTED_FILE_TYPES.includes(files[i].type) &&
          files[i].size <= MAX_FILE_SIZE
        ) {
          newFiles.push(files[i]);
        } else {
          setError("Invalid file type or size");
        }
        if (selectedFiles.length + newFiles.length === MAX_FILES) break;
      }
    } else {
      setError("Maximum number of files reached");
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);
  return (
    <>
      <label
        htmlFor="hs-pro-deuuf"
        className="relative cursor-pointer font-semibold text-blue-500 hover:text-green-700 rounded-lg decoration-2 hover:underline focus-within:outline-none focus-within:ring-green-600 focus-within:ring-offset-2 dark:text-primary-500 dark:hover:text-green-600"
      >
        <span>{text ? text : "Browse"}</span>
        <input
          id="hs-pro-deuuf"
          type="file"
          className="sr-only"
          name="hs-pro-deuuf"
          onChange={handleFileChange}
          multiple
        />
      </label>
      {error && <p className="text-sm italic text-red-600">{error}</p>}
    </>
  );
};

export default BrowseInput;
