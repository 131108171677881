import React from "react";
// @ts-ignore
// import AuthenticationCharts from "../../assets/images/authentication-charts.svg";
// @ts-ignore
// import AuthenticationChartsDark from "../../assets/images/authentication-charts-dark.svg";
// @ts-ignore
// import CanvasNodeBackground from "../../assets/images/CanvasNodeBackgroundWithoutBG.png";
import BuildIcon from "../../assets/icons/Build.svg";
import CollaborateIcon from "../../assets/icons/Collaborate.svg";
import ExecuteIcon from "../../assets/icons/Execute.svg";

import BrandLogo from "../BrandLogo";

const Sidebar = () => {
  return (
    <div className="hidden min-h-screen w-1/3 max-w-[430px] bg-sky-100 lg:flex flex-col justify-between p-6 dark:bg-slate-900">
      {/* <!-- Header --> */}
      <div className="flex justify-between items-center dark:text-neutral-200">
        <div className="flex flex-col">
          <h1 className="text-[24px]">Create Planboard</h1>
          <span className="text-sm text-gray-500 mt-3 dark:text-neutral-400">
            Crafting AI-Powered Business Strategies with Planboard
          </span>
        </div>
      </div>
      {/* <!-- End Header --> */}

      {/* <!-- Body --> */}
      {/* <div>
        <img
          className=""
          // src={AuthenticationCharts}
          src={CanvasNodeBackground}
          alt="Image Description"
        />
        <img
          className="hidden dark:block"
          //   src="../../assets/svg/pro/authentication-charts-dark.svg"
          src={AuthenticationChartsDark}
          alt="Image Description"
        />
      </div> */}
      <div className="space-y-5 dark:text-neutral-200">
        <div className="flex space-x-3 p-3 bg-blue-100 rounded-lg dark:bg-slate-900">
          <img
            src={BuildIcon}
            className="w-20 bg-gray-100 rounded-full"
            alt="Build"
          />
          <div className="">
            <div className="text-lg font-semibold">Build Strategy</div>
            <div className="font-thin text-sm mt-2 dark:text-neutral-300">
              Unveil superpowers of AI and build the strategic plans in minutes
            </div>
          </div>
        </div>
        <div className="flex space-x-3 p-3 bg-blue-100 rounded-lg dark:bg-slate-900">
          <img
            src={CollaborateIcon}
            className="w-20 bg-gray-100 rounded-full"
            alt="Build"
          />
          <div className="">
            <div className="text-lg font-semibold">Collaborate</div>
            <div className="font-thin text-sm mt-2 dark:text-neutral-300">
              Ignite strategic collaboration with the team
            </div>
          </div>
        </div>
        <div className="flex space-x-3 p-3 bg-blue-100 rounded-lg dark:bg-slate-900">
          <img
            src={ExecuteIcon}
            className="w-20 bg-gray-100 rounded-full"
            alt="Build"
          />
          <div className="">
            <div className="text-lg font-semibold">Execute</div>
            <div className="font-thin text-sm mt-2 dark:text-neutral-300">
              Execute strategies and dig deeper to ensure success
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Body --> */}

      {/* <!-- Footer --> */}
      <div className="flex justify-center gap-x-8">
        <BrandLogo collapsed={false} />
      </div>
      {/* <!-- End Footer --> */}
    </div>
  );
};

export default Sidebar;
