import React, { useEffect, useState } from "react";
import NotificationAPI from "../../../api/services/Notification";

import Bell_Front_Icon from "../../../assets/3DIcons/Bell-Front.png";
import Bell_Right_Icon from "../../../assets/3DIcons/Bell-Right.png";
import { useAuth } from "../../../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const NotificationLabel = ({
  handleClick,
}: {
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  const [count, setCount] = useState(0);
  const { authed } = useSelector((state: RootState) => state.login);

  const fetchCount = async () => {
    if (!authed) return;
    try {
      const response = await NotificationAPI.unreadCount();
      if (response.status === 200) {
        setCount(response.data.data); // Update state with fetched data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   const intervalId = setInterval(fetchCount, 5000);
  //   if (!authed) clearInterval(intervalId);

  //   // Cleanup: Clear the interval when the component unmounts
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [authed]);
  return (
    <div className="hs-tooltip inline-block [--placement:bottom]">
      <button
        id="hs-pro-dnnd"
        type="button"
        className="hs-tooltip-toggle relative w-[38px] h-[38px] inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-slate-700 dark:focus:bg-slate-700"
        onClick={handleClick}
      >
        {/* <svg
          className="flex-shrink-0 w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
          <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
        </svg> */}
        {count > 0 ? (
          <>
            <img
              src={Bell_Right_Icon}
              alt="notification"
              className="w-5 opacity-60"
            />
            <span className="flex absolute top-0 end-0 -mt-1.5 -me-1.5">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75 dark:bg-primary-600"></span>
              <span className="relative min-w-[18px] min-h-[18px] inline-flex justify-center items-center text-[10px] bg-primary-500 text-white rounded-full px-1">
                {count}
              </span>
            </span>
          </>
        ) : (
          <img
            src={Bell_Front_Icon}
            alt="notification"
            className="w-5 opacity-60"
          />
        )}
      </button>
      <span
        className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 inline-block absolute invisible z-20 py-1.5 px-2.5 bg-gray-900 text-xs text-white rounded-lg dark:bg-neutral-700"
        role="tooltip"
      >
        Notifications
      </span>
    </div>
  );
};

export default NotificationLabel;
