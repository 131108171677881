import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import AnalyticsAPI from "../../../api/services/Analytics";
import { useParams } from "react-router-dom";

// const options = {
//   grid: { top: 20, right: 40, bottom: 60, left: 40 },
//   xAxis: {
//     type: "category",
//     axisLabel: { interval: 0, rotate: 30 },
//     data: [
//       "Research",
//       "Analysis",
//       "Planning",
//       "Budgeting",
//       "Assessment",
//       "Risk Analysis",
//       "Development",
//       "Testing",
//       "Marketing",
//       "Training",
//       "Launch",
//       "Evaluation",
//       "Content",
//       "Support",
//       "Cost Analysis",
//       "Post Launch",
//     ],
//   },
//   yAxis: {
//     type: "value",
//   },
//   series: [
//     {
//       data: [
//         400, 300, 350, 200, 280, 160, 79, 340, 200, 190, 234, 143, 346, 264,
//         193, 432,
//       ],
//       type: "bar",
//       smooth: true,
//     },
//   ],
//   tooltip: {
//     trigger: "axis",
//   },
// };

export default function Bar() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AnalyticsAPI.getFocusAreaChart(id);
        const data = response.data.data;
        const names = data.map((item) => item.name);
        const percentages = data.map((item) => ({ value: item.percentage, itemStyle: { color: item.metaData.color } }));

        setOptions({
          grid: { top: 20, right: 40, bottom: 120, left: 40 },
          xAxis: {
            type: "category",
            axisLabel: { interval: 0, rotate: 30 },
            data: names,
          },
          yAxis: {
            type: "value",
            min: 0,
            max: 100,
            axisLabel: {
              formatter: "{value} %",
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 20,
            },
            {
              start: 0,
              end: 20,
            },
          ],
          series: [
            {
              data: percentages,
              type: "bar",
              smooth: true,
            },
          ],
          tooltip: {
            trigger: "axis",
            formatter: "{c}%",
          },
        });

        setLoading(false);
      } catch (error) {
        console.log({ error });
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    console.log({ options });
  }, [options]);
  return (
    <div className="border rounded-lg p-4 shadow-lg dark:border-neutral-700 h-[500px]">
      <div className="inline-flex w-full border-b pb-3 text-xl font-semibold dark:border-neutral-500 dark:text-neutral-200">
        Focus area wise progress
      </div>
      <div className="w-full max-h-[500px] rounded-lg dark:text-neutral-200">
        <ReactECharts option={options} />
      </div>
    </div>
  );
}
