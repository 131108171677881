import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import ErrorIcon from "./ErrorIcon";
import ErrorMessage from "./ErrorMessage";
import { Eye, EyeOff } from "lucide-react";
import { Tooltip } from "@mui/material";

export default function Password({
  field,
  label,
  name,
  id,
  value,
  showForgotPassword,
  form: { touched, errors },
  ...props
}) {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <label
          htmlFor="password"
          className="block text-sm mb-2 dark:text-white"
        >
          {label}
        </label>
        {showForgotPassword ? (
          <Link
            className="text-sm text-primary-400 decoration-2 hover:underline font-medium focus:outline-none"
            to={ROUTES.forgetPassword}
          >
            Forgot password?
          </Link>
        ) : null}
      </div>
      <div className="relative">
        <input
          type={passwordVisible ? "text" : "password"}
          {...field}
          {...props}
          className="py-3 px-4 block w-full hover:bg-gray-100 dark:hover:bg-slate-800 border border-gray-200 rounded-lg text-sm focus:border-primary-600 focus:ring-primary-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
          required
          aria-describedby="password-error"
        />
        <Tooltip title={passwordVisible ? "Show" : "Hide"}>
          <button
            onClick={(e) => togglePasswordVisibility(e)}
            className="dark:text-white p-1 hover:bg-gray-200 dark:hover:bg-slate-800 rounded-full"
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {passwordVisible ? <Eye size={16} /> : <EyeOff size={16} />}
          </button>
        </Tooltip>
        <ErrorIcon touched={touched} errors={errors} field={field} />
      </div>
      <ErrorMessage touched={touched} errors={errors} field={field} />
    </div>
  );
}
