import { useState, useEffect } from "react";
import TeamsAPI from "../api/services/Teams";

const useOrganizationRoles = () => {
  const [options, setOptions] = useState([
    { value: "Creator" },
    { value: "Contributor" },
    { value: "Viewer" },
  ]);
  const [subscription, setSubscription] = useState({
    currentPlan: {
      subscriptionPlan: "",
      noOfCreators: 0,
      noOfContributors: 0,
      noOfViewers: 0,
      noOfPlanboard: 0,
    },
    utilize: {
      noOfCreators: 0,
      noOfContributors: 0,
      noOfViewers: 0,
      noOfPlanboard: 0,
    },
  });

  const fetchSubscription = async () => {
    try {
      const response = await TeamsAPI.subscribedPlanUsage();
      if (response.status === 200) {
        setSubscription(response.data.data);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchSubscription();
  }, []);

  useEffect(() => {
    let newOptions = [...options];
    if (
      subscription.utilize.noOfCreators ===
      subscription.currentPlan.noOfCreators
    ) {
      newOptions = newOptions.map((option) =>
        option.value === "Creator" ? { ...option, disabled: true } : option
      );
    } else
      newOptions = newOptions.map((option) =>
        option.value === "Creator" ? { ...option, disabled: false } : option
      );
    if (
      subscription.utilize.noOfContributors ===
      subscription.currentPlan.noOfContributors
    ) {
      newOptions = newOptions.map((option) =>
        option.value === "Contributor" ? { ...option, disabled: true } : option
      );
    } else
      newOptions = newOptions.map((option) =>
        option.value === "Contributor" ? { ...option, disabled: false } : option
      );
    if (
      subscription.utilize.noOfViewers === subscription.currentPlan.noOfViewers
    ) {
      newOptions = newOptions.map((option) =>
        option.value === "Viewer" ? { ...option, disabled: true } : option
      );
    } else
      newOptions = newOptions.map((option) =>
        option.value === "Viewer" ? { ...option, disabled: false } : option
      );
    // Make sure to update the state if options have changed
    if (newOptions !== options) {
      setOptions(newOptions);
    }
  }, [subscription]);

  return { options, subscription, fetchSubscription };
};

export default useOrganizationRoles;
